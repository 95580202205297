import FilteredSelect from "../common/components/LocationSelectionStep/FilteredSelect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export default function LocationSelectionStep() {
  const { t } = useTranslation();
  const locations = useSelector((state: RootState) => state.booking.useCaseLocations);

  return (
    <div className="flex flex-col justify-center px-5">
      {locations ? (
        <FilteredSelect locations={locations} />
      ) : (
        <p className="text-gray-700">{t("book_location.no_location")}</p>
      )}
    </div>
  );
}
