export const hexToRgba = (hex: string, opacity: number) => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');
  
    // Parse the hex color
    let r = parseInt(hex.substr(0, 2), 16);
    let g = parseInt(hex.substr(2, 2), 16);
    let b = parseInt(hex.substr(4, 2), 16);
  
    // Return the rgba color
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const isEmptyObject = (obj: any) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};