import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TenantLogo from '../../../../layout/components/TenantLogo';
import { Order } from '../../../../../types/order';
import CustomButton from '../../../../layout/components/CustomButton';
import { RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { calculateDuration } from '../../../../../util/date';
import { Reservation } from '../../../../../types/bookingCreation';
import moment from 'moment';
import { reservationRemoved, setAssetGroup, setSelectedGroup } from '../../../../../redux/slice/bookingCreation/bookingCreationSlice';
import { useState } from 'react';
import { useRemoveReservation } from '../../../../../redux/flows/removeReservationFlow';
import CouponDialog from '../../../common/components/ConfirmationStep/CouponDialog';
import {getEquipmentName} from "../../../../../util";

interface BookingTicketProps {
  order?: Order;
  location?: string;
  reservations: Reservation[];
  amount?: number;
}
const styles = {
  root: (theme: any) => ({
    background: theme.palette.background.paper || theme.palette.primary.main,
    color: theme.palette.text.secondary || 'white',
    borderRadius: 0.5,
    width: '350px',
    position: 'relative',
    boxShadow: theme.shadows[5],
    marginBottom: '10px',
  }),
  rightTextAlign: (theme: any) => ({
    color: theme.palette.text.secondary || 'white',
    // textAlign: 'right',
  }),
  text: (theme: any) => ({
    color: theme.palette.text.secondary || 'white',
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontStyle: theme.typography.h5.fontStyle
  }),
  boldedTitles: (theme: any) => ({
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    letterHeight: theme.typography.h2.letterHeight,
    letterSpacing: theme.typography.h2.letterSpacing,
    fontStyle: theme.typography.h5.fontStyle
  }),
  itemPrice: (theme: any) => ({
  }),
  headerLogo: (theme: any) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    marginTop: '25px',
    paddingLeft: '15px',
    paddingRight: '3px',
    [theme.breakpoints.down('md')]: {
      height: '33px',
    },
  }),
  ticketDot: (theme: any) => ({
    width: '5px',
    height: '10px',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: '29%',
  }),
  ticketDotLeft: (theme: any) => ({
    ...styles.ticketDot(theme),
    left: 0,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  }),
  ticketDotRight: (theme: any) => ({
    ...styles.ticketDot(theme),
    right: 0,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  }),
  ticketClass: {
    width: '100%',
    padding: '0px 12px',
    margin: '4px 0px',
    position: 'relative',
    borderBottomColor: 'transparent',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '7%',
      width: '86%',
      borderBottom: '1px solid gray',
    },
  },
  ticketcontent: {
    paddingRight: '12px',
    paddingLeft: '12px !important',
    paddingTop: '5px !important',
  },
  itemClass: {
    position: 'relative',
    paddingTop: '3px',
    '&:not(:last-child)': {
      borderBottom: '1px solid gray',
    },
  },
  bottomTicketClass: {
    width: '100%',
    padding: '0px 12px',
    margin: '0px 0px',
  },
  button: {
    width: { xs: '60%', sm: '70%', md: '60%' },
    height: { xs: '50px', sm: '60px', md: '70px' },
    marginLeft: { xs: '5px', sm: '10px' },
    marginTop: { xs: '2px', sm: '5px' },
  }
};

type Coupon = {
  code: string
  amount: number
}


const BookingTicket = (props: BookingTicketProps) => {
  const {
    order,
    location,
    reservations,
    amount,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const removeReservation = useRemoveReservation();
  const tenantConfig = useSelector((state: RootState) => state.tenantConfig.config);
  const selectedLocation = useSelector((state: RootState) => state.bookingCreation.selectedLocation);
  const equipments = useSelector((state: RootState) =>
    selectedLocation ? state.bookingCreation.groupsByLocation[selectedLocation.id] : undefined
  );
  const recipientData = useSelector((state: RootState) => state.bookingCreation.recipientData);
  const [couponDialogOpen, setCouponDialogOpen] = useState<boolean>(false);

  const findEquipmentName = (groupId: string) => {
    if (!equipments) return '';
    const targetGroup = equipments.find((group: any) => group.id === groupId);
    if (targetGroup) {
      return getEquipmentName(targetGroup.name, targetGroup?.langNames, "title");
    }

    return ''; // Return empty if no matching group is found
  };

  const calculateTotalPrice = (reservations: Reservation[]): number => {
    return reservations.reduce((total, reservation) => {
      let price = reservation.payment.requested_amount ?? reservation.reservationItems[0].price;

      if (typeof price === "string") {
        price = parseFloat(price);
      }

      if (isNaN(price)) {
        console.warn(`Invalid price detected: ${price}`);
        return total;
      }

      return total + price
    }, 0);
  };

  const findCoupons = (reservations: Reservation[]) => {
    const foundCoupons: Coupon[] = []

    for (const reservation of reservations) {
      const code = reservation.payment.voucher?.code;
      const amount = parseFloat((reservation.reservationItems[0].price - reservation.payment.requested_amount!).toFixed(2))

      if (code && amount) {
        foundCoupons.push({ code, amount });
      }
    }

    return foundCoupons
  }

  function consolidateCoupons(coupons: Coupon[]): Coupon[] {
    const couponMap = new Map<string, number>();

    coupons.forEach(({ code, amount }) => {
      const currentAmount = parseFloat((couponMap.get(code) || 0).toFixed(2));
      const formattedAmount = parseFloat((currentAmount + parseFloat(amount.toFixed(2))).toFixed(2));
      couponMap.set(code, formattedAmount)
    }
    );

    return Array.from(couponMap, ([code, amount]) => ({ code, amount }));
  }

  const totalPrice = calculateTotalPrice(reservations);

  const handleDeleteReservation = async (reservationId: string) => {
    dispatch(reservationRemoved({ reservationId }));
    await removeReservation(reservationId);
    // Update actual selected Group
    if (equipments && equipments.length > 0) {
      dispatch(setSelectedGroup(equipments[equipments?.length - 1]))
      dispatch(setAssetGroup({ id: equipments[equipments?.length - 1].id }))
    }
  }

  const coupons = findCoupons(reservations);

  const showPricingInformation = !tenantConfig.paymentProvider?.includes('no_payment');
  const shwowCouponButton = tenantConfig.allowCoupons && coupons.length === 0;

  const consolidatedCoupons = consolidateCoupons(coupons);

  // Ticket view after placing the Order ==> we retrieve the location
  //   const retrievedLocation = useSelector(Selector.Location.makeLocation(order?.locationId ?? ''));
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box sx={styles.root}>
        <Box sx={styles.ticketDotLeft} />
        <Grid container spacing={2} direction="column">
          <Grid item container justifyContent="flex-start" direction="row">
            <Box sx={styles.headerLogo}>
              <TenantLogo style={{ width: "60%", height: "60%" }} />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={styles.ticketClass} spacing={2} direction="column">
          <Grid item justifyContent="space-between" direction="row" sx={styles.ticketcontent}>
            <Grid item>
              <Typography sx={styles.text}>{t('orders.ticket_overview.location')}</Typography>
              <Typography sx={styles.boldedTitles}>
                {/* <strong>{order ? retrievedLocation?.name : location}</strong> */}
                <strong>{location}</strong>
              </Typography>
            </Grid>
            <Grid item sx={{ paddingTop: '5px' }}>
              <Typography sx={styles.text}>{t('orders.ticket_overview.recipient_details')}</Typography>
              <Typography sx={styles.boldedTitles}>
                {/* <strong>{order ? retrievedLocation?.name : location}</strong> */}
                <strong>{recipientData.firstName} {recipientData.lastName}</strong>
              </Typography>
              <Typography sx={styles.boldedTitles}>
                {/* <strong>{order ? retrievedLocation?.name : location}</strong> */}
                <strong>{recipientData.email}</strong>
              </Typography>
              {
                recipientData && recipientData.phone !== '+' &&
                <Typography sx={styles.boldedTitles}>
                  <strong>{recipientData.phone}</strong>
                </Typography>
              }
            </Grid>
            <Typography sx={styles.text} style={{ paddingTop: '5px', marginBottom: '-3px' }}>
              {t('orders.ticket_overview.booked_item')}
            </Typography>
            {reservations.map((reservation, index) => {
              // Extract start and end dates from reservationItems
              const reservationStartDate = moment(reservation.reservationItems[0].fromDate);
              const reservationEndDate = moment(reservation.reservationItems[0].toDate);
              const equipmentName = findEquipmentName(reservation.reservationItems[0].groupId);
              const price = reservation.reservationItems[0].price;

              // Assume amount, equipment, price, and tenantConfig are available in your context or passed as props
              return (
                <Grid key={index} sx={styles.itemClass} item justifyContent="flex-start">
                  <Typography sx={styles.boldedTitles} style={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Box>
                        <strong>
                          {amount}x {t('orders.ticket_overview.confirmation_step.locker')} - {t('orders.ticket_overview.confirmation_step.size')} {equipmentName}
                        </strong>
                      </Box>
                      <Box>
                        <strong>
                          {price}
                          {tenantConfig.currencySymbole}
                        </strong>
                      </Box>
                    </Box>
                  </Typography>
                  <Grid item xs>
                    <Box id="start-time">{reservationStartDate.format(t('formats.date_time'))}</Box>
                  </Grid>
                  <Grid item xs>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', direction: 'row', alignItems: 'center' }}>
                      <Box id="end-time">{reservationEndDate.format(t('formats.date_time'))}</Box>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>


        <Grid container sx={styles.bottomTicketClass} spacing={2} direction="column">
          <Grid item justifyContent="flex-start" sx={styles.ticketcontent}>
            {
              consolidatedCoupons.map((coupon) => (
                <Typography sx={styles.boldedTitles} style={{ display: 'flex', }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography sx={styles.text}>{t('book_location.confirmation.coupon_item')} ({coupon.code})</Typography>
                    <Box>
                      <strong>-{coupon.amount}{tenantConfig.currencySymbole}</strong>
                    </Box>
                  </Box>
                </Typography>
              ))
            }

            <Typography sx={styles.boldedTitles} style={{ display: 'flex', }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography sx={styles.text}>{t('book_location.confirmation.total_items')}</Typography>
                <Box>
                  {/* <strong>{parseFloat(totalPrice.toFixed(2))}{tenantConfig.currencySymbole}</strong> */}
                  <strong>{totalPrice}{tenantConfig.currencySymbole}</strong>
                </Box>
              </Box>
            </Typography>
          </Grid>
          {shwowCouponButton && (
            <Box sx={styles.button}>
              <CustomButton
                style={{ width: '100%', height: '80%' }}
                isButtonDark={true}
                fullWidth={true}
                onClick={() => setCouponDialogOpen(true)}
                fontSize={"18px"}
                children={t('orders.ticket_overview.button')}
              />
            </Box>
          )}
          <h5 className='mb-8 ml-3 text-sm font-normal'>
            {t('book_location.confirmation.tax_information', {
              taxRate: tenantConfig?.taxRate,
            })}
          </h5>
        </Grid>
        <Box sx={styles.ticketDotRight} />
      </Box>
      <CouponDialog
        open={couponDialogOpen}
        onClose={() => setCouponDialogOpen(false)}
        content={undefined} />


    </Box>
  );
};
export default BookingTicket;
