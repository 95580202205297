import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Apartment,
  ApartmentDetails,
  ApartmentRentalState,
  MarkerData,
} from "../../../types/apartments";

const initialState: ApartmentRentalState = {
  locationApartmentList: [],
  selectedApartment: null,
  step: 0,
  nextEnabled: false,
  isMapOpen: false,
  apartmentDetails: null,
  numberOfGuest: 1,
  selectedMarker: null,
};

const apartmentRentalSlice = createSlice({
  name: "apartmentRental",
  initialState,
  reducers: {
    setAllApartment: (state, action: PayloadAction<Apartment[]>) => {
      state.locationApartmentList = action.payload;
    },
    setIsMapOpen: (state, action: PayloadAction<boolean>) => {
      state.isMapOpen = action.payload;
    },
    setSelectedApartment: (state, action: PayloadAction<Apartment>) => {
      state.selectedApartment = action.payload;
    },
    navigatedToPreviousStep: (state) => {
      if (state.isMapOpen) {
        state.isMapOpen = false;
      }
      state.step -= 1;
    },
    setNextButtonClick: (state) => {
      state.step += 1;
    },
    setApartmentDetails: (state, action: PayloadAction<ApartmentDetails>) => {
      state.apartmentDetails = action.payload;
    },
    setNumberOfGuest: (state, action: PayloadAction<number>) => {
      state.numberOfGuest = action.payload;
    },
    setSelectedMarker: (state, action: PayloadAction<MarkerData>) => {
      state.selectedMarker = action.payload;
    },
    sortApartment: (state, action: PayloadAction<"ASC" | "DESC">) => {
      const sortType = action.payload;
      if (sortType === "ASC") {
        state.locationApartmentList.sort((a, b) => a.price - b.price);
      } else {
        state.locationApartmentList.sort((a, b) => b.price - a.price);
      }
    },
    bookingCreationCompleted: () => initialState,
  },
});

export const {
  setAllApartment,
  navigatedToPreviousStep,
  setSelectedMarker,
  setSelectedApartment,
  setNextButtonClick,
  setIsMapOpen,
  setApartmentDetails,
  setNumberOfGuest,
  bookingCreationCompleted,
  sortApartment,
} = apartmentRentalSlice.actions;
export default apartmentRentalSlice.reducer;
