import React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TenantLogo from './TenantLogo';
import { palette } from '../../../tenants/kolula/layout/palette';

const styles = {
    logo: {
        width: '200px',
        margin: '0 auto',
        // marginTop: '20px',
        // Use the theme's breakpoints
        '@media (min-width:1200px)': {
            width: '300px',
        },
    },
};

interface MainLayoutProps {
    children: React.ReactNode;
    handleClose: () => void;
    handleClickBack: () => void;
    hasBackButton?: boolean
}

const BookingDialogLayout = (props: MainLayoutProps) => {
    // Custom CSS for hiding scrollbar
    const hideScrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        overflowY: 'scroll', // Ensure vertical scrolling is enabled
    };

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <AppBar position="fixed" sx={{ boxShadow: 'none'}}>
                <Toolbar className='items-center justify-between w-full bg-white'>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <ArrowBackIosOutlinedIcon  />
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Back
                        </Typography>
                    </IconButton>
                    <Box sx={styles.logo}>
                        <TenantLogo />
                    </Box>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <CancelOutlinedIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                sx={{
                    ...hideScrollbarStyles,
                    width: '100%',
                    height: 'calc(100vh - 64px)', // Adjust based on AppBar height
                    marginTop: '64px', // Match AppBar height
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
};

export default BookingDialogLayout;
