import React from 'react';

interface BookingIconProps {
  color?: string;
  fill?: string;
  size?: number;
  isActive?: boolean;
}

export const BookingIcon: React.FC<BookingIconProps> = ({ 
  color = '#666666',
  fill = 'none',
  size = 32 
}) => {
  return (
    <svg 
      width={size} 
      height={size} 
      viewBox="0 0 32 32" 
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M22 8V9M22 13V14M22 18V19M22 23V24M10 17H17M10 20H14M4.5 7C3.67157 7 3 7.67157 3 8.5V12.5351C4.1956 13.2267 5 14.5194 5 16C5 17.4806 4.1956 18.7733 3 19.4649V23.5C3 24.3284 3.67157 25 4.5 25H27.5C28.3284 25 29 24.3284 29 23.5V19.4649C27.8044 18.7733 27 17.4806 27 16C27 14.5194 27.8044 13.2267 29 12.5351V8.5C29 7.67157 28.3284 7 27.5 7H4.5Z" 
        stroke={color} 
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
