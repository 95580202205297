import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";
import BookLocationHeader from "../../common/util/BookLocationHeader";
import TenantLogo from "../../../layout/components/TenantLogo";
import GeneralDialog from "../../../common/GeneralDialog";
import { Box } from "@mui/material";
import { RootState } from "../../../../redux/store";
import {
  bookingCreationCompleted,
  setUseCase,
} from "../../../../redux/slice/bookingCreation/bookingCreationSlice";
import { styled } from "@mui/system";
import {
  senderRetrievalFlowChanged,
  senderRetrievalStepChanged,
} from "../../../../redux/slice/senderRetrieval/senderRetrievalSlice";
import LockerDetails from "./LockerDetails";
import LockerOpen from "./LockerOpen";
import SuccessMessage from "./SuccessMessage";
import routes from "../../../../routes/routes";
import SenderRetrievalNavigateBackAction from "../../common/util/SenderRetrievalNavigateBackAction";

export enum SenderRetrievalSteps {
  lockerDetails,
  lockerOpen,
  successMessage,
}

const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  color: "white",
  height: "100%",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.default,
  paddingBottom: `calc(${theme.spacing(1)}px + env(safe-area-inset-bottom, 0))`,
  display: "flex",
  flexDirection: "column",
}));

const HeaderWrapper = styled(Box)({
  position: "relative",
  marginTop: "20px",
});

const StepWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "100%",
  maxWidth: 1000,
  margin: "0 auto",
  overflowY: "auto",
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(6),
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  width: "150px",
  marginTop: theme.spacing(1),
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
}));

const BackButton = styled(Box)({
  boxSizing: "border-box",
  marginRight: "auto",
});

export const CancelButton = styled(Box)({
  boxSizing: "border-box",
  marginLeft: "auto",
});

const SenderRetrievalPage = () => {
  const stepWrapperRef = useRef<HTMLElement>();
  const [pageDialogOpen, setPageDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const step = useSelector((state: RootState) => state.senderRetrieval.step);

  useEffect(() => {
    stepWrapperRef.current = document.getElementById(
      "SenderRetrievalProcessStepWrapper"
    )!;

    dispatch(
      senderRetrievalStepChanged({
        step: SenderRetrievalSteps.lockerDetails,
        nextEnabled: true,
        // nextEnabled: !!selectedLocation,
      })
    );

    // Set the usecase
    dispatch(setUseCase({ useCase: "p2p" }));

    // Set the bookingFlow
    dispatch(
      senderRetrievalFlowChanged({
        senderRetrievalFlow: ["lockerDetails", "lockerOpen", "successMessage"],
      })
    );
  }, []);

  const headerText = useMemo(() => {
    switch (step) {
      case SenderRetrievalSteps.lockerDetails:
        return t("senderRetrieval.lockerDetails.header_text");
      case SenderRetrievalSteps.lockerOpen:
        return t("senderRetrieval.lockerOpen.header_text");
      case SenderRetrievalSteps.successMessage:
        return "";
      default:
        return "";
    }
  }, [step]);

  const isLoading = () => {
    if (step === SenderRetrievalSteps.lockerDetails) {
      return false;
    }

    return false;
  };

  const closePage = () => {
    navigate(routes.home);
    dispatch(bookingCreationCompleted());
  };

  const isLastStep = step === SenderRetrievalSteps.successMessage;
  return (
    <Root>
      <HeaderWrapper>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <BackButton>
            {!isLastStep && <SenderRetrievalNavigateBackAction />}
          </BackButton>
          <LogoBox>
            <TenantLogo />
          </LogoBox>

          <Box className="w-3 h-10"></Box>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <BookLocationHeader text={headerText} />
        </Box>
      </HeaderWrapper>
      <StepWrapper id={"SenderRetrievalProcessStepWrapper"}>
        {step === SenderRetrievalSteps.lockerDetails && <LockerDetails />}
        {step === SenderRetrievalSteps.lockerOpen && <LockerOpen />}
        {step === SenderRetrievalSteps.successMessage && <SuccessMessage />}
      </StepWrapper>

      <LoaderOverlay open={isLoading()} />
      <GeneralDialog
        open={pageDialogOpen}
        onClose={() => setPageDialogOpen(false)}
        onContinue={closePage}
        content={t("use_order.closing_dialog.content")}
      />
    </Root>
  );
};

export default SenderRetrievalPage;
