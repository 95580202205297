import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import DoorsOverview from "../../../useOrder/steps/DoorsOverview";
import { RootState } from "../../../../redux/store";
import { setRentalStarted, setSelectedBooking } from "../../../../redux/slice/booking/bookingSlice";
import { BookingSummary } from "../../../../types/bookingCreation";
import { Rental } from "../../../../types/rental";
import { useOpenLocker } from "../../../../redux/flows/openLockerFlow";
import { setNextButtonClick } from "../../../../redux/slice/itemRetrieval/itemRetrievalSlice";

const styles = {
  container: {
    display: "flex",
    height: "60vh",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    boxSizing: "border-box",
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-around",
  },
  instructionsTextContainer: {
    backgroundColor: "#EDF0F5",
    margin: '1px 1px 1px 1px'
  },
  instructionsText: {
    fontFamily: "IBM Plex Sans, sans-serif",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
    marginTop: "16px",
    color: "#333333",
  },
  instructionsTextWrapper: {
    width: "328px",
    display: "flex",
    flexDirection: "column",
    margin: '64px auto',
    "@media (min-width: 1280px)": {
      width: "328px",
    },
  },
  doorsOverviewWrapper: {
    display: "flex",
    flexDirection: 'column',
    height: "350px",
    textAlign: 'left'
  },
  buttonContainer: {
    position: "fixed",
    bottom: "16px",
    left: "0",
    right: "0",
    display: "flex",
    justifyContent: "center",
    padding: "0 16px",
  },
};

function LockerDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pickupDetailsResponseData = useSelector(
    (state: RootState) => state.itemRetrieval.pickupDetailsResponseData
  );
  const rental = pickupDetailsResponseData?.items[0].rental ?? undefined;
  const rentalFinished = useSelector(
    (state: RootState) => state.booking.rentalFinished
  );

  const [minutesSinceOrderStarted, setMinutesSinceOrderStarted] =
    useState<number>(0);

  const openLocker = useOpenLocker(rental as unknown as Rental);


  useEffect(() => {
    console.log("pickupDetailsResponseData", pickupDetailsResponseData);

    dispatch(setSelectedBooking((pickupDetailsResponseData as unknown) as BookingSummary));
  })


  const onClickRetrieveItem = async () => {
    const orderRental = pickupDetailsResponseData?.items[0].rental;

    if (!rental && orderRental) {
      dispatch(setRentalStarted({ rental: (orderRental as unknown as Rental) }));
    }

    const depotId = orderRental?.doors[0].depotId;
    const door = orderRental?.doors[0].door;

    if (depotId && door) {
      await openLocker();
    }
  };


  const handleClickRetrieveItem = async () => {
    await onClickRetrieveItem();
    dispatch(setNextButtonClick());
  }

  const [finishOrderDialogOpen, setFinishOrderDialogOpen] =
    useState<boolean>(false);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Box sx={styles.doorsOverviewWrapper}>
          <DoorsOverview
            rental={pickupDetailsResponseData?.items[0].rental}
            orderFinished={rentalFinished}
            finishOrderDialogOpen={finishOrderDialogOpen}
            minutesSinceOrderStarted={minutesSinceOrderStarted}
            onCloseFinishOrderDialog={() => setFinishOrderDialogOpen(false)}
          />
        </Box>
        <Box
          sx={styles.instructionsTextWrapper}
          className="p-2 rounded-md bg-kolula-appLightBlue2"
        >
          <p className="text-kolula-itemListBorder">
            <span className="font-bold">
              {t("itemRetrieval.lockerDetails.nb")}:{" "}
            </span>
            {t("itemRetrieval.lockerDetails.note")}
          </p>
        </Box>
      </Box>

      {/* <div className="fixed flex justify-center w-full px-5 bottom-16"> */}
      <Box sx={styles.buttonContainer}>
        <CustomButton
          isButtonDark={true}
          // isButtonDark={bookingSummary?.length > 0 ? false : true}
          fullWidth={true}
          onClick={handleClickRetrieveItem}
          isOutlined={true}
          children={t("itemRetrieval.lockerDetails.next_button_title")}
        />
      </Box>
    </Box>
  )
}

export default LockerDetails