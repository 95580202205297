import React from 'react';
import BookingSummary from './BookingSummary';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const BookingOverview = () => {
  const apartmentDetails = useSelector((state: RootState) => state.apartment.apartmentDetails);
  const numberOfGuests = useSelector((state: RootState) => state.apartment.numberOfGuest);
  
  return (
    <BookingSummary
      roomImage={apartmentDetails?.additionalImages[0] ?? ''}
      roomTitle={apartmentDetails?.name ?? ''}
      address={apartmentDetails?.Group.location.address.street ?? ''}
      pricePerNight={
        apartmentDetails?.Group.pricecategory?.pricescale.find(
          (item: any) => item.durationInMinutes === 1440
        )?.price ?? 0
      }
      dates={{
        start: "Feb 08",
        end: "Feb 10"
      }}
      numberOfGuests={numberOfGuests ?? 1}
      additionalServices={apartmentDetails?.assetAdditionalServices ?? []}
    />
  )
};

export default BookingOverview;
