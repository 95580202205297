import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetApartmentDetailsQuery } from "../../../api/apartment/apartmentApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setApartmentDetails } from "../../../redux/slice/apartmentRental/apartmentRentalSlice";
import { setAssetGroup } from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import ExpandIcon from "./../../../assets/icons/expand_icon.png";
import FullLockerView from "../common/components/locationFromBottomBar/FullLockerView";
import LoaderOverlay from "../../layout/components/LoaderOverlay";

const ApartmentOverview = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { selectedApartment } = useSelector(
    (state: RootState) => state.apartment
  );

  const { data, isLoading } = useGetApartmentDetailsQuery({
    apartmentId: selectedApartment?.assetId ?? "",
  });

  const handleOpenImage = (image: string) => {
    setSelectedImage(image);
    setOpenImage(true);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === (data?.additionalImages?.length ?? 0) - 1
        ? 0
        : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0
        ? (data?.additionalImages?.length ?? 0) - 1
        : prevIndex - 1
    );
  };

  useEffect(() => {
    if (data) {
      dispatch(setApartmentDetails(data));
      dispatch(setAssetGroup({ id: data.groupId }));
    }
  }, [data]);

  useEffect(() => {
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";

    // Cleanup function to restore scrolling when component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  if (isLoading) {
    return <LoaderOverlay open={isLoading} />;
  }

  return (
    <div className="max-w-full mx-auto h-screen flex flex-col overflow-hidden p-4">
      {/* Image Carousel */}
      <div className="relative w-full overflow-hidden rounded-lg border border-gray-200">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}
        >
          {data?.additionalImages?.map((image, index) => (
            <div key={index} className="min-w-full relative">
              <img
                src={image}
                alt={`Accommodation view ${index + 1}`}
                className="w-full h-64 object-cover sm:h-80"
              />
              <button
                onClick={() => handleOpenImage(image)}
                className="absolute top-4 right-4 bg-black bg-opacity-50 p-2 rounded-md flex items-center justify-center"
              >
                <img src={ExpandIcon} alt="Expand" className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
        <button
          onClick={handlePrevImage}
          className="absolute top-1/2 left-4 transform -translate-y-1/2  bg-opacity-50 p-2 rounded-full"
        >
          &#10094;
        </button>
        <button
          onClick={handleNextImage}
          className="absolute top-1/2 right-4 transform -translate-y-1/2  bg-opacity-50 p-2 rounded-full"
        >
          &#10095;
        </button>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2 bg-[#2B2B2B42] rounded-lg p-2">
          {data?.additionalImages?.map((_, index) => (
            <span
              key={index}
              className={`h-2 w-2 rounded-full cursor-pointer ${
                index === currentImageIndex ? "bg-white" : "bg-gray-400"
              }`}
              onClick={() => setCurrentImageIndex(index)}
            ></span>
          ))}
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto mt-4 text-black">
        <h1 className="text-xl font-semibold mb-4">{data?.name}</h1>

        <div className="flex justify-between items-center mb-6">
          <div className="flex gap-2 text-sm text-gray-600">
            <span>
              {data?.accomodationdetail?.guests} {t("apartment.guests")}
            </span>
            <span>
              • {data?.accomodationdetail?.bedrooms} {t("apartment.bedrooms")}
            </span>
            <span>
              • {data?.accomodationdetail?.beds} {t("apartment.beds")}
            </span>
            <span>
              • {data?.accomodationdetail?.baths} {t("apartment.baths")}
            </span>
          </div>
          <div className="text-right">
            <span className="text-teal-500 font-medium">
              {data?.Group?.pricecategory?.pricescale[0].price}€
            </span>
            <span className="text-gray-600 font-medium">
              /{t("apartment.night")}
            </span>
          </div>
        </div>

        <h2 className="text-lg font-semibold mb-2">
          {t("apartment.facility_description_title")}
        </h2>
        <p className="text-sm text-gray-600 mb-6">
          {data?.accomodationdetail?.facilityDescription}
        </p>

        <h2 className="text-lg font-semibold mb-2">
          {t("apartment.important_note")}
        </h2>
        <p className="text-sm text-gray-600 mb-6">
          {data?.accomodationdetail?.importantNote}
        </p>

        <h2 className="text-lg font-semibold mb-2">
          {t("apartment.location")}
        </h2>
        <div className="w-full h-48 rounded-lg overflow-hidden border border-gray-200">
          <iframe
            title="Property Location"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            src={`https://www.google.com/maps?q=${data?.Group?.location?.address?.geoLat},${data?.Group?.location?.address?.geoLng}&z=15&output=embed`}
            allowFullScreen
          />
        </div>
      </div>

      {/* Full Image View Modal */}
      {selectedImage && openImage && (
        <FullLockerView
          setOpenImage={setOpenImage}
          image={selectedImage}
          openImage={openImage}
        />
      )}
    </div>
  );
};

export default ApartmentOverview;
