import { useVerifyLocationCodeMutation } from "../../api/receiver/receiverApiSlice";
import { enqueueNotification } from "../../redux/slice/notification/notificationSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setNextButtonClick } from "../../redux/slice/itemRetrieval/itemRetrievalSlice";

const useVerifyLocationCode = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pickupDetailsResponseData, receivedQRCodeNumber } = useSelector(
    (state: RootState) => state.itemRetrieval
  );

  const [verifyLocationCodeMutation, { isLoading }] =
    useVerifyLocationCodeMutation();

  const handleClickRetrieveItem = () => {
    dispatch(setNextButtonClick());
  };

  const handleVerifyLocationCode = async () => {
    if (!pickupDetailsResponseData || !pickupDetailsResponseData.locationId) {
      throw new Error("Invalid pickup details");
    }

    const submittedData = {
      locationId: pickupDetailsResponseData.locationId,
      code: receivedQRCodeNumber,
    };

    try {
      const response = await verifyLocationCodeMutation(submittedData);

      if (response.error) {
        dispatch(
          enqueueNotification({
            severity: "error",
            message: t("itemRetrieval.confirmLocation.notification.error"),
          })
        );
      } else if (response.data) {
        dispatch(
          enqueueNotification({
            severity: "success",
            message: t("itemRetrieval.confirmLocation.notification.success"),
          })
        );
        handleClickRetrieveItem();
      }
    } catch (error) {
      console.error("Error verifying location code:", error);
      // Handle error case
    }
  };

  return { handleVerifyLocationCode, isLoading };
};

export default useVerifyLocationCode;
