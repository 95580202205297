import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setHasSelectedLocation } from "../../redux/slice/bookingCreation/bookingCreationSlice";
import { RootState } from "../../redux/store";
import FilteredSelect from "../bookLocation/common/components/LocationSelectionStep/FilteredSelect";
import LocationSelectionDetails from "../bookLocation/common/locationSelection/LocationSelectionDetails";
import Header from "../layout/components/Header";
import TenantLogo from "../layout/components/TenantLogo";
import LocationListComponent from "./LocationListComponent";

const StyledText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2.25),
  width: `calc(100% - ${theme.spacing(4.5)}px)`,
  paddingTop: theme.spacing(10),
  fontSize: theme.typography.h1.fontSize,
  textAlign: "center",
  textTransform: "none",
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(10),
  },
}));

export default function LocationSelectionPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasSelectedLocation, selectedLocation } = useSelector(
    (state: RootState) => state.bookingCreation
  );
  const equipments = useSelector((state: RootState) =>
    selectedLocation
      ? state.bookingCreation.groupsByLocation[selectedLocation.id]
      : undefined
  );
  const locations = useSelector((state: RootState) => state.booking.locations);

  return (
    <div className="flex flex-col justify-center">
      <Header>
        <TenantLogo />
      </Header>
      <h1 className="mb-6 text-center text-2xl font-semibold -tracking-[0.0225rem]">{t("book_location.select_location")}</h1>

      {hasSelectedLocation && equipments ? (
        <LocationListComponent equipments={equipments} />
      ) : (
        <div className="flex flex-col items-center justify-between px-5 pb-10">
          {/* <div className="flex flex-col items-center justify-between px-5 pb-40"> */}
          <div
            className={`w-full max-w-[1000px] ${
              !hasSelectedLocation && "h-96"
            }`}
          >
            {locations ? (
              <FilteredSelect locations={locations} />
            ) : (
              <p className="text-gray-700">{t("book_location.no_location")}</p>
            )}
          </div>
          {selectedLocation && (
            <div className="overflow-scroll max-h-min no-scrollbar">
              <LocationSelectionDetails selectedLocation={selectedLocation} />
            </div>
          )}

          {/* <div className="fixed bottom-0 flex justify-center w-full px-5 pb-16">
            <CustomButton
              isButtonDark={true}
              fullWidth={true}
              onClick={handleHasSelectedLocation}
              children={t("book_location.next")}
            />
          </div> */}
        </div>
      )}
    </div>
  );
}
