import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FullScreenDialog from "../../common/FullScreenDialog";
import { useGetLocationListQuery } from "../../../api/locations/locationsApiSlice";
import { setUseCaseLocations } from "../../../redux/slice/booking/bookingSlice";
import { useDispatch } from "react-redux";
import TemporaryStorageNewBookingPage from "./newBooking/TemporaryStorageNewBookingPage";

interface BookLocationDialogProps {
  open: boolean;
  locationId?: string;
}

const TempStorageBookLocationDialog = (props: BookLocationDialogProps) => {
  const location = useLocation();
  const { returnPage } = location.state || {};

  const dispatch = useDispatch();

  // Note: It's currently important that BookLocationPage is only mounted when location is available
  // due to body scroll locking

  const { data: locations } = useGetLocationListQuery("storage");

  useEffect(() => {
    if (locations) {
      dispatch(setUseCaseLocations(locations));
    }
  }, [locations]);

  return (
    <FullScreenDialog open={props.open}>
      {<TemporaryStorageNewBookingPage returnPage={returnPage} />}
    </FullScreenDialog>
  );
};

export { TempStorageBookLocationDialog };
