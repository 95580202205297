import React from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { Box, IconButton, Typography } from '@mui/material';

const styles = {
  root: (theme: any) => ({
    display: 'inline-flex', // Use inline-flex to allow content to dictate width
    alignItems: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    position: 'relative',
    color: 'white',
    borderRadius: 0.5,
    height: '100%',
    maxWidth: '100%', // Optional: Ensures the box does not overflow its parent
  }),
  contentSpacing: (theme: any) => ({
    marginRight: theme.spacing(2),
    color: 'white',
  }),
  primary: (theme: any) => ({
    backgroundColor: theme.palette.primary.main,
  }),
  secondary: (theme: any) => ({
    backgroundColor: theme.palette.secondary.main,
  }),
  info: (theme: any) => ({
    backgroundColor: theme.palette.info.main,
  }),
  warning: (theme: any) => ({
    backgroundColor: theme.palette.warning.main,
  }),
  error: (theme: any) => ({
    backgroundColor: theme.palette.error.main,
  }),
  success: (theme: any) => ({
    backgroundColor: theme.palette.success.main,
  }),
  icon: {
    marginRight: '8px', // Add some spacing between the icon and the text
  },
  closeButton: {
    marginLeft: 'auto', // Push the button to the far right
  },
  closeButtonIcon: (theme: any) => ({
    color: 'white',
  }),
};

interface InfoBoxProps {
  variant: 'primary' | 'secondary' | 'info' | 'warning' | 'error' | 'success';
  text: string;
  onClose?: () => void;
}

const InfoBox = (props: InfoBoxProps) => {
  const IconComponent = (() => {
    switch (props.variant) {
      case 'info':
        return InfoIcon;
      case 'warning':
        return WarningIcon;
      case 'error':
        return ErrorIcon;
      case 'success':
        return SuccessIcon;
      default:
        return null;
    }
  })();

  return (
    <Box
      sx={(theme) => ({
        ...styles.root(theme),
        ...styles[props.variant](theme),
      })}
    >
      {/* Icon on the left */}
      {IconComponent && <IconComponent sx={styles.icon} />}

      {/* Text in the middle */}
      <Typography sx={(theme) => ({ ...styles.contentSpacing(theme) })}>
        {props.text}
      </Typography>

      {/* Cancel button on the right */}
      {props.onClose && (
        <IconButton sx={styles.closeButton} onClick={props.onClose} size="small">
          <CancelIcon fontSize="small" sx={styles.closeButtonIcon} />
        </IconButton>
      )}
    </Box>
  );
};

export default InfoBox;
