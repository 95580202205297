import React from 'react'
import FullScreenDialog from '../common/FullScreenDialog';
import MoreToComeContent from './MoreToComeContent';


interface MoreToComeDialogProps {
  open: boolean;
}

const MoreToComeDialog = (props: MoreToComeDialogProps) => {
  return (
    <FullScreenDialog open={props.open}>
      {<MoreToComeContent />}
    </FullScreenDialog>
  )
}

export default MoreToComeDialog
