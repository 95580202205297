import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import { setNextButtonClick, setReceivedEmail } from "../../../../redux/slice/itemRetrieval/itemRetrievalSlice";
import { RootState } from "../../../../redux/store";
import DebouncedTextInput from "../../common/components/PersonalDataStep/DebouncedTextInput";
import { useSendEmailCodeMutation } from "../../../../api/receiver/receiverApiSlice";
import { useLocation } from "react-router";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    boxSizing: "border-box",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  instructionsText: {
    fontFamily: "IBM Plex Sans, sans-serif",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
    marginTop: "16px",
    color: "#333333"
  },
  buttonContainer: {
    position: "fixed",
    bottom: "16px",
    left: "0",
    right: "0",
    display: "flex",
    justifyContent: "center",
    padding: "0 16px",
  },
};

function EmailVerification() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { receivedEmail, isReceiverEmailValid, nextEnabled } = useSelector(
    (state: RootState) => state.itemRetrieval
  );
  const [sendEmailCode] = useSendEmailCodeMutation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const onEmailChange = (email: string) => dispatch(setReceivedEmail(email));

  const handleClickRetrieveItem = async() => {
    await sendEmailCode({token: token ?? ''});
    dispatch(setNextButtonClick());
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <DebouncedTextInput
          label={t('book_location.personal_data.email')}
          initialValue={receivedEmail}
          onChange={onEmailChange}
          valid={isReceiverEmailValid}
          autoComplete="email"
        />
        <Typography sx={styles.instructionsText}>
          {t("itemRetrieval.emailVerification.instructions_text")}
        </Typography>
      </Box>
      <Box sx={styles.buttonContainer}>
        <CustomButton
          isButtonDark
          disabled={!nextEnabled}
          fullWidth
          onClick={handleClickRetrieveItem}
          isOutlined
        >
          {t("itemRetrieval.emailVerification.next_button_title")}
        </CustomButton>
      </Box>
    </Box>
  );
}

export default EmailVerification;
