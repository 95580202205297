import { EquipmentSelectProps } from '../../../../types/baseTypes';
import { Typography } from '@mui/material';
import { convertMinutesToHours } from '../../../../util';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

function EquipmentSelect(props: EquipmentSelectProps) {
    const { t } = useTranslation();
    const { durationInMinutes, price } = props.priceCategoryInfo.priceScale[0]
    const tenantConfig = useSelector((state: RootState) => state.tenantConfig.config);
    return (
        <div className='flex justify-start w-full cursor-pointer'>
            <div className='mr-8'>
                <img className='rounded' src={props.image} alt="" width={88} height={66} />
            </div>

            <div className='' style={{ maxWidth: 'calc(100% - 96px)' }}> {/* Adjust based on your layout */}
                <Typography variant="body1" sx={{
                    display: 'inline-block', // Ensure display is set correctly
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%' // Ensure text does not exceed container width
                }}>
                    {props.name}
                </Typography>
                <Typography variant="body1">
                    {t('book_location.from')} <span className='font-bold'>{price}{tenantConfig.currencySymbole} / {convertMinutesToHours(durationInMinutes)}</span>
                </Typography>
            </div>
        </div>
    );
}

export default EquipmentSelect;
