import React, {useEffect, useRef} from "react";
import {Box, Chip, styled, Tabs} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {BookingSummary} from "../../../types/bookingCreation";
import {setSelectedServiceType} from "../../../redux/slice/booking/bookingSlice";
import {ServiceTypeSelectorOption} from "../../../types/order";
import {useTranslation} from "react-i18next";

// Get tenant from environment variable with fallback
const currentTenant = process.env.REACT_APP_TENANT?.toLowerCase() || "kolula";
interface ServiceTypeSelectorItem {
  name: string;
  value: ServiceTypeSelectorOption;
  count?: number;
}

interface ServiceTypeSelectorProps {
  initialSelected: ServiceTypeSelectorOption;
  onChange?: (value: string) => void;
  bookingSummary: BookingSummary[];
}

// Styled components
const ScrollableTabs = styled(Tabs)({
  "& .MuiTabs-scroller": {
    overflow: "auto !important",
  },
  "& .MuiTabs-flexContainer": {
    gap: "8px",
    padding: "10px 40px",
  },
  // Hide scrollbar
  "& .MuiTabs-scroller::-webkit-scrollbar": {
    display: "none",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
});
//#005475
const StyledChip = styled(Chip)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    height: "28px",
    // padding: '4px 0',
    margin: "0 8px",
    backgroundColor: selected ? theme.palette.primary.light : theme.palette.action.hover,
    "& .MuiChip-label": {
      padding: "4px 8px",
    },
    "&.MuiChip-clickable": {
      "&:hover": {
        backgroundColor: selected
          ? theme.palette.action.hover
          : theme.palette.action.hover,
      },
    },
  })
);

const CountBadge = styled(Box)(({ theme }) => ({
  padding: "4px",
  backgroundColor: theme.palette.primary.light,
  borderRadius: "4px",
  fontSize: "8px",
  fontWeight: "bold",
  color: "white",
  marginLeft: "8px",
  width: "20px",
  textAlign: "center",
}));

const ServiceTypeSelector = ({
  initialSelected,
  bookingSummary,
}: ServiceTypeSelectorProps) => {
  const { selectedServiceType } = useSelector(
    (state: RootState) => state.booking
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Define the tenant configurations
  const TENANT_SERVICES: Record<string, ServiceTypeSelectorOption[]> = {
    vonovia: [ServiceTypeSelectorOption.RENTAL, ServiceTypeSelectorOption.P2P],
    neuland: [ServiceTypeSelectorOption.APARTMENT],
    kolula: [
      ServiceTypeSelectorOption.RENTAL,
      ServiceTypeSelectorOption.STORAGE,
      ServiceTypeSelectorOption.P2P,
      ServiceTypeSelectorOption.PARCEL,
      ServiceTypeSelectorOption.APARTMENT,
    ],
    dhu: [
      ServiceTypeSelectorOption.RENTAL,
      ServiceTypeSelectorOption.STORAGE,
      ServiceTypeSelectorOption.P2P,
    ],
  };

  // Helper function to get translation key based on service type
  const getServiceTranslationKey = (
    serviceType: ServiceTypeSelectorOption
  ): string => {
    const mapping: Record<ServiceTypeSelectorOption, string> = {
      [ServiceTypeSelectorOption.ALL]: "all",
      [ServiceTypeSelectorOption.RENTAL]: "equipment_rental",
      [ServiceTypeSelectorOption.STORAGE]: "temporary_storage",
      [ServiceTypeSelectorOption.P2P]: "private_handover",
      [ServiceTypeSelectorOption.PARCEL]: "parcel_delivery",
      [ServiceTypeSelectorOption.APARTMENT]: "apartment_rental",
    };

    return mapping[serviceType] || "unknown_service"; // Fallback for unexpected values
  };

  // Create service types array based on tenant
  const serviceTypes: ServiceTypeSelectorItem[] = (
    TENANT_SERVICES[currentTenant] || []
  ).map((value) => ({
    name: t(`service_type_selector.${getServiceTranslationKey(value)}`),
    value,
  }));

  const chipRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleServiceTypeClick = (
    value: ServiceTypeSelectorOption,
    index: number
  ) => {
    dispatch(setSelectedServiceType(value));

    const selectedChip = chipRefs.current[index];
    if (selectedChip) {
      selectedChip.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "center",
      });
    }
  };

  useEffect(() => {
    dispatch(setSelectedServiceType(initialSelected));
  }, [initialSelected]);

  const getServiceTypeCount = (
    serviceType: ServiceTypeSelectorOption
  ): number => {
    if (serviceType === ServiceTypeSelectorOption.ALL) {
      return bookingSummary.length;
    }

    return bookingSummary.filter((booking) => booking.service === serviceType)
      .length;
  };

  const getServiceTypeCountDisplay = (count: number): string => {
    if (count > 29) {
      return "29+";
    }
    return count.toString();
  };

  useEffect(() => {
    const index = serviceTypes.findIndex((type) => type.value === selectedServiceType);
    if (index !== -1 && chipRefs.current[index]) {
      chipRefs.current[index]?.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  }, [selectedServiceType]);

  return (
    <Box sx={{ width: "100%", maxWidth: 380, margin: "auto" }}>
      <ScrollableTabs
        value={false}
        variant="scrollable"
        scrollButtons={false}
        aria-label="service type selector"
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          {serviceTypes.map((serviceType, index) => (
            <StyledChip
              key={serviceType.value}
              selected={selectedServiceType === serviceType.value}
              variant="filled"
              ref={(el) => (chipRefs.current[index] = el)}
              onClick={() => handleServiceTypeClick(serviceType.value, index)}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className={`font-sm ${
                      (currentTenant === "vonovia" || (selectedServiceType === serviceType.value && currentTenant === "dhu")) ? "text-white" : ""
                    }`}
                  >
                    {serviceType.name}
                  </div>
                  {getServiceTypeCount(serviceType.value) > 0 && (
                    <CountBadge>
                      {getServiceTypeCountDisplay(
                        getServiceTypeCount(serviceType.value)
                      )}
                    </CountBadge>
                  )}
                </Box>
              }
            />
          ))}
        </Box>
      </ScrollableTabs>
    </Box>
  );
};

export default ServiceTypeSelector;
