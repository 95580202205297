import React, { useEffect } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { enqueueNotification } from "../../../../../redux/slice/notification/notificationSlice";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { theme } from "../../../../../material-ui/theme";
import { palette } from "../../../../../tenants/vonovia/layout/palette";

// Set Monday as the first day of the week
moment.updateLocale("en", { week: { dow: 1 } });
moment.updateLocale("de", { week: { dow: 1 } });

const StyledPickerInput = styled(TextField)(({ theme, value }) => ({
  "& .MuiOutlinedInput-root": {
    height: "62px",
    "& fieldset": {
      borderColor: value
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    },
    "&:hover fieldset": {
      borderColor: value
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.light,
    },
  },
  "& .MuiInputBase-input": {
    color: theme.palette.primary.contrastText,
  },
}));

interface BookingDatePickerProps {
  value: Moment | null;
  onChange: (date: Moment | null) => void;
  shouldDisableDate: (date: Moment | null) => boolean;
  availableDates: string[];
  label: string;
}

const BookingDatePicker: React.FC<BookingDatePickerProps> = React.memo(
  (props: BookingDatePickerProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const parseDates = (dates: string[]): Moment[] =>
      dates.map((date) => moment(date, "DD.MM.YYYY"));
    const parsedDates = parseDates(props.availableDates);

    const isDateDisabled = (date: Moment | null): boolean => {
      return !parsedDates.some((parsedDate) => parsedDate.isSame(date, "day"));
    };

    useEffect(() => {
      const tenantName = process.env.REACT_APP_TENANT;

      if (tenantName) {
        import(
          `../../../../../tenants/${tenantName}/assets/styles/calendar.css`
        ).catch((error) => {
          dispatch(
            enqueueNotification({
              severity: "error",
              message: t(
                `Failed to load styles for ${tenantName}, error: ${error}`
              ),
            })
          );
        });
      } else {
        dispatch(
          enqueueNotification({
            severity: "error",
            message: t("No tenant name provided in environment variables."),
          })
        );
      }
    }, [dispatch, t]);

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
          disablePast={true}
          maxDate={moment().add(2, "month")}
          shouldDisableDate={isDateDisabled}
          value={props.value}
          onChange={props.onChange}
          format="DD MMM YYYY"
          label={props.label}
          slots={{ textField: StyledPickerInput }}
          slotProps={{
            textField: {
              variant: "outlined",
              value: props.value,
              InputLabelProps: {
                sx: {
                  color: "black",
                  opacity: 0.8,
                  marginTop: "4px",
                },
              },
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayOutlinedIcon
                      style={{ color: palette.primary.main }}
                    />
                  </InputAdornment>
                ),
              },
            },
          }}
        />
      </LocalizationProvider>
    );
  }
);

export default BookingDatePicker;
