import { TenantConfig } from "../types/tenantConfig";

const tenant = process.env.REACT_APP_TENANT as string;

export const DefaultConfig: TenantConfig = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL!,
    key: process.env.REACT_APP_API_KEY!,
  },
  gtm: {
    id: process.env.REACT_APP_GTM_ID!,
    auth: process.env.REACT_APP_GTM_AUTH!,
    preview: process.env.REACT_APP_GTM_PREVIEW!,
  },
  seo: {
    appTitle: "software for smart sharing",
    appDescription:
      "kolula ermöglicht kontaktlose Übergabe von Waren, den einfachen Zugang zu Produkten und sichereres temporäres Verwahren von Gegenständen.",
    ogTitle: "Software für smartes Sharing",
    ogDescription:
      "kolula ermöglicht kontaktlose Übergabe von Waren, den einfachen Zugang zu Produkten und sichereres temporäres Verwahren von Gegenständen.",
    ogImage:
      "https://www.kolula.com/wp-content/uploads/2024/08/branchen-uebersicht-1240x636.jpg",
    ogUrl: "https://www.kolula.com/",
  },
  languages: ["en", "de"],
  fallbackLng: "en",
  taxRate: 19,
  currency: "EUR",
  currencySymbole: "€",
  paymentProvider: ["no_payment"],

  phoneInput: {
    defaultCountry: "de",
    preferredCountries: ["de", "at", "ch", "nl", "be"],
  },

  contact: {
    phone: "+4989244183790",
    phoneFormatted: "089 244 183 790",
  },
  toolbarItems: ["locations", "orders", "info"],

  allowCoupons: true,
  allowNewsLetter: false,
  consentRequired: true,
  services: ["productRental", "p2p", "roomRental", "storage"],
  isEnableLocationVerification: true,
};

export default DefaultConfig;
