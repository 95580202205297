import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { navigatedToPreviousStep } from '../../../../redux/slice/itemRetrieval/itemRetrievalSlice';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChevronLeftIcon } from '@heroicons/react/24/solid'

interface StyledIconButtonProps {
    show: boolean;
}

const StyledIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'show',
})<StyledIconButtonProps>(({ show, theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.primary.main,
    display: show ? 'flex' : 'none',
}));


const BackText = styled(Typography)({
    color: 'black',
});

interface BookLocationNavigateBackActionProps { }

const SenderRetrievalNavigateBackAction = (props: BookLocationNavigateBackActionProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const step = useSelector((state: RootState) => state.itemRetrieval.step);

    const showBackButton = step !== 0;

    const handleBackClick = () => {
        dispatch(navigatedToPreviousStep());
    };

    return (
        <StyledIconButton show={showBackButton} onClick={handleBackClick}>
            <ChevronLeftIcon className="size-8 text-black-500" />
            <BackText>{t('general.back')}</BackText>
        </StyledIconButton>
    );
};

export default SenderRetrievalNavigateBackAction;
