import BookingSummaryCard1 from "./BookingSummaryCard1";
import SwipeableViews from "react-swipeable-views";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBooking } from "../../../../../redux/slice/booking/bookingSlice";
import { BookingSummary as BookingSummaryProps } from "../../../../../types/bookingCreation";
import { RootState } from "../../../../../redux/store";

interface Props {
  userReservation: BookingSummaryProps[];
}

function BookingSummary(props: Props) {
  const { selectedServiceType } = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();
  const selectedBooking = useSelector((state: RootState) => state.booking.selectedBooking);
  const summaryBookings = useSelector((state: RootState) => state.booking.bookingSummary);
  const rentalExpired = useSelector((state: RootState) => state.booking.rentalExpired);

  const initialActiveStep = selectedBooking
    ? summaryBookings?.findIndex((booking: any) => booking.id === selectedBooking.id)
    : 0;
  const [activeStep, setActiveStep] = useState(initialActiveStep !== -1 ? initialActiveStep : 0);

  useEffect(() => {
    if (props.userReservation.length > 0 && !selectedBooking) {
      dispatch(setSelectedBooking(props.userReservation[0]));
    }
  }, [props.userReservation, dispatch]);

  const handleChangeIndex = (index: number) => {
    setActiveStep(index);
    updateFocusedItem(index);
  };

  const handleSwitching = (index: number, type: string) => {
    console.log(`Switching to ${type === "next" ? "right" : "left"}: ${index}`);
  };

  const updateFocusedItem = (index: number) => {
    if (props.userReservation.length > index && index >= 0) {
      dispatch(setSelectedBooking(props.userReservation[index]));
    }
  };

  const [marginBottom, setMarginBottom] = useState(0);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    console.log({ userAgent });
    let marginBottom = 40; // Default marginBottom

    if (userAgent.includes("Win")) {
      marginBottom = 0;
    } else if (userAgent.includes("Mac")) {
      marginBottom = 80;
    } else if (
      userAgent.includes("X11") ||
      userAgent.includes("Linux") ||
      /Android/.test(userAgent)
    ) {
      marginBottom = 30;
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
      marginBottom = 40; // Assuming default for iOS or leave it unchanged if specific logic is not needed
    }

    setMarginBottom(marginBottom);
  }, []);

  // Reset scroll and focus the first item when selectedServiceType changes
  useEffect(() => {
    if (props.userReservation.length > 0) {
      setActiveStep(0);
      dispatch(setSelectedBooking(props.userReservation[0]));
    }
  }, [selectedServiceType, props.userReservation, dispatch]);

  return (
    <div className="flex flex-row overflow-hidden relative">
      <SwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleChangeIndex}
        onSwitching={handleSwitching}
        enableMouseEvents // Enable swiping with a mouse
        className="p-2.5 w-[380px] overflow-visible no-scrollbar"
        style={{
          padding: "0 40px",
          paddingBottom: 0,
          marginBottom: marginBottom,
          marginTop: 20,
        }}
      >
        {props.userReservation?.map((bookingItem, index) => (
          <div
            key={bookingItem.id}
            className={`w-[280px] ${
              index === activeStep ? "blur-none opacity-100" : "blur-[1px] opacity-98"
            }`}
          >
            {/* <BookingSummaryCard
              bookingSummaryData={bookingItem}
              rentalExpired={rentalExpired}
              amount={1}
            /> */}
            <BookingSummaryCard1
              bookingSummaryData={bookingItem}
              rentalExpired={rentalExpired}
              amount={1}
            />
          </div>
        ))}
      </SwipeableViews>
    </div>
  );
}

export default BookingSummary;
