import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FilteredSelect from "../../common/components/LocationSelectionStep/FilteredSelect";
import { RootState } from "../../../../redux/store";
import CustomButton from "../../../layout/components/CustomButton";
import { setNextButtonClick } from "../../../../redux/slice/temporaryStorage/temporaryStorageSlice";

export default function LocationSelectionStep() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locations = useSelector(
    (state: RootState) => state.booking.useCaseLocations
  );
  const { hasSelectedLocation } = useSelector(
    (state: RootState) => state.bookingCreation
  );

  const handleClickNext = () => {
    dispatch(setNextButtonClick());
  };
  return (
    <div className="flex flex-col justify-center mx-5 lg:mx-0">
      {locations ? (
        <FilteredSelect locations={locations} />
      ) : (
        <p className="text-gray-700">{t("book_location.no_location")}</p>
      )}

      <div className="fixed flex w-full max-w-md px-5 left-0 items-center
       justify-center right-0 bottom-4 m-auto">
        <CustomButton
          isButtonDark={true}
          fullWidth={true}
          onClick={handleClickNext}
          isOutlined={true}
          disabled={!hasSelectedLocation}
          children={t(
            "temporary_storage.new_booking.location.next_button_title"
          )}
        />
      </div>
    </div>
  );
}
