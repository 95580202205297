import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import routes from '../../routes/routes';
import { usePrevious } from '../../util/usePrevious';
import { useQueryParams } from '../../util/useQueryParams';
import LoaderOverlay from '../layout/components/LoaderOverlay';
import { useCompleteStripePayment } from '../../redux/flows/completeStripePaymentFlow';
import { bookingCreationCompleted, PaymentCompletedPayload } from '../../redux/slice/bookingCreation/bookingCreationSlice';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import CustomButton from '../layout/components/CustomButton';
import { Assets } from '../../assets/assets';
import TenantLogo from '../layout/components/TenantLogo';

const RootWrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    boxSizing: 'border-box',
    backgroundColor: 'var(--dark-background-color)',
}))

const ContentWrapper = styled(Box)(({ theme }) => ({
    maxWidth: 1000,
    margin: '0 auto',
    textAlign: 'center',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
}));

const TenantLogoWrapper = styled(Box)(({ theme }) => ({
    width: '100px',
    [theme.breakpoints.up('lg')]: {
        width: '150px',
    },
    margin: '0 auto',
}));


const StyledHeaderTypography = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(4),
}))

const StyledInfoTypography = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(3),
}))

const StripePaymentReturnPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const completeStripePayment = useCompleteStripePayment()

    const { t } = useTranslation();
    const queryParams = useQueryParams();
    const paymentCompletionLoading = useSelector((state: RootState) => state.bookingCreation.loading.paymentCompletion);
    const previousPaymentCompletionLoading = usePrevious(paymentCompletionLoading);
    const orderIds = useSelector((state: RootState) => state.bookingCreation.payment.orderIds);
    const [completionFailed, setCompletionFailed] = useState<boolean>(false);

    useEffect(() => {
        const manageCompleteStripePayment = async () => {
            const reservationIds = queryParams.get('reservationIds');

            if (typeof reservationIds !== 'string') {
                setCompletionFailed(true)
                return
            }

            const response = await completeStripePayment(reservationIds.split(',')) as PaymentCompletedPayload
            if (!response.orderIds?.length) {
                return
            }

            navigate(routes.orders)
            dispatch(bookingCreationCompleted())

            return
        }

        manageCompleteStripePayment()

    }, []);

    useEffect(() => {
        if (!paymentCompletionLoading && previousPaymentCompletionLoading) {
            if (orderIds?.length) {
                navigate(routes.orders);
                dispatch(bookingCreationCompleted());
            } else {
                setCompletionFailed(true)
            }
        }
    }, [paymentCompletionLoading, orderIds]);

    const navigateToBookings = () => {
        navigate(routes.orders);
        dispatch(bookingCreationCompleted())
    };
    return (


        <RootWrapper>
            <ContentWrapper>
                <TenantLogoWrapper>
                    <TenantLogo style={{ width: "100%", height: "100%", }} />
                </TenantLogoWrapper>

                {Boolean(orderIds) && (<>
                    <StyledHeaderTypography variant={'h1'} >
                        {t('payments.stripe.return.header')}
                    </StyledHeaderTypography>
                    <img
                        src={Assets.unDraw.transferMoney}
                        style={{ height: 160, marginTop: '40px' }}
                        alt="transferMoney"
                    />
                    <StyledInfoTypography variant={'body1'}>
                        {t('payments.stripe.return.info')}
                    </StyledInfoTypography>
                </>)}

                {completionFailed && (<>
                    <StyledHeaderTypography variant={'h1'}>
                        {t('payments.stripe.return.error_header')}
                    </StyledHeaderTypography>
                    {/* <img src={Assets.unDraw.warning} style={{ height: 160, marginTop: '40px' }} alt="warning" /> */}
                    <StyledInfoTypography variant={'body1'}>
                        {t('payments.stripe.return.error_info')}
                    </StyledInfoTypography>


                    <CustomButton
                        style={{ width: '50%', height: '100%', marginTop: '32px' }}
                        isButtonDark={true}
                        fullWidth={false}
                        onClick={navigateToBookings}
                        fontSize={"18px"}
                        children={t('payments.stripe.return.go_to_bookings')}
                    />
                </>)}
            </ContentWrapper>
            <LoaderOverlay
                open={paymentCompletionLoading}
            />
        </RootWrapper >
    );
};

export default StripePaymentReturnPage;
