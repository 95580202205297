import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import DoorsOverview from "../../../useOrder/steps/DoorsOverview";
import { setRentalStarted, setSelectedBooking } from "../../../../redux/slice/booking/bookingSlice";
import { baseStore, RootState } from "../../../../redux/store";
import { BookingSummary } from "../../../../types/bookingCreation";
import { Rental } from "../../../../types/rental";
import { useOpenLocker } from "../../../../redux/flows/openLockerFlow";
import { receiverApiSlice } from "../../../../api/receiver/receiverApiSlice";
import {
    setNextButtonClick,
    setLockerDetailsResponseData,
} from "../../../../redux/slice/senderRetrieval/senderRetrievalSlice";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";

const styles = {
    container: {
        display: "flex",
        height: "60vh",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        boxSizing: "border-box",
    },
    content: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "space-around",
    },
    instructionsTextContainer: {
        backgroundColor: "#EDF0F5",
        margin: '1px 1px 1px 1px'
    },
    instructionsText: {
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "28px",
        marginTop: "16px",
        color: "#333333",
    },
    instructionsTextWrapper: {
        width: "328px",
        display: "flex",
        flexDirection: "column",
        margin: '64px auto 0 auto',
        "@media (min-width: 1280px)": {
            width: "328px",
        },
    },
    doorsOverviewWrapper: {
        display: "flex",
        flexDirection: 'column',
        height: "350px",
    },
    buttonContainer: {
        position: "fixed",
        bottom: "16px",
        left: "0",
        right: "0",
        display: "flex",
        justifyContent: "center",
        padding: "0 16px",
    },
};

function LockerDetails() {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [finishOrderDialogOpen, setFinishOrderDialogOpen] = useState<boolean>(false);
    const [searchParams] = useSearchParams(location.search);
    const { token } = useParams()
    const temporaryToken = searchParams.get("temporaryToken");

    const pickupDetailsResponseData = useSelector(
        (state: RootState) => state.senderRetrieval.pickupDetailsResponseData
    );
    const rental = pickupDetailsResponseData?.items[0].rental ?? undefined;


    const rentalFinished = useSelector(
        (state: RootState) => state.booking.rentalFinished
    );

    const [minutesSinceOrderStarted, setMinutesSinceOrderStarted] =
        useState<number>(0);

    const openLocker = useOpenLocker(rental as unknown as Rental);


    useEffect(() => {
        dispatch(setSelectedBooking((pickupDetailsResponseData as unknown) as BookingSummary));
    })

    useEffect(() => {
        async function getPickupDetails() {
            setLoading(true);

            const result = await baseStore.dispatch(
                receiverApiSlice.endpoints.getPickupDetails.initiate({
                    token,
                })
            );

            if (result.isSuccess && result.data) {
                const data = {
                    ...result.data,
                    items: [{ ...result.data.items[0], rental: { ...result.data.items[0].rental, token: temporaryToken } }]
                }

                dispatch(setLockerDetailsResponseData(data));
                setLoading(false);
            }
        }

        getPickupDetails();
    }, []);


    if (loading) {
        return <LoaderOverlay open={loading} />;
    }

    const onClickRetrieveItem = async () => {

        const orderRental = pickupDetailsResponseData?.items[0].rental;


        if (!rental && orderRental) {
            dispatch(setRentalStarted({ rental: (orderRental as unknown as Rental) }));
        }

        const depotId = orderRental?.doors[0].depotId;
        const door = orderRental?.doors[0].door;

        if (depotId && door) {
            await openLocker();
        }
    };

    const handleClickRetrieveItem = async () => {
        await onClickRetrieveItem();
        dispatch(setNextButtonClick(4));
    }

    return (
        <Box sx={styles.container}>
            <Box sx={styles.content}>
                <Box sx={styles.doorsOverviewWrapper}>
                    {pickupDetailsResponseData && (
                        <DoorsOverview
                            rental={pickupDetailsResponseData?.items[0].rental}
                            orderFinished={rentalFinished}
                            finishOrderDialogOpen={finishOrderDialogOpen}
                            minutesSinceOrderStarted={minutesSinceOrderStarted}
                            onCloseFinishOrderDialog={() => setFinishOrderDialogOpen(false)}
                        />
                    )}
                </Box>
            </Box>

            {/* <div className="fixed flex justify-center w-full px-5 bottom-16"> */}
            <Box sx={styles.buttonContainer}>
                <CustomButton
                    isButtonDark={true}
                    // isButtonDark={bookingSummary?.length > 0 ? false : true}
                    fullWidth={true}
                    onClick={handleClickRetrieveItem}
                    isOutlined={true}
                    children={t("itemRetrieval.lockerDetails.next_button_title")}
                />
            </Box>
        </Box>
    )
}

export default LockerDetails