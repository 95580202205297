export const palette = {
  accordion: {
    expanded: "#F3F3F3",
    collapse: "#F9F9F9",
  },
  primary: {
    dark: "#ffffff",
    main: "#1E1E1E",
    light: "#005475",
    contrastText: "#000",
  },
  secondary: {
    dark: "#2c93c7",
    main: "#515557",
    light: "#1E1E1E",
    contrastText: "#005475",
  },
  background: {
    default: "#F9F9F9", // Dark background for the main areas of the app
    paper: "#ffffff", // White background for elements like cards and dialogs
    notActiveBg: "#2c93c7",
  },
  info: {
    main: "#ffffff", // For active doors
    light: "#1CA3A8",
    dark: "#005475",
    contrastText: "#00547540",
  },
  toolbar: {
    active: "#6112E7",
    background: "#ffffff",
  },
  action: {
    disabled: "rgba(255, 255, 255, 0.26)",
    active: "#ffffff",
    selected: "#ffffff",
    hover: "#00547540",
    disabledBackground: "rgba(174, 200, 209, 0.3)",
  },
  text: {
    primary: "#161616", // White text on dark backgrounds
    secondary: "#0a0a0a", // Dark text on light backgrounds
    disabled: "rgb(190,190,190)",
    main: "#ffffff",
  },
  divider: "#a0a6a7",
  border: {
    default: "#DEDFDF",
  },
};
