import React from "react";
import { useTranslation } from "react-i18next";

function BookingSummaryNote() {
  const { t } = useTranslation();

  return (
    <div className="bg-[#EDF0F5] rounded p-2 max-w-2xl text-sm">
      <span className="font-bold">
        {" "}
        {t("orders.ticket_overview.apartment_overview_note.nb")}
      </span>
      {t("orders.ticket_overview.apartment_overview_note.content")}
    </div>
  );
}

export default BookingSummaryNote;
