const PrivacyShieldIcon = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="94"
      height="94"
      viewBox="0 0 94 94"
      fill="none"
    >
      <path
        d="M35.25 49.9375L44.0625 58.75L58.75 38.1875M47 10.6299C38.1316 19.0516 26.321 23.6707 14.0922 23.5C12.5366 28.2391 11.746 33.1958 11.75 38.1836C11.75 60.0856 26.7273 78.4861 47 83.707C67.2727 78.49 82.25 60.0895 82.25 38.1875C82.25 33.0567 81.4275 28.1178 79.9079 23.4961H79.3125C66.7948 23.4961 55.4208 18.6081 47 10.6299Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PrivacyShieldIcon;
