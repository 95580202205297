import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";
export const hintsApiSlice = createApi({
  reducerPath: "hintsApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["hintList"],
  endpoints: (builder) => ({
    getHintList: builder.query({
      query: () => {
        return {
          url: `/config`,
        };
      },
      providesTags: ["hintList"],
    }),
  }),
});
export const { useGetHintListQuery } = hintsApiSlice;