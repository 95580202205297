import React from 'react';
import { Box } from '@mui/material';

interface KolulaSquareProps {
  posX: number;
  posY: number;
}

const KolulaBoxSquare = (props: KolulaSquareProps) => {
  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.primary.main,
        width: '30px',
        height: '30px',
        position: 'absolute',
        left: props.posX,
        top: props.posY,
      }}
    />
  );
};

export default KolulaBoxSquare;
