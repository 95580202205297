// hooks/useCreateNewReservation.ts
import { useDispatch } from "react-redux";
import { baseStore } from "../store"; // Adjust the import based on your file structure
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import { rentalsApiSlice } from "../../api/rentals/rentalsApiSlice";
import { setRentalStarted } from "../slice/booking/bookingSlice";
import { Rental } from "../../types/rental";
import { BookingSummary } from "../../types/bookingCreation";
import { enqueueNotification } from "../slice/notification/notificationSlice";
import { getI18n } from "react-i18next";

const startRentalCall = async (order: BookingSummary) => {
  try {
    const result = await baseStore.dispatch(
      rentalsApiSlice.endpoints.startRental.initiate({
        orderId: order.id,
        items: [order.items[0].id],
      })
    );

    if ("data" in result) {
      return result.data[0] as Rental; // Assuming result.data will have a Rental type, adjust if needed
    }

    if ("error" in result) {
      throw result.error;
    }
  } catch (error) {
    Logger.for("startRentalCall").error(error);
    throw error;
  }
};

export const useStartRental = (order: BookingSummary | null) => {
  const dispatch = useDispatch();

  const startRental = async () => {
    try {
      if (order) {
        const rental = await startRentalCall(order);
        if (rental) {
          console.log("Rental started successfully");
          dispatch(setRentalStarted({ rental: rental }));
        } else {
          dispatch(enqueueNotification({
            severity: 'error',
            message: getI18n().t(`Error occurs while starting the order.`),
          }));
        }
      }
    } catch (error) {
      Logger.for("startRental").error(error);
      ErrorTracker.trackException(error as Error);
      dispatch(enqueueNotification({
        severity: 'error',
        message: getI18n().t(`Error occurs while starting the order.`),
      }));
    }
  };

  return startRental;
};
