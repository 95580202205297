import Logger from '../util/logger';
import ErrorTracker from '../util/errorTracker';

/**
 * Specifies the current version of the kolula IndexedDB. This should be incremented whenever the structure of
 * the DB has changed and a migration is required. Take a look at KolulaIndexedDB::updateDatabaseToLatestVersion.
 * Note: This must be an integer!
 */
const DB_VERSION: number = 4;
const DB_NAME = process.env.REACT_APP_TENANT || 'kolula';

export const OBJECT_STORE_PERSONAL_DATA = 'personal_data';
export const OBJECT_STORE_BOOKING_FLOW_DATA = 'booking_flow_data';
export const OBJECT_STORE_ORDERS = 'orders';
export const OBJECT_STORE_ORDER_IDS = 'orderIds';
export const OBJECT_STORE_STARTED_ORDERS = 'started_orders';

export const INDEX_ORDER_ID = 'indexOrderId';

class KolulaIndexedDB {
    private logger: typeof Logger;
    private initializationPromise: Promise<void>;
    private db?: IDBDatabase;

    public static deleteDatabase(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const deleteRequest = indexedDB.deleteDatabase(DB_NAME);
            deleteRequest.onsuccess = () => resolve();
            deleteRequest.onerror = () => reject();
        });
    }

    constructor() {
        this.logger = Logger.for('DB') as any;
        this.initializationPromise = new Promise((resolve, reject) => {            
            const openRequest = indexedDB.open(DB_NAME, DB_VERSION);         
            openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                this.updateDatabaseToLatestVersion(
                    openRequest.result,
                    (event.target as any)?.transaction,
                    event.oldVersion,
                );
            };
            openRequest.onerror = () => {
                this.logger.error('IndexedDB could not be initialized');
                ErrorTracker.trackMessage('IndexedDB could not be initialized');
                reject('IndexedDB could not be initialized');
            };

            openRequest.onsuccess = () => {
                this.db = openRequest.result;
                this.db.onversionchange = () => this.db && this.db.close();
                resolve();
            };
        });
    }

    public async get(objectStore: string, key: string): Promise<any> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readonly');
            const request = await transaction.objectStore(objectStore).get(key);

            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject();
        });
    }

    public async getAll(objectStore: string): Promise<any[]> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readonly');
            const request = await transaction.objectStore(objectStore).getAll();

            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject();
        });
    }

    public async getAllByIndex(objectStore: string, indexName: string, key: string): Promise<any[]> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readwrite');
            const index = transaction.objectStore(objectStore).index(indexName);
            const request = index.getAll(key);

            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject();
        });
    }

    public async add(objectStore: string, data: object): Promise<void> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readwrite');
            const request = transaction.objectStore(objectStore).add(data);

            request.onsuccess = () => resolve();
            request.onerror = () => reject();
        });
    }

    public async put(objectStore: string, data: object | string): Promise<void> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readwrite');
            const request = transaction.objectStore(objectStore).put(data);

            request.onsuccess = () => resolve();
            request.onerror = () => reject();
        });
    }

    public async delete(objectStore: string, key: string): Promise<void> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readwrite');
            const request = transaction.objectStore(objectStore).delete(key);

            request.onsuccess = () => resolve();
            request.onerror = () => reject();
        });
    }

    private async assertDBInitialized(): Promise<void> {
        await this.initializationPromise;

        if (!this.db) {
            throw new Error('IndexedDB is not initialized');
        }
    }

    private updateDatabaseToLatestVersion(db: IDBDatabase, transaction: IDBTransaction, oldVersion: number): void {
        this.logger.log(`Starting upgrade for DB kolula from version ${oldVersion} to ${DB_VERSION}`);

        if (oldVersion < 1) { // Initialization (migration from version 0 to 1)
            db.createObjectStore(OBJECT_STORE_PERSONAL_DATA, { keyPath: 'id' });
            const ordersObjectStore = db.createObjectStore(OBJECT_STORE_ORDERS, { keyPath: 'id' });
            ordersObjectStore.createIndex(INDEX_ORDER_ID, 'orderId', { unique: false });
        }

        if (oldVersion < 2) {
            db.createObjectStore(OBJECT_STORE_STARTED_ORDERS, { keyPath: 'id' });
        }

        if (oldVersion < 3) {
            const getAllRequest = transaction.objectStore(OBJECT_STORE_PERSONAL_DATA).getAll();
            getAllRequest.onsuccess = () => {
                getAllRequest.result.forEach(personalData => {
                    transaction.objectStore(OBJECT_STORE_PERSONAL_DATA).put({
                        ...personalData,
                        phone: '',
                    });
                });
            };
        }
        if (oldVersion < 4) {
            const bookingFlowStore = db.createObjectStore(OBJECT_STORE_BOOKING_FLOW_DATA, {keyPath: 'id'});
            bookingFlowStore.createIndex('id', 'id', {unique: false});
        }
    }
}

export default KolulaIndexedDB;
