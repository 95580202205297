import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import moment from "moment";
import BookingDatePicker from "../common/components/OrderDataStep/BookingDatePicker";
import {
  checkInDateSelected,
  checkOutDateSelected,
  checkOutDateSelectedClear,
} from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import NumericInput from "../../common/NumberOfCustomers";
import CityAndLocationSelection from "./components/CityAndDistrictSelection";

const LocationAndDurationSelectionStep = () => {
  const { t } = useTranslation();
  const locations = useSelector(
    (state: RootState) => state.booking.useCaseLocations
  );

  const startDate = useSelector(
    (state: RootState) => state.bookingCreation.orderData.checkInDate
  );
  const endDate = useSelector(
    (state: RootState) => state.bookingCreation.orderData.checkOutDate
  );

  const dispatch = useDispatch();

  const handleDateChange = (
    start: moment.Moment | null,
    end: moment.Moment | null
  ) => {
    if (start) {
      dispatch(checkInDateSelected({ checkInDate: start.startOf("day") }));
      dispatch(checkOutDateSelectedClear());
    }
    if (end) {
      if (start && end.isSameOrBefore(start)) {
        // Ensure checkout date is at least one day after check-in date
        dispatch(checkOutDateSelected({ checkOutDate: start.clone().add(1, "day") }));
      } else {
        dispatch(checkOutDateSelected({ checkOutDate: end }));
      }
    }
  };

  const generateAvailableDates = (minDate?: moment.Moment | null) => {
    const start = minDate || moment(new Date()).startOf("day");
    const end = moment().add(1, "months").endOf("month").startOf("day"); // End of the month, 1 year later
    const dates: any = [];

    for (let date = start; date.isBefore(end); date.add(1, "day")) {
      dates.push(date.format("DD-MM-YYYY"));
    }

    return dates;
  };

  const generateAvailableCheckOutDates = (checkInDate: moment.Moment | null) => {
    if (!checkInDate) return generateAvailableDates();

    const start = checkInDate.clone().add(1, "day"); // Start from check-in date + 1 day
    const end = moment().add(1, "months").endOf("month").startOf("day"); // End of the month, 1 year later
    const dates: any = [];

    for (let date = start; date.isBefore(end); date.add(1, "day")) {
      dates.push(date.format("DD-MM-YYYY"));
    }

    return dates;
  };

  const availableCheckInDates = generateAvailableDates();
  const availableCheckOutDates = generateAvailableCheckOutDates(startDate);

  return (
    <div className="flex flex-col w-full max-w-2xl gap-6 px-3 mx-auto sm:px-5">
      <div className="w-full">
        {locations ? (
          <CityAndLocationSelection locations={locations} />
        ) : (
          <p className="text-gray-700">{t("book_location.no_location")}</p>
        )}
      </div>

      <div className="w-full flex items-center justify-between">
        <div className="">
          <BookingDatePicker
            value={startDate || null}
            onChange={(date) => handleDateChange(date || null, endDate)}
            shouldDisableDate={(date) => false}
            availableDates={availableCheckInDates}
            label={t("apartment.check_in")}
          />
        </div>
        <div className="flex items-center justify-center text-gray-400 font-bold mx-4">
          -
        </div>
        <div className="">
          <BookingDatePicker
            value={endDate || null}
            onChange={(date) => handleDateChange(startDate, date || null)}
            shouldDisableDate={(date) => {
              if (startDate) {
                return date ? date.isSameOrBefore(startDate) : false;
              }
              return false;
            }}
            availableDates={availableCheckOutDates}
            label={t("apartment.check_out")}
          />
        </div>
      </div>

      <div className="flex items-center justify-between gap-2 p-2 sm:flex-row sm:items-center sm:p-3 sm:gap-4">
        <span className="text-base font-normal text-gray-600 sm:text-base">
          {t("apartment.number_guests")}
        </span>
        <NumericInput />
      </div>
    </div>
  );
};

export default LocationAndDurationSelectionStep;