import { useDispatch, useSelector } from "react-redux";
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import { saveConsent } from "../../storage/consent";
import { setConsent } from "../slice/consent/consentSlice";

export const useStoreConsent = (consent: boolean) => {
  const dispatch = useDispatch();

  const storeConsent = async () => {
    try {
      saveConsent(consent);
      dispatch(setConsent(consent));
    } catch (error) {
      Logger.for("storeConsent").error(error);
      ErrorTracker.trackException(error as Error);
    }
  };
  return storeConsent;
};
