import React, { useEffect } from 'react';
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { useTranslation } from "react-i18next";
import { useGetApartmentDetailsQuery } from '../../../api/apartment/apartmentApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setApartmentDetails } from '../../../redux/slice/apartmentRental/apartmentRentalSlice';
import { setAssetGroup } from '../../../redux/slice/bookingCreation/bookingCreationSlice';
import { formatCurrency } from '../../../util';

const StyledContainer = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  margin: '0 auto',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    maxWidth: '80%',
  },
  padding: theme.spacing(2),
}));

const CarouselContainer = styled(Box)({
  width: '100%',
  flex: '0 0 auto',
});

const ScrollableContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  marginTop: theme.spacing(2),
  height: 0,
}));

const CarouselImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '300px',
  objectFit: 'cover',
  [theme.breakpoints.down('sm')]: {
    height: '200px',
  },
}));

const ApartmentOverview = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {selectedApartment } = useSelector(
    (state: RootState) => state.apartment
  );

  const {data, isLoading} = useGetApartmentDetailsQuery({ apartmentId: selectedApartment?.assetId ?? '' }); 

  useEffect(() => {
    if (data) {
      dispatch(setApartmentDetails(data));
      dispatch(setAssetGroup({id: data.groupId}));
    }
  },[data])

  useEffect(() => {
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    
    // Cleanup function to restore scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <StyledContainer>
      <CarouselContainer>
        <Carousel
          animation="slide"
          indicators={true}
          navButtonsAlwaysVisible={true}
          indicatorContainerProps={{
            style: {
              position: 'absolute',
              bottom: '16px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              padding: '4px 8px',
              gap: '4px',
              backgroundColor: '#cfd2d4',
              border: '1px solid #cfd2d4',
              borderRadius: '20px',
              width: 'auto',
              display: 'inline-flex'
            }
          }}
          indicatorIconButtonProps={{
            style: {
              color: 'rgba(0, 0, 0, 0.3)',
              padding: '1px',
              margin: '0 2px',
            }
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: '#ffffff',
              padding: '1px',
              margin: '0 2px',
            }
          }}
          navButtonsProps={{
            style: {
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              color: '#000',
            },
          }}
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: '16px',
          }}
        >
          {data?.additionalImages?.map((image, index) => (
            <CarouselImage
              key={index}
              src={image}
              alt={`Accommodation view ${index + 1}`}
            />
          ))}
        </Carousel>
      </CarouselContainer>

      <ScrollableContent>
        <Box>
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 600, fontSize: "16px" }}>
           {data?.name}
          </Typography>

          <Box sx={{ display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 4 }}>
              <Box sx={{ display: 'flex',  fontSize: "12px", }}>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600, fontSize: "12px" }}>
              {data?.accomodationdetail?.guests} {t("venue_reservation.guests")} &nbsp;
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600, fontSize: "12px" }}>
              • {data?.accomodationdetail?.bedrooms} {t("venue_reservation.bedrooms")} &nbsp;
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600, fontSize: "12px" }}>
              • {data?.accomodationdetail?.beds} {t("venue_reservation.beds")} &nbsp;
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600, fontSize: "12px" }}>
              • {data?.accomodationdetail?.baths} {t("venue_reservation.baths")}
            </Typography>
            </Box>
          <Typography sx={{ fontSize: "16px",textAlign: 'right' }} variant="body2" color="primary">
          <Typography component="span" sx={{ color: '#00A4B1',fontWeight: 500, }}>{formatCurrency(data?.Group?.pricecategory?.pricescale[0].price ?? 0, 'de-DE')}</Typography>
          <Typography component="span" sx={{ color: '#666',fontWeight: 500, }}> / {t("apartment.night")}</Typography>
          </Typography>
          </Box>


          <Typography variant="h6" color="primary" gutterBottom sx={{ fontWeight: 600, fontSize: "14px", lineHeight: "24px" }}>
            {t("apartment.facility_description_title")}
          </Typography>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3, fontWeight: 400, fontSize: "14px", lineHeight: "20px"}}>
           {data?.accomodationdetail?.facilityDescription}
          </Typography>

          <Typography variant="h6" color="primary" gutterBottom sx={{ fontWeight: 600, fontSize: "12px", lineHeight: "24px" }}>
            {t("apartment.important_note")}
          </Typography>

          <Typography variant="body2" color="text.secondary" sx={{ mb: 3, fontWeight: 400,  fontSize: "14px", lineHeight: "20px" }}>
           {data?.accomodationdetail?.importantNote}
          </Typography>

          <Typography variant="h6" color="primary" gutterBottom sx={{ fontWeight: 600, fontSize: "12px", lineHeight: "24px" }}>
          {t("apartment.location")}
          </Typography>

          <Box 
            sx={{ 
              width: '100%',
              height: '200px',
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              overflow: 'hidden',
              mb: 3
            }}
          >
            <iframe
              title="Property Location"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              src={`https://www.google.com/maps?q=${data?.Group?.location?.address?.geoLat},${data?.Group?.location?.address?.geoLng}&z=15&output=embed`}
              allowFullScreen
            />
          </Box>
        </Box>
      </ScrollableContent>
    </StyledContainer>
  );
};

export default ApartmentOverview;
