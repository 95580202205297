import React, { useEffect, useState } from "react";
import { getLogo } from "../../../util/tenantConfig";

const TenantLogo = (props: React.ComponentProps<"img">) => {
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    const fetchAssets = async () => {
      const logo = await getLogo();
      if (typeof logo === "string") {
        setLogo(logo);
      } else {
        setLogo(null);
      }
    };
    fetchAssets();
  }, []);
  
  return (
    <>
      {logo && (
        <img src={logo} className="block max-h-8 max-w-52" {...props} alt="Logo"/>
      )}
    </>
  );
};

export default TenantLogo;
