import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { LocationPros } from "../../../../types/baseTypes";
import { RootState } from "../../../../redux/store";
import { setRoomRentalLocation, setSelectedLocation } from "../../../../redux/slice/bookingCreation/bookingCreationSlice";

export default function CityAndLocationSelection({
  locations,
}: {
  locations: LocationPros[];
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const selectedLocation = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  );
  const roomRentalLocation = useSelector(
    (state: RootState) => state.bookingCreation.roomRentalLocation
  )

  const allowTyping = locations.length > 5;

  const cities = Array.from(new Set(locations.map((loc) => loc.address.city)));

  // Auto-select city if there's only one
  React.useEffect(() => {
    if (cities.length === 1 && !roomRentalLocation.city) {
      const singleCity = cities[0];
      dispatch(setRoomRentalLocation({ city: singleCity, district: null }));
    }
  }, [cities, dispatch, roomRentalLocation.city]);

  const locationsForCity = roomRentalLocation.city
  ? [
      t("book_location.all_districts"),
      ...Array.from(
        new Set(
          locations
            .filter((loc) => loc.address.city === roomRentalLocation.city)
            .map((loc) => loc.address.district)
            .filter(Boolean) // Removes undefined/null values
        )
      ),
    ]
  : [];

  const handleCityChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    dispatch(setRoomRentalLocation({ city: value, district: null }));
    dispatch(setSelectedLocation(null));
  };

  const handleLocationChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    if (value === t("book_location.all_districts")) {
      dispatch(setRoomRentalLocation({ city: roomRentalLocation.city, district: 'all' }));
    } else {
      dispatch(setRoomRentalLocation({ city: roomRentalLocation.city, district: value }));
    }
  };


  return (
    <div>
      <Autocomplete
        className="rounded-full"
        disablePortal
        id="combo-box-demo"
        options={cities}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: selectedLocation?.name
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            },
            "&:hover fieldset": {
              borderColor: selectedLocation?.name
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.primary.light,
            },
            "&.Mui-disabled": {
              "& fieldset": {
                borderColor: `#E5E5E5!important`,
              },
              "& input": {
                color: `#E5E5E5 !important`,
                WebkitTextFillColor: `#E5E5E5 !important`,
              },
            },
          },
          "& .MuiInputBase-input": {
            color: theme.palette.primary.contrastText,
          },
          "& .Mui-focused": {
            color: theme.palette.primary.light,
          },
          "& .MuiSvgIcon-root": {
            color: `#E5E5E5`,
          },
          "& .MuiInputLabel-root.Mui-disabled": {
            color: `#E5E5E5 !important`,
          },
        }}
        disabled={cities.length === 1}
        value={roomRentalLocation.city ? `${roomRentalLocation.city}` : ""}
        onChange={handleCityChange}
        freeSolo={allowTyping}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("book_location.location_city")}
            InputProps={{
              ...params.InputProps,
              readOnly: !allowTyping,
              style: { color: "black" },
            }}
          />
        )}
        renderOption={(props, option, state) => (
          <li
            {...props}
            style={{
              backgroundColor: state.selected ? theme.palette.grey[300] : "transparent",
              color: "black",
            }}
          >
            {option}
          </li>
        )}
      />
      <Autocomplete
        className="rounded-full mt-6"
        disablePortal
        id="combo-box-demo"
        options={locationsForCity}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: selectedLocation?.name
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            },
            "&:hover fieldset": {
              borderColor: selectedLocation?.name
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.primary.light,
            },
            "&.Mui-disabled": {
              "& fieldset": {
                borderColor: `${theme.palette.primary.main} !important`,
              },
              "& input": {
                color: `${theme.palette.primary.contrastText} !important`,
                WebkitTextFillColor: `${theme.palette.primary.contrastText} !important`,
              },
            },
          },
          "& .MuiInputBase-input": {
            color: theme.palette.primary.contrastText,
          },
          "& .Mui-focused": {
            color: theme.palette.primary.light,
          },
          "& .MuiSvgIcon-root": {
            color: theme.palette.primary.light,
          },
          "& .MuiInputLabel-root.Mui-disabled": {
            color: `${theme.palette.primary.main} !important`,
          },
        }}
        value={
          roomRentalLocation.district === 'all'
            ? t("book_location.all_districts")
            : roomRentalLocation.district
              ? `${roomRentalLocation.district}`
              : ""
        }
        onChange={handleLocationChange}
        freeSolo={allowTyping}
        disabled={roomRentalLocation.city === null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("book_location.district")}
            InputProps={{
              ...params.InputProps,
              readOnly: !allowTyping,
              style: { color: "black" },
            }}
          />
        )}
        renderOption={(props, option, state) => (
          <li
            {...props}
            style={{
              backgroundColor: state.selected ? theme.palette.grey[300] : "transparent",
              color: "black",
            }}
          >
            {option}
          </li>
        )}
      />
    </div>
  );
}
