import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TenantLogo from "../../../layout/components/TenantLogo";
import CustomButton from "../../../layout/components/CustomButton";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { calculateDuration } from "../../../../util/date";
import moment from "moment";
import { useState } from "react";
import CouponDialog from "../../common/components/ConfirmationStep/CouponDialog";
import { useApplyCoupon } from "../../../../redux/flows/applyCouponFlow";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";
import {
  calculateDisplayPrice,
  calculateTotalPrice,
  consolidateCoupons,
  findCoupons,
} from "../../../../util/payments";
import { setNextButtonClick } from "../../../../redux/slice/temporaryStorage/temporaryStorageSlice";
import {getEquipmentName} from "../../../../util";

const styles = {
  root: (theme: any) => ({
    background: theme.palette.background.paper || theme.palette.primary.main,
    color: theme.palette.text.secondary || "white",
    borderRadius: 0.5,
    width: "350px",
    position: "relative",
    boxShadow: theme.shadows[5],
    marginBottom: "100px",
  }),

  text: (theme: any) => ({
    color: theme.palette.text.secondary || "white",
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontStyle: theme.typography.h5.fontStyle,
  }),
  location: (theme: any) => ({
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    letterHeight: theme.typography.h2.letterHeight,
    letterSpacing: theme.typography.h2.letterSpacing,
    fontStyle: theme.typography.h5.fontStyle,
  }),
  headerLogo: (theme: any) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "50%",
    marginTop: "25px",
    paddingLeft: "15px",
    paddingRight: "3px",
    [theme.breakpoints.down("md")]: {
      height: "33px",
    },
  }),
  ticketDot: (theme: any) => ({
    width: "5px",
    height: "10px",
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: "29%",
  }),
  ticketDotLeft: (theme: any) => ({
    ...styles.ticketDot(theme),
    left: 0,
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  }),
  ticketDotRight: (theme: any) => ({
    ...styles.ticketDot(theme),
    right: 0,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  }),
  ticketClass: {
    width: "100%",
    padding: "0px 12px",
    margin: "4px 0px",
    position: "relative",
    borderBottomColor: "transparent",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "7%",
      width: "86%",
      borderBottom: "1px solid gray",
    },
  },
  ticketcontent: {
    paddingRight: "12px",
    paddingLeft: "12px !important",
    paddingTop: "5px !important",
  },
  itemClass: {
    position: "relative",
    paddingTop: "3px",
    "&:not(:last-child)": {
      borderBottom: "1px solid gray",
    },
  },
  bottomTicketClass: {
    width: "100%",
    padding: "0px 12px",
    margin: "0px 0px",
  },
  button: {
    width: { xs: "60%", sm: "70%", md: "60%" },
    height: { xs: "50px", sm: "60px", md: "70px" },
    marginLeft: { xs: "5px", sm: "10px" },
    marginTop: { xs: "2px", sm: "5px" },
  },
};

const BookingSummary = ({ loading }: { loading: boolean }) => {
  const amount = 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tenantConfig = useSelector(
    (state: RootState) => state.tenantConfig.config
  );

  const { selectedLocation, reservations } = useSelector(
    (state: RootState) => state.bookingCreation
  );

  const equipments = useSelector((state: RootState) =>
    selectedLocation
      ? state.bookingCreation.groupsByLocation[selectedLocation.id]
      : undefined
  );
  const [couponDialogOpen, setCouponDialogOpen] = useState<boolean>(false);


  const [showLoading, setShowLoading] = useState(false);
  const applyCoupon = useApplyCoupon();

  const findEquipmentName = (groupId: string) => {
    if (!equipments) return "";
    const targetGroup = equipments.find((group: any) => group.id === groupId);
    if (targetGroup) {
      return getEquipmentName(targetGroup.name, targetGroup?.langNames, "title");
    }

    return ""; // Return empty if no matching group is found
  };
  const handleClickNext = () => {
    dispatch(setNextButtonClick());
  };
  const coupons = findCoupons(reservations);
  const totalPrice = calculateTotalPrice(reservations);
  const displayPrice = calculateDisplayPrice(totalPrice, coupons);

  const shwowCouponButton = tenantConfig.allowCoupons && coupons.length === 0;

  const consolidatedCoupons = consolidateCoupons(coupons);

  if (loading) {
    return <LoaderOverlay open={loading} />;
  }
  return (
    <div className="flex flex-col items-center justify-center px-5">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box sx={styles.root}>
          <Box sx={styles.ticketDotLeft} />
          <Grid container spacing={2} direction="column">
            <Grid item container justifyContent="flex-start" direction="row">
              <Box sx={styles.headerLogo}>
                <TenantLogo
                  style={{ width: "60%", height: "60%" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={styles.ticketClass}
            spacing={2}
            direction="column"
          >
            <Grid
              item
              justifyContent="space-between"
              direction="row"
              sx={styles.ticketcontent}
            >
              <Grid item>
                <Typography sx={styles.text}>
                  {t("orders.ticket_overview.location")}
                </Typography>
                <Typography sx={styles.location}>
                  <strong>{selectedLocation?.name}</strong>
                </Typography>
              </Grid>
              <Typography
                sx={styles.text}
                style={{ paddingTop: "5px", marginBottom: "-3px" }}
              >
                {t("orders.ticket_overview.booked_item")}
              </Typography>
              {reservations.map((reservation: any, index: any) => {
                // Extract start and end dates from reservationItems
                const reservationStartDate = moment(
                  reservation.reservationItems[0].fromDate
                );
                const reservationEndDate = moment(
                  reservation.reservationItems[0].toDate
                );
                const equipmentName = findEquipmentName(
                  reservation.reservationItems[0].groupId
                );
                const price = reservation.reservationItems[0].price;

                return (
                  <Grid
                    key={index}
                    sx={styles.itemClass}
                    item
                    justifyContent="flex-start"
                  >
                    <Typography
                      sx={styles.location}
                      style={{ display: "flex" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <strong>
                            {amount}x {equipmentName} -{" "}
                            {calculateDuration(
                              reservationStartDate.toDate(),
                              reservationEndDate.toDate()
                            )}
                          </strong>
                        </Box>
                        <Box>
                          <strong>
                            {price}
                            {tenantConfig.currencySymbole}
                          </strong>
                        </Box>
                      </Box>
                    </Typography>
                    <Grid item xs>
                      <Box id="start-time">
                        {reservationStartDate.format(t("formats.date_time"))}
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          direction: "row",
                          alignItems: "center",
                        }}
                      >
                        <Box id="end-time">
                          {reservationEndDate.format(t("formats.date_time"))}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid
            container
            sx={styles.bottomTicketClass}
            spacing={2}
            direction="column"
          >
            <Grid item justifyContent="flex-start" sx={styles.ticketcontent}>
              {consolidatedCoupons.map((coupon) => (
                <Typography sx={styles.location} style={{ display: "flex" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography sx={styles.text}>
                      {t("book_location.confirmation.coupon_item")} (
                      {coupon.code})
                    </Typography>
                    <Box>
                      <strong>
                        {!showLoading
                          ? `-${
                              coupon.amount > totalPrice
                                ? totalPrice
                                : coupon.amount
                            }`
                          : "..."}
                        {tenantConfig.currencySymbole}
                      </strong>
                    </Box>
                  </Box>
                </Typography>
              ))}

              <Typography sx={styles.location} style={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography sx={styles.text}>
                    {t("book_location.confirmation.total_items")}
                  </Typography>
                  <Box>
                    <strong>
                      {!showLoading
                        ? parseFloat(displayPrice.toFixed(2))
                        : "..."}
                      {tenantConfig.currencySymbole}
                    </strong>
                  </Box>
                </Box>
              </Typography>
            </Grid>
            {shwowCouponButton && (
              <Box sx={styles.button}>
                <CustomButton
                  style={{ width: "100%", height: "80%" }}
                  isButtonDark={true}
                  fullWidth={true}
                  onClick={() => setCouponDialogOpen(true)}
                  fontSize={"18px"}
                  children={t("orders.ticket_overview.button")}
                />
              </Box>
            )}
            <h5 className="mb-8 ml-3 text-sm font-normal">
              {t("book_location.confirmation.tax_information", {
                taxRate: tenantConfig?.taxRate,
              })}
            </h5>
          </Grid>
          <Box sx={styles.ticketDotRight} />
        </Box>

        <LoaderOverlay open={showLoading} />

        <CouponDialog
          open={couponDialogOpen}
          onClose={() => setCouponDialogOpen(false)}
          content={undefined}
        />
      </Box>
      <div className="fixed flex w-full max-w-md px-5 bottom-4 m-auto items-center justify-center">
        <CustomButton
          isButtonDark={true}
          fullWidth={true}
          onClick={handleClickNext}
          isOutlined={true}
          children={t(
            "temporary_storage.new_booking.booking_summary.next_button_title"
          )}
        />
      </div>
    </div>
  );
};
export default BookingSummary;
