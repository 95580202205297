import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Checkbox,
    TextField,
    Button,
    IconButton,
    FormControlLabel,
    styled,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { getI18n } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { addService, couponRedemptionLoading, editService, removeService, setTotalPrice } from '../../../redux/slice/bookingCreation/bookingCreationSlice';
import { formatDate } from '../../../util/date';
import moment from 'moment';
import { useFetchVoucher } from '../../../redux/flows/fetchVoucherFlow';
import LoaderButton from '../../layout/components/LoaderButton';
import { formatCurrency } from '../../../util';

interface BookingSummaryProps {
    roomImage: string;
    roomTitle: string;
    address: string;
    pricePerNight: number;
    dates: {
        start: string;
        end: string;
    };
    numberOfGuests: number;
    additionalServices: {
        id: string;
        assetId: string;
        serviceName: string;
        unitPrice: number;
        isPaymentCompulsory: boolean;
        quantity: boolean;
    }[];
}

const ConfirmButton = styled(LoaderButton)(({ theme }) => ({
    width: '120px',
    height: '44px',
    gap: '10px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: theme.palette.primary.light, // Disable background color change on hover
        boxShadow: 'none', // Disable any box-shadow on hover
    },
}));

const BookingSummary: React.FC<BookingSummaryProps> = ({
    roomImage,
    roomTitle,
    address,
    pricePerNight,
    dates,
    numberOfGuests,
    additionalServices,
}) => {
    const [showAll, setShowAll] = React.useState(false);
    // const [quantity, setQuantity] = useState(1);
    const [quantities, setQuantities] = useState<Record<string, number>>(
        additionalServices.reduce<Record<string, number>>((acc, service) => {
            acc[service.serviceName] = 1;
            return acc;
        }, {})
    );   
    const fetchVoucher = useFetchVoucher(); 
    const displayedServices = showAll 
        ? additionalServices 
        : additionalServices.slice(0, 4);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tenantConfig = useSelector((state: RootState) => state.tenantConfig.config);
    const startDate = useSelector((state: RootState) => state.bookingCreation.orderData.checkInDate);
    const endDate = useSelector((state: RootState) => state.bookingCreation.orderData.checkOutDate);
    const isCouponReedemptionLoading = useSelector((state: RootState) => state.bookingCreation.loading.couponRedemption);
    const locationId = useSelector((state: RootState) => state.apartment.selectedApartment?.locationId);
    const groupId = useSelector((state: RootState) => state.apartment.selectedApartment?.groupId);
    const voucher = useSelector((state: RootState) => state.bookingCreation.roomRentalVoucher);

    const [voucherCode, setVoucherCode] = useState("");

    const [checkedServices, setCheckedServices] = useState<Record<string, boolean>>(
        displayedServices.reduce<Record<string, boolean>>((acc, service) => {
            acc[service.serviceName] = false;
            return acc;
        }, {})
    );

    const handleCheckboxChange = (serviceName: string) => {
        setCheckedServices((prevState) => {
            const newCheckedState = {
                ...prevState,
                [serviceName]: !prevState[serviceName],
            };

            if (!prevState[serviceName]) {
                dispatch(addService({
                    serviceId: additionalServices.find((service) => service.serviceName === serviceName)?.id!, 
                    name: serviceName,
                    quantity: quantities[serviceName],
                }));
            } else {
                dispatch(removeService({ name: serviceName }));
            }

            return newCheckedState;
        });
    };
    
    const handleIncrement = (serviceName: string) => {
        setQuantities((prevQuantities) => {
            const newQuantities = {
                ...prevQuantities,
                [serviceName]: prevQuantities[serviceName] + 1,
            };
            dispatch(editService({ 
                serviceId: additionalServices.find((service) => service.serviceName === serviceName)?.id!,
                name: serviceName, 
                quantity: newQuantities[serviceName] 
            }));
            return newQuantities;
        });
    };

    const handleDecrement = (serviceName: string) => {
        setQuantities((prevQuantities) => {
            const newQuantities = {
                ...prevQuantities,
                [serviceName]: Math.max(1, prevQuantities[serviceName] - 1),
            };
            dispatch(editService({ 
                serviceId: additionalServices.find((service) => service.serviceName === serviceName)?.id!,
                name: serviceName, 
                quantity: newQuantities[serviceName] 
            }));
            return newQuantities;
        });
    };

    const additionalServicesTotal = additionalServices.reduce((total, service) => {
        if (checkedServices[service.serviceName] && !(service.unitPrice === 0) && service.unitPrice) {
            const quantity = service.quantity ? quantities[service.serviceName] : 1;
            return total + service.unitPrice * quantity;
        }
        return total;
    }, 0);

    const calculateNights = () => {
        if (!startDate || !endDate) {
            return 0; 
        }
        return moment(endDate).diff(moment(startDate), 'days');
    };

    const handleVoucherApply = async() => {
        dispatch(couponRedemptionLoading({ loading: true }))
        await fetchVoucher(voucherCode, locationId!, groupId!); // Todo: refactor to not use !
    }

    const numberOfNights = calculateNights();

    let totalPrice = numberOfNights * pricePerNight + 50 + additionalServicesTotal;
    let reductionPrice = 0;
    if (voucher) {
        if (voucher.discountIsPercentage === true) {
            reductionPrice = totalPrice * (voucher.discountValue / 100);
        } else {
            reductionPrice = voucher.discountValue;
        }
    }
    totalPrice = totalPrice - reductionPrice;

    useEffect(() => {
        dispatch(setTotalPrice(totalPrice + reductionPrice));
    }, [totalPrice])

    return (
        <div className="max-w-[600px] mx-auto p-4 md:p-6">
            <Box className="flex gap-4 mb-4 border-b border-gray-300 pb-2">
                <img
                    src={roomImage}
                    alt="Room"
                    className="w-[100px] h-[100px] object-cover rounded-lg"
                />
                <Box className="flex-1">
                    <Typography sx={{fontWeight: 500, fontSize: '14px'}}>{roomTitle}</Typography>
                    <Typography color="text.secondary" sx={{fontWeight: 400, fontSize: '12px'}}>
                        {address}
                    </Typography>
                    <Typography sx={{fontWeight: 500, fontSize: '16px'}}>
                        {formatCurrency(pricePerNight, 'de-DE')} / {t("apartment.night")}
                    </Typography>
                </Box>
            </Box>

            <Box className="p-2 mb-1 border border-gray-300 rounded-md">
                <Typography variant="subtitle1" color="primary" sx={{fontWeight: 500, fontSize: '14px'}}>
                    {t('book_location.booking_summary.first_section_subtitle')}
                </Typography>
                <Box sx={{ fontWeight: 400, fontSize: '14px', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{t('book_location.booking_summary.dates')}</Typography>
                    <Typography>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</Typography>
                </Box>
                <Box className="flex justify-between">
                    <Typography>{t('book_location.booking_summary.number_of_guests')}</Typography>
                    <Typography>{numberOfGuests}</Typography>
                </Box>
            </Box>

            <Box className="pt-2 ">
                <Typography variant="subtitle1" color="primary" className="mb-2 text-base" style={{ fontWeight: 'bold' }}>
                    {t('book_location.booking_summary.second_section_subtitle')}
                </Typography>
                <Typography className="mb-4 text-sm">
                    <span className='text-gray-500'>{t('book_location.booking_summary.services_description')}</span>
                </Typography>

                {displayedServices.filter((service) => !service.isPaymentCompulsory).map((service, index) => (
                    <Box key={index} className="flex items-center justify-between ">
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    sx={{
                                        color: '#6b7280', 
                                        '&.Mui-checked': {
                                            color: '#26DDE6', 
                                        },
                                        pr: '2px'
                                    }}
                                    checked={checkedServices[service.serviceName]}
                                    onChange={() => handleCheckboxChange(service.serviceName)}
                                />
                            }
                            label={
                                <Typography className="text-sm">
                                    {service.serviceName}
                                    {service.unitPrice === 0 ? (
                                        <span className="text-gray-500"> | {t('book_location.booking_summary.free')}</span>
                                    ) : (
                                        service.unitPrice && (
                                            <span className="text-gray-500">
                                                {` | ${service.unitPrice}€ ${t("apartment.per_person")} `}
                                            </span>
                                        )
                                    )}
                                </Typography>
                            }
                            sx={{
                                marginRight: '-15px',
                            }}
                        />
                        {checkedServices[service.serviceName] && service.quantity === true && (
                            <Box className="flex items-center gap-1">
                                <button onClick={() => handleDecrement(service.serviceName)} className="rounded-full border border-gray-400 p-2 text-sm flex items-center justify-center w-4 h-4 text-black">
                                -
                                </button>
                                <div className="rounded-full border border-gray-400 px-3 text-center text-sm text-black">
                                {quantities[service.serviceName]}
                                </div>
                                <button onClick={() => handleIncrement(service.serviceName)} className="rounded-full border border-gray-400 p-2 text-sm flex items-center justify-center w-4 h-4 text-black">
                                +
                                </button>
                            </Box>
                        )}
                    </Box>
                ))}

                {additionalServices.length > 4 && (
                    <Typography
                        onClick={() => setShowAll(!showAll)}
                        sx={{
                            color: '#26DDE6',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            mt: 1
                        }}
                    >
                        <>
                            {showAll ? t("apartment.show_less") : t("apartment.show_more")}
                            <KeyboardArrowDownIcon 
                                sx={{ 
                                    transform: showAll ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s'
                                }} 
                            />
                        </>
                    </Typography>
                )}
            </Box>

            <Box className="flex gap-2 mt-2 mb-8">
                <TextField
                    placeholder={t("general.enter_coupon_code")}
                    size="small"
                    className="flex-1"
                    sx={{
                        '& .MuiInputBase-root': {
                            height: '40px',
                        },
                    }}
                    onChange={(e) => setVoucherCode(e.target.value)}
                />
                <ConfirmButton
                    sx={{
                        backgroundColor: '#26DDE6',
                        color: 'white',
                        height: '40px',
                        '&:hover': {
                            backgroundColor: '#26DDE6',
                        },
                        textTransform: 'none',
                    }} 
                    loading={isCouponReedemptionLoading}
                    onClick={handleVoucherApply}
                >
                    {t('orders.ticket_overview.button')}
                </ConfirmButton>
            </Box>

            {/* Price Details Section */}
            <Box className="p-2 mt-2 border border-gray-200 rounded-md">
                <Typography variant="body2" color="primary" sx={{ fontWeight: 600, marginBottom: '8px'}}>
                    {t('book_location.booking_summary.price_details')}
                </Typography>

                <Box className="space-y-2">
                    <Box className="flex justify-between">
                        <Typography>{`${numberOfNights} ${t("apartment.night", {count:numberOfNights})}`}</Typography>
                        <Typography>{formatCurrency(numberOfNights * pricePerNight, 'de-DE')}</Typography>
                    </Box>

                    {/* <Box className="flex justify-between">
                        <Typography>{t('book_location.booking_summary.cleaning_fee')}</Typography>
                        <Typography>50€</Typography>
                    </Box> */}

                    {
                        additionalServices
                            .filter((service) => service.isPaymentCompulsory)
                            .map((service) => (
                                <Box key={service.id} className="flex justify-between">
                                <Typography>{service.serviceName}</Typography>
                                <Typography>{formatCurrency(service.unitPrice, 'de-DE')}</Typography>
                                </Box>
                            ))
                    }

                    <Box className="flex justify-between">
                        <Typography>{t('book_location.booking_summary.additional_services')}</Typography>
                        <Typography>{formatCurrency(additionalServicesTotal, 'de-DE')}</Typography>
                    </Box>

                    {
                        reductionPrice > 0 &&
                        <Box className="flex justify-between">
                            <Typography>{t('book_location.booking_summary.reduction')}</Typography>
                            <Typography>-{formatCurrency(reductionPrice, 'de-DE')}</Typography>
                        </Box>
                    }

                    <Box className="flex justify-between pt-3 font-medium border-t border-gray-200">
                        <Typography>{t('book_location.booking_summary.total')}</Typography>
                        <Typography>{formatCurrency(totalPrice, 'de-DE')}</Typography>
                    </Box>

                    <Typography color="text.secondary" className="text-sm">
                        {t('book_location.confirmation.tax_information', {
                            taxRate: tenantConfig?.taxRate,
                        })}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

export default BookingSummary;
