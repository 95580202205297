import React from "react";
import ban from "../../../assets/icons/ban.svg";
import { useTranslation } from "react-i18next";
import { navigatedToPreviousStep } from "../../../redux/slice/apartmentRental/apartmentRentalSlice";
import { useDispatch } from "react-redux";

const NoResult = () => {
  const dispatch = useDispatch();
  const handleBackClick = () => {
    dispatch(navigatedToPreviousStep());
  };
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center p-8 text-center">
      <div
        className="mb-4 p-4 rounded-full"
        style={{ backgroundColor: "#C9F7F9" }}
      >
        <img
          src={ban}
          alt="No results found"
          width="48"
          height="48"
          className="text-gray-500"
        />
      </div>
      <h3 className="mb-2 text-lg font-semibold text-gray-900">
        {t("apartment.no_apartment.title")}
      </h3>
      <p className="mb-6 text-sm text-gray-600">
        {t("apartment.no_apartment.sub_title")}
      </p>
      <button
        className="px-4 py-2 text-sm font-medium text-black border border-[#00C9C9]
         rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-[#00C9C9]"
        onClick={handleBackClick}
      >
        {t("apartment.no_apartment.button_title")}
      </button>
    </div>
  );
};

export default NoResult;
