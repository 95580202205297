import { Box } from '@mui/material';
import TenantLogo from './TenantLogo';

const SplashScreen = () => {
    return (
        <Box
            sx={{
                 height: '100vh', // Full viewport height
                 width: '100vw',  // Full viewport width
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center', // Center content vertically
                 flexDirection: 'column-reverse',
            }}
        >
            <Box
                sx={(theme) => ({
                    padding: theme.spacing(4),
                })}
            >
                <TenantLogo />
            </Box>
        </Box>
    );
};

export default SplashScreen;