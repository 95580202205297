import React, { useEffect, useRef, useState } from "react";
import FloatingViewMapButton from "../common/util/FloatingViewMapButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
interface MarkerData {
  position: google.maps.LatLngLiteral;
  label: string;
  title: string;
  description: string;
  image: string;
}
const GoogleMapWithMarkers: React.FC = () => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<MarkerData | null>(null);

  const { locationApartmentList, selectedMarker: currentSelectedMarker } =
    useSelector((state: RootState) => state.apartment)!;

  useEffect(() => {
    const initMap = (): void => {
      const mapCenter = {
        lat: locationApartmentList?.[0]?.geoLat ?? 0.0,
        lng: locationApartmentList?.[0]?.geoLng ?? 0,
      };
      // Update map options to match the style
      const map = new google.maps.Map(mapRef.current as HTMLElement, {
        zoom: 13,
        center: mapCenter,
        disableDefaultUI: true, // Disable default UI controls
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide points of interest labels
          },
        ],
      });
      // Extended marker data to match the image

      function transformData(): MarkerData[] {
        return locationApartmentList?.map((item: any) => {
          return {
            position: {
              lat: item?.geoLat,
              lng: item?.geoLng,
            },
            label: `€${item?.price?.toString()}`,
            title: item?.name,
            description: item?.description,
            image: item?.featuredImage,
          };
        });
      }

      const markers: MarkerData[] = transformData();

      // Add global styles for InfoWindows
      const style = document.createElement("style");
      style.textContent = `
        .gm-style-iw-c {
          padding: 0 !important;
          background: transparent !important;
          box-shadow: none !important;
          max-width: none !important;
          border: none !important;
        }
        .gm-style-iw-d {
          overflow: visible !important;
          max-width: none !important;
        }
        .gm-style-iw-tc {
          display: none !important;
        }
        .gm-ui-hover-effect {
          display: none !important;
        }
      `;
      document.head.appendChild(style);
      // Add global click handler function to window
      (window as any).handleMarkerClick = function (markerData: any) {
        const modalContent = document.createElement("div");
        modalContent.innerHTML = `
          <div style="
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            max-width: 350px;
            background: white;
            border-radius: 12px;
            box-shadow: 0 2px 10px rgba(0,0,0,0.1);
            overflow: hidden;
            z-index: 1000;
          ">
            <div style="position: relative;">
              <!-- Close Button -->
              <button
                onclick="document.querySelector('.location-modal').remove()"
                style="
                  position: absolute;
                  top: 8px;
                  right: 8px;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  background: #F5F5F5;
                  border: none;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0;
                  z-index: 1;
                "
              >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M1 1L13 13M1 13L13 1" stroke="#666" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </button>
              <div style="display: flex; padding: 12px;">
                <img src="${markerData.image}" alt="${markerData.title}" style="
                  width: 80px;
                  height: 80px;
                  object-fit: cover;
                  border-radius: 8px;
                  margin-right: 12px;
                ">
                <div style="flex: 1;">
                  <h3 style="
                    margin: 0 0 4px 0;
                    font-size: 14px;
                    font-weight: 500;
                  ">${markerData.title}</h3>
                  <p style="
                    margin: 0;
                    font-size: 12px;
                    color: #666;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  ">${markerData.description}</p>
                </div>
              </div>
            </div>
          </div>
        `;
        // Remove any existing modal
        const existingModal = document.querySelector(".location-modal");
        if (existingModal) {
          existingModal.remove();
        }
        modalContent.firstElementChild?.classList.add("location-modal");
        document.body.appendChild(modalContent);
      };
      markers.forEach((markerData, index) => {
        const { position, label } = markerData;
        const marker = new google.maps.Marker({
          position,
          map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 0,
          },
        });
        const infoWindow = new google.maps.InfoWindow({
          content: `
            <div
              onclick="handleMarkerClick(${JSON.stringify(markerData).replace(
                /"/g,
                "&quot;"
              )})"
              style="
                position: relative;
                padding: 6px 12px;
                font-size: 14px;
                font-weight: bold;
                background: black;
                color: white;
                border-radius: 8px;
                text-align: center;
                min-width: 65px;
                margin-bottom: 8px;
                cursor: pointer;
              "
            >
              ${label}
              <div style="
                position: absolute;
                bottom: -12px;
                left: 50%;
                transform: translateX(-50%);
                width: 20px;
                height: 12px;
                overflow: visible;
                background-color: black;
                clip-path: polygon(50% 100%, 0 0, 100% 0);
                z-index: 1;
              "></div>
            </div>
          `,
          pixelOffset: new google.maps.Size(0, -6),
          disableAutoPan: true,
        });
        // Add click listener to marker
        marker.addListener("click", () => {
          (window as any).handleMarkerClick(markerData);
        });
        infoWindow.open(map, marker);
      });
    };
    // Load the map when Google Maps API is available
    if (window.google) {
      initMap();
    } else {
      console.error("Google Maps API is not loaded.");
    }
  }, []);
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "90vh",
        bottom: 0,
        overflow: "hidden", // Prevent any potential scrolling
      }}
    >
      {/* Map - updated to fill entire container */}
      <div
        className="z-10"
        ref={mapRef}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></div>

      {/* Floating Card */}
      {selectedMarker && (
        <div
          style={{
            position: "absolute",
            bottom: "100px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "white",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            padding: "15px",
            width: "90%",
            maxWidth: "350px",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <img
              src={selectedMarker.image}
              alt={selectedMarker.title}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
            <div>
              <h3 style={{ margin: 0, fontSize: "16px", fontWeight: "bold" }}>
                {selectedMarker.title}
              </h3>
              <p style={{ margin: "5px 0", fontSize: "14px", color: "#555" }}>
                {selectedMarker.description}
              </p>
            </div>
          </div>
          <button
            style={{
              display: "block",
              marginTop: "10px",
              width: "100%",
              padding: "10px",
              fontSize: "14px",
              fontWeight: "bold",
              color: "white",
              backgroundColor: "#00C9C9",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            View details
          </button>
        </div>
      )}
      {/* Updated Show List Button */}
      <div
        style={{
          position: "absolute",
          bottom: "70px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "auto",
          minWidth: "120px",
          zIndex: 20, // Added higher z-index to appear above map
        }}
      >
        <FloatingViewMapButton isMapView />
      </div>
    </div>
  );
};
export default GoogleMapWithMarkers;
