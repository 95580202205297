import { Slide } from "@mui/material";
import React from "react";

interface FullScreenDialogProps {
  open: boolean;
  children: React.ReactNode;
}

const FullScreenDialog = (props: FullScreenDialogProps) => {
  return (
    <Slide
      in={props.open}
      direction={"up"}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <div
        className="fixed inset-0 z-50 mx-auto max-w-md overflow-y-auto bg-background"
      >
        {props.children}
      </div>
    </Slide>
  );
};

export default FullScreenDialog;
