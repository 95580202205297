import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";
import {CreateIssueReportPayload} from "../types";

export const issueReportApiSlice = createApi({
    reducerPath: "issueReportApiSlice",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["issueReportCreation"],
    endpoints: (builder) => ({
        createNewIssueReport: builder.mutation({
            // Corrected to accept data as argument and use POST method
            query: (data: CreateIssueReportPayload) => {
                const {token, ...rest} = data;
                return {
                    url: `/damage-reports`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    data: rest,
                };
            },
            invalidatesTags: ["issueReportCreation"], // Assumed you want to invalidate locationList on create
        }),
    }),
});

export const {
    useCreateNewIssueReportMutation,
} = issueReportApiSlice;
