// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store"; // Adjust the import based on your file structure
import moment from "moment";
import { CreateNewReservationData } from "../../api/types";
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import { useCreateNewReservationMutation } from "../../api/reservations/reservationsApiSlice";
import { processReservationData } from "./processors";
import { reservationCreated } from "../slice/bookingCreation/bookingCreationSlice";

// import { Reservation } from "../../types/bookingCreation";

export const useCreateNewReservation = () => {
  const tenantConfig = useSelector(
    (state: RootState) => state.tenantConfig.config
  );

  const assetGroupSelection = useSelector(
    (state: RootState) => state.bookingCreation.assetGroupId
  );
  const orderData = useSelector(
    (state: RootState) => state.bookingCreation.orderData
  );
  const [createNewReservationMutation] = useCreateNewReservationMutation();
  const dispatch = useDispatch();

  const createNewReservation = async () => {
    try {
      if (!assetGroupSelection || !orderData) {
        return;
      }

      let fromDate;
      let toDate;
      if (orderData.checkInDate && orderData.checkOutDate) {
        fromDate = orderData.checkInDate.toISOString();
        toDate = orderData.checkOutDate.toISOString();
      }
      else {        
        if (orderData.time) {
          fromDate = moment(
            `${orderData.date!.format("YYYY-MM-DD")} ${orderData.time}`,
            "YYYY-MM-DD HH:mm"
          );
        } else {
          fromDate = moment(orderData.date!).startOf("day");
        }
        
        toDate = fromDate.clone().add(orderData.duration, "minutes");
        fromDate = fromDate.toISOString();
        if (orderData.time !== "00:00") {
          toDate = toDate.toISOString(); 
        } else {
          toDate = toDate.subtract(1, "days").toISOString();
        }
      } 

      const newReservation: Array<CreateNewReservationData> = [
        {
          groupId: assetGroupSelection,
          amount: orderData.amount,
          fromDate: fromDate,
          toDate: toDate,
          isFullDay: false,
        },
      ];

      // Call the mutation function
      const response = await createNewReservationMutation(
        newReservation
      ).unwrap();

      localStorage.setItem("new_reservation", JSON.stringify(response));
      console.log("Reservation created successfully");
      const processedReservation = processReservationData(response);
      if (processedReservation) {
        dispatch(reservationCreated({ reservation: processedReservation }));
      }

      return processedReservation;
    } catch (error) {
      Logger.for("createNewReservation").error(error);
      ErrorTracker.trackException(error as Error);
      // Handle the error state or dispatch error actions if necessary
    }
  };

  return createNewReservation;
};
