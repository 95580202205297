// hooks/useRemoveReservation.ts
import { useDispatch, useSelector } from 'react-redux';
import { useRemoveReservationMutation } from '../../api/reservations/reservationsApiSlice';
import Logger from '../helpers/logger';
import ErrorTracker from '../helpers/errorTracker';
import { reservationRemoved } from '../slice/bookingCreation/bookingCreationSlice'; // Import the appropriate action if you have one
import { RootState } from '../store';
import { enqueueNotification } from '../slice/notification/notificationSlice';
import { getI18n, useTranslation } from 'react-i18next';



export const useRemoveReservation = () => {
  const [removeReservationMutation] = useRemoveReservationMutation();
  const reservations = useSelector((state: RootState) => state.bookingCreation.reservations);
  const dispatch = useDispatch();
  const { t } = useTranslation();
 
  const removeReservation = async (id?: string) => {
    try {
      let reservationId;
      if (!id) {
        reservationId = reservations?.[reservations.length - 1]?.reservationId;
      } else {
        reservationId = id;
      }
      if (!reservationId) {
        throw new Error('Reservation ID is required');
      }

      await removeReservationMutation({ reservationId }).unwrap();
      console.log('Reservation removed successfully');
      
      dispatch(reservationRemoved({reservationId})); 

      if (id) {
        dispatch(
          enqueueNotification({
            severity: 'success',
            message: t("success.delete_booking"),
          })
        );
      }

    } catch (error) {
      Logger.for('removeReservation').error(error);
      ErrorTracker.trackException(error as Error);
      dispatch(
        enqueueNotification({
          severity: 'error',
          message: t("error.delete_booking"),
        })
      );

    }
  };

  return removeReservation;
};
