import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";
import { AssetGroup } from "../../types/assetGroup";

export const processAssetGroupData = (assetGroupData: any): AssetGroup => {
  return {
    id: assetGroupData.id,
    name: assetGroupData.name,
    langNames: assetGroupData.langNames,
    description: assetGroupData.description,
    availabilities: assetGroupData.availibility,
    isFullDay: assetGroupData.isFullDay,
  };
};

export const processAssetGroupsData = (
  assetGroupsData: any[]
): AssetGroup[] => {
  return assetGroupsData.map(
    (assetGroupData: any): AssetGroup => processAssetGroupData(assetGroupData)
  );
};

export const locationsApiSlice = createApi({
  reducerPath: "locationsApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["locationList", "locationITems", "locationItemsList", "BookingDetailsByEmail", "BookingDetailsByToken"],
  endpoints: (builder) => ({
    getLocationList: builder.query({
      query: (queryParam) => {
        // Updated to not accept any arguments
        return {
          url: queryParam ? `/location?services=${queryParam}` : `/location`,
        };
      },
      providesTags: ["locationList"],
    }),

    getLocationDetails: builder.query({
      query: ({ locationId }) => {
        return {
          url: `/location/${locationId}`,
        };
      },
      providesTags: ["locationList"],
    }),

    getLocationEquipment: builder.query({
      query: ({ locationId, types }) => {
        return {
          url: types
            ? `/location/${locationId}/groups/include-price?types=${types}`
            : `/location/${locationId}/groups/include-price`,
        };
      },
      providesTags: ["locationITems"],
    }),

    getAssetGroupsByLocationIdsList: builder.query({
      query: ({ locationIds }: { locationIds: string[] }) => {
        return {
          url: `/locations/${locationIds.toString()}/groups`,
        };
      },
      transformResponse: (response: any) => {
        return processAssetGroupsData(response);
      },
      providesTags: ["locationList"],
    }),

    getDepotByLocationId: builder.query({
      query: ({ locationId }: { locationId: string }) => {
        return {
          url: `/location/${locationId}/depot`,
        };
      },
      providesTags: ["locationList"],
    }),
    getLocationItemsByLocationId: builder.query({
      query: ({ locationId }: { locationId: string }) => {
        return {
          url: `/location/${locationId}/groups/include-price`,
        };
      },
      providesTags: ["locationItemsList"],
    }),
    getBookingDetailsByEmail: builder.query({
      query: ({email}) => ({
        url: `/orders/email/${email}`,
      }),
      providesTags: ["BookingDetailsByEmail"],
    }),
    getBookingDetailsByToken: builder.query({
      query: ({token}) => ({
        url: `/orders/bookings/${token}`,
      }),
      providesTags: ["BookingDetailsByToken"],
    }),
  }),
});

export const {
  useGetLocationListQuery,
  useGetLocationEquipmentQuery,
  useGetLocationDetailsQuery,
  useGetLocationItemsByLocationIdQuery,
  useGetAssetGroupsByLocationIdsListQuery,
  useGetDepotByLocationIdQuery,
  useLazyGetBookingDetailsByEmailQuery,
  useLazyGetBookingDetailsByTokenQuery,
} = locationsApiSlice;
