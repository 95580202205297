// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store"; // Adjust the import based on your file structure
import { InitializeNoPaymentData } from "../../api/types";
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import {
  noPaymentInitialized,
} from "../slice/bookingCreation/bookingCreationSlice";

import { useInitializeNoPaymentMutation } from "../../api/payments/paymentsApiSlice";

export const useInitializeNoPayment = ({ service }: { service: string }) => {
  const reservationIds = useSelector((state: RootState) =>
    state.bookingCreation.reservations.map(
      (reservation: any) => reservation.reservationId
    )
  );

  const contact = useSelector(
    (state: RootState) => state.bookingCreation.personalData
  );

  const recipient = useSelector(
    (state: RootState) => state.bookingCreation.recipientData
  );

  const [initializeNoPaymentMutation] = useInitializeNoPaymentMutation();

  const dispatch = useDispatch();

  const initializeNoPayment = async () => {
    try {
      if (reservationIds.length && contact) {
        const returnUrl = `${
          window.location.origin
        }/payments/no_payment/return?reservationIds=${reservationIds.join(
          ","
        )}`;

        const recipientData = Object.values(recipient).every(
          (value) => value === null
        )
          ? undefined
          : recipient

        const payload: InitializeNoPaymentData = {
          reservationIds: reservationIds,
          returnUrl,
          contact: contact,
          recipient: recipientData,
          service
        };

        const response = await initializeNoPaymentMutation(payload).unwrap();

        if (response) {
          dispatch(noPaymentInitialized());
        }

        return response;
      }
    } catch (error) {
      Logger.for("createNewReservation").error(error);
      ErrorTracker.trackException(error as Error);
      // Handle the error state or dispatch error actions if necessary
    }
  };

  return initializeNoPayment;
};
