import { PaymentProvider } from "./../../../types/tenantConfig";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EquipmentListProps, LocationPros } from "../../../types/baseTypes";
import {
  BraintreePaymentToken,
  // CouponAppliedReservation,
  PersonalData,
  PersonalDataValidation,
  Reservation,
  SearchRoomDataValidation,
  SomeoneElseData,
  SomeoneElseDataValidation,
  StripeToken,
  VippsToken,
} from "../../../types/bookingCreation";
import moment from "moment";
import { Order, OrderId } from "../../../types/order";
import { getEnumNameByNumber, getEnumNumberByName } from "../../../util/enum";
import {
  isValidEmail,
  isValidPhone,
  isValidUscCustomerId,
} from "../../../util/validation";
import DefaultConfig from "../../../util/defaultConfig";
import { AssetGroupAvailability } from "../../../types/assetGroup";

export enum BookingCreationERStep {
  locationSelection,
  assetGroupSelection,
  orderData,
  confirmation,
  personalData,
  paymentTypeSelection,
  payment,
}

export enum BookingCreationP2PStep {
  locationSelection,
  assetGroupSelection,
  orderData,
  recipientData,
  confirmation,
  personalData,
  paymentTypeSelection,
  payment,
}

export interface BookingCreationStartedPayload {
  locationId: string;
}
export interface BookingFlowPayload {
  bookingFlow: string[];
}

export interface DateSelectedPayload {
  date: moment.Moment | null;
}

export interface CheckInDateSelectedPayload {
  checkInDate: moment.Moment | null;
}

export interface CheckOutDateSelectedPayload {
  checkOutDate: moment.Moment | null;
}

export interface NumberOfGuestsSelectedPayload {
  numberOfGuests: number;
}

export interface TimeSelectedPayload {
  time: string;
}

export interface DurationSelectedPayload {
  duration: number;
}

export interface AssetCountSelectedPayload {
  assetCount: number;
}

export interface AssetGroupActivatedPayload {
  id: string;
}

export interface AssetGroupPayload {
  id: string;
}

export interface AssetGroupDeactivatedPayload {
  id: string;
}

export interface AssetGroupCountChangedPayload {
  id: string;
  count: number;
}

export interface PaymentTypeChangedPayload {
  paymentType: string;
}

export interface ReservationCreatedPayload {
  reservation: Reservation;
}

export interface ReservationRemovedPayload {
  reservationId?: string;
}

export interface FirstNameChangedPayload {
  firstName: string;
}

export interface LastNameChangedPayload {
  lastName: string;
}

export interface SomeoneElseFullNameChangedPayload {
  fullName: string;
}

export interface SomeoneElseEmailChangedPayload {
  email: string;
}

export interface PhoneChangedPayload {
  phone: string;
}

export interface EmailChangedPayload {
  email: string;
}

export interface AgreementChangedPayload {
  isAgreed: boolean;
}

export interface NewsletterConsentChangedPayload {
  newsletterConsent: boolean;
}

export interface UscCustomerIdChangedPayload {
  uscCustomerId: string;
}

export interface PersonalDataChangedPayload {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  newsletterConsent: boolean;
  uscCustomerId?: string;
}

export interface CouponRedemptionLoadingPayload {
  loading: boolean;
}

export interface BookingCreationCanceledPayload {}

export interface BraintreePaymentInitializedPayload {
  braintreePaymentToken: BraintreePaymentToken;
}

export interface VippsPaymentInitializedPayload {
  vippsToken: VippsToken;
  checkoutFrontendUrl: string;
}
export interface StripePaymentInitializedPayload {
  token: StripeToken;
  checkoutFrontendUrl: string;
}

export interface NoPaymentInitializedPayload {
  orderIds: string[];
  reservationIds: string[];
  statusCode: string;
}

export interface PaymentInitializationFailedPayload {}

export interface PaymentInitializationRetryStartedPayload {}

export interface PaymentCompletionBeganPayload {}

export interface PaymentCompletedPayload {
  orderId: OrderId;
  orderIds?: OrderId[];
  orders?: Order[];
}

export interface PaymentCompletionFailedPayload {}

export interface BookingStepChangedPayload {
  step: number;
  nextEnabled: boolean;
}

export interface BookingCreationCompletedPayload {}

export interface BookingCreationSelection {
  date: moment.Moment | null;
  checkInDate: moment.Moment | null;
  checkOutDate: moment.Moment | null;
  time: string;
  duration: number;
  amount: number;
  numberOfGuests: number;
}

export interface GroupsByLocation {
  [locationId: string]: EquipmentListProps[];
}

export interface GroupsByLocationPayload {
  locationId: string;
  groups: EquipmentListProps[];
}

export interface UseCasePayload {
  useCase: string;
}

export interface AdditionalService {
  serviceId: string;
  name: string;
  quantity: number; // -1 means no quantity for the service
}

export interface RemoveService {
  name: string;
}

export interface SomeoneElse {
  isForSomeoneElse: boolean;
}

export interface RoomRentalVoucher {
  id: string;
  code: string;
  discountIsPercentage: boolean;
  discountValue: number;
}

export interface RoomRentalLocation {
  city: string | null;
  district: string | null;
}


export interface AddAnotherEquipmentPayload {}

export interface BookingCreationState {
  locationId: string | null;
  multipleBoardTypes: boolean;
  isAddingAnotherEquipment: boolean;
  partner: "usc" | null;
  step: number;
  nextEnabled: boolean;
  orderData: BookingCreationSelection;
  assetGroupId: string | null;
  availabilites: AssetGroupAvailability | null;
  personalData: PersonalData;
  recipientData: PersonalData;
  someoneElseData: SomeoneElseData;
  personalDataValidation: PersonalDataValidation;
  recipientDataValidation: PersonalDataValidation;
  someoneElseDataValidation: SomeoneElseDataValidation;
  reservations: Reservation[];
  payment: {
    provider: "braintree" | "vipps" | "stripe" | "no_payment" | null;
    paymentType: string;
    paymentInitFailed: boolean;
    // orderId: null | OrderId;
    orderIds?: OrderId[] | null;
    orders?: Order[] | null;
    braintree: {
      paymentToken: null | BraintreePaymentToken;
    };
    vipps: {
      token: null | VippsToken;
      checkoutFrontendUrl: null | string;
    };
    stripe: {
      token: null | StripeToken;
      checkoutFrontendUrl: null | string;
    };
  };
  appliedCouponCode: string | null;
  loading: {
    couponRedemption: boolean;
    paymentCompletion: boolean;
  };
  bookingFlow: string[];
  selectedLocation: LocationPros | null;
  selectedCity: string | null;
  hasSelectedLocation: boolean;
  selectedGroup: EquipmentListProps | null;
  groupsByLocation: GroupsByLocation;
  useCase: string | null;
  additionalServices: AdditionalService[];
  isForSomeoneElse: boolean;
  roomRentalVoucher: RoomRentalVoucher | null;
  totalPrice: number;
  roomRentalLocation: RoomRentalLocation;
  loader: boolean;
}

const initialState: BookingCreationState = {
  locationId: null,
  multipleBoardTypes: false,
  isAddingAnotherEquipment: false,
  partner: null,
  step: 0,
  nextEnabled: false,
  orderData: {
    date: null,
    checkInDate: null,
    checkOutDate: null,
    time: "0",
    duration: 0,
    amount: 1,
    numberOfGuests: 1,
  },
  assetGroupId: null,
  availabilites: null,
  personalData: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    newsletterConsent: false,
    isAgreed: false,
  },
  recipientData: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    newsletterConsent: false,
    isAgreed: false,
  },
  someoneElseData: {
    fullName: "",
    email: "",
  },
  personalDataValidation: {
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    uscCustomerId: false,
    isAgreed: false,
  },
  someoneElseDataValidation: {
    fullName: false,
    email: false,
  },
  recipientDataValidation: {
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    uscCustomerId: false,
    isAgreed: false,
  },
  reservations: [],
  payment: {
    provider: null,
    paymentType: "",
    paymentInitFailed: false,
    orderIds: null,
    braintree: {
      paymentToken: null,
    },
    vipps: {
      token: null,
      checkoutFrontendUrl: null,
    },
    stripe: {
      token: null,
      checkoutFrontendUrl: null,
    },
  },
  appliedCouponCode: null,
  loading: {
    couponRedemption: false,
    paymentCompletion: false,
  },
  bookingFlow: [],
  selectedLocation: null,
  selectedCity: null,
  hasSelectedLocation: false,
  selectedGroup: null,
  groupsByLocation: {},
  useCase: null,
  additionalServices: [],
  isForSomeoneElse: false,
  roomRentalVoucher: null,
  totalPrice: 0,
  roomRentalLocation: {
    city: null,
    district: null
  },
  loader: false,
};

const bookingCreationSlice = createSlice({
  name: "bookingCreation",
  initialState,
  reducers: {
    bookingFlowChanged: (state, action: PayloadAction<BookingFlowPayload>) => {
      state.bookingFlow = [...action.payload.bookingFlow];
    },
    bookingCreationStarted: (
      state,
      action: PayloadAction<BookingCreationStartedPayload>
    ) => {
      state.locationId = action.payload.locationId;
      state.personalData = {
        ...initialState.personalData,
      };
    },
    bookingCreationCanceled: () => initialState,
    setNextEnabled: (state, action: PayloadAction<boolean>) => {
      state.nextEnabled = action.payload;
    },
    
    setAvailabilities: (
      state,
      action: PayloadAction<AssetGroupAvailability>
    ) => {
      state.availabilites = action.payload;
    },
    navigatedToNextStep: (state) => {
      // Custom logic for navigating to the next step
      const paymentProvider = DefaultConfig.paymentProvider;

      let nextStep = state.step;
      do {
        nextStep++;
        if (state.step === BookingCreationERStep.locationSelection) {
          // Do nothing
        } else if (state.step === BookingCreationERStep.assetGroupSelection) {
          // Do nothing
        } else if (state.step === BookingCreationERStep.confirmation) {
          // Do nothing
        } else if (
          state.useCase === "rental" &&
          state.step === BookingCreationERStep.personalData &&
          paymentProvider &&
          paymentProvider.length === 1
        ) {
          state.payment.provider = paymentProvider[0] as PaymentProvider;

          nextStep = BookingCreationERStep.payment;
        } else if (
          state.useCase === "p2p" &&
          state.step === BookingCreationP2PStep.personalData &&
          paymentProvider &&
          paymentProvider.length === 1
        ) {
          state.payment.provider = paymentProvider[0] as PaymentProvider;

          nextStep = BookingCreationP2PStep.payment;
        }

        const nextStepName = getEnumNameByNumber(
          state.useCase === "rental"
            ? BookingCreationERStep
            : BookingCreationP2PStep,
          nextStep
        );
        if (!nextStepName) {
          throw new Error("Invalid step");
        }
        if (state.bookingFlow.indexOf(nextStepName) === -1) {
          // Break if the step is not found in booking flow
          break;
        }
      } while (
        state.bookingFlow.indexOf(
          getEnumNameByNumber(
            state.useCase === "rental"
              ? BookingCreationERStep
              : BookingCreationP2PStep,
            nextStep
          )
        ) === -1
      );

      state.step = nextStep;
      state.nextEnabled =
        (nextStep === BookingCreationERStep.confirmation &&
          state.useCase === "rental") ||
        (nextStep === BookingCreationP2PStep.confirmation &&
          state.useCase === "p2p") ||
        ((nextStep === BookingCreationP2PStep.personalData ||
          nextStep === BookingCreationERStep.personalData) &&
          state.useCase !== null &&
          isPersonalDataValid(
            validatePersonalData(state.personalData, state.useCase),
            state.useCase
          ));
    },
    navigatedToPreviousStep: (state) => {
      const bookingFlow = state.bookingFlow;
      let currentStepIndex;
      if (state.useCase === "rental") {
        currentStepIndex = bookingFlow.indexOf(
          getEnumNameByNumber(BookingCreationERStep, state.step)
        );
      } else {
        currentStepIndex = bookingFlow.indexOf(
          getEnumNameByNumber(BookingCreationP2PStep, state.step)
        );
      }

      const previousStepName = bookingFlow[currentStepIndex - 1];

      let previousStep;
      if (state.useCase === "rental") {
        if (state.isAddingAnotherEquipment) {
          previousStep = BookingCreationERStep.confirmation;
          state.isAddingAnotherEquipment = false;
        } else {
          previousStep = getEnumNumberByName(
            BookingCreationERStep,
            previousStepName
          );
        }
      } else {
        previousStep = getEnumNumberByName(
          BookingCreationP2PStep,
          previousStepName
        );
      }
      const resetBoardSelection = previousStep === 0;

      state.step = previousStep;
      state.assetGroupId = resetBoardSelection
        ? initialState.assetGroupId
        : state.assetGroupId;
      state.nextEnabled = true;
    },
    dateSelected: (state, action: PayloadAction<DateSelectedPayload>) => {
      state.nextEnabled = false;
      state.orderData = {
        ...initialState.orderData,
        date: action.payload.date,
      };
    },
    dateSelectedClear: (state) => {
      state.orderData = {
        ...initialState.orderData,
        date: null,
      };
    },
    checkInDateSelected: (state, action: PayloadAction<CheckInDateSelectedPayload>) => {
      state.nextEnabled = false;
      state.orderData.checkInDate = action.payload.checkInDate;
      if (state.useCase === 'room') {
        if (isSearchRoomDataValid({
          city: state.roomRentalLocation.city !== null,
          district: state.roomRentalLocation.district !== null,
          checkInDate: true, 
          checkOutDate: state.orderData.checkOutDate !== null,
          numberOfGuests: true, 
        }) === true) {
          state.nextEnabled = true;
        }
      }
    },
    checkInDateSelectedClear: (state) => {
      state.orderData.checkInDate = null;
      state.nextEnabled = false;
    },
    checkOutDateSelected: (state, action: PayloadAction<CheckOutDateSelectedPayload>) => {
      state.nextEnabled = false;
      state.orderData.checkOutDate = action.payload.checkOutDate;
      if (state.useCase === 'room') {
        if (isSearchRoomDataValid({
          city: state.roomRentalLocation.city !== null,
          district: state.roomRentalLocation.district !== null,
          checkInDate: state.orderData.checkInDate !== null,
          checkOutDate: true,
          numberOfGuests: true, 
        }) === true) {
          state.nextEnabled = true;
        }
      }
    },
    checkOutDateSelectedClear: (state) => {
      state.orderData.checkOutDate = null;
      state.nextEnabled = false;
    },

    numberOfGuestsSelected: (state, action: PayloadAction<NumberOfGuestsSelectedPayload>) => {
      state.nextEnabled = false;
      state.orderData = {
        ...initialState.orderData,
        numberOfGuests: action.payload.numberOfGuests,
      };
    },
    numberOfGuestsSelectedClear: (state) => {
      state.orderData = {
        ...initialState.orderData,
        numberOfGuests: 1,
      };
    },
    timeSelected: (state, action: PayloadAction<TimeSelectedPayload>) => {
      state.nextEnabled = false;
      state.orderData = {
        ...state.orderData,
        time: action.payload.time,
        duration: 0,
        amount: 1,
      };
    },
    durationSelected: (
      state,
      action: PayloadAction<DurationSelectedPayload>
    ) => {
      state.nextEnabled = Boolean(action.payload.duration);
      state.orderData = {
        ...state.orderData,
        duration: action.payload.duration,
        amount: 1,
      };
    },
    durationSelectedClear: (state) => {
      state.orderData = {
        ...state.orderData,
        duration: 0,
        amount: 0,
      };
    },
    assetCountSelected: (
      state,
      action: PayloadAction<AssetCountSelectedPayload>
    ) => {
      state.orderData.amount = action.payload.assetCount;
    },
    setAssetGroup: (state, action: PayloadAction<AssetGroupPayload>) => {
      state.assetGroupId = action.payload.id;
    },
    assetGroupActivated: (
      state,
      action: PayloadAction<AssetGroupActivatedPayload>
    ) => {
      state.assetGroupId = action.payload.id;
      state.nextEnabled = true;
    },
    assetGroupDeactivated: (state) => {
      state.assetGroupId = null;
      state.selectedGroup = null;
      state.nextEnabled = false;
    },
    assetGroupCountChanged: (
      state,
      action: PayloadAction<AssetGroupCountChangedPayload>
    ) => {
      state.assetGroupId = action.payload.id;
      state.nextEnabled =
        state.step === 1 ? action.payload.count >= 1 : state.nextEnabled;
    },
    reservationCreated: (
      state,
      action: PayloadAction<ReservationCreatedPayload>
    ) => {
      console.log('updating the reservations state with ', action.payload.reservation)
      const newReservations = [ ...state.reservations, action.payload.reservation];
      state.reservations = newReservations;
    },
    reservationRemoved: (
      state,
      action: PayloadAction<ReservationRemovedPayload>
    ) => {
      if (!action.payload.reservationId) {
        state.reservations.pop();
      } else {
        const reservationId = action.payload.reservationId;

        const index = state.reservations.findIndex(
          (reservation) => reservation.reservationId === reservationId
        );

        if (index !== -1) {
          state.reservations.splice(index, 1);
        }
      }
    },
    couponAppliedToReservations: (
      state,
      action: PayloadAction<Reservation[]>
    ) => {
      const allReservations = state.reservations.map((reservation) => {
        const foundReservation = action.payload.find(
          (r) => r.id === reservation.reservationId
        );

        if (foundReservation) {
          return {
            ...reservation,
            payment: {
              ...reservation.payment,
              ...foundReservation.payment,
              totalPrice: reservation.payment.totalPrice, // Preserve the original totalPrice
            },
          };
        }

        return reservation;
      });

      state.reservations = allReservations;
    },
    setAppliedCouponCode: (state, action: PayloadAction<string>) => {
      state.appliedCouponCode = action.payload;
    },
    firstNameChanged: (
      state,
      action: PayloadAction<FirstNameChangedPayload>
    ) => {
      state.personalData.firstName = action.payload.firstName.trim();
      state.personalDataValidation = validatePersonalData(
        state.personalData,
        state.useCase
      );
      state.nextEnabled = isPersonalDataValid(
        state.personalDataValidation,
        state.useCase
      );
    },
    lastNameChanged: (state, action: PayloadAction<LastNameChangedPayload>) => {
      state.personalData.lastName = action.payload.lastName.trim();
      state.personalDataValidation = validatePersonalData(
        state.personalData,
        state.useCase
      );
      state.nextEnabled = isPersonalDataValid(
        state.personalDataValidation,
        state.useCase
      );
    },
    phoneChanged: (state, action: PayloadAction<PhoneChangedPayload>) => {
      if (
        action.payload.phone !== "" &&
        !action.payload.phone.trim().startsWith("+")
      ) {
        state.personalData.phone = `+${action.payload.phone.trim()}`;
      } else {
        state.personalData.phone = action.payload.phone.trim();
      }
      state.personalDataValidation = validatePersonalData(
        state.personalData,
        state.useCase
      );
      state.nextEnabled = isPersonalDataValid(
        state.personalDataValidation,
        state.useCase
      );
    },
    emailChanged: (state, action: PayloadAction<EmailChangedPayload>) => {
      state.personalData.email = action.payload.email.trim();
      state.personalDataValidation = validatePersonalData(
        state.personalData,
        state.useCase
      );
      state.nextEnabled = isPersonalDataValid(
        state.personalDataValidation,
        state.useCase
      );
    },
    recipientFirstNameChanged: (
      state,
      action: PayloadAction<FirstNameChangedPayload>
    ) => {
      state.recipientData.firstName = action.payload.firstName.trim();
      state.recipientDataValidation = validatePersonalData(
        state.recipientData,
        state.useCase
      );
      state.nextEnabled = isRecipientDataValid(state.recipientDataValidation);
    },
    recipientLastNameChanged: (
      state,
      action: PayloadAction<LastNameChangedPayload>
    ) => {
      state.recipientData.lastName = action.payload.lastName.trim();
      state.recipientDataValidation = validatePersonalData(
        state.recipientData,
        state.useCase
      );
      state.nextEnabled = isRecipientDataValid(state.recipientDataValidation);
    },
    recipientPhoneChanged: (
      state,
      action: PayloadAction<PhoneChangedPayload>
    ) => {
      if (
        action.payload.phone !== "" &&
        !action.payload.phone.trim().startsWith("+")
      ) {
        state.recipientData.phone = `+${action.payload.phone.trim()}`;
      } else {
        state.recipientData.phone = action.payload.phone.trim();
      }
      state.recipientDataValidation = validatePersonalData(
        state.recipientData,
        state.useCase
      );
      state.nextEnabled = isRecipientDataValid(state.recipientDataValidation);
    },
    recipientEmailChanged: (
      state,
      action: PayloadAction<EmailChangedPayload>
    ) => {
      state.recipientData.email = action.payload.email.trim();
      state.recipientDataValidation = validatePersonalData(
        state.recipientData,
        state.useCase
      );
      state.nextEnabled = isRecipientDataValid(state.recipientDataValidation);
    },
    agreementChanged: (
      state,
      action: PayloadAction<AgreementChangedPayload>
    ) => {
      state.personalData.isAgreed = action.payload.isAgreed;
      state.personalDataValidation = validatePersonalData(
        state.personalData,
        state.useCase
      );
      state.nextEnabled = isPersonalDataValid(
        state.personalDataValidation,
        state.useCase
      );
    },
    newsletterConsentChanged: (
      state,
      action: PayloadAction<NewsletterConsentChangedPayload>
    ) => {
      state.personalData.newsletterConsent = action.payload.newsletterConsent;
    },
    paymentTypeChanged: (
      state,
      action: PayloadAction<PaymentTypeChangedPayload>
    ) => {
      state.payment.paymentType = action.payload.paymentType;
    },
    uscCustomerIdChanged: (
      state,
      action: PayloadAction<UscCustomerIdChangedPayload>
    ) => {
      state.personalData.uscCustomerId = action.payload.uscCustomerId.trim();
      state.personalDataValidation = validatePersonalData(
        state.personalData,
        state.useCase
      );
      state.nextEnabled = isPersonalDataValid(
        state.personalDataValidation,
        state.useCase
      );
    },
    personalDataChanged: (
      state,
      action: PayloadAction<PersonalDataChangedPayload>
    ) => {
      state.personalData = {
        ...state.personalData,
        ...action.payload,
      };
      state.personalDataValidation = validatePersonalData(
        state.personalData,
        state.useCase
      );
    },
    someoneElseFullNameChanged: (
      state,
      action: PayloadAction<SomeoneElseFullNameChangedPayload>
    ) => {
      state.someoneElseData.fullName = action.payload.fullName;
      state.someoneElseDataValidation = validateSomeoneElseData(
        state.someoneElseData,
        state.useCase
      );
      state.nextEnabled = isSomeoneElseDataValid(
        state.someoneElseDataValidation,
        state.useCase
      ) && isPersonalDataValid(
        state.personalDataValidation,
        state.useCase
      );
    },
    someoneElseEmailChanged: (
      state,
      action: PayloadAction<SomeoneElseEmailChangedPayload>
    ) => {
      state.someoneElseData.email = action.payload.email;
      state.someoneElseDataValidation = validateSomeoneElseData(
        state.someoneElseData,
        state.useCase
      );
      state.nextEnabled = isSomeoneElseDataValid(
        state.someoneElseDataValidation,
        state.useCase
      ) && isPersonalDataValid(
        state.personalDataValidation,
        state.useCase
      );
    },
    couponRedemptionLoading: (
      state,
      action: PayloadAction<CouponRedemptionLoadingPayload>
    ) => {
      state.loading.couponRedemption = action.payload.loading;
    },
    initializePaymentProvider: (
      state,
      action: PayloadAction<PaymentProvider | null>
    ) => {
      state.payment.provider = action.payload;
    },
    braintreePaymentInitialized: (
      state,
      action: PayloadAction<BraintreePaymentInitializedPayload>
    ) => {
      state.payment.provider = "braintree";
      state.payment.braintree = {
        paymentToken: action.payload.braintreePaymentToken,
      };
    },
    vippsPaymentInitialized: (
      state,
      action: PayloadAction<VippsPaymentInitializedPayload>
    ) => {
      state.payment.provider = "vipps";
      state.payment.vipps = {
        token: action.payload.vippsToken,
        checkoutFrontendUrl: action.payload.checkoutFrontendUrl,
      };
    },
    stripePaymentInitialized: (
      state,
      action: PayloadAction<StripePaymentInitializedPayload>
    ) => {
      state.payment.provider = "stripe";
      state.payment.stripe = {
        token: action.payload.token,
        checkoutFrontendUrl: action.payload.checkoutFrontendUrl,
      };
    },
    noPaymentInitialized: (state) => {
      state.payment.provider = "no_payment";
    },
    completeStripePayment(
      state,
      action: PayloadAction<{ reservationId: string }>
    ) {},
    paymentInitializationFailed: (
      state,
      action: PayloadAction<PaymentInitializationFailedPayload>
    ) => {
      state.payment.paymentInitFailed = true;
    },
    paymentInitializationRetryStarted: (
      state,
      action: PayloadAction<PaymentInitializationRetryStartedPayload>
    ) => {
      state.payment.paymentInitFailed = false;
    },
    paymentCompletionBegan: (state) => {
      state.loading.paymentCompletion = true;
    },
    setIsAddingAnotherEquipment: (state, action: PayloadAction<boolean>) => {
      state.isAddingAnotherEquipment = action.payload;
    },
    paymentCompleted: (state, action: PayloadAction<string[]>) => {
      state.payment.orderIds = action.payload;
      state.loading.paymentCompletion = false;
    },
    paymentCompletionFailed: (state) => {
      state.loading.paymentCompletion = false;
    },
    bookingStepChanged: (
      state,
      action: PayloadAction<BookingStepChangedPayload>
    ) => {
      state.step = action.payload.step;
      state.nextEnabled = action.payload.nextEnabled;
    },
    bookingCreationCompleted: () => initialState,
    setSelectedLocation: (
      state,
      action: PayloadAction<LocationPros | null>
    ) => {
      state.selectedLocation = action.payload;
      state.selectedGroup = null;
    },
    setRoomRentalLocation: (
      state,
      action: PayloadAction<RoomRentalLocation>
    ) => {
      state.roomRentalLocation = action.payload;
      state.nextEnabled = isSearchRoomDataValid({
        city: state.roomRentalLocation.city !== null,
        district: state.roomRentalLocation.district !== null, 
        checkInDate: state.orderData.checkInDate !== null, 
        checkOutDate: state.orderData.checkOutDate !== null,
        numberOfGuests: true, 
      });
    },
    setSelectedGroup: (
      state,
      action: PayloadAction<EquipmentListProps | null>
    ) => {
      state.selectedGroup = action.payload;
    },
    setHasSelectedLocation: (state, action: PayloadAction<boolean>) => {
      state.hasSelectedLocation = action.payload;
      // Re-evaluate nextEnabled based on the current state
      if (state.hasSelectedLocation) {
        if (state.useCase === 'room') {
          state.nextEnabled = isSearchRoomDataValid({
            city: true,
            district: true, 
            checkInDate: state.orderData.checkInDate !== null, 
            checkOutDate: state.orderData.checkOutDate !== null,
            numberOfGuests: true, 
          });
        } else {
          state.nextEnabled = true;
        }
      } else {
        state.nextEnabled = false;
      }   
    },
    addAnotherGroup: (
      state,
      action: PayloadAction<AddAnotherEquipmentPayload>
    ) => {
      state.orderData = {
        ...initialState.orderData,
      };
      state.nextEnabled = false;
      state.step = 1;
      state.assetGroupId = null;
      state.selectedGroup = null;
      state.personalData = {
        ...initialState.personalData,
      };
      state.personalDataValidation = {
        ...initialState.personalDataValidation,
      };
    },
    setGroupsByLocation: (
      state,
      action: PayloadAction<GroupsByLocationPayload>
    ) => {
      const locationId = action.payload.locationId;
      const groups = action.payload.groups;
      if (!state.groupsByLocation[locationId]) {
        state.groupsByLocation[locationId] = groups;
      }
    },
    setUseCase: (state, action: PayloadAction<UseCasePayload>) => {
      state.useCase = action.payload.useCase;
    },
    addService: (state, action: PayloadAction<AdditionalService>) => {
      state.additionalServices.push(action.payload);
    },
    editService: (state, action: PayloadAction<AdditionalService>) => {
      const index = state.additionalServices.findIndex(
        (service) => service.name === action.payload.name
      );
      if (index !== -1) {
        state.additionalServices[index] = { ...state.additionalServices[index], ...action.payload };
      }
    },
    removeService: (state, action: PayloadAction<RemoveService>) => {
      state.additionalServices = state.additionalServices.filter(
        (item) => item.name !== action.payload.name
      );
    },
    setForSomeoneElse: (state, action: PayloadAction<SomeoneElse>) => {
      state.isForSomeoneElse = action.payload.isForSomeoneElse;
      // Check if switching back to mySelf and fields are filled
      if (!state.isForSomeoneElse) {
        state.nextEnabled = isPersonalDataValid(
          state.personalDataValidation,
          state.useCase
        );
      } else {
        state.nextEnabled = isSomeoneElseDataValid(
          state.someoneElseDataValidation,
          state.useCase
        ) && isPersonalDataValid(
          state.personalDataValidation,
          state.useCase
        );
      }
    },
    setRoomRentalVoucher: (state, action: PayloadAction<RoomRentalVoucher>) => {
      state.roomRentalVoucher = action.payload;      
    },
    setTotalPrice: (state, action: PayloadAction<number>) => {
      state.totalPrice = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
  },
});

// @todo validating usc customer id like that is probably something that easily breaks at some point
// @todo this function should actually get the information whether this is a USC booking or not
const validatePersonalData = (
  data: PersonalData,
  useCase: string | null
): PersonalDataValidation => {
  const firstNameValid = data.firstName.length > 0;
  const lastNameValid = data.lastName.length > 0;
  const phoneValid = data.phone.length > 1 && isValidPhone(data.phone);
  const emailValid = data.email.length > 0 && isValidEmail(data.email);
  const uscCustomerIdValid =
    data.uscCustomerId === undefined ||
    (data.uscCustomerId.length > 0 && isValidUscCustomerId(data.uscCustomerId));
  const isAgreedValid = useCase === "rental" ? data.isAgreed === true : true;

  return {
    firstName: firstNameValid,
    lastName: lastNameValid,
    phone: phoneValid,
    email: emailValid,
    uscCustomerId: uscCustomerIdValid,
    isAgreed: isAgreedValid,
  };
};

const validateSomeoneElseData = (
  data: SomeoneElseData,
  useCase: string | null
): SomeoneElseDataValidation => {
  const fullNameValid = data.fullName.length > 0;
  const emailValid = data.email.length > 0 && isValidEmail(data.email);

  return {
    fullName: fullNameValid,
    email: emailValid,
  };
};

const isPersonalDataValid = (
  validation: PersonalDataValidation,
  service: string | null
): boolean => {
  if (service === "p2p") {
    return (
      validation.firstName &&
      validation.lastName &&
      validation.email &&
      validation.uscCustomerId &&
      validation.isAgreed
    );
  }
  return (
    validation.firstName &&
    validation.lastName &&
    validation.email &&
    validation.phone &&
    validation.uscCustomerId &&
    validation.isAgreed
  );
};

const isSomeoneElseDataValid = (
  validation: SomeoneElseDataValidation,
  service: string | null
): boolean => {
  return (
    validation.fullName &&
    validation.email
  );
};

const isRecipientDataValid = (validation: PersonalDataValidation): boolean => {
  return validation.firstName && validation.email;
};

const isSearchRoomDataValid = (validation: SearchRoomDataValidation): boolean => {
  return (
    validation.city
    && validation.district
    && validation.checkInDate 
    && validation.checkOutDate
    && validation.numberOfGuests
  );
};

export const {
  bookingFlowChanged,
  bookingCreationStarted,
  bookingCreationCanceled,
  navigatedToNextStep,
  navigatedToPreviousStep,
  dateSelected,
  dateSelectedClear,
  checkInDateSelected,
  checkInDateSelectedClear,
  checkOutDateSelected,
  checkOutDateSelectedClear,
  timeSelected,
  durationSelected,
  durationSelectedClear,
  assetCountSelected,
  assetGroupActivated,
  assetGroupDeactivated,
  assetGroupCountChanged,
  reservationCreated,
  reservationRemoved,
  couponAppliedToReservations,
  setAppliedCouponCode,
  firstNameChanged,
  lastNameChanged,
  phoneChanged,
  setIsAddingAnotherEquipment,
  emailChanged,
  recipientFirstNameChanged,
  recipientLastNameChanged,
  recipientPhoneChanged,
  recipientEmailChanged,
  agreementChanged,
  newsletterConsentChanged,
  paymentTypeChanged,
  uscCustomerIdChanged,
  personalDataChanged,
  someoneElseFullNameChanged,
  someoneElseEmailChanged,
  couponRedemptionLoading,
  initializePaymentProvider,
  braintreePaymentInitialized,
  vippsPaymentInitialized,
  stripePaymentInitialized,
  noPaymentInitialized,
  paymentInitializationFailed,
  paymentInitializationRetryStarted,
  paymentCompletionBegan,
  paymentCompleted,
  paymentCompletionFailed,
  bookingStepChanged,
  bookingCreationCompleted,
  setNextEnabled,
  setAvailabilities,
  setSelectedLocation,
  setRoomRentalLocation,
  setSelectedGroup,
  setHasSelectedLocation,
  addAnotherGroup,
  setGroupsByLocation,
  setAssetGroup,
  setUseCase,
  addService,
  editService,
  removeService,
  setForSomeoneElse,
  setRoomRentalVoucher,
  setTotalPrice,
  setLoader,
} = bookingCreationSlice.actions;

export default bookingCreationSlice.reducer;
