import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../redux/useDispatch";
import BookingDatePicker from "../common/components/OrderDataStep/BookingDatePicker";
import BookingTimeSelect from "../common/components/OrderDataStep/BookingTimeSelect";
import BookingDurationSelect from "../common/components/OrderDataStep/BookingDurationSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import moment from "moment-timezone";
import {
  getAvailableDurations,
  getAvailableTimeSlots,
} from "../util/orderDataStepHelper";
import {
  dateSelected,
  durationSelected,
  timeSelected,
} from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import LoaderOverlay from "../../layout/components/LoaderOverlay";
import _ from "lodash";

const styles = {
  root: {
    padding: "2px",
    paddingTop: 0,
  },
  dateTimeWrapper: {
    width: "350px",
    display: "flex",
    flexDirection: "column",
    margin: `20px auto 0 auto`,
    "@media (min-width: 1280px)": {
      width: "350px",
    },
  },
  inputWrapper: {
    width: "350px",
    display: "flex",
    flexDirection: "column",
    margin: `12px auto 0 auto`,
    "@media (min-width: 1280px)": {
      width: "350px",
    },
  },
};

const OrderDataStep = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const groupAvailabilities = useSelector(
    (state: RootState) => state.bookingCreation.availabilites
  );
  const orderData = useSelector(
    (state: RootState) => state.bookingCreation.orderData
  );
  const selectedGroup = useSelector(
    (state: RootState) => state.bookingCreation.selectedGroup
  );
  const isLoading = _.isEmpty(groupAvailabilities);

  const handleDateChange = (date: moment.Moment | null) => {
    dispatch(dateSelected({ date }));
  };

  const handleTimeChange = (time: string) => {
    dispatch(timeSelected({ time }));
  };

  let availableDates;
  if (groupAvailabilities) {
    availableDates = Object.keys(groupAvailabilities).filter((dateKey) => {
      const timeSlots = groupAvailabilities[dateKey];
      return Object.values(timeSlots).some((slot) =>
        // @ts-ignore
        Object.values(slot).some((value) => value > 0)
      );
    });
  }

  const availableTimeSlots =
    groupAvailabilities && orderData.date
      ? getAvailableTimeSlots(groupAvailabilities, orderData.date)
      : [];

  const availableDurations =
    groupAvailabilities && orderData.date && orderData.time
      ? getAvailableDurations(
          groupAvailabilities,
          orderData.date,
          orderData.time
        )
      : [];

  const enableTimeSelect = Boolean(groupAvailabilities && orderData.date);
  const enableDurationSelect = Boolean(
    groupAvailabilities && orderData.date && orderData.time !== ""
  );

  // Extract the price information
  const priceMap =
    selectedGroup?.priceCategoryInfo?.priceScale?.reduce((acc: any, curr: any) => {
      acc[curr?.durationInMinutes] = curr.price;
      return acc;
    }, {} as Record<number, number>) || {};
    

  return (
    <Box sx={styles.root}>
      {groupAvailabilities &&
        (_.isEmpty(groupAvailabilities) ? (
          <Box className="pl-5">
            <Typography>
              {t("book_location.order_data.error.no_availabilities_for_group")}
            </Typography>
          </Box>
        ) : (
          <>
            <Box sx={styles.dateTimeWrapper}>
              {availableDates && (
                <BookingDatePicker
                  value={orderData.date}
                  onChange={(date) => handleDateChange(date)}
                  shouldDisableDate={(date) => false}
                  availableDates={availableDates}
                  label={t("book_location.reservation_details.drop_off_date")}
                />
              )}
            </Box>
            <Box sx={styles.inputWrapper}>
              <BookingTimeSelect
                availableSlots={availableTimeSlots}
                value={orderData.time}
                onChange={handleTimeChange}
                availableDurations={availableDurations}
                disabled={!enableTimeSelect}
                label={t("book_location.reservation_details.drop_off_time")}
              />
            </Box>
            <Box sx={styles.inputWrapper}>
              <BookingDurationSelect
                value={orderData.duration}
                onChange={(duration) =>
                  dispatch(durationSelected({ duration }))
                }
                availableDurations={availableDurations}
                disabled={!enableDurationSelect}
                prices={priceMap}
                label={t("book_location.reservation_details.duration")}
              />
            </Box>
            <Box
              sx={styles.inputWrapper}
              className="p-2 rounded-md bg-kolula-appLightBlue2"
            >
              <p className="text-kolula-itemListBorder">
                <span className="font-bold">
                  {t("book_location.reservation_details.nb")}:{" "}
                </span>
                {t("book_location.reservation_details.note")}
              </p>
            </Box>
          </>
        ))}
      <LoaderOverlay open={isLoading} />
    </Box>
  );
};

export default OrderDataStep;
