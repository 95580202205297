import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { Order } from '../../../types/order';
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import KolulaBox from "./kolula12/KolulaBox";
import CP12Box from "./cp12/CP12Box";
import { RootState } from "../../../redux/store";
import {
  makeLocation,
  selectDepotByLocationAndDepotId,
} from "../../../redux/slice/booking/bookingSlice";
import CP67Box from "./cp67/CP67Box";
import CP82Box from "./cp82/CP82Box";
import { BookingSummary } from "../../../types/bookingCreation";
import CP20Box from "./cp20/CP20Box";

interface ContainerBoxProps {
  order: BookingSummary;
  activeDoors: number[];
}

const ContainerBox = (props: ContainerBoxProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tenantName = process.env.REACT_APP_TENANT;

  const depotId = props?.order?.items[0]?.rental?.doors[0]?.depotId || null;
  const displayDepot = useSelector((state: RootState) =>
    depotId
      ? selectDepotByLocationAndDepotId(
          state,
          props?.order?.locationId,
          depotId
        )
      : null
  );

  if (!displayDepot) {
    return null;
  }

  let containerBox;
  switch (displayDepot?.containerType) {
    // case 'db4':
    //     containerBox = <DBBox orderId={props.order.id} activeDoors={props.activeDoors} title={displayDepot.name} />;
    //     break;
    case "cp12":
      containerBox = (
        <CP12Box
          orderId={props?.order?.id}
          activeDoors={props?.activeDoors}
          title={displayDepot?.name}
        />
      );
      break;
    case "cp67":
      containerBox = (
        <CP67Box
          orderId={props?.order?.id}
          activeDoors={props?.activeDoors}
          title={displayDepot?.name}
        />
      );
      break;
    case "cp82":
      containerBox = (
        <CP82Box
          orderId={props?.order?.id}
          activeDoors={props?.activeDoors}
          title={displayDepot?.name}
        />
      );
      break;
    case "cp20": // We need to implement a cp20 layout
      containerBox = (
        <CP20Box
          orderId={props?.order?.id}
          activeDoors={props?.activeDoors}
          title={displayDepot?.name}
        />
      );
      break;
    case "kolula12":
      containerBox = (
        <KolulaBox
          orderId={props?.order?.id}
          activeDoors={props?.activeDoors}
        />
      );
      break;
    default:
      return null;
  }

  return <>{containerBox}</>;
};

export default ContainerBox;
