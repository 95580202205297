import { Box, styled, Typography } from "@mui/material";
import { t } from "i18next";
import errorIcon from '../../../../assets/icons/error-icon.svg'
import routes from "../../../../routes/routes";
import CustomButton from "../../../layout/components/CustomButton";
import { useNavigate } from "react-router";

const styles = {
    root: (theme: any) => ({
        paddingTop: theme.spacing(16),
    }),
    content: (theme: any) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",

        background: theme.palette.background.paper || theme.palette.primary.main,
        color: theme.palette.text.secondary || 'white',
        borderRadius: 0.5,
        margin: '0px 32px',
        marginBottom: '10px',
        boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)'
    }),
    actions: (theme: any) => ({
        display: "flex",
        paddingTop: theme.spacing(1),
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "10%",
    }),
    actionContainer: {
        position: "fixed",
        bottom: "16px",
        left: "0",
        right: "0",
        display: "flex",
        justifyContent: "center",
        padding: "0 16px",
        width: "full",
    },
    buttonContainer: {
        position: "fixed",
        bottom: "16px",
        left: "0",
        right: "0",
        display: "flex",
        justifyContent: "center",
        padding: "0 16px",
    },
    hyperLink: (theme: any) => ({
        textDecoration: 'underline',
        color: theme.palette.primary.light
    })
}

const StyledHeaderText = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2.25),
    width: `calc(100% - ${theme.spacing(7.5)}px)`,
    // paddingTop: theme.spacing(3),
    fontSize: theme.typography.h1.fontSize,
    textAlign: 'center',
    textTransform: 'none',
    [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(6),
    },
    padding: '42px 24px 24px 24px'
}));

function FailureMessage() {
    const navigate = useNavigate()

    const handleHomeButtonClick = () => {
        navigate(routes.home)
    }

    return (
        <Box sx={styles.root}>
            <Box sx={styles.content}>
                <StyledHeaderText variant="h1">
                    {t("itemRetrieval.errorMessage.header_text")}
                </StyledHeaderText>
                <img src={errorIcon} alt="check circle icon" />
                <Typography
                    variant="h2"
                    sx={{
                        color: (theme) => theme.palette.primary.contrastText,
                        margin: '24px 0 42px 0',
                        fontSize: (theme) => theme.typography.h5.fontSize,
                        fontWeight: (theme) => theme.typography.h5.fontWeight,
                        textAlign: 'center',
                        lineHeight: '28px'
                    }}
                >
                    {t("itemRetrieval.errorMessage.description")}
                </Typography>
            </Box>
            <Box sx={styles.buttonContainer}>
                <CustomButton
                    isButtonDark
                    disabled={false}
                    fullWidth
                    onClick={handleHomeButtonClick}
                    isOutlined
                >
                    {t("itemRetrieval.failureMessage.home_button_title")}
                </CustomButton>
            </Box>
        </Box>
    );
}

export default FailureMessage;
