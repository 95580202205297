import { Box } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { availabilitiesApiSlice } from "../../../../api/availabilities/availabilitiesApiSlice";
import CloseIcon from "../../../../components/icons/CloseIcon";
import { useApplyCoupon } from "../../../../redux/flows/applyCouponFlow";
import { useCreateNewReservation } from "../../../../redux/flows/createReservationFlow";
import { useFetchGroups } from "../../../../redux/flows/fetchGroupsFlow";
import {
  bookingCreationCompleted as EquipmentRentalBookingCreationCompleted,
  dateSelectedClear,
  durationSelectedClear,
  setAvailabilities,
  setUseCase,
} from "../../../../redux/slice/bookingCreation/bookingCreationSlice";
import {
  bookingCreationCompleted as TempStorageBookingCreationCompleted,
  newBookingFlowChanged,
  newBookingStepChanged,
} from "../../../../redux/slice/temporaryStorage/temporaryStorageSlice";
import { RootState, baseStore } from "../../../../redux/store";
import routes from "../../../../routes/routes";
import GTM from "../../../../util/gtm";
import { usePrevious } from "../../../../util/usePrevious";
import GeneralDialog from "../../../common/GeneralDialog";
import Header from "../../../layout/components/Header";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";
import TenantLogo from "../../../layout/components/TenantLogo";
import BookLocationHeader from "../../common/util/BookLocationHeader";
import TemStorageNavigateBackAction from "../../common/util/TemStorageNavigateBackAction";
import BookingSummary from "../newBooking/BookingSummary";
import DateDurationSelection from "../newBooking/DateDurationSelection";
import LocationSelection from "../newBooking/LocationSelection";
import LockerSizeSelection from "../newBooking/LockerSizeSelection";
import PersonalData from "../newBooking/PersonalData";
import Payment from "./Payment";

export enum TemporaryStorageSteps {
  locationSelection,
  lockerSizeSelection,
  dateDurationSelection,
  personalData,
  bookingSummary,
  payment,
}

const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  color: "white",
  height: "100%",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.default,
  paddingBottom: `calc(${theme.spacing(1)}px + env(safe-area-inset-bottom, 0))`,
  display: "flex",
  flexDirection: "column",
}));

export const CancelButton = styled(Box)({
  boxSizing: "border-box",
  marginLeft: "auto",
});

const StepWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "100%",
  maxWidth: 1000,
  margin: "0 auto",
  overflowY: "auto",
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(6),
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  width: "150px",
  marginTop: theme.spacing(1),
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
}));

interface BookLocationDialogProps {
  returnPage: string;
}

const TemporaryStorageNewBookingPage = (props: BookLocationDialogProps) => {
  const stepWrapperRef = useRef<HTMLElement>();
  const [pageDialogOpen, setPageDialogOpen] = useState<boolean>(false);
  const [loadingCreatingReservation, setLoadingCreatingReservation] =
    useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fetchGroups = useFetchGroups("storage");
  const assetGroupId = useSelector(
    (state: RootState) => state.bookingCreation.assetGroupId
  );
  const createNewReservation = useCreateNewReservation();

  const step = useSelector((state: RootState) => state.temporaryStorage.step);
  const reservations = useSelector(
    (state: RootState) => state.bookingCreation.reservations
  );
  const applyCoupon = useApplyCoupon();
  const appliedCouponCode = useSelector(
    (state: RootState) => state.bookingCreation.appliedCouponCode
  );

  const previousStep = usePrevious(step);
  useEffect(() => {
    stepWrapperRef.current = document.getElementById(
      "temporaryStorageProcessStepWrapper"
    )!;

    dispatch(
      newBookingStepChanged({
        step: TemporaryStorageSteps.locationSelection,
        nextEnabled: true,
      })
    );

    // Set the usecase
    dispatch(setUseCase({ useCase: "temp_storage_new_booking" }));

    // Set the bookingFlow
    dispatch(
      newBookingFlowChanged({
        newBookingFlow: [
          "locationSelection",
          "lockerSizeSelection",
          "dateDurationSelection",
          "personalData",
          "bookingSummary",
          "payment",
        ],
      })
    );
  }, []);

  const headerText = useMemo(() => {
    switch (step) {
      case TemporaryStorageSteps.locationSelection:
        return t("temporary_storage.new_booking.location.header_text");
      case TemporaryStorageSteps.lockerSizeSelection:
        return t("temporary_storage.new_booking.locker_size.header_text");
      case TemporaryStorageSteps.dateDurationSelection:
        return t("temporary_storage.new_booking.date_duration.header_text");
      case TemporaryStorageSteps.personalData:
        return t("temporary_storage.new_booking.persons_details.header_text");
      case TemporaryStorageSteps.bookingSummary:
        return t("temporary_storage.new_booking.booking_summary.header_text");
      default:
        return "";
    }
  }, [step]);

  const fetchAssetGroupAvailabilities = async (
    assetGroupId: string,
    from: Date,
    to: Date
  ) => {
    const data = await baseStore.dispatch(
      availabilitiesApiSlice.endpoints.fetchAssetGroupAvailabilities.initiate({
        assetGroupId: assetGroupId,
        from: from,
        to: to,
      })
    );
    return data;
  };

  useEffect(() => {
    const manageReduxData = async () => {
      if (step === TemporaryStorageSteps.lockerSizeSelection) {
        // Clean data
        dispatch(dateSelectedClear());
        dispatch(durationSelectedClear());
        await fetchGroups();
      } else if (
        step === TemporaryStorageSteps.dateDurationSelection &&
        assetGroupId &&
        previousStep &&
        !(previousStep > TemporaryStorageSteps.dateDurationSelection)
      ) {
        // TODO change to user input
        const fromDate = moment().tz("Europe/Berlin").toDate();
        const toDate = moment()
          .tz("Europe/Berlin")
          .add(1, "months")
          .endOf("month")
          .toDate();
        const { data: availabilities } = await fetchAssetGroupAvailabilities(
          assetGroupId,
          fromDate,
          toDate
        );

        if (availabilities) {
          dispatch(setAvailabilities(availabilities.availabilities));
        }
      }
      // // previous step is orderData, current step is personal data
      if (
        previousStep === TemporaryStorageSteps.personalData &&
        step === TemporaryStorageSteps.bookingSummary
      ) {
        if (reservations.length === 0) {
          setLoadingCreatingReservation(true);
          const processedReservation = await createNewReservation();

          if (processedReservation && appliedCouponCode) {
            const reservationIds = reservations.map(
              (reservation: any) => reservation.reservationId!
            );
            reservationIds.push(processedReservation.reservationId!);
            await applyCoupon(reservationIds, appliedCouponCode);
          }
          setLoadingCreatingReservation(false);
        }
      }

      GTM.trackEecCheckoutStep(step);
    };
    manageReduxData();
  }, [step]);

  const isLoading = () => {
    if (step === TemporaryStorageSteps.locationSelection) {
      return false;
    }

    return false;
  };

  const closePage = () => {
    navigate(routes.home);
    dispatch(TempStorageBookingCreationCompleted());
    dispatch(EquipmentRentalBookingCreationCompleted());
  };

  return (
    <Root>
      <Header>
        <div className="size-12 absolute left-0">
          <TemStorageNavigateBackAction />
        </div>
        <TenantLogo />
        <button
          className="size-12 absolute right-0 text-foreground/90"
          onClick={() => setPageDialogOpen(true)}
        >
          <CloseIcon />
        </button>
      </Header>
      <Box sx={{ marginTop: "20px", marginBottom: "30px" }}>
        <BookLocationHeader text={headerText} />
      </Box>

      <StepWrapper id={"ItemRetrievalProcessStepWrapper"}>
        {step === TemporaryStorageSteps.locationSelection && (
          <LocationSelection />
        )}
        {step === TemporaryStorageSteps.lockerSizeSelection && (
          <LockerSizeSelection />
        )}
        {step === TemporaryStorageSteps.dateDurationSelection && (
          <DateDurationSelection />
        )}
        {step === TemporaryStorageSteps.personalData && <PersonalData />}
        {step === TemporaryStorageSteps.bookingSummary && (
          <BookingSummary loading={loadingCreatingReservation} />
        )}
        {step === TemporaryStorageSteps.payment && <Payment />}
      </StepWrapper>

      <LoaderOverlay open={isLoading()} />
      <GeneralDialog
        open={pageDialogOpen}
        onClose={() => setPageDialogOpen(false)}
        onContinue={closePage}
        content={t("use_order.closing_dialog.content")}
      />
    </Root>
  );
};

export default TemporaryStorageNewBookingPage;
