import React from 'react';

interface InfoIconProps {
  color?: string;
  fill?: string;
  size?: number;
  isActive?: boolean;
}

export const InfoIcon: React.FC<InfoIconProps> = ({ 
  color = '#666666',
  fill = 'none',
  size = 32 
}) => {
  return (
    <svg 
      width={size} 
      height={size} 
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 15L15.0553 14.9723C15.8195 14.5903 16.6799 15.2805 16.4727 16.1093L15.5273 19.8907C15.3201 20.7195 16.1805 21.4097 16.9447 21.0277L17 21M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM16 11H16.01V11.01H16V11Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
