import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

// Styled component using MUI v5's `styled`
interface CP20BoxDoorProps {
    top: number;
    left: number;
    height: number;
    doorNumber: number;
    active: boolean;
}

const SquareBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'top' && prop !== 'left' && prop !== 'height' && prop !== 'active',
})<{ top: number; left: number; height: number; active: boolean }>(({ theme, top, left, height, active }) => ({
    position: 'absolute',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: '8px',
    paddingBottom: '2px',
    width: '60px',
    top: `${top}px`,
    left: `${left}px`,
    height: `${height}px`,
    backgroundColor: active ? theme.palette.primary.light : theme.palette.primary.dark,
}));

const NumberText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
    color: active ? theme.palette.text.main : "black",
    fontWeight: 'bold',
    fontSize: '10px',
}));

const CP20BoxDoor = (props: CP20BoxDoorProps) => {
    const { top, left, height, doorNumber, active } = props;

    return (
        <SquareBox top={top} left={left} height={height} active={active}>
            <NumberText active={active}>{doorNumber}</NumberText>
        </SquareBox>
    );
}

export default CP20BoxDoor;
