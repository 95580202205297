import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";
import { InitializeNoPaymentData, InitializeStripePaymentData } from "../types";

export const paymentsApiSlice = createApi({
  reducerPath: "paymentsApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["paymentList", "payment"],
  endpoints: (builder) => ({
    initializeStripePayment: builder.mutation({
      query: (payload: InitializeStripePaymentData) => {
        return {
          url: `/payment/reservations/stripe`,
          method: "POST",
          data: {
            reservationIds: payload.reservationIds, // Optional for multi reservations payment. Used for vonovia
            contact: {
              firstname: payload.contact.firstName,
              lastname: payload.contact.lastName,
              email: payload.contact.email,
              telephone: payload.contact.phone,
            },
            recipient: {
              firstname: payload.recipient?.firstName || null,
              lastname: payload.recipient?.lastName || null,
              email: payload.recipient?.email || null,
              telephone: payload.recipient?.phone || null,
            },
            returnUrl: payload.returnUrl,
            cancelUrl: payload.cancelUrl,
            service: payload.service,
          },
        };
      },
      invalidatesTags: ["paymentList"],
    }),

    completeStripePayment: builder.mutation({
      query: (payload: { reservationIds: string[] }) => {
        return {
          url: `/payments/reservations/stripe/execute`,
          method: "POST",
          data: {
            reservationIds: payload.reservationIds,
          },
        };
      },
      invalidatesTags: ["paymentList"],
    }),

    initializeNoPayment: builder.mutation({
      query: (payload: InitializeNoPaymentData) => {
        return {
          url: `/payment/reservations/no_payment`,
          method: "POST",
          data: {
            reservationIds: payload.reservationIds, // Optional for multi reservations payment. Used for vonovia
            contact: {
              firstname: payload.contact.firstName,
              lastname: payload.contact.lastName,
              email: payload.contact.email,
              telephone: payload.contact.phone,
            },
            recipient: {
              firstname: payload.recipient?.firstName || null,
              lastname: payload.recipient?.lastName || null,
              email: payload.recipient?.email || null,
              telephone: payload.recipient?.phone || null,
            },
            returnUrl: payload.returnUrl,
            service: payload.service,
          },
        };
      },
      invalidatesTags: ["paymentList"],
    }),

    completeNoPayment: builder.mutation({
      query: (payload: { reservationIds: string[] }) => {
        return {
          url: `/order/reservations/${payload.reservationIds.join(",")}`,
          method: "GET",
        };
      },
      invalidatesTags: ["paymentList"],
    }),
  }),
});

export const {
  useInitializeStripePaymentMutation,
  useCompleteStripePaymentMutation,
  useInitializeNoPaymentMutation,
  useCompleteNoPaymentMutation,
} = paymentsApiSlice;
