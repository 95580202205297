import { Moment } from "moment";

export const calculateDuration = (fromDate: Date, toDate: Date): string => {
    const timeDiff = new Date(toDate).getTime() - new Date(fromDate).getTime();
    const secondsInHour = 3600;
    const secondsInDay = 24 * secondsInHour;
  
    if (timeDiff < secondsInDay * 1000) {
        const hours = Math.round(timeDiff / (secondsInHour * 1000));
        return `${hours}h`;
    } else {
        const days = Math.round(timeDiff / (secondsInDay * 1000));
        return `${days}d`;
    }
  };

export const formatDate = (date: Moment | null): string => {
    if (!date) {
        return ""; 
    }
    return date.format("MMM D");
}