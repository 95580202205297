import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

interface CP67BoxDoorProps {
    top: number;
    left: number;
    height: number;
    doorNumber: number;
    active: boolean;
}

const Square = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'left' && prop !== 'top' && prop !== 'height',
})<{ left: number; top: number; height: number; active: boolean }>(({ theme, left, top, height, active }) => ({
    display: 'flex',
    position: 'absolute',
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: '8px',
    paddingBottom: '2px',
    width: '60px',
    left,
    top,
    height,
    backgroundColor: active ? theme.palette.info.main : theme.palette.background.default,
}));

const NumberText = styled(Typography)({
    color: 'white',
    fontWeight: 'normal',
    fontSize: '11px',
});

const CP67BoxDoor = (props: CP67BoxDoorProps) => {
    const theme = useTheme();

    return (
        <Square
            left={props.left}
            top={props.top}
            height={props.height}
            active={props.active}
        >
            <NumberText>{props.doorNumber}</NumberText>
        </Square>
    );
};

export default CP67BoxDoor;
