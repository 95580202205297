import { Box } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { availabilitiesApiSlice } from "../../../api/availabilities/availabilitiesApiSlice";
import CloseIcon from "../../../components/icons/CloseIcon";
import { useApplyCoupon } from "../../../redux/flows/applyCouponFlow";
import { useCreateNewReservation } from "../../../redux/flows/createReservationFlow";
import { useFetchGroups } from "../../../redux/flows/fetchGroupsFlow";
import { useRemoveReservation } from "../../../redux/flows/removeReservationFlow";
import {
  addAnotherGroup,
  bookingCreationCompleted,
  bookingFlowChanged,
  bookingStepChanged,
  dateSelectedClear,
  durationSelectedClear,
  setAvailabilities,
  setIsAddingAnotherEquipment,
  setUseCase,
} from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import { baseStore, RootState } from "../../../redux/store";
import routes from "../../../routes/routes";
import GTM from "../../../util/gtm";
import { usePrevious } from "../../../util/usePrevious";
import GeneralDialog from "../../common/GeneralDialog";
import Header from "../../layout/components/Header";
import LoaderOverlay from "../../layout/components/LoaderOverlay";
import TenantLogo from "../../layout/components/TenantLogo";
import BookLocationHeader from "../common/util/BookLocationHeader";
import BookLocationNavigateBackAction from "../common/util/BookLocationNavigateBackAction";
import BookLocationNavigateNextAction from "../common/util/BookLocationNavigateNextAction";
import ConfirmationStep from "./ConfirmationStep";
import EquipmentSelectionStep from "./EquipmentSelectionStep";
import LocationSelectionStep from "./LocationSelectionStep";
import OrderDataStep from "./OrderDataStep";
import PaymentStep from "./PaymentStep";
import PersonalDataStep from "./PersonalDataStep";

export enum BookingCreationStep {
  locationSelection,
  assetGroupSelection,
  orderData,
  confirmation,
  personalData,
  paymentTypeSelection,
  payment,
}

const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  color: "white",
  height: "100%",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.default,
  paddingBottom: `calc(${theme.spacing(1)}px + env(safe-area-inset-bottom, 0))`,
  display: "flex",
  flexDirection: "column",
}));

const StepWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "100%",
  maxWidth: 1000,
  margin: "0 auto",
  overflowY: "auto",
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(6),
  },
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  width: "calc(100% - 42px)",
  maxWidth: 1000,
  margin: "0 auto",
  paddingBottom: theme.spacing(5),
  // position: 'fixed',
  bottom: 10,
  left: 0,
  right: 0,
  [theme.breakpoints.up("lg")]: {
    paddingBottom: theme.spacing(5),
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  width: "150px",
  marginTop: theme.spacing(1),
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
}));

interface BookLocationDialogProps {
  returnPage: string;
}

const BookLocationPage = (props: BookLocationDialogProps) => {
  const stepWrapperRef = useRef<HTMLElement>();
  const [pageDialogOpen, setPageDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const locationId = props.locationId;
  const step = useSelector((state: RootState) => state.bookingCreation.step);
  const previousStep = usePrevious(step);
  const orderData = useSelector(
    (state: RootState) => state.bookingCreation.orderData
  );
  const reservations = useSelector(
    (state: RootState) => state.bookingCreation.reservations
  );
  const selectedLocation = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  );
  // const { data: equipements } = useGetLocationEquipmentQuery({ locationId: selectedLocation?.id });
  const assetGroupId = useSelector(
    (state: RootState) => state.bookingCreation.assetGroupId
  );
  const appliedCouponCode = useSelector(
    (state: RootState) => state.bookingCreation.appliedCouponCode
  );

  const createNewReservation = useCreateNewReservation();
  const applyCoupon = useApplyCoupon();
  const removeReservation = useRemoveReservation();
  const fetchGroups = useFetchGroups("rental");

  // const location: Location | null = useSelector(Selector.Location.makeLocation(locationId));
  // const assetGroupSelectionId = useSelector(Selector.BookingCreation.assetGroupSelection);

  // const locationAssetGroups: AssetGroup[] | null = useSelector(Selector.Location.makeLocationAssetGroups(locationId));

  // const braintreePayment = useSelector(Selector.BookingCreation.braintreePayment);
  // const paymentInitFailed = useSelector((state: RootState) => state.bookingCreation.payment.paymentInitFailed)
  const paymentProvider = useSelector(
    (state: RootState) => state.bookingCreation.payment.provider
  );

  useEffect(() => {
    // dispatch(loadBookingFlow({}));
    stepWrapperRef.current = document.getElementById(
      "bookingProcessStepWrapper"
    )!;
    // @todo body scroll lock breaks the phone number input. Maybe remove it entirely?
    //disableBodyScroll(stepWrapperRef.current);
    //return () => enableBodyScroll(stepWrapperRef.current!);
    dispatch(
      bookingStepChanged({
        step: BookingCreationStep.locationSelection,
        nextEnabled: !!selectedLocation,
      })
    );

    // Set the usecase
    dispatch(setUseCase({ useCase: "rental" }));

    // Set the bookingFlow
    dispatch(
      bookingFlowChanged({
        bookingFlow: [
          "locationSelection",
          "assetGroupSelection",
          "orderData",
          "confirmation",
          "personalData",
          "paymentTypeSelection",
          "payment",
        ],
      })
    );
  }, []);

  // useEffect(() => {
  //     if (location) {
  //         const autoSelectNextSlot = typeof queryParams.get('now') === 'string';

  //         if (autoSelectNextSlot) {
  //             queryParams.delete('now');
  //             history.replace({ search: queryParams.toString() });
  //         }

  //         dispatch(Command.BookingCreation.startBookingCreation({ locationId }));
  //     }
  // }, [location?.id]);

  // useEffect(() => {
  //     const assetGroupLoaded =
  //         (locationAssetGroups && Array.isArray(locationAssetGroups) && locationAssetGroups.length > 0);

  //     if (step === BookingCreationStep.assetGroupSelection && !assetGroupLoaded) {
  //         dispatch(Command.Location.fetchAssetGroups({ locationId }));
  //     }
  // }, []);

  const fetchAssetGroupAvailabilities = async (
    assetGroupId: string,
    from: Date,
    to: Date
  ) => {
    const data = await baseStore.dispatch(
      availabilitiesApiSlice.endpoints.fetchAssetGroupAvailabilities.initiate({
        assetGroupId: assetGroupId,
        from: from,
        to: to,
      })
    );
    return data;
  };

  useEffect(() => {
    if (
      reservations.length === 0 &&
      step === BookingCreationStep.confirmation
    ) {
      dispatch(bookingCreationCompleted());
    }
  }, [reservations]);

  useEffect(() => {
    const manageReduxData = async () => {
      if (step === BookingCreationStep.assetGroupSelection) {
        // Clean data
        dispatch(dateSelectedClear());
        dispatch(durationSelectedClear());
        await fetchGroups();
      } else if (
        step === BookingCreationStep.orderData &&
        assetGroupId &&
        previousStep &&
        !(previousStep > BookingCreationStep.orderData)
      ) {
        // TODO change to user input
        const fromDate = moment().tz("Europe/Berlin").toDate();
        const toDate = moment()
          .tz("Europe/Berlin")
          .add(1, "months")
          .endOf("month")
          .toDate();
        const { data: availabilities } = await fetchAssetGroupAvailabilities(
          assetGroupId,
          fromDate,
          toDate
        );

        if (availabilities) {
          dispatch(setAvailabilities(availabilities.availabilities));
        }
      }
      // // previous step is orderData, current step is personal data
      if (
        previousStep === BookingCreationStep.orderData &&
        step === BookingCreationStep.confirmation
      ) {
        const processedReservation = await createNewReservation();

        if (processedReservation && appliedCouponCode) {
          // Due to asynchronous nature of redux; we add the returned processed reservation to previous state
          const reservationIds = reservations.map(
            (reservation: any) => reservation.reservationId!
          );
          reservationIds.push(processedReservation.reservationId!);
          await applyCoupon(reservationIds, appliedCouponCode);
        }
      }
      // if (previousStep === BookingCreationStep.orderData && step === BookingCreationStep.confirmation) {
      //     dispatch(Command.BookingCreation.calculateDefaultBoardTypeCounts({}));
      //     dispatch(Command.BookingCreation.createNewReservation({}));
      // }
      // // previous step is asset group selection, current step is personal data
      // // TODO check if this is still needed
      // if (false && previousStep === BookingCreationStep.boardSelection && step === BookingCreationStep.personalData) {
      //     dispatch(Command.BookingCreation.createNewReservation({}));
      // }

      // // previous step is personal data, current step is board selection
      // // or previous step is personal data, current step is orderData
      if (
        step === BookingCreationStep.orderData &&
        assetGroupId &&
        previousStep &&
        previousStep > BookingCreationStep.orderData
      ) {
        // user navigated back, so delete reservation
        await removeReservation();
        const fromDate = moment().tz("Europe/Berlin").toDate();
        const toDate = moment()
          .tz("Europe/Berlin")
          .add(1, "months")
          .endOf("month")
          .toDate();
        const { data: availabilities } = await fetchAssetGroupAvailabilities(
          assetGroupId,
          fromDate,
          toDate
        );
        if (availabilities) {
          dispatch(setAvailabilities(availabilities.availabilities));
        }
      }

      if (
        previousStep === BookingCreationStep.personalData &&
        step === BookingCreationStep.confirmation &&
        paymentProvider &&
        paymentProvider.length > 1
      ) {
        // when there is more than one payment provider configured, we need to initialize payment type selection
        // dispatch(initializePaymentTypeSelection({}));
      }
      // previous step is payment summary, current step is payment
      // or previous step is payment type selection, current step is payment
      if (
        (previousStep === BookingCreationStep.confirmation &&
          step === BookingCreationStep.payment) ||
        (previousStep === BookingCreationStep.paymentTypeSelection &&
          step === BookingCreationStep.payment)
      ) {
        // dispatch(initializePaymentProvider({}));
      }

      GTM.trackEecCheckoutStep(step);
    };
    manageReduxData();
  }, [step]);

  const headerText = useMemo(() => {
    switch (step) {
      case BookingCreationStep.locationSelection:
        return t("book_location.select_location");
      case BookingCreationStep.assetGroupSelection:
        return t("book_location.select_equipment");
      case BookingCreationStep.orderData:
        return t("book_location.order_data.header_text");
      // case BookingCreationStep.boardSelection: return t(
      //     'book_location.board_selection.header_text',
      //     { date: orderData.date!.format(t('formats.date')) },
      // );
      case BookingCreationStep.confirmation:
        return t("book_location.confirmation.header_text");
      case BookingCreationStep.personalData:
        return t("book_location.personal_data.header_text");
      // case BookingCreationStep.paymentTypeSelection: return t('book_location.payment.header_text');
      // case BookingCreationStep.payment: return braintreePayment.paymentToken
      //     ? t('book_location.payment.header_text')
      //     : (paymentInitFailed ? t('book_location.payment.header_text_init_error') : '');
      default:
        return "";
    }
    // }, [step, orderData.date, braintreePayment.paymentToken, paymentInitFailed]);
  }, [step, orderData.date]);

  const isLoading = () => {
    if (
      step === BookingCreationStep.personalData ||
      step === BookingCreationStep.confirmation
    ) {
      // third step, enter personal data
      // return !reservation;
      return false;
    }

    // if (step === BookingCreationStep.assetGroupSelection) {
    //     // first step, select asset group
    //     return Boolean(_.isEmpty(locationAssetGroups));
    // } else if (step === BookingCreationStep.orderData) {
    //     // Loader is triggered by the OrderDataStep component
    // } else if (step === BookingCreationStep.personalData || step === BookingCreationStep.confirmation) {
    //     // third step, enter personal data
    //     return !reservation;
    // }
    return false;
  };

  const closePage = () => {
    // history.goBack();
    navigate(routes.home);
    dispatch(bookingCreationCompleted());
  };

  const handleAddAnotherEquipment = () => {
    dispatch(setIsAddingAnotherEquipment(true));
    dispatch(addAnotherGroup({}));
  };

  const isLastStep = step === BookingCreationStep.payment;
  return (
    <Root>
      <Header>
        <div className="size-12 absolute left-0">
          <BookLocationNavigateBackAction />
        </div>
        <TenantLogo />
        <button className="size-12 absolute right-0 text-foreground/90" onClick={() => setPageDialogOpen(true)}>
          <CloseIcon />
        </button>
      </Header>
      <Box sx={{ marginTop: "20px" }}>
        <BookLocationHeader text={headerText} />
      </Box>
      <StepWrapper id={"bookingProcessStepWrapper"}>
        {step === BookingCreationStep.locationSelection && (
          <LocationSelectionStep />
        )}
        {step === BookingCreationStep.assetGroupSelection && (
          <EquipmentSelectionStep />
        )}
        {step === BookingCreationStep.orderData && <OrderDataStep />}
        {step === BookingCreationStep.confirmation && (
          <ConfirmationStep onAddAnotherEquipment={handleAddAnotherEquipment} />
        )}
        {step === BookingCreationStep.personalData && <PersonalDataStep />}
        {step === BookingCreationStep.payment && <PaymentStep />}
      </StepWrapper>
      <ActionsWrapper>
        {!isLastStep && <BookLocationNavigateNextAction />}
      </ActionsWrapper>
      <LoaderOverlay open={isLoading()} />
      <GeneralDialog
        open={pageDialogOpen}
        onClose={() => setPageDialogOpen(false)}
        onContinue={closePage}
        content={t("use_order.closing_dialog.content")}
      />
    </Root>
  );
};

export default BookLocationPage;
