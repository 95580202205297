import React from 'react';
import { Box } from '@mui/material';

interface KolulaBoxRectangleProps {
  posX: number;
  posY: number;
}

const KolulaBoxRectangle = (props: KolulaBoxRectangleProps) => {
  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.primary.main,
        width: '61px',
        height: '30px',
        position: 'absolute',
        left: props.posX,
        top: props.posY,
      }}
    />
  );
};

export default KolulaBoxRectangle;
