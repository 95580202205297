import { useEffect, useState } from "react";
import { initializeI18n } from "../i18n/i18n";
import { TenantConfig, loadTheme, setFavicon, setSeoTags } from "./tenantConfig";

const useTenantInitialization = () => {
  const [theme, setTheme] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const initializeTenant = async () => {
      try {
        const tenantConfig = await TenantConfig();
        await setFavicon();
        await setSeoTags();
        const theme = await loadTheme();
        setTheme(theme);
        await initializeI18n(tenantConfig);
        setIsLoading(false);
      } catch (error) {
        console.error("Error initializing tenant:", error);
        setIsLoading(false);
      }
    };
    initializeTenant();
  }, []);

  useEffect(() => {
    const splashTimeout = setTimeout(() => {
      setShowSplash(false);
    }, 2000);

    return () => clearTimeout(splashTimeout);
  }, []);

  return { theme, isLoading, showSplash };
};

export default useTenantInitialization;
