import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import PickupDetailsCard from "../../common/components/BookingSummary/PickupDetailsCard";
import {
  itemRetrievalStepChanged,
  setNextButtonClick,
  setPickupDetailsResponseData,
} from "../../../../redux/slice/itemRetrieval/itemRetrievalSlice";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { baseStore, RootState } from "../../../../redux/store";
import { receiverApiSlice } from "../../../../api/receiver/receiverApiSlice";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";
import { ItemRetrievalSteps } from "./ItemRetrievalPage";

const styles = {
  outerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "89vh",
    marginTop: "-130px",
    flexDirection: "column",
  },
  innerBox: {
    height: "calc(100% - 160px)",
    maxWidth: 1000,
    margin: "0 auto",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    width: "200px",
    margin: "0 auto",
    marginTop: "20px",
    // Use the theme's breakpoints
    "@media (min-width:1200px)": {
      width: "300px",
    },
  },
  buttonWrapper: {
    height: "100%",
    width: "100%",
    border: "1px solid red",
  },
  inputWrapper: {
    width: "350px",
    display: "flex",
    flexDirection: "column",
    margin: `12px auto 0 auto`,
    "@media (min-width: 1280px)": {
      width: "350px",
    },
  },
};

function PickupDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  // Extract the token from the query parameters
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const pickupDetails = useSelector(
    (state: RootState) => state.itemRetrieval.pickupDetails
  );
  const handleClickRetrieveItem = () => {
    dispatch(setNextButtonClick());
  };

  useEffect(() => {
    async function getPickupDetails() {
      setLoading(true);
      const result = await baseStore.dispatch(
        receiverApiSlice.endpoints.getPickupDetails.initiate({
          token,
        })
      );

      if (result.isSuccess && result.data) {
        if (result.data.items[0].rental.state === 'item_retrieved_confirmed') {
          dispatch(
            itemRetrievalStepChanged({
              step: ItemRetrievalSteps.failureMessage,
              nextEnabled: false,
            })
          );

          return
        }

        dispatch(setPickupDetailsResponseData(result.data));
        setLoading(false);
      }
    }

    getPickupDetails();
  }, []);

  if (loading) {
    return <LoaderOverlay open={loading} />;
  }

  return (
    <Box sx={styles.outerBox}>
      <div className="h-[70vh] overflow-scroll no-scrollbar flex items-center justify-center -mt-16">
        {!loading && <PickupDetailsCard pickupDetails={pickupDetails} />}
      </div>
      <div className="fixed flex justify-center w-full px-5 bottom-4">
        <CustomButton
          isButtonDark={true}
          // isButtonDark={bookingSummary?.length > 0 ? false : true}
          fullWidth={true}
          onClick={handleClickRetrieveItem}
          isOutlined={true}
          children={t("itemRetrieval.pickupDetails.next_button_title")}
        />
      </div>
    </Box>
  );
}

export default PickupDetails;
