import React from "react";
import { FiMinusCircle } from "react-icons/fi";
import { GoPlusCircle } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { setNumberOfGuest } from "../../redux/slice/apartmentRental/apartmentRentalSlice";
import { RootState } from "../../redux/store";

interface NumericInputProps {
  min?: number;
  max?: number;
  step?: number;
  onChange?: (value: number) => void;
}

const NumericInput: React.FC<NumericInputProps> = ({
  min = 0,
  max = 100,
  step = 1,
  onChange,
}) => {
  const { numberOfGuest } = useSelector((state: RootState) => state.apartment);
  const dispatch = useDispatch();
  const handleDecrease = () => {
    const newValue = Math.max(numberOfGuest - step, min);
    dispatch(setNumberOfGuest(newValue));
    onChange?.(newValue);
  };

  const handleIncrease = () => {
    const newValue = Math.min(numberOfGuest + step, max);
    dispatch(setNumberOfGuest(newValue));
    onChange?.(newValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      dispatch(setNumberOfGuest(newValue));
      onChange?.(newValue);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={handleDecrease}
        className="p-2 rounded-full focus:outline-none"
      >
        <FiMinusCircle className="text-[#333333] w-6 h-6" />
      </button>
      <input
        value={numberOfGuest}
        onChange={handleInputChange}
        className="w-24 text-center text-black h-14 border rounded-xl
         focus:outline-none focus:border-[#26DDE6]"
      />
      <button
        onClick={handleIncrease}
        className="p-2 rounded-full focus:outline-none"
      >
        <GoPlusCircle className="text-[#333333] w-6 h-6" />
      </button>
    </div>
  );
};

export default NumericInput;
