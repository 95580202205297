import { Box, Grid, Link, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import {useDispatch, useSelector} from "react-redux";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {setTenantConfiguration} from "../../redux/slice/config/configSlice";
import {enqueueNotification} from "../../redux/slice/notification/notificationSlice";
import {RootState} from "../../redux/store";
import routes from "../../routes/routes";
import TenantConfig from "../../util/tenantConfig";
import Header from "../layout/components/Header";
import TenantLogo from "../layout/components/TenantLogo";

interface BannerConfig {
    src: string;
    href: string;
}

const CarouselContainer = styled(Box)({
    width: "100%",
    flex: "0 0 auto",
    padding: "0 6px",
    borderRadius: "16px",
});

const CarouselImage = styled("img")(({theme}) => ({
    objectFit: "cover",
    width: "100%",
    borderRadius: "16px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));

const HomePage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const navigate = useNavigate();

    const {config} = useSelector((state: RootState) => state.tenantConfig);
    const [carouselImages, setCarouselImages] = useState<BannerConfig[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [clickAndCollect, setClickAndCollect] = useState<string>("");
    const [privateHandOvers, setPrivateHandovers] = useState<string>("");
    const [productRental, setProductRental] = useState<string>("");
    const [reservation, setReservation] = useState<string>("");
    const [roomRental, setRoomRental] = useState<string>("");
    const [temporaryStorage, setTemporaryStorage] = useState<string>("");
    const [moreToCome, setMoreToCome] = useState<string>("");
    const tenantName = process.env.REACT_APP_TENANT;
    const xsValue = config.services.length > 2 ? 6 : 12;

    useEffect(() => {
        const retrieveTenantConfig = async () => {
            const config = await TenantConfig();
            dispatch(setTenantConfiguration(config));
        };
        retrieveTenantConfig();
    }, [dispatch]);

    useEffect(() => {
        if (tenantName) {
            const loadImages = async () => {
                try {
                    const [
                        imagesModule,
                        clickAndCollectImage,
                        privateHandOversImage,
                        productRentalImage,
                        reservationImage,
                        roomRentalImage,
                        temporaryStorageImage,
                    ] = await Promise.all([
                        import(
                            `../../tenants/${tenantName}/assets/img/carouselImages/${i18n.language}/index.ts`
                            ),
                        import(`../../tenants/${tenantName}/assets/img/ClickandCollect.webp`),
                        import(`../../tenants/${tenantName}/assets/img/privateHandOvers.webp`),
                        import(`../../tenants/${tenantName}/assets/img/Product-rental.webp`),
                        import(`../../tenants/${tenantName}/assets/img/reservation.webp`),
                        import(`../../tenants/${tenantName}/assets/img/roomRental.webp`),
                        import(`../../tenants/${tenantName}/assets/img/Temporary-storage.webp`),
                    ]);
                    setCarouselImages(imagesModule.default);
                    setClickAndCollect(clickAndCollectImage.default);
                    setPrivateHandovers(privateHandOversImage.default);
                    setProductRental(productRentalImage.default);
                    setReservation(reservationImage.default);
                    setRoomRental(roomRentalImage.default);
                    setTemporaryStorage(temporaryStorageImage.default);

                    if (config?.services.includes("moreToCome")) {
                        const moreToComeModule = await import(
                            `../../tenants/${tenantName}/assets/img/moreToCome.webp`
                            );
                        setMoreToCome(moreToComeModule.default);
                    }

                } catch (error) {
                    dispatch(
                        enqueueNotification({
                            severity: "error",
                            message: t(`Failed to load images for ${tenantName}, error: ${error}`)
                        })
                    );
                }
            };
            loadImages();
        }
    }, [tenantName, i18n.language, dispatch, t, config?.services]);

    const handleCarouselChange = (now?: number, _?: number) => {
        if (now !== undefined) {
            setCurrentIndex(now);
        }
    };

    return (
        <div className="min-h-full px-5">
            <Header>
                <TenantLogo/>
            </Header>

            {carouselImages.length > 0 && (
                <CarouselContainer className="mx-0 mb-9 w-full aspect-[16/7] !px-0">
                    <Carousel
                        className="aspect-[14/5]"
                        animation="slide"
                        indicators={false}
                        autoPlay={true}
                        interval={5000}
                        onChange={handleCarouselChange}
                        navButtonsProps={{
                            style: {
                                display: "none",
                            },
                        }}
                    >
                        {carouselImages.map((banner, i) => (
                            <Box
                                key={i}
                                onClick={() => navigate(banner.href)}
                                sx={{cursor: 'pointer'}}
                            >
                                <CarouselImage
                                    src={banner.src}
                                    alt={`Banner ${i + 1}`}
                                />
                            </Box>
                        ))}
                    </Carousel>
                    {/* Custom Indicator */}
                    {carouselImages.length > 1 && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                                margin: "auto",
                                width: 50 * carouselImages?.length,
                                borderRadius: "4px",
                                height: "19px",
                                padding: "8px",
                                marginTop: "10px",
                                backgroundColor: "#F5F5F5",
                                marginBottom: 5,
                            }}
                        >
                            {carouselImages.map((_, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        width: "30px",
                                        height: "3px",
                                        borderRadius: "8px",
                                        backgroundColor:
                                            index === currentIndex ? "#FFFFFF" : "#404040",
                                        transition: "background-color 0.3s ease",
                                    }}
                                />
                            ))}
                        </Box>
                    )}
                    {/* Image Position Text */}
                </CarouselContainer>
            )}

            <span className="flex justify-center mb-4 text-base leading-5 tracking-[.02rem]">
        {t("home.do")}
      </span>
            {/* Services Section */}
            <div className="flex flex-col items-center justify-center">
                <Grid container spacing={2}>
                    {/* Storage Rental */}
                    {config?.services.includes("storage") && (
                        <Grid item xs={xsValue}>
                            <Link
                                component={RouterLink}
                                to={routes.temStorageNewBooking}
                                style={{textDecoration: "none"}}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        backgroundColor: "#cfcfcf33",
                                        borderRadius: 1,
                                        padding: 1,
                                        textAlign: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${temporaryStorage})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            height: "100px",
                                            borderRadius: 1,
                                            position: "relative",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            mt={1}
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                borderRadius: "0 0 0.5rem 0.5rem",
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                position: "absolute",
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: 1,
                                                maxWidth: "100%",
                                                color: "#FFFFFF",
                                                transition: "all 0.3s ease",
                                                textAlign: "center",
                                                whiteSpace: "normal",
                                                lineHeight: 1.2,
                                                "&:hover": {
                                                    height: "auto",
                                                },
                                            }}
                                        >
                                            {t("home.temporary_storage")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    )}

                    {/* Product rental */}
                    {config?.services.includes("productRental") && (
                        <Grid item xs={xsValue}>
                            <Link
                                component={RouterLink}
                                to={routes.bookLocationEquipmentRentalPath}
                                style={{textDecoration: "none"}}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        backgroundColor: "#cfcfcf33",
                                        borderRadius: 1,
                                        padding: 1,
                                        textAlign: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${privateHandOvers})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            height: "100px",
                                            borderRadius: 1,
                                            position: "relative",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            mt={1}
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                borderRadius: "0 0 0.5rem 0.5rem",
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                position: "absolute",
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: 1,
                                                maxWidth: "100%",
                                                color: "#FFFFFF",
                                                transition: "all 0.3s ease",
                                                textAlign: "center",
                                                whiteSpace: "normal",
                                                lineHeight: 1.2,
                                                "&:hover": {
                                                    height: "auto",
                                                },
                                            }}
                                        >
                                            {t("home.product_rental")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    )}

                    {/* Private Handovers */}
                    {config?.services.includes("p2p") && (
                        <Grid item xs={xsValue}>
                            <Link
                                component={RouterLink}
                                to={routes.bookLocationP2PPath}
                                style={{textDecoration: "none"}}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        backgroundColor: "#cfcfcf33",
                                        borderRadius: 1,
                                        padding: 1,
                                        textAlign: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${clickAndCollect})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            height: "100px",
                                            borderRadius: 1,
                                            position: "relative",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            mt={1}
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                borderRadius: "0 0 0.5rem 0.5rem",
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                position: "absolute",
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: 1,
                                                maxWidth: "100%",
                                                color: "#FFFFFF",
                                                transition: "all 0.3s ease",
                                                textAlign: "center",
                                                whiteSpace: "normal",
                                                lineHeight: 1.2,
                                                "&:hover": {
                                                    height: "auto",
                                                },
                                            }}
                                        >
                                            {t("home.private_handovers")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    )}

                    {/* Parcel delivery */}
                    {config?.services.includes("parcelDelivery") && (
                        <Grid item xs={xsValue}>
                            <Link
                                component={RouterLink}
                                to={""}
                                style={{textDecoration: "none"}}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        backgroundColor: "#cfcfcf33",
                                        borderRadius: 1,
                                        padding: 1,
                                        textAlign: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${productRental})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            height: "100px",
                                            borderRadius: 1,
                                            position: "relative",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            mt={1}
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                borderRadius: "0 0 0.5rem 0.5rem",
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                position: "absolute",
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: 1,
                                                maxWidth: "100%",
                                                color: "#FFFFFF",
                                                transition: "all 0.3s ease",
                                                textAlign: "center",
                                                whiteSpace: "normal",
                                                lineHeight: 1.2,
                                                "&:hover": {
                                                    height: "auto",
                                                },
                                            }}
                                        >
                                            {t("home.click_and_collect")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    )}

                    {config?.services.includes("moreToCome") && (
                        <Grid item xs={xsValue}>
                            <Link
                                component={RouterLink}
                                to={routes.moreToComePath}
                                style={{textDecoration: "none"}}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        backgroundColor: "#cfcfcf33",
                                        borderRadius: 1,
                                        padding: 1,
                                        textAlign: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${moreToCome})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            height: "100px",
                                            borderRadius: 1,
                                            position: "relative",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            mt={1}
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                borderRadius: "0 0 0.5rem 0.5rem",
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                position: "absolute",
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: 1,
                                                maxWidth: "100%",
                                                color: "#FFFFFF",
                                                transition: "all 0.3s ease",
                                                textAlign: "center",
                                                whiteSpace: "normal",
                                                lineHeight: 1.2,
                                                "&:hover": {
                                                    height: "auto",
                                                },
                                            }}
                                        >
                                            {t("home.more_to_come")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    )}

                    {config?.services.includes("roomRental") && (
                        <Grid item xs={xsValue}>
                            <Link
                                component={RouterLink}
                                to={routes.roomRentalPath}
                                style={{textDecoration: "none"}}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        backgroundColor: "#cfcfcf33",
                                        borderRadius: 1,
                                        padding: 1,
                                        textAlign: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${roomRental})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            height: "100px",
                                            borderRadius: 1,
                                            position: "relative",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            mt={1}
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                borderRadius: "0 0 0.5rem 0.5rem",
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                position: "absolute",
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: 1,
                                                maxWidth: "100%",
                                                color: "#FFFFFF",
                                                transition: "all 0.3s ease",
                                                textAlign: "center",
                                                whiteSpace: "normal",
                                                lineHeight: 1.2,
                                                "&:hover": {
                                                    height: "auto",
                                                },
                                            }}
                                        >
                                            {t("home.room_rental")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    )}

                    {config?.services.includes("reservation") && (
                        <Grid item xs={xsValue}>
                            <Link
                                component={RouterLink}
                                to={routes.venueReservationPath}
                                style={{textDecoration: "none"}}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        backgroundColor: "#cfcfcf33",
                                        borderRadius: 1,
                                        padding: 1,
                                        textAlign: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${reservation})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            height: "100px",
                                            borderRadius: 1,
                                            position: "relative",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            mt={1}
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                borderRadius: "0 0 0.5rem 0.5rem",
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                position: "absolute",
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: 1,
                                                maxWidth: "100%",
                                                color: "#FFFFFF",
                                                transition: "all 0.3s ease",
                                                textAlign: "center",
                                                whiteSpace: "normal",
                                                lineHeight: 1.2,
                                                "&:hover": {
                                                    height: "auto",
                                                },
                                            }}
                                        >
                                            {t("home.venue_reservation")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
};
export default HomePage;
