import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";

export const bookingsApiSlice = createApi({
  reducerPath: "bookingsApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["bookingSummary"],
  endpoints: (builder) => ({
    getUserOrder: builder.query({
      query: ({ orderIds }) => {
        return {
          url: `/orders/${orderIds}?include-group=true&createdBy=any`,
        };
      },
      providesTags: ["bookingSummary"],
    }),
  }),
});

export const { useGetUserOrderQuery, useLazyGetUserOrderQuery } =
  bookingsApiSlice;
