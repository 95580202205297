import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n/i18n";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { baseStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";

import SplashScreen from "./modules/layout/components/SplashScreen";
import useTenantInitialization from "./util/useTenantInitilization";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const MainApp = () => {
  const { theme, isLoading, showSplash } = useTenantInitialization(); // using the custom hook
  if (isLoading || showSplash) {
    return <SplashScreen />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={baseStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};

root.render(
  <React.StrictMode>
    <MainApp />
  </React.StrictMode>
);

reportWebVitals();
