// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store"; // Adjust the import based on your file structure
import { CompleteStripePaymentData } from "../../api/types";
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import {
  paymentCompleted,
  paymentCompletionBegan,
  paymentCompletionFailed,
} from "../slice/bookingCreation/bookingCreationSlice";

import { useCompleteStripePaymentMutation } from "../../api/payments/paymentsApiSlice";
import { useCallback } from "react";
import KolulaStorageManager from "../../storage/KolulaStorageManager";
import { enqueueNotification } from "../slice/notification/notificationSlice";
import { t } from "i18next";

export const useCompleteStripePayment = () => {
  const contact = useSelector(
    (state: RootState) => state.bookingCreation.personalData
  );

  const indexDBStore = new KolulaStorageManager();

  const [completeStripePaymentMutation] = useCompleteStripePaymentMutation();
  const dispatch = useDispatch();

  // Use useCallback to memoize the completeStripePayment function
  return useCallback(
    async (reservationIds: string[]) => {
      dispatch(paymentCompletionBegan());

      try {
        if (reservationIds.length && contact) {
          const payload: CompleteStripePaymentData = {
            reservationIds: reservationIds,
          };

          const response = await completeStripePaymentMutation(
            payload
          ).unwrap();

          if (response.orderIds.length) {
            await indexDBStore.insertInternalMultipleOrdersIds(
              response.orderIds
            );

            dispatch(paymentCompleted(response));
            dispatch(
              enqueueNotification({
                severity: "success",
                message: t("book_location.payment.success"),
              })
            );
          }

          return response;
        }
      } catch (error) {
        dispatch(paymentCompletionFailed());
        dispatch(
          enqueueNotification({
            severity: "error",
            message: t("book_location.payment.failed"),
          })
        );

        Logger.for("createNewReservation").error(error);
        ErrorTracker.trackException(error as Error);
        // Handle the error state or dispatch error actions if necessary
      }
    },
    [contact, completeStripePaymentMutation, dispatch]
  );
};
