import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { EquipmentSelectProps } from "../../../../../types/baseTypes";
import {convertMinutesToHours, getEquipmentName} from "../../../../../util";
import { useTranslation } from "react-i18next";
import { palette } from "../../../../../tenants/kolula/layout/palette";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useTheme } from "@mui/material";

interface EquipmentListAccordionProps extends EquipmentSelectProps {
  expanded: boolean; // Whether this accordion is expanded
  onAccordionChange: () => void; // Function to handle accordion expansion
}

export default function EquipmentListAccordion(props: EquipmentListAccordionProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const tenantConfig = useSelector((state: RootState) => state.tenantConfig.config);
  const { durationInMinutes, price } = props.priceCategoryInfo?.priceScale[0];
  return (
    <div className="border-b-[1px] border-kolula-itemListBorder w-full">
      <Accordion
        expanded={props.expanded} // Control the expansion state
        onChange={props.onAccordionChange} // Handle expansion state changes
        sx={{
          bgcolor: props.expanded ? palette.accordion.expanded : palette.accordion.collapse,
          boxShadow: "none",
          borderRadius: 0,
        }}
      >
        <AccordionSummary aria-controls="panel1-content" id="panel1-header">
          <div className={`flex justify-start w-full cursor-pointer`}>
            <div className="flex-shrink-0 mr-8">
              <img className="rounded" src={props.image} alt="" width={88} height={66} />
            </div>
            <div className="" style={{ maxWidth: "calc(100% - 96px)" }}>
              <Typography
                variant="body1"
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: props.expanded ? 10 : 2, // Dynamically set the line clamp
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "bold",
                  maxHeight: props.expanded ? "auto" : `${2 * 24}px`, // Adjust max-height based on expansion
                }}
              >
                {getEquipmentName(props.name, props?.langNames, "title")}
              </Typography>
              <Typography variant="body1">
                {t("book_location.from")}{" "}
                <span className="font-bold">
                  {price}
                  {tenantConfig.currencySymbole} / {convertMinutesToHours(durationInMinutes)}
                </span>
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <b>{t("book_location.description")}</b>
            <p className="text-kolula-itemListBorder">
                {getEquipmentName(props.equipment.description, props?.langNames, "description")}
            </p>
          </div>
          <div className={`h-[1px] bg-${theme.palette.primary.light} w-full my-4`}></div>
          <div className="flex flex-col items-center justify-between w-full">
            <div className="flex items-center justify-between w-full">
              <div className="font-bold">{t("book_location.prices")}</div>
              <div>{t("book_location.vat")}</div>
            </div>
            {props.equipment.priceCategoryInfo?.priceScale?.map((priceScale) => {
              return (
                <div className="flex items-center justify-between w-full text-kolula-itemListBorder">
                  <div>{convertMinutesToHours(priceScale?.durationInMinutes)}</div>
                  <div>
                    {priceScale.price}
                    {tenantConfig.currencySymbole}
                  </div>
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
