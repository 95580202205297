import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import CustomButton from "../../../layout/components/CustomButton";
import { setNextButtonClick } from "../../../../redux/slice/temporaryStorage/temporaryStorageSlice";
import PersonalDataStep from "./PersonalDataStep";

export default function PersonalData() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { nextEnabled } = useSelector(
    (state: RootState) => state.bookingCreation
  );

  const handleClickNext = () => {
    dispatch(setNextButtonClick());
  };
  return (
    <div className="flex flex-col justify-center items-center ">
      <PersonalDataStep />
      <div
        className="fixed flex w-full max-w-md px-3 left-0 right-0
       items-center justify-center bottom-4 m-auto"
      >
        <CustomButton
          isButtonDark={true}
          fullWidth={true}
          onClick={handleClickNext}
          isOutlined={true}
          disabled={!nextEnabled}
          children={t(
            "temporary_storage.new_booking.location.next_button_title"
          )}
        />
      </div>
    </div>
  );
}
