import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";
import { AssetGroup } from "../../types/assetGroup";


const processAssetGroupData = (assetGroupData: any): AssetGroup => {
    return {
        id: assetGroupData.id,
        name: assetGroupData.name,
        langNames: assetGroupData.langNames,
        description: assetGroupData.description,
        availabilities: assetGroupData.availibility,
        isFullDay: assetGroupData.isFullDay,
    };
};

export const availabilitiesApiSlice = createApi({
    reducerPath: "availabilitiesApiSlice",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["availabilities"],
    endpoints: (builder) => ({
        fetchAssetGroupAvailabilities: builder.query<AssetGroup, { assetGroupId: string, from: Date, to: Date }>({
            query: ({ assetGroupId, from, to }) => {
              const fromDate = from.toISOString();
              const toDate = to.toISOString();
              return `/group/${assetGroupId}/availabilities/${fromDate}/${toDate}`;
            },
            transformResponse: (response: any) => {
                // Process the response data before returning it
                return processAssetGroupData(response);
            },
            providesTags: ['availabilities'],
          }),
    }),
});

export const {useFetchAssetGroupAvailabilitiesQuery} = availabilitiesApiSlice;
