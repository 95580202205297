import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';

interface CP82BoxDoorProps {
    top: number;
    left: number;
    height: number;
    doorNumber: number;
    active: boolean;
}

// Styled component using MUI v5's `styled`
const Square = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'top' && prop !== 'left'
})<{ top: number; left: number; height: number; active: boolean }>(({ theme, top, left, height, active }) => ({
    position: 'absolute',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: '8px',
    paddingBottom: '2px',
    width: '60px',
    top: `${top}px`,
    left: `${left}px`,
    height: `${height}px`,
    backgroundColor: active ? theme.palette.info.main : theme.palette.background.default,
}));

const NumberText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
    color: active ? theme.palette.text.main : "black",
    fontWeight: 'bold',
    fontSize: '10px',
}));

const CP82BoxDoor = (props: CP82BoxDoorProps) => {
    const { top, left, height, doorNumber, active } = props;

    return (
        <Square top={top} left={left} height={height} active={active}>
            <NumberText active={active}>{doorNumber}</NumberText>
        </Square>
    );
};

export default CP82BoxDoor;
