import React from 'react';

interface HomeIconProps {
  color?: string;
  fill?: string;
  size?: number;
  isActive ?: boolean;
}

export const HomeIcon: React.FC<HomeIconProps> = ({
  color = '#666666',
  fill = 'none',
  size = 32,
  isActive = false
}) => {
  return isActive ? (

    <svg 
    width={size} 
    height={size} 
    viewBox="0 0 32 32" 
    fill={fill} 
    xmlns="http://www.w3.org/2000/svg"
    >
      <path
       d="M15.2929 5.12151C15.6834 4.73098 16.3166 4.73098 16.7071 5.12151L28.2929 16.7073C28.6834 17.0978 29.3166 17.0978 29.7071 16.7073C30.0976 16.3168 30.0976 15.6836 29.7071 15.2931L18.1213 3.70729C16.9497 2.53572 15.0503 2.53572 13.8787 3.70729L2.29289 15.2931C1.90237 15.6836 1.90237 16.3168 2.29289 16.7073C2.68342 17.0978 3.31658 17.0978 3.70711 16.7073L15.2929 5.12151Z"
        fill={fill}
        />
      <path d="M16 7.24283L26.8787 18.1215C26.9183 18.1611 26.9588 18.1994 27 18.2364V26.5002C27 27.8809 25.8807 29.0002 24.5 29.0002H20C19.4477 29.0002 19 28.5525 19 28.0002V22.0002C19 21.4479 18.5523 21.0002 18 21.0002H14C13.4477 21.0002 13 21.4479 13 22.0002V28.0002C13 28.5525 12.5523 29.0002 12 29.0002H7.5C6.11929 29.0002 5 27.8809 5 26.5002V18.2364C5.04124 18.1994 5.08169 18.1611 5.12132 18.1215L16 7.24283Z" 
      fill={fill}
      />
    </svg>
    
    ) : (

      <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 15.9998L14.9393 4.06043C15.5251 3.47465 16.4749 3.47465 17.0607 4.06043L29 15.9998M6 12.9998V26.4998C6 27.3282 6.67157 27.9998 7.5 27.9998H13V21.4998C13 20.6713 13.6716 19.9998 14.5 19.9998H17.5C18.3284 19.9998 19 20.6713 19 21.4998V27.9998H24.5C25.3284 27.9998 26 27.3282 26 26.4998V12.9998M11 27.9998H22"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

    );
};  
