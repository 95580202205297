import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig } from "axios";
import DefaultConfig from "./defaultConfig";
const BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

export default axiosInstance;

// Axios instance for private API requests with JSON headers
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': DefaultConfig.api.key,
        'X-Language': 'de',
        'X-Tenant': process.env.REACT_APP_TENANT
    },
});

// Function to refresh the JWT token
const refreshToken = async (): Promise<string | undefined> => {
    const token = JSON.parse(localStorage.getItem('token') || 'null');
    const refresh = token?.refresh;

    if (!refresh) {
        localStorage.clear();
        window.location.reload();
        return;
    }

    try {
        const response = await axios.post(
            `${BASE_URL}/auth/token/refresh/`,
            { refresh },
            {
                headers: { 'Content-Type': 'application/json' },
            }
        );
        token['access'] = response.data.access;
        localStorage.setItem('token', JSON.stringify(token));
        return response.data.access;
    } catch (error) {
        localStorage.clear();
        window.location.reload();
    }
};

// Request interceptor for axiosPrivate to add the Authorization and X-Tenant headers
axiosPrivate.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token = JSON.parse(localStorage.getItem('token') || 'null');
        if (token && config.headers) {
            if (!config.headers['Authorization']) {
                config.headers['Authorization'] = `Bearer ${token.access}`;
            }
        }
        return config;
    },
    (error: AxiosError) => Promise.reject(error),
);

// Response interceptor for axiosPrivate to handle token refresh
axiosPrivate.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        const prevRequest = error.config as InternalAxiosRequestConfig & { sent?: boolean };
        if (error.response?.status === 401 && !prevRequest.sent) {
            prevRequest.sent = true;
            const newAccessToken = await refreshToken();
            if (newAccessToken) {
                prevRequest.headers!['Authorization'] = `Bearer ${newAccessToken}`;
            }
            return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
    }
);
