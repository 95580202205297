import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Data, Hints } from "../../../types/hints";

export interface HintsState {
    pages: Data | null;
}

const initialState: HintsState = {
   pages: {
      dataProtectionPage: {
         content: {
            de: "",
            en: "",
         },
         type: "",
      },
      faqsPage: {
         content: {
            de: {
                content: {
                    title: "",
                    content: [],
                },
            },
            en: {
                content: {
                    title: "",
                    content: [],
                },
            },
        },
        type: "",
      },
      receiverFaqsPage: {
         content: {
            de: [],
            en: [],
         },
         type: "",
      },
      hintsAndTipsPage: {
         content: {
            de: [],
            en: [],
         },
         type: "",
      },
      imprintPage: {
         content: {
            de: "",
            en: "",
         },
         type: "",
      },
      termOfServicePage: {
         content: {
            de: "",
            en: "",
         },
         type: "",
      },
   }
};

const hintsSlice = createSlice({
    name: "hintsSlice",
    initialState: initialState,
    reducers: {
        setHints: (
            state,
            action: PayloadAction<any>
        ) => {
            state.pages = action.payload;
        }
    },
});

export const {
    setHints 
   } = hintsSlice.actions;

export default hintsSlice.reducer;