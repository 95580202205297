import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserAuthenticatedPayload } from "../../../types/baseTypes";

export interface ConsentState {
    consent: boolean | null;
    consentLoaded: boolean;
}

const initialState: ConsentState = {
    consent: null,
    consentLoaded: false,
};

const consentSlice = createSlice({
    name: "consentSlice",
    initialState: initialState,
    reducers: {
        setConsent: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.consent = action.payload;
            state.consentLoaded = true;

        },
        setLoadedConsent: (
            state, 
            action: PayloadAction<boolean | void>
        ) => {
            state.consentLoaded = true;
        }
    },
});


export const { setConsent, setLoadedConsent } = consentSlice.actions;

export default consentSlice.reducer;