import React, { useState } from "react";
import { Box, Link, styled, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import checkCircleIcon from '../../../../assets/icons/check-circle.svg'
import { RootState } from "../../../../redux/store";
import KoluluBoxInfoBox from "../../../useOrder/box/KolulaBoxInfoBox";
import { t } from "i18next";

const styles = {
    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    actions: (theme: any) => ({
        display: "flex",
        paddingTop: theme.spacing(1),
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "10%",
    }),
    actionContainer: {
        position: "fixed",
        bottom: "16px",
        left: "0",
        right: "0",
        display: "flex",
        justifyContent: "center",
        padding: "0 16px",
        width: "full",
    },
    hyperLink: (theme: any) => ({
        textDecoration: 'underline',
        color: theme.palette.primary.light
    })
}

const StyledHeaderText = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2.25),
    width: `calc(100% - ${theme.spacing(7.5)}px)`,
    // paddingTop: theme.spacing(3),
    fontSize: theme.typography.h1.fontSize,
    textAlign: 'center',
    textTransform: 'none',
    [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(6),
    },
    // paddingBottom: theme.spacing(6),
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),

    padding: '24px 24px 16px 24px'
}));

const ShadowOverlay = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9,
}));


function SuccessMessage() {
    const [infoOpen, setInfoOpen] = useState<boolean>(false);
    const orderId = useSelector((state: RootState) => state.booking.selectedBooking!.id);

    return (
        <Box sx={styles.content}>
            {infoOpen &&
                <ShadowOverlay onClick={() => setInfoOpen(false)}>
                    <KoluluBoxInfoBox onClose={() => setInfoOpen(false)}
                        orderId={orderId!}
                    />
                </ShadowOverlay>
            }

            <StyledHeaderText variant="h1">
                {t("itemRetrieval.successMessage.header_text")}
            </StyledHeaderText>
            <img src={checkCircleIcon} alt="check circle icon" />
            <Typography
                variant="h2"
                sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                    margin: '20px 0',
                    fontSize: (theme) => theme.typography.h5.fontSize,
                    fontWeight: (theme) => theme.typography.h5.fontWeight,
                    textAlign: 'center',
                    paddingBottom: '40px',
                    paddingX: '20px',
                    lineHeight: '28px'
                }}
            >
                <Trans
                    i18nKey={'itemRetrieval.successMessage.description'}
                    components={{
                        homepage: (
                            <Link href={window.location.origin} sx={styles.hyperLink} />
                        ),
                    }}
                />
            </Typography>
            <Box sx={styles.actions}>
                <Box sx={styles.actionContainer}>
                    <Typography
                        variant="h2"
                        sx={{
                            color: "#7D8081",
                            fontSize: (theme) => theme.typography.body1.fontSize,
                            fontWeight: (theme) => theme.typography.body1.fontWeight,
                            textAlign: 'center',
                            paddingBottom: '20px',
                            paddingX: '20px',
                        }}
                    >
                        <Trans
                            i18nKey={'itemRetrieval.successMessage.help'}
                            components={{
                                contactSupport: (
                                    <Link href="#" onClick={() => setInfoOpen(true)} sx={styles.hyperLink} />
                                ),
                            }}
                        />
                    </Typography>
                    {/* </div> */}
                </Box>
            </Box>
        </Box>
    );
}

export default SuccessMessage;
