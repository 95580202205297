import React, { useState } from 'react';
import { Box, Grow, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { OrderId } from '../../../../types/order';
import CP67BoxDoor from './CP67BoxDoor';
import KoluluBoxInfoBox from '../KolulaBoxInfoBox';
// import Assets from '../../../../assets/assets';

// Styled components using MUI v5's `styled`
const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    width: '243px',
    height: '302px',
    margin: '0 auto',
    padding: '0px',
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: '8px',
    border: '1px solid white',
    overflow: 'hidden', // Add overflow for scrollbars
    transform: 'scale(135%) translateY(45px)',
}));

const DoorWrapper = styled(Box)({
    position: 'relative',
});

const Rectangle = styled(Box)(({ theme }) => ({
    width: '243px',
    height: '30px',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '13px',
    fontWeight: 'bold',
    marginLeft: '10px',
}));

const InfoIconButton = styled(IconButton)({
    width: '30px',
    height: '30px',
});

const ShadowOverlay = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 9,
});

const InfoBoxWrapper = styled(Box)({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
});

interface CP67BoxProps {
    orderId?: OrderId;
    activeDoors: number[];
    title: string;
}

const CP67Box = (props: CP67BoxProps) => {
    // const InfoIcon = Assets.icon.infoIcon.component;
    const [infoOpen, setInfoOpen] = useState<boolean>(false);
    const activeDoor = props.activeDoors[0];
    const enableInfo = Boolean(props.orderId);

    const getLeftSize = (columnOrder: number) => {
        if (columnOrder === 1) {
            return 0;
        } else if (columnOrder === 2) {
            return 61;
        } else if (columnOrder === 3) {
            return 122;
        } else {
            return 183;
        }
    };

    const renderColumnWithCU = (
        activeDoor: number, 
        columnOrder: number, 
        size: number, 
        doorsHeight: number, 
        firstDoorNumber: number,
        cuHeight: number,
        cuNumber: number,
    ) => {
        const leftSize = getLeftSize(columnOrder);
        const doors = [];
        let topOffset = 32;

        for (let i = 0; i < size; i++) {
            if (i === cuNumber - 1) {
                doors.push({ top: topOffset, height: cuHeight, doorNumber: cuNumber });
                topOffset += cuHeight + 1;
            } else {
                doors.push({ top: topOffset, height: doorsHeight, doorNumber: firstDoorNumber + i });
                topOffset += doorsHeight + 1;
            }
        }

        return doors.map((door, index) => (
            <CP67BoxDoor
                key={door.doorNumber}
                top={door.top}
                left={leftSize ?? 0}
                height={door.height}
                doorNumber={door.doorNumber}
                active={
                    ((activeDoor - firstDoorNumber < cuNumber) && (activeDoor - firstDoorNumber === index)) 
                        ? 
                        true 
                        :
                        ((activeDoor - firstDoorNumber > cuNumber - 1) 
                        && (activeDoor - firstDoorNumber === index)) 
                            ? true 
                            : false
                }
            />
        ));
    };

    const renderColumn = (
        activeDoor: number, 
        columnOrder: number, 
        size: number, 
        doorsHeight: number, 
        firstDoorNumber: number,
    ) => {
        const leftSize = getLeftSize(columnOrder);
        return Array.from({ length: size }, (_, index) => {
            const top = 32 + index * (doorsHeight + 1);
            const doorNumber = firstDoorNumber + index;
            return (
                <CP67BoxDoor 
                    key={doorNumber} 
                    top={top} 
                    left={leftSize ?? 0} 
                    height={doorsHeight} 
                    doorNumber={doorNumber} 
                    active={index + 1 === activeDoor - firstDoorNumber + 1 ? true : false} 
                />
            );
        });
    };

    const renderFirstColumn = (activeDoor: number, columnOrder: number) => {
        const size = 7;        
        const doorsHeight = 32.8; 
        const firstDoorNumber = 1;
        const cuHeight = 66.3;
        const cuNumber = 2;
        return renderColumnWithCU(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber, cuHeight, cuNumber);
    };
    
    const renderSecondColumn = (activeDoor: number, columnOrder: number) => {
        const size = 12;        
        const doorsHeight = 21.5; 
        const firstDoorNumber = 8;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderThirdColumn = (activeDoor: number, columnOrder: number) => {
        const size = 8;        
        const doorsHeight = 32.75; 
        const firstDoorNumber = 20;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };

    const renderFourthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 28;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderFifthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 33;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderSixthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 38;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderSeventhColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 43;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderEighthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 48;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderNinthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 53;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderTenthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 58;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };

    const renderEleventhColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 63;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    

    return (
        <Box>
            {infoOpen && <ShadowOverlay />}
            <Root>
                <DoorWrapper>
                    <Rectangle left={0} top={0}>
                        <Title>{props.title}</Title>
                        {enableInfo && (  
                            <InfoIconButton size="small" onClick={() => setInfoOpen(true)}>
                                {/* <InfoIcon /> */}
                            </InfoIconButton>                    
                        )}
                    </Rectangle>
                    {renderFirstColumn(props.activeDoors[0], 1)}
                    {renderSecondColumn(props.activeDoors[0], 2)}
                    {renderThirdColumn(props.activeDoors[0], 3)}
                    {renderFourthColumn(props.activeDoors[0], 4)}
                    {renderFifthColumn(props.activeDoors[0], 5)}
                    {renderSixthColumn(props.activeDoors[0], 6)}
                    {renderSeventhColumn(props.activeDoors[0], 7)}
                    {renderEighthColumn(props.activeDoors[0], 8)}
                    {renderNinthColumn(props.activeDoors[0], 9)}
                    {renderTenthColumn(props.activeDoors[0], 10)}
                    {renderEleventhColumn(props.activeDoors[0], 11)}
                </DoorWrapper>
                <Grow in={infoOpen} timeout={500}>
                    <InfoBoxWrapper>
                        {/* <KoluluBoxInfoBox 
                            open={infoOpen}
                            onClose={() => setInfoOpen(false)}
                            orderId={props.orderId}
                        /> */}
                    </InfoBoxWrapper>
                </Grow>
            </Root>
        </Box>
    );
};

export default CP67Box;
