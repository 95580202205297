import { baseStore } from "../store"; // Adjust the import based on your file structure
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import { Rental } from "../../types/rental";
import { lockersApiSlice } from "../../api/lockers/lockersApiSlice";

const openLockerCall = async (rental: Rental) => {
  const data = await baseStore.dispatch(
    lockersApiSlice.endpoints.openLocker.initiate({
      depotId: rental.doors[0].depotId,
      door: rental.doors[0].door,
      token: rental.token,
    })
  );

  return data;
};

export const useOpenLocker = (rental: Rental | undefined) => {
  const openLocker = async () => {
    try {
      if (rental) {
        await openLockerCall(rental);
      }
    } catch (error) {
      Logger.for("openLocker").error(error);
      ErrorTracker.trackException(error as Error);
      // Handle the error state or dispatch error actions if necessary
    }
  };

  return openLocker;
};
