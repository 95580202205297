export const palette = {
  accordion: {
    expanded: "#F3F3F3",
    collapse: "#F9F9F9",
  },
  primary: {
    dark: "#ffffff",
    main: "#1E1E1E",
    light: "#26DDE6",
    contrastText: "#000",
  },
  secondary: {
    dark: "#161616",
    main: "#515557",
    light: "#1E1E1E",
    contrastText: "#31B4BF",
  },
  background: {
    default: "#F9F9F9", // Dark background for the main areas of the app
    paper: "#ffffff", // White background for elements like cards and dialogs
    notActiveBg: "26DDE6",
  },
  info: {
    main: "#26DDE6", // For active doors
    light: "#1CA3A8",
    dark: "#00a4b1",
  },
  toolbar: {
    active: "#6112E7",
    background: "#ffffff",
  },
  text: {
    primary: "#161616", // White text on dark backgrounds
    secondary: "#0a0a0a", // Dark text on light backgrounds
    disabled: "rgb(190,190,190)",
    main: "#000000",
  },
  action: {
    disabled: "rgba(255, 255, 255, 0.26)",
    selected: "#000",
    active: "#333333",
    disabledBackground: "rgba(38, 221, 230, 0.3)",
  },
  divider: "#a0a6a7",
  border: {
    default: "#DEDFDF",
    strong: "#333333",
  },
};
