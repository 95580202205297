import React, { useEffect } from "react";
import { Link, useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loadTenantConfig } from "../../../redux/slice/config/configSlice";
import { useAppDispatch } from "../../../redux/useDispatch";
import { RootState } from "../../../redux/store";
import routes from "../../../routes/routes";
import { HomeIcon } from "../../../components/toolbarIcons/HomeIcon";
import { LocationIcon } from "../../../components/toolbarIcons/LocationIcon";
import { BookingIcon } from "../../../components/toolbarIcons/BookingIcon";
import { InfoIcon } from "../../../components/toolbarIcons/InfoIcon";
import { useTheme } from "@mui/material";

type ToolbarItems = {
  [key: string]: {
    name: string;
    route: string;
    icon: React.FC<{ color?: string; fill?: string; size?: number; isActive?: boolean}>;
  };
};

const toolbarIcons: ToolbarItems = {
  home: { icon: HomeIcon, route: routes.home, name: "home" },
  locations: { icon: LocationIcon, route: routes.locations, name: "locations" },
  orders: { icon: BookingIcon, route: routes.orders, name: "orders" },
  info: { icon: InfoIcon, route: routes.hints, name: "info" },
};

const Toolbar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const tenantToolbarItems = useSelector(
    (state: RootState) => state.tenantConfig.config.toolbarItems
  );

  const locationsTabActive = Boolean(useMatch(routes.locations));
  const ordersTabActive = Boolean(useMatch(routes.orders));
  const hintsTabActive = Boolean(useMatch(routes.hints));
  const homeTabActive = Boolean(useMatch(routes.home));

  useEffect(() => {
    dispatch(loadTenantConfig());
  }, [dispatch]);

  const getActiveClass = (item: string) => {
    return (
      (homeTabActive && item === "home") ||
      (locationsTabActive && item === "locations") ||
      (ordersTabActive && item === "orders") ||
      (hintsTabActive && item === "info")
    ) ? 'active' : '';
  };

  return (
    <nav 
      id="toolbar" 
      className="max-w-md mx-auto"
      style={{
        height: "59px",
        backgroundColor: "white",
      }}
    >
      <div className="flex h-full">
        {tenantToolbarItems?.map((item: string) => {
          const isActive = getActiveClass(item) === 'active';
          const Icon = toolbarIcons[item].icon;
          const iconColor = isActive ?  theme.palette.primary.light : theme.palette.primary.main;
          
          return (
            <Link
              key={toolbarIcons[item].route}
              to={toolbarIcons[item].route}
              className={`flex flex-col items-center justify-center h-full ${isActive ? 'active' : ''}`}
              style={{
                padding: "0",
                flex: 1,
                minWidth: 0,
                backgroundColor: isActive ? theme.palette.primary.light : 'transparent',
              }}
            >
              <span 
                className="flex items-center justify-center icon-wrapper"
                style={{
                  width: "32px",
                  height: "32px",
                }}
              >
                <Icon 
                  color={iconColor}
                  fill={isActive ? theme.palette.action.active : 'none'}
                  size={32}
                  isActive={isActive}
                />
              </span>
              <span 
                style={{
                  fontSize: "0.75rem",
                  color: isActive ? theme.palette.action.active :  theme.palette.primary.main,
                  fontFamily: "'ballinger', sans-serif",
                  fontWeight: isActive ? 700 : 400,
                  lineHeight: 1.43,
                  letterSpacing: "0.01071em",
                  textAlign: "center",
                  width: "100%",
                  padding: "0 2px",
                }}
              >
                {t(`toolbar.${toolbarIcons[item].name}`)}
              </span>
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default Toolbar;
