import { ReactComponent as CloseIcon } from "./icons/x-circle.svg";
import { ReactComponent as InfoIcon } from "./icons/information-circle.svg";
import { ReactComponent as InfoIconWhiteFilled } from "./icons/information-circle-white-filled.svg";
import flagGermany from "./flags/germany.svg";
import flagNorway from "./flags/norway.svg";
import flagUnitedKingdom from "./flags/united-kingdom.svg";
import udTransferMoney from "./unDraw/undraw-transfer-money.svg";
import udWarning from "./unDraw/undraw-warning.svg";
import privacyIcon from './icons/consent/privacyIcon.svg';

/** Makes the configuration of the assets per tenant typesafe */
export interface TenantAssets {
  logo: string;
}

export const Assets = {
  flags: {
    germany: flagGermany,
    unitedKingdom: flagUnitedKingdom,
    norway: flagNorway,
  },
  logo: {
    kolula: "kolula",
    vonovia: "vonovia",
  },
  icon: {
    consent: {
      privacyIcon: {
        url: privacyIcon,
      },
    },
    infoIcon: {
      component: InfoIcon,
    },
    infoIconWhiteFilled: {
      component: InfoIconWhiteFilled,
    },
    closeIcon: {
      component: CloseIcon,
    },
  },
  unDraw: {
    transferMoney: udTransferMoney,
    warning: udWarning,
  },
};
