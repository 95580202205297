import React from 'react';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrderId } from '../../../types/order';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { Assets } from '../../../assets/assets';
import routes from "../../../routes/routes";


interface KoluluBoxInfoBoxProps {
  onClose: () => void;
  orderId: OrderId;
}

function getKeyWithFaqInLowercase(obj: Record<string, any>, keySubstring?: string): string | undefined {
  const generalFaq = keySubstring ? keySubstring.toLowerCase() : 'faq';
  for (const generalFaqsPage in obj) {
      if (generalFaqsPage.toLowerCase().includes( generalFaq)) {
          return generalFaqsPage;
      }
  }
  return undefined;
}
const KoluluBoxInfoBox = (props: KoluluBoxInfoBoxProps) => {
  const CloseIcon = Assets.icon.closeIcon.component;
  const { t } = useTranslation();
  const tenantConfig = useSelector((state: RootState) => state.tenantConfig.config)
  const hintPages = useSelector((state: RootState) => state.hints.pages);
  const location = useLocation();
 
  let faqsPath = '/hints';
  if (hintPages) {
    if(location.pathname === "/recipient/orders"){
      faqsPath = '/hints/page/' + getKeyWithFaqInLowercase(hintPages, 'receiverFaqsPage')
    }else{
    faqsPath = '/hints/page/' + getKeyWithFaqInLowercase(hintPages) ;
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.paper,
        width: '350px',
        height: '300px',
        margin: '0 auto',
        padding: theme => theme.spacing(2),
        paddingTop: theme => theme.spacing(1.5),
        boxSizing: 'border-box',
        borderRadius: '2px',
        position: 'relative',
        boxShadow: '0px 0px 0px 3px rgba(255,255,255,1)',
      }}
    >
      <IconButton
        size="small"
        sx={{
          color: theme => theme.palette.primary.main,
          position: 'absolute',
          top: 8,
          right: 10,
        }}
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>

      <Box sx={{ borderBottom: '1px solid gray', marginBottom: '15px' }}>
        <Typography
          sx={{
            color: theme => theme.palette.primary.main,
            marginBottom: '20px',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          {t('use_order.kolula_box.title')}
        </Typography>
      </Box>

      <Box sx={{ borderBottom: '1px solid gray', marginBottom: '15px' }}>
        <Link style={{ textDecoration: 'none' }} href={`tel:${tenantConfig?.contact.phone}`} color={'inherit'}>
          <Typography
            sx={{
              color: theme => theme.palette.primary.main,
              marginBottom: '2px',
              fontSize: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('use_order.kolula_box.call_us')}
          </Typography>
          <Typography
            sx={{
              color: theme => theme.palette.primary.main,
              fontSize: '15px',
              marginBottom: '15px',
            }}
          >
            {tenantConfig?.contact.phoneFormatted}
          </Typography>
        </Link>
      </Box>

      <Box sx={{ borderBottom: '1px solid gray', marginBottom: '15px' }}>
        <Link
          component={RouterLink}
          to={routes.makeIssueReportsUrl(props.orderId)}
          color={'inherit'}
          sx={{ textDecoration: 'none' }}
        >
          <Typography
            sx={{
              color: theme => theme.palette.primary.main,
              marginBottom: '2px',
              fontSize: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('use_order.kolula_box.report_damage')}
          </Typography>
          <Typography
            sx={{
              color: theme => theme.palette.primary.main,
              fontSize: '15px',
              marginBottom: '15px',
            }}
          >
            {t('use_order.kolula_box.use_our_form')}
          </Typography>
        </Link>
      </Box>

      <Box sx={{ borderBottom: '1px solid gray', marginBottom: '15px' }}>
        <Link style={{ textDecoration: 'none' }} component={RouterLink} to={faqsPath} color={'inherit'}>
          <Typography
            sx={{
              color: theme => theme.palette.primary.main,
              marginBottom: '2px',
              fontSize: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('use_order.kolula_box.hints_and_tips')}
          </Typography>
          <Typography
            sx={{
              color: theme => theme.palette.primary.main,
              fontSize: '15px',
              marginBottom: '15px',
            }}
          >
            {t('use_order.kolula_box.look_at_our_faqs')}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default KoluluBoxInfoBox;
