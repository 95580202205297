import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EquipmentListProps, LocationPros } from "../../../types/baseTypes";
import {
  BookingState,
  BookingSummary,
} from "../../../types/bookingCreation";
import { RootState } from "../../store";
import { AssetGroup } from "../../../types/assetGroup";
import { isEmptyObject } from "../../../util/utilFunctions";
import { Rental } from "../../../types/rental";
import { Depot } from "../../../types/depot";
import moment from "moment";
import { ServiceTypeSelectorOption } from "../../../types/order";

const initialState: BookingState = {
  isCameraDisallowed: false,
  bookingId: "",
  bookingSummary: [],
  locations: [],
  useCaseLocations: [],
  equipmentListByLocation: null,
  selectedBooking: null,
  rentalStarted: false,
  rentalFinished: false,
  rentalExpired: false,
  itemState: null,
  selectedServiceType: ServiceTypeSelectorOption.RENTAL, // default shows all
  receivedQRCodeNumber: "",
  isQRCodeNumberValid: false,
  isLocationValid: false,
};

export interface AssetGroupFetchedByLocationIdsPayload {
  locationIds: string[];
  assetGroups: AssetGroup[];
}

export interface DepotsFetchedByLocationIdPayload {
  depots: { [key: string]: any };
}

export interface RentalStartedPayload {
  rental: Rental;
}

export interface RentalFinishedPayload {
  orderId: string;
  updatedOrder: BookingSummary;
}

export interface ItemStateUpdatepayload {
  orderId: string;
  updatedOrder: BookingSummary;
  state:
    | "sender_started_using"
    | "item_placement_confirmed"
    | "receiver_started_using"
    | "item_retrieved_confirmed"
    | "sender_retrieved_confirmed";
}

const bookingSlice = createSlice({
  name: "bookingSlice",
  initialState: initialState,
  reducers: {
    setBookingId: (state, action: PayloadAction<string>) => {
      state.bookingId = action.payload;
    },
    setLocations: (state, action: PayloadAction<LocationPros[]>) => {
      state.locations = action.payload;
    },
    setUseCaseLocations: (state, action: PayloadAction<LocationPros[]>) => {
      state.useCaseLocations = action.payload;
    },
    setSelectedBooking: (
      state,
      action: PayloadAction<BookingSummary | null>
    ) => {
      state.selectedBooking = action.payload;
      if (
        action.payload?.items[0].rental &&
        !isEmptyObject(action.payload?.items[0].rental)
      ) {
        if (action.payload?.items[0].rental.isActive === false) {
          state.rentalFinished = true;
        } else {
          state.rentalStarted = true;
          state.rentalFinished = false;
          if (
            action.payload?.service === "p2p" &&
            action.payload?.items[0].rental.state
          ) {
            state.itemState = action.payload?.items[0].rental.state;
          } else {
            state.itemState = null;
          }
        }
      } else {
        state.rentalStarted = false;
        state.rentalFinished = false;
        state.itemState = null;
      }

      const checkIfOrderIsActive = () => {
        const currentLocationTime = new Date();
        const endDate = moment(action.payload?.items[0].toDate);
        const locationDate = moment(currentLocationTime);
        return locationDate > endDate;
      };

      if (checkIfOrderIsActive()) {
        state.rentalExpired = true;
      } else {
        state.rentalExpired = false;
      }
    },
    setEquipmentListByLocation: (
      state,
      action: PayloadAction<EquipmentListProps[] | null>
    ) => {
      state.equipmentListByLocation = action.payload;
    },

    setBookingSummary: (
      state,
      action: PayloadAction<BookingSummary[] | []>
    ) => {
      if (state.bookingSummary && state.bookingSummary.length === 0) {
        state.bookingSummary = action.payload;
      }
    },
    setRentalStarted: (state, action: PayloadAction<RentalStartedPayload>) => {
      const rental = action.payload.rental;

      if (state.selectedBooking && state.selectedBooking.items.length > 0) {
        state.selectedBooking.items[0].rental = rental;
      }

      if (rental && rental.orderId) {
        const bookingIndex = state.bookingSummary.findIndex(
          (booking) => booking.id === rental.orderId
        );

        if (bookingIndex !== -1) {
          state.bookingSummary[bookingIndex] = {
            ...state.bookingSummary[bookingIndex],
            items: state.bookingSummary[bookingIndex].items.map((item, index) =>
              index === 0 ? { ...item, rental } : item
            ),
          };
        }
      }
      state.rentalStarted = true;
      if (state.selectedBooking?.service === "p2p") {
        state.itemState = "sender_started_using";
      }
    },
    setRentalFinished: (
      state,
      action: PayloadAction<RentalFinishedPayload>
    ) => {
      const { orderId, updatedOrder } = action.payload;
      const bookingIndex = state.bookingSummary.findIndex(
        (booking) => booking.id === orderId
      );

      if (bookingIndex !== -1) {
        state.bookingSummary[bookingIndex] = updatedOrder;
      }
      state.rentalFinished = true;
    },
    setItemState: (state, action: PayloadAction<ItemStateUpdatepayload>) => {
      const { orderId, updatedOrder } = action.payload;
      const bookingIndex = state.bookingSummary.findIndex(
        (booking) => booking.id === orderId
      );

      if (bookingIndex !== -1) {
        state.bookingSummary[bookingIndex] = updatedOrder;
      }
      state.itemState = action.payload.state;
    },
    setAllGroupsByLocation: (
      state,
      action: PayloadAction<AssetGroupFetchedByLocationIdsPayload>
    ) => {
      return {
        ...state,
        locations: state.locations.map((location) => {
          if (action.payload.locationIds.includes(location.id)) {
            return {
              ...location,
              assetGroups: action.payload.assetGroups,
            };
          }
          return location;
        }),
      };
    },
    setAllDepotsByLocation: (
      state,
      action: PayloadAction<DepotsFetchedByLocationIdPayload>
    ) => {
      const { depots } = action.payload;

      return {
        ...state,
        locations: state.locations.map((location) => {
          // Check if depots have been fetched for this specific location
          if (depots[location.id]) {
            return {
              ...location,
              // Attach the fetched depots to the matching location
              depots: depots[location.id],
            };
          }
          return location;
        }),
      };
    },
    setSelectedServiceType: (
      state,
      action: PayloadAction<ServiceTypeSelectorOption>
    ) => {
      state.selectedServiceType = action.payload;
    },
    setReceivedQRCodeNumber: (state, action: PayloadAction<string>) => {
      state.receivedQRCodeNumber = action.payload.trim();
    },
    setIsLocationValid: (state, action: PayloadAction<boolean>) => {
      state.isLocationValid = action.payload;
    },
    setIsCameraDisallowed: (state, action: PayloadAction<boolean>) => {
      state.isCameraDisallowed = action.payload;
    },
  },
});

export const {
  setBookingId,
  setLocations,
  setUseCaseLocations,
  setBookingSummary,
  setSelectedBooking,
  setEquipmentListByLocation,
  setRentalStarted,
  setRentalFinished,
  setItemState,
  setIsLocationValid,
  setReceivedQRCodeNumber,
  setAllGroupsByLocation,
  setAllDepotsByLocation,
  setSelectedServiceType,
  setIsCameraDisallowed
} = bookingSlice.actions;

export default bookingSlice.reducer;

export const selectBookingSummary = (state: RootState) =>
  state.booking.bookingSummary;

export const selectLocationById = (state: RootState, locationId: string) =>
  state.booking.locations.find((location: any) => location.id === locationId);

export const makeLocationAssetGroups = (
  state: RootState,
  locationId: string
) => {
  const location = state.booking.locations.find(
    (location: any) => location.id === locationId
  );
  return location ? location.assetGroups || null : null;
};

export const selectAssetGroupById = (state: RootState, groupId: string) => {
  for (const location of state.booking.locations) {
    if (location.assetGroups) {
      const assetGroup = location.assetGroups.find(
        (group: any) => group.id === groupId
      );
      if (assetGroup) {
        return assetGroup;
      }
    }
  }
  return null;
};

export const makeLocation = (state: RootState, locationId: string) => {
  return (
    state.booking.locations.find(
      (location: any) => location.id === locationId
    ) || null
  );
};

export const selectDepotByLocationId = (
  state: RootState,
  locationId: string
) => {
  const location = state.booking.locations.find(
    (location: any) => location.id === locationId
  );
  return location ? location.depots || null : null;
};

export const selectDepotByLocationAndDepotId = (
  state: RootState,
  locationId: string,
  depotId: string
) => {
  const location = state.booking.locations.find(
    (location: any) => location.id === locationId
  );

  if (location && location.depots) {
    return location.depots.find((depot: Depot) => depot.id === depotId) || null;
  }

  return null;
};
