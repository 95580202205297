import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import React, { useEffect, useState } from "react";
import PageRoutes from "./routes/PageRoutes";
import MainLayout from "./modules/layout/components/MainLayout";
import { RootState } from "./redux/store";
import Notifier from "./modules/common/Notifier";
import { useGetHintListQuery } from "./api/hints/hintsApiSlice";
import { setHints } from "./redux/slice/hints/hintsSlice";
import { useFetchAllGroups } from "./redux/flows/fetchAllGroupsFlow";
import { useFetchAllDepots } from "./redux/flows/fetchAllDepotsFlow";
import { useLoadConsent } from "./redux/flows/loadConsentFlow";
import { setLocations } from "./redux/slice/booking/bookingSlice";
import { useGetLocationListQuery } from "./api/locations/locationsApiSlice";
import { useMatch } from "react-router";

function App() {
  const dispatch = useDispatch();
  const stateLocations = useSelector(
    (state: RootState) => state.booking.locations
  );
  const [fetchedGroups, setFetchedGroups] = useState(false);
  const [fetchedDepots, setFetchedDepots] = useState(false);
  const { data: hints } = useGetHintListQuery(null);
  const fetchAllGroups = useFetchAllGroups();
  const fetchAllDepots = useFetchAllDepots();
  const loadConsent = useLoadConsent();
  const consent = useSelector((state: RootState) => state.consent.consent);
  const { data: locations } = useGetLocationListQuery(null);
  const showToolBar = useSelector(
    (state: RootState) => state.consent.consent !== null
  );

  const locationP2PRouteMatch = useMatch("/recipient/orders");
  const senderRetrievalP2PRouteMatch = useMatch("/finishRental/:token");
  const showToolBarBasedOnRoute =
    !(locationP2PRouteMatch || senderRetrievalP2PRouteMatch) && showToolBar;

  const loadGoogleMapsScript = (apiKey: string) => {
    if (
      !window.google &&
      !document.querySelector('script[src*="maps.googleapis.com"]')
    ) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = () => {
        console.log("Google Maps API loaded!");
      };
    }
  };

  useEffect(() => {
    if (locations) {
      dispatch(setLocations(locations));
    }
  }, [dispatch, locations]);

  useEffect(() => {
    const getAssetGroups = async () => {
      await fetchAllGroups();
    };
    if (!fetchedGroups && stateLocations.length > 0) {
      getAssetGroups();
      setFetchedGroups(true);
    }
  }, [fetchAllGroups, stateLocations, fetchedGroups]);

  useEffect(() => {
    const getDepots = async () => {
      await fetchAllDepots();
    };
    if (!fetchedDepots && stateLocations.length > 0) {
      getDepots();
      setFetchedDepots(true);
    }
  }, [fetchAllDepots, stateLocations, fetchedDepots]);

  useEffect(() => {
    if (hints && hints.data) {
      dispatch(setHints(hints.data));
    }
  }, [hints, dispatch]);

  useEffect(() => {
    loadConsent();
  }, []);

  useEffect(() => {
    if (consent) {
      loadGoogleMapsScript(process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "");
    }
  }, [consent]);

  useEffect(() => {
    const tenantName = process.env.REACT_APP_TENANT;
    if (tenantName) {
      import(`./tenants/${tenantName}/variables.css`).catch((error) => {
        console.error(
          "Error while importing tenant variables.css. Keeping default variables",
          error
        );
      });
    }
  }, []);

  return (
    <MainLayout showToolBar={showToolBarBasedOnRoute}>
      <Notifier />
      <PageRoutes />
    </MainLayout>
  );
}

export default App;
