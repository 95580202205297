import { Rental } from "./rental";

export type OrderId = string;
export type InternalOrderId = string;

/**
 * Orders from the booking system sometimes require to be split into multiple local orders.
 * OrderId (consisting of InternalOrderId + ItemIds) identifies local orders.
 * InternalOrderId identifies the order from the booking system
 */
export interface Order {
  id: OrderId;
  bookingNumber: string;
  locationId: string;
  items: Array<{
    id: string;
    groupId: string;
    amount: number;
    fromDate: Date;
    toDate: Date;
    rental?: Rental;
  }>;
  reservationId?: string;
  contactData?: {
    firstname: string;
    lastname: string;
    sub: string;
    telephone: string;
  };
  createdBy: "admin" | "user";
  service: string;
}

export interface ServiceTypeSelectorItem {
  name: string;
  value: ServiceTypeSelectorOption;
  count?: number;
}

export enum ServiceTypeSelectorOption {
  RENTAL = "rental",
  APARTMENT = "apartment",
  STORAGE = "storage",
  P2P = "p2p",
  ALL = "all",
  PARCEL = "parcel"
}

export type InternalOrder = Order;

export interface ReservationItem {
  fromDate: string;
  amount: number;
  id: string;
  groupId: string;
  toDate: string;
  rental: Rental;
}
export interface Reservation {
  createdDate: string;
  reservationId: string;
  locationId: string;
  id: string;
  items: ReservationItem[];
  bookingNumber: string;
  msg?: string;
}
