import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import { setNextButtonClick } from "../../../../redux/slice/itemRetrieval/itemRetrievalSlice";
import { useSendEmailCodeMutation, useVerifyOTPCodeMutation } from "../../../../api/receiver/receiverApiSlice";
import { RootState } from "../../../../redux/store";
import { useLocation } from "react-router";
import { enqueueNotification } from "../../../../redux/slice/notification/notificationSlice";
import OtpInput from 'react-otp-input';

const styles = {
  outerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    boxSizing: "border-box",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  instructionsText: {
    fontFamily: "IBM Plex Sans, sans-serif",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
    marginTop: "16px",
    color: "#333333",
  },
  resendLink: {
    color: "#00a4b1",
    textDecoration: "underline",
    cursor: "pointer",
  },
  otpInputStyle: {
    width: "3rem",
    height: "3rem",
    margin: "0 8px",
    fontSize: "1.5rem",
    textAlign: "center",
    border: "1px solid #333333",
    borderRadius: "4px",
  },
};

const maskEmail = (email: string): string => {
  const [localPart, domain] = email.split("@");
  const maskedLocalPart = localPart[0] + "*".repeat(localPart.length - 1);
  const [domainName, domainExtension] = domain.split(".");
  const maskedDomainName = domainName.slice(0, 2) + "*".repeat(domainName.length - 2);
  return `${maskedLocalPart}@${maskedDomainName}.${domainExtension}`;
};

function CodeVerification() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [verifyCode] = useVerifyOTPCodeMutation();
  const [sendEmailCode] = useSendEmailCodeMutation();
  const [pin, setPin] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const [isVerified, setIsVerified] = React.useState(false);
  const orderData = useSelector((state: RootState) => state.itemRetrieval.pickupDetailsResponseData);

  const [countdown, setCountdown] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token") || "";
  const email = orderData && orderData.recipientData ? orderData.recipientData.email : null;

  const handleResend = async () => {
    if (canResend) {
      await sendEmailCode({ token: token ?? '' });
      setCountdown(20); // Reset countdown
      setCanResend(false);
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setCanResend(true);
    }
  }, [countdown]);

  const handleChange = (value: string) => {
    setPin(value);
  }

  useEffect(() => {
    const verifyOTP = async (code: string) => {
      const otpResponse = await verifyCode({ orderId: orderData?.id ?? '', code });
      if (otpResponse?.error) {
        const errorData = otpResponse?.error as { data: { error: { message: string } } };
        const errorMessageData = errorData?.data?.error?.message;
        let errorMessage = t("itemRetrieval.codeVerification.error_occurred");
        if (errorMessageData === "Invalid verification code") {
          errorMessage = t("itemRetrieval.codeVerification.invalid_code")
        }
        setHasError(true);
        setIsVerified(false);
        dispatch(
          enqueueNotification({
            severity: "error",
            message: errorMessage,
          })
        );
      } else {
        setHasError(false);
        setIsVerified(true);
      }
    };

    if (pin.length === 6) {
      verifyOTP(pin);
    }
  }, [pin, verifyCode, orderData, dispatch]);

  return (
    <Box sx={styles.outerBox}>
      <Box sx={styles.content}>
        <OtpInput
          value={pin}
          onChange={handleChange}
          numInputs={6}
          renderInput={(props: any, index: number) => {
            const isFilled = pin.length > index;
            return (
              <input
                {...props}
                // Ensuring only numeric input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                className={
                  `otp-input ${hasError ? "otp-input-error" : isVerified ? "otp-input-success" : isFilled ? "otp-input-filled" : ""
                  }`
                }
              />
            )
          }}
        // inputStyle={hasError ? 'otp-input-error' : (isVerified ? 'otp-input-success' : 'otp-input')}
        />

        <Typography sx={styles.instructionsText}>
          <span style={{ color: '#333' }}>{t("itemRetrieval.codeVerification.send_verification")}</span>
          <span style={{ color: '#00a4b1' }}>{email ? maskEmail(email) : 'No email available'}</span>
          <span style={{ color: '#333' }}>{t("itemRetrieval.codeVerification.enter_code")}</span>
        </Typography>
        <Typography sx={styles.instructionsText}>
          <span style={{ color: '#8c8c8c' }}>{t("itemRetrieval.codeVerification.code_not_received")}</span>
          <span>&nbsp;</span>
          {canResend ? (
            <span
              style={styles.resendLink}
              onClick={handleResend}
            >
              {t("itemRetrieval.codeVerification.resend_code")}
            </span>
          ) : (
            <span style={{ color: '#8c8c8c' }}>Resend Code in {countdown}s</span>
          )}
        </Typography>
      </Box>
      <div className="fixed flex justify-center w-full px-5 bottom-16">
        <CustomButton
          isButtonDark={true}
          fullWidth={true}
          disabled={!isVerified}
          onClick={() => dispatch(setNextButtonClick())}
          isOutlined={true}
          children={t("itemRetrieval.codeVerification.next_button_title")}
        />
      </div>
    </Box>
  );
}

export default CodeVerification;
