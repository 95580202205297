import React, { useEffect, useState } from "react";
import { LocationPros } from "../../../../types/baseTypes";
import { Box, Grid, useTheme } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useGetLocationItemsByLocationIdQuery } from "../../../../api/locations/locationsApiSlice";
// import LocationSelectionItemCard from "./LocationSelectionItemCard";
// import { StorageCompartmentItems } from "../../../../redux/slice/location/locationSlice";
import ExpandIcon from "./../../../../assets/icons/expand_icon.png";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";
import FullLockerView from "../components/locationFromBottomBar/FullLockerView";
const styles = {
  outerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  innerBox: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
};
interface Props {
  selectedLocation: LocationPros | null;
}
function LocationSelectionDetails({ selectedLocation }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: equipments, isLoading } = useGetLocationItemsByLocationIdQuery({
    locationId: selectedLocation?.id!,
  });

  const [openImage, setOpenImage] = React.useState(false);

  const startDate =
    selectedLocation?.openingHours?.[0]?.times[0]?.fromHour ?? 0;
  const endDate = selectedLocation?.openingHours?.[0]?.times[0]?.toHour ?? 0;

  const fromTime = moment({ hour: startDate, minute: 0 }).format("hh:mm A"); // 06:00 AM
  const toTime = moment({ hour: endDate, minute: 0 }).format("hh:mm A"); // 10:00 PM
  const address = {
    geoLng: selectedLocation?.address.geoLng,
    geoLat: selectedLocation?.address.geoLat,
  };

  const mapSrc = `https://www.google.com/maps?q=${address.geoLat},${address.geoLng}&z=15&output=embed`;

  const [marginBottom, setMarginBottom] = useState(0);

  const handleOpenImage = () => setOpenImage(true);
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    let marginBottom = 60; // Default marginBottom

    if (userAgent.includes("Win")) {
      marginBottom = 0;
    } else if (userAgent.includes("Mac")) {
      marginBottom = 100;
    } else if (
      userAgent.includes("X11") ||
      userAgent.includes("Linux") ||
      /Android/.test(userAgent)
    ) {
      marginBottom = 50;
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
      marginBottom = 60; // Assuming default for iOS or leave it unchanged if specific logic is not needed
    }

    setMarginBottom(marginBottom);
  }, []);

  if (isLoading) {
    return <LoaderOverlay open={isLoading} />;
  }
  return (
    <Box sx={styles.outerBox}>
      <Box sx={styles.innerBox}>
        <div
          style={{
            paddingBottom: 0,
            marginBottom: marginBottom,
          }}
          className="w-96 max-w-screen-sm px-4 h-[50vh] overflow-scroll
           no-scrollbar mt-2 "
        >
          {/* Map */}
          <Box
            sx={{
              width: "100%",
              height: "9rem",
              border: `1px solid ${theme.palette.primary.light}`,
              borderRadius: "16px",
              padding: "2px",
              my: 4,
            }}
          >
            <iframe
              className="h-36 rounded-xl"
              title="Location Map"
              src={mapSrc}
              style={{ width: "100%", height: "100%", border: "0" }}
              allowFullScreen
              loading="lazy"
            ></iframe>
          </Box>
          {/* item details */}
          <div className={`w-full border-${theme.palette.primary.light} h-36 flex items-center mt-2`}>
            {selectedLocation && selectedLocation.locationImages.length > 0 && (
                <div className="w-1/2 relative ">
                  <button
                    onClick={handleOpenImage}
                    className="absolute bg-[#00000080] h-6 w-6
                  rounded-md right-2 top-2 flex items-center justify-center"
                  >
                    <img
                      className="h-4 object-cover "
                      src={ExpandIcon}
                      alt="locker"
                    />
                  </button>
                  <img
                    className="h-36 w-full object-cover "
                    src={selectedLocation?.locationImages[0]}
                    alt="locker"
                  />
                </div>
            )}
            <div className="ml-2 ">
              <div className="">
                <p className="font-bold text-base">
                  {t("location_from_bottom_bar.location_info")}
                </p>
                <p className="font-normal text-[#333333] text-base">
                  {selectedLocation?.address.street}
                </p>
                <p className="font-bold text-base">
                  {selectedLocation?.address.postalCode}{" "} {selectedLocation?.address.city}
                </p>
              </div>

              <div className="mt-2">
                <p className="font-normal text-[#333333] text-base">
                  {t("location_from_bottom_bar.opening_hours")}:
                </p>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item>
                    <strong className="text-base">
                      {fromTime}
                      <span className="mx-1">-</span>
                      {toTime}
                    </strong>
                  </Grid>
                </Box>
              </div>
            </div>
          </div>
          {/* Items */}
          {/* {equipments?.slice(0, 3)?.map((item: StorageCompartmentItems) => (
            <div key={item.id}>
              <LocationSelectionItemCard item={item} />
            </div>
          ))} */}
        </div>
      </Box>
      {
        selectedLocation && selectedLocation.locationImages.length > 0 && (
          <FullLockerView
            setOpenImage={setOpenImage}
            image={selectedLocation?.locationImages[0]!}
            openImage={openImage}
          />
        )
      }
    </Box>
  );
}

export default LocationSelectionDetails;
