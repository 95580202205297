import moment, { Moment } from "moment";
import { AssetGroupAvailability, AvailabilitySlot } from "../../../types/assetGroup";
import { i18n } from "../../../i18n/i18n";

/**
 * Returns all time slots on the given date with at least one duration that has at least one available board
 *
 * @param availability
 * @param selectedDate
 */
export const getAvailableTimeSlots = (
    availability: AssetGroupAvailability,
    selectedDate: moment.Moment,
): string[] => {
    const dateKey = selectedDate.format('DD.MM.YYYY');
    if (!availability.hasOwnProperty(dateKey)) {
        return [];
    }
    const allTimeSlots = Object.keys(availability[dateKey]);
    const localTimeZone = moment.tz.guess();

    const filteredTimeSlots = allTimeSlots
        .map(timeKey => {
            const europeanTimeZone = 'Europe/Berlin';
            const timeInEurope = moment.tz(`${dateKey} ${timeKey}`, 'DD.MM.YYYY HH:mm', europeanTimeZone);
            const localTime = timeInEurope.tz(localTimeZone);
            return localTime.format('HH:mm');
        })
        .filter(timeKey => {
            // const timeSlot: AvailabilitySlot = availability[dateKey][timeKey];
            // for (const durationRecord of Object.values(timeSlot)) {
            //     for (const availableCount of Object.values(durationRecord)) {
            //         if (availableCount > 0) {
            //             return true;
            //         }
            //     }
            // }

            // return false;
            return true;
        })
        .sort();
    return filteredTimeSlots;
};

/**
 * Returns all durations on the given date and time-slot with at least on available board
 *
 * @param availability
 * @param selectedDate
 * @param selectedTime
 */
export const getAvailableDurations = (
    availability: AssetGroupAvailability,
    selectedDate: moment.Moment,
    selectedTime: string,
): number[] => {
    const dateKey = selectedDate!.format('DD.MM.YYYY');
    if (!availability.hasOwnProperty(dateKey)) {
        return [];
    }
    // const timeSlot: AvailabilitySlot = availability[dateKey][selectedTime];
    const localTimeZone = moment.tz.guess(); // Get user's local timezone
    const europeanTimeZone = 'Europe/Berlin';
    const localTime = moment.tz(`${dateKey} ${selectedTime}`, 'DD.MM.YYYY HH:mm', localTimeZone);
    const timeInEurope = localTime.tz(europeanTimeZone).format('HH:mm');
    const timeSlot: AvailabilitySlot = availability[dateKey][timeInEurope];
    
    const durations: number[] = [];

    for (const duration in timeSlot) {
        if (timeSlot[duration] < 1) {
            // either the duration does not exist or there are no assets available for it
            continue;
        }
        durations.push(Number(duration));

        // const durationValue = availability[dateKey][selectedTime].duration;

        // // Note: For partner USC only a duration of 1 hour is available
        // if (isPartnerUsc && durationValue !== 1) {
        //     continue;
        // }

        // if (!durations.includes(durationValue)) {
        //     durations.push(durationValue);
        // }
    }

    // TODO check why a custom sort is needed
    return durations.sort((a, b) => a - b);
};
export const getTimeFormat = (date: Moment) => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
        return date.format('hh:mm A');
    } else {
        return date.format('HH:mm');
    }
};
