import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { XCircleIcon } from "@heroicons/react/24/outline";

const StyledIconButton = styled(IconButton)({
  marginLeft: "auto",
});

const StyledCancelIcon = styled(XCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

interface BookLocationCancelButtonProps {
  onClose: () => void;
}

const BookLocationCancelButton = (props: BookLocationCancelButtonProps) => {
  return (
    <Box>
      <StyledIconButton onClick={props.onClose}>
        <StyledCancelIcon className="size-8 text-black-500" fontSize="small" />
      </StyledIconButton>
    </Box>
  );
};

export default BookLocationCancelButton;