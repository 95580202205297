import { getI18n } from "react-i18next";
import { BookingStatusType } from "../types/baseTypes";
import { Reservation } from "../types/order";
import { Rental } from "../types/rental";
import i18nInstance from "i18next";

export function convertMinutesToHours(
  minutes: number
): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const translatedHourLabel = getI18n().t("book_location.confirmation.hours");
  const translatedMinutesLabel = getI18n().t("book_location.confirmation.minutes");

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours} ${translatedHourLabel} ${remainingMinutes} ${translatedMinutesLabel}`;
  } else if (hours > 0) {
    return `${hours} ${translatedHourLabel}`;
  } else {
    return `${remainingMinutes} ${translatedMinutesLabel}`;
  }
}
type OrderItem = { id: string };

export function getIdString(items: OrderItem[]): string {
  return items?.map((item) => item?.id)?.join(",");
}

export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

export const getBookingStatusType = (
  rental: Rental,
  rentalExpired: boolean
): BookingStatusType => {
  if (rentalExpired) return "finished";
  if (isEmptyObject(rental)) return "booked";

  return rental.isActive ? "active" : "finished";
};

export function formatDateTime(input: string) {
  // Split the input into the date and the time portion
  const lastSpaceIndex = input.lastIndexOf(" "); // Find the last space before 'am' or 'pm'

  // Extract the date and time parts
  const date = input.slice(0, lastSpaceIndex - 5); // The date portion (subtracting 5 to exclude the time)
  const time = input.slice(lastSpaceIndex - 5); // The time portion, including am/pm

  // Return the formatted string with a vertical bar
  return `${date} | ${time}`;
}

export function transformReservations(reservations: Reservation[]): { id: string }[] {
  return reservations?.map((reservation) => ({ id: reservation?.id}));
}

/**
 * Helper to format currency dependending on the locale.
 * Remarks: Change format for english locale to 'en-US' if needed.
 */
export const formatCurrency = (value: number, locale = 'de-DE', currency = 'EUR') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(value);
};

export const getEquipmentName = (
    equipmentName: string,
    equipmentLangNames?: Record<string, {title: string, description: string}>,
    returnType: "title" | "description" = "title"
) => {
  const lang = i18nInstance.language;

  if (!equipmentLangNames) {
    return equipmentName;
  }

  if (Object.keys(equipmentLangNames).includes(lang)) {
    if (Object.keys(equipmentLangNames[lang]).includes(returnType)){
      return equipmentLangNames[lang][returnType]
    }
  }
  return equipmentName;
}
