import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";

export const receiverApiSlice = createApi({
  reducerPath: "receiverApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["pickupDetails"],
  endpoints: (builder) => ({
    getPickupDetails: builder.query({
      query: ({ token }) => {
        return {
          url: `/order/token/${token}`,
        };
      },
      providesTags: ["pickupDetails"],
    }),

    sendEmailCode: builder.mutation({
      query: (data: { token: string }) => {
        return {
          url: `order/verify/initiate`,
          method: "POST",
          data,
        };
      },
    }),

    verifyOTPCode: builder.mutation({
      query: (data: { orderId: string; code: string }) => {
        return {
          url: `order/verify/confirm`,
          method: "POST",
          data,
        };
      },
    }),

    verifyLocationCode: builder.mutation({
      query: (dataValue) => {
        return {
          url: "/location/verify/code",
          method: "POST",
          data: dataValue,
        };
      },
    }),
  }),
});

export const {
  useGetPickupDetailsQuery,
  useSendEmailCodeMutation,
  useVerifyOTPCodeMutation,
  useVerifyLocationCodeMutation,
} = receiverApiSlice;
