import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import { Fade, Snackbar, useMediaQuery } from '@mui/material';
import InfoBox from '../layout/components/InfoBox';
import { RootState } from '../../redux/store';
import { dequeueNotification } from '../../redux/slice/notification/notificationSlice';
import SimpleInfoBox from '../layout/components/SimpleInfoBox';

const Notifier = () => {
    const dispatch = useDispatch();
    const notification = useSelector((state: RootState) => state.notification[0]);
    const [open, setOpen] = useState<boolean>(false);

    // Define the media queries for small and medium screens
    const isSmallOrMediumScreen = useMediaQuery('(max-width:960px)'); // Material-UI's default "md" breakpoint

    // Define the width based on the screen size
    const width = isSmallOrMediumScreen ? '80%' : '30%';

    const closeNotification = () => {
        if (notification) {
            dispatch(dequeueNotification({id: notification.id}));
        }
    };

    useEffect(() => {
        setOpen(false);

        if (notification) {
            setTimeout(() => setOpen(true), 70);
            setTimeout(closeNotification, notification.autoHideDuration);
        }
    }, [notification]);

    return (
        notification && (
          <Snackbar
            key={notification?.id + (open ? 'open' : 'closed')}
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ width: '100%' }}
          >
            <div style={{ width: width, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <InfoBox
                variant={notification?.severity}
                text={notification?.message}
                onClose={closeNotification}
              />
            </div>
          </Snackbar>
        )
    );
      
};

export default Notifier;
