import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import TenantLogo from "../layout/components/TenantLogo";
import { Trans, useTranslation } from "react-i18next";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import Header from "../layout/components/Header";

const styles = {
  hintsLink: (theme: any) => ({
    color: theme.palette.secondary.contrastText,
    textDecorationColor: theme.palette.secondary.contrastText,
  }),
};

// Styled components
const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
  color: "black",
  height: "100%",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const StyledCancelIcon = styled(XCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const MoreToComeContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { config } = useSelector((state: RootState) => state.tenantConfig);

  // Function to redirect to the root URL
  const redirectToRoot = () => {
    navigate("/"); // Redirect to the root URL
  };

  const email = config?.email; 

  return (
    <div>
      <Header>
        <div className="size-12"></div>
        <TenantLogo />
        <IconButton onClick={redirectToRoot}>
          <StyledCancelIcon
            className="size-8 text-black-500"
            fontSize="small"
          />
        </IconButton>
      </Header>
      <h5
        style={{
          fontWeight: 600,
          fontSize: "1.5rem", // 24px
          marginTop: "2.5rem", // 40px
          marginBottom: "1.5rem", // 24px
          textAlign: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        {t("moreToCome.header")}
      </h5>
      <p
        style={{
          textAlign: "center",
          fontSize: "1rem", // 16px
          letterSpacing: "0.32px",
          lineHeight: "1.25rem", // 20px
          color: "gray",
          paddingLeft: "20px",
          paddingRight: "20px",
          marginBottom: "1.5rem", // 24px
        }}
      >
        {t("moreToCome.first_text")}
      </p>
      <p
        style={{
          textAlign: "center",
          fontSize: "1rem", // 16px
          letterSpacing: "0.32px",
          lineHeight: "1.25rem", // 20px
          color: "gray",
          paddingLeft: "20px",
          paddingRight: "20px",
          marginBottom: "1.5rem", // 24px
        }}
      >
        <Trans
          i18nKey="moreToCome.second_text"
          components={{
            a: (
              <a href={`mailto:${email}`}>
                <Typography sx={styles.hintsLink} style={{ fontSize: "18px" }}>
                  {email}
                </Typography>
              </a>
            ),
          }}
        />
      </p>
      {/* Uncomment if needed */}
      {/* <TextField
        variant="outlined"
        multiline
        rows={4}
        placeholder="Type your suggestions here..."
        className="w-full mt-4 rounded-lg"
      /> */}

      {/* Uncomment if needed */}
      {/* <Box className="flex justify-center w-[94%] mt-auto">
        <CustomButton isButtonDark fullWidth>
          {t("moreToCome.button")}
        </CustomButton>
      </Box> */}
    </div>
  );
};

export default MoreToComeContent;
