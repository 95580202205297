import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import InputLabel from "@mui/material/InputLabel";

const SelectStyled = styled(Select)(({ theme, value }) => ({
  backgroundColor: theme.palette.background.default,
  "& .MuiSelect-select:focus": {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  "& .MuiSelect-select:not(:focus)": {
    backgroundColor: theme.palette.background.default,
    width: "100%",
  },
  height: "62px",
  "& .MuiInputBase-input": {
    color: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor:
      value !== 0
        ? theme.palette.primary.light
        : theme.palette.primary.contrastText,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.light,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.light,
  },
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.contrastText,
  },
}));

const Icon = styled(ExpandMoreIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  color: theme.palette.primary.contrastText,
}));

interface BookingDurationSelectProps {
  value: number;
  onChange: (value: number) => void;
  availableDurations: number[];
  disabled: boolean;
  prices: { [duration: number]: number }; // Add a prices prop to provide the price for each duration
  label: string;
}

const BookingDurationSelect: React.FC<BookingDurationSelectProps> = (props) => {
  const { t } = useTranslation();
  const tenantConfig = useSelector(
    (state: RootState) => state.tenantConfig.config
  );
  const shouldShrinkLabel = props.value !== 0;

  const renderSelectedValue = (selectedValue: unknown) => {
    const duration = selectedValue as number; // Type casting to number
    if (duration === 0) {
      return <em>{props.label}</em>;
    }

    return duration < 1440
      ? t("general.hours_val", { count: duration / 60 })
      : t("general.days_val", { count: duration / 1440 });
  };

  return (
    <FormControl>
      {shouldShrinkLabel && (
        <InputLabel sx={{ color: "#333333" }} id="demo-select-large-label">
          {props.label}
        </InputLabel>
      )}
      <SelectStyled
        labelId="demo-select-large-label"
        id="demo-select-small"
        value={props.value}
        disabled={props.disabled || props.availableDurations.length === 0}
        label={shouldShrinkLabel ? t("general.duration") : null}
        onChange={(event) => props.onChange(Number(event.target.value))}
        IconComponent={Icon}
        renderValue={renderSelectedValue}
      >
        <MenuItemStyled value={0}>
          <span>{props.label}</span>
        </MenuItemStyled>
        {props.availableDurations.map((duration) => (
          <MenuItemStyled key={duration} value={duration}>
            <Typography variant="body2" className={"durationHour"}>
              {duration < 1440
                ? t("general.hours_val", { count: duration / 60 })
                : t("general.days_val", { count: duration / 1440 })}
            </Typography>
            <Typography variant="body2" className={"durationPrice"}>
              {`${props.prices[duration]?.toFixed(2) || "0.00"} ${
                tenantConfig.currencySymbole
              }`}
            </Typography>
          </MenuItemStyled>
        ))}
      </SelectStyled>
    </FormControl>
  );
};

export default BookingDurationSelect;
