import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { initializePaymentProvider, NoPaymentInitializedPayload, paymentInitializationFailed } from '../../../../../redux/slice/bookingCreation/bookingCreationSlice';
import LoaderOverlay from '../../../../layout/components/LoaderOverlay';
import { useInitializeNoPayment } from '../../../../../redux/flows/initializeNoPaymentFlow';


const RootWrapper = styled(Box)(({ theme }) => ({
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: theme.spacing(2),
}))

const NoPayment = ({ service }: { service: string }) => {
    const dispatch = useDispatch();
    const initializeNoPayment = useInitializeNoPayment({service})
    const [isInitialized, setIsInitialized] = React.useState(true);
    const paymentInitilaized = useRef(false)


    useEffect(() => {
        const manageNoPayment = async () => {

            try {
                const response = (await initializeNoPayment() as NoPaymentInitializedPayload[])[0];

                if (response.orderIds.length && response.reservationIds.length) {
                    setIsInitialized(true);
                }

                if (!response || !response.orderIds.length || !response.reservationIds.length) {
                    dispatch(paymentInitializationFailed({}));
                } else {
                    const returnUrl = `${window.location.origin
                        }/payments/no_payment/return?reservationIds=${response.reservationIds.join(
                            ","
                        )}`;

                    window.location.replace(returnUrl)
                    initializePaymentProvider(null)
                }
            }
            catch (error) {
                dispatch(paymentInitializationFailed({}));
                initializePaymentProvider(null)
            }
        };

        // In React Strict Mode, this function is called twice during development.
        // To prevent the payment initialization from running multiple times, we use a ref.
        // In production, this function will only be called once, so using a ref will not cause any issues.
        if (!paymentInitilaized.current) {
            paymentInitilaized.current = true;
            manageNoPayment();
        }

    }, [dispatch, initializeNoPayment]);

    return (
        <RootWrapper>
            {!isInitialized ? <LoaderOverlay open={true} /> :
                <section >
                </section>
            }
        </RootWrapper>
    );
};

export default NoPayment;
