import React, { useEffect, useState } from "react";
import {  useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  PersonalData,
  PersonalDataValidation,
} from "../../../types/bookingCreation";
import DebouncedPhoneNumberInput from "../common/components/PersonalDataStep/DebouncedPhoneNumberInput";
import DebouncedTextInput from "../common/components/PersonalDataStep/DebouncedTextInput";
import { RootState } from "../../../redux/store";
import { TenantConfig as TConfig } from "../../../types/tenantConfig";
import TenantConfig from "../../../util/tenantConfig";
import { Box } from "@mui/material";
import { recipientEmailChanged, recipientFirstNameChanged, recipientLastNameChanged, recipientPhoneChanged } from "../../../redux/slice/bookingCreation/bookingCreationSlice";

const RecipientDataStep = () => {
  // const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recipientData: PersonalData = useSelector(
    (state: RootState) => state.bookingCreation.recipientData
  );
  const recipientDataValidation: PersonalDataValidation = useSelector(
    (state: RootState) => state.bookingCreation.recipientDataValidation
  );
  const partner = useSelector(
    (state: RootState) => state.bookingCreation.partner
  );

  const onFirstNameChange = (firstName: string) =>
    dispatch(recipientFirstNameChanged({ firstName }));
  const onLastNameChange = (lastName: string) =>
    dispatch(recipientLastNameChanged({ lastName }));
  const onPhoneChange = (phone: string) => {
    dispatch(recipientPhoneChanged({ phone: phone }));
  };
  const onEmailChange = (email: string) =>
    dispatch(recipientEmailChanged({ email }));
  // const tenantConfig = useSelector((state: RootState) => state. Selector.Config.config);

  const [tenantConfig, setTenantConfig] = useState<TConfig>();

  useEffect(() => {
    const retrieveTenantConfig = async () => {
      const config = await TenantConfig();
      setTenantConfig(config);
    };
    retrieveTenantConfig();
  }, []);

  return (
    <Box
      className="p-3"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <DebouncedTextInput
        label={t("book_location.personal_data.first_name")}
        initialValue={recipientData.firstName}
        onChange={onFirstNameChange}
        valid={recipientDataValidation.firstName}
        autoComplete={"given-name"}
      />
      <DebouncedTextInput
        label={t("book_location.personal_data.last_name")}
        initialValue={recipientData.lastName}
        onChange={onLastNameChange}
        required={false}
        valid={recipientDataValidation.lastName}
        autoComplete={"family-name"}
      />
      <DebouncedTextInput
        label={t("book_location.personal_data.email")}
        initialValue={recipientData.email}
        onChange={onEmailChange}
        valid={recipientDataValidation.email}
        autoComplete={"email"}
      />
      <DebouncedPhoneNumberInput
        initialValue={recipientData.phone}
        onChange={onPhoneChange}
        valid={recipientDataValidation.phone}
        required={false}
      />
    </Box>
  );
};

export default RecipientDataStep;
