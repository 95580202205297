import { useDispatch } from "react-redux";
import { baseStore } from "../store"; // Adjust the import based on your file structure
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import { enqueueNotification } from "../slice/notification/notificationSlice";
import { t } from "i18next";
import { reservationsApiSlice } from "../../api/reservations/reservationsApiSlice";
import { couponAppliedToReservations } from "../slice/bookingCreation/bookingCreationSlice";

interface AdditionalService {
  serviceId: string;
  name: string;
  quantity: number; // -1 means no quantity for the service
}

const updateReservationCall = async (
  reservationId: string,
  voucherId?: string,
  additionalServices?: AdditionalService[],
  totalPrice?: number
) => {
  try {
    const result = await baseStore.dispatch(
      reservationsApiSlice.endpoints.updateReservation.initiate({
        reservationId: reservationId,
        voucherId: voucherId,
        additionalServices: additionalServices,
        totalPrice: totalPrice,
      })
    );
    if ("error" in result && result.error) {
      throw new Error(`Failed to update Reservation. Error: ${result.error}`);
    }
    return result;
  } catch (error) {
    throw new Error(
      `Failed to update Reservation for reservationId ${reservationId}: ${error}`
    );
  }
};

export const useUpdateReservation = (
  reservationId: string,
  voucherId?: string,
  additionalServices?: AdditionalService[],
  totalPrice?: number
) => {
  const dispatch = useDispatch();

  const updateReservation = async () => {
    try {
      const updatedReservation = await updateReservationCall(
        reservationId,
        voucherId,
        additionalServices,
        totalPrice
      );
      dispatch(couponAppliedToReservations([updatedReservation.data[0]]));
      console.log(
        "Reservation successfully updated",
        updatedReservation.data[0]
      );
      localStorage.setItem(
        "new_reservation",
        JSON.stringify(updatedReservation.data[0])
      );
    } catch (error) {
      Logger.for("reservationChanged").error(error);
      ErrorTracker.trackException(error as Error);
      dispatch(
        enqueueNotification({
          severity: "error",
          message: t("use_order.handovers.item_placement.error"),
        })
      );
      // Propagate the error
      throw "Error occurs while changing the Reservation";
    }
  };

  return updateReservation;
};
