import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { loadStripeCheckout } from '../../../../../util/stripe';
import { paymentInitializationFailed, stripePaymentInitialized, StripePaymentInitializedPayload } from '../../../../../redux/slice/bookingCreation/bookingCreationSlice';
import { useInitializeStripePayment } from '../../../../../redux/flows/initializeStripePaymentFlow';
import LoaderOverlay from '../../../../layout/components/LoaderOverlay';

declare global {
    interface Window {
        StripeCheckout: (options: {
            checkoutFrontendUrl: string;
            iFrameContainerId: string;
            language: string;
            token: string;
        }) => void;
    }
}

const STRIPE_CHECKOUT_FRAME_CONTAINER_ID = 'stripe-checkout-frame-container';

const RootWrapper = styled(Box)(({ theme }) => ({
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: theme.spacing(2),
}))

const StripePayment = ({ service }: {service: string}) => {
    const dispatch = useDispatch();
    const initializeStripePayment = useInitializeStripePayment({service})
    const [isInitialized, setIsInitialized] = React.useState(false);
    const paymentInitilaized = useRef(false)

    useEffect(() => {
        const manageStripePayment = async () => {
            const response = (await initializeStripePayment() as StripePaymentInitializedPayload[])?.[0];

            if (response?.checkoutFrontendUrl && response.token) {
                dispatch(stripePaymentInitialized(response));
                setIsInitialized(true);
            }

            if (!response?.checkoutFrontendUrl || !response.token) {
                dispatch(paymentInitializationFailed({}));
            } else {
                const initStripe = async () => {
                    const stripe = await loadStripeCheckout();
                    return stripe;
                };
                initStripe().then((stripe) => {

                    if (stripe && response.token) {
                        stripe.redirectToCheckout({
                            sessionId: response.token,
                        });
                    }
                });
            }
        };

        // In React Strict Mode, this function is called twice during development.
        // To prevent the payment initialization from running multiple times, we use a ref.
        // In production, this function will only be called once, so using a ref will not cause any issues.
        if (!paymentInitilaized.current) {
            paymentInitilaized.current = true;
            manageStripePayment();
        }

    }, [dispatch, initializeStripePayment]);

    return (
        <RootWrapper>
            {!isInitialized ? <LoaderOverlay open={true} /> :
                <section id={STRIPE_CHECKOUT_FRAME_CONTAINER_ID}>
                </section>
            }
        </RootWrapper>
    );
};

export default StripePayment;
