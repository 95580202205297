import { Box, Button, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LuChevronsUpDown } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../components/icons/CloseIcon";
import { useCreateNewReservation } from "../../../redux/flows/createReservationFlow";
import { useFetchApartments } from "../../../redux/flows/fetchApartmentsFlow";
import { useUpdateReservation } from "../../../redux/flows/updateReservationFlow";
import {
  bookingCreationCompleted as resetApartmentBooking,
  sortApartment,
} from "../../../redux/slice/apartmentRental/apartmentRentalSlice";
import {
  bookingFlowChanged,
  bookingStepChanged,
  bookingCreationCompleted as resetBookingCreation,
  setSelectedLocation,
  setUseCase,
} from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import { RootState } from "../../../redux/store";
import routes from "../../../routes/routes";
import GTM from "../../../util/gtm";
import { usePrevious } from "../../../util/usePrevious";
import GeneralDialog from "../../common/GeneralDialog";
import Header from "../../layout/components/Header";
import LoaderOverlay from "../../layout/components/LoaderOverlay";
import TenantLogo from "../../layout/components/TenantLogo";
import ApartmentRentalNavigateBackAction from "../common/util/ApartmentRentalNavigateBackAction";
import ApartmentRentalNavigateNextAction from "../common/util/ApartmentRentalNavigateNextAction";
import BookLocationCancelButton from "../common/util/BookLocationCancelButton";
import BookLocationHeader from "../common/util/BookLocationHeader";
import FloatingViewMapButton from "../common/util/FloatingViewMapButton";
import ApartmentOverview from "./ApartmentOverview";
import ApartmentSelectionStep from "./ApartmentSelectionStep";
import BookingOverview from "./BookingOverview";
import LocationAndDurationSelectionStep from "./LocationAndDurationSelectionStep";
import Payment from "./Payment";
import PersonalData from "./PersonalDataStep";
// import EquipmentSelectionStep from "./EquipmentSelectionStep";

export enum BookingCreationStep {
  locationSelection,
  apartmentSelection,
  apartmentOverview,
  bookingSummary,
  personalData,
  payment,
}

const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  color: "white",
  height: "100%",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.default,
  paddingBottom: `calc(${theme.spacing(1)}px + env(safe-area-inset-bottom, 0))`,
  display: "flex",
  flexDirection: "column",
}));

const HeaderWrapper = styled(Box)({
  position: "relative",
  marginTop: "10px",
});

const StepWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "100%",
  maxWidth: 1000,
  margin: "0 auto",
  overflowY: "auto",
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(6),
  },
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  width: "calc(100% - 42px)",
  maxWidth: 1000,
  margin: "0 auto",
  paddingBottom: theme.spacing(5),
  bottom: 10,
  left: 0,
  right: 0,
  [theme.breakpoints.up("lg")]: {
    paddingBottom: theme.spacing(5),
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  width: "150px",
  marginTop: theme.spacing(1),
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
}));

const BackButton = styled(Box)({
  boxSizing: "border-box",
  marginRight: "auto",
  zIndex: 1000,
});

export const CancelButton = styled(Box)({
  boxSizing: "border-box",
  marginLeft: "auto",
});

interface BookLocationDialogProps {
  returnPage: string;
}

const BookLocationPage = (props: BookLocationDialogProps) => {
  const stepWrapperRef = useRef<HTMLElement>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const voucher = useSelector(
    (state: RootState) => state.bookingCreation.roomRentalVoucher
  );
  const totalPrice = useSelector(
    (state: RootState) => state.bookingCreation.totalPrice
  );
  const additionalServices = useSelector(
    (state: RootState) => state.bookingCreation.additionalServices
  );
  const open = Boolean(anchorEl);
  const { locationApartmentList, isMapOpen } = useSelector(
    (state: RootState) => state.apartment
  )!;
  const reservation = localStorage.getItem("new_reservation");
  const reservationId =
    reservation && reservation != "undefined"
      ? JSON.parse(reservation).id
      : null;
  const roomRentalLocation = useSelector(
    (state: RootState) => state.bookingCreation.roomRentalLocation
  );

  const [pageDialogOpen, setPageDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const locationId = props.locationId;
  const step = useSelector((state: RootState) => state.apartment.step);
  const previousStep = usePrevious(step);
  const orderData = useSelector(
    (state: RootState) => state.bookingCreation.orderData
  );
  const createNewReservation = useCreateNewReservation();
  const updateReservation = useUpdateReservation(
    reservationId,
    voucher?.id,
    additionalServices,
    totalPrice
  );

  const selectedLocation = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  );
  const assetGroupId = useSelector(
    (state: RootState) => state.bookingCreation.assetGroupId
  );
  const appliedCouponCode = useSelector(
    (state: RootState) => state.bookingCreation.appliedCouponCode
  );

  const fetchApartments = useFetchApartments();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModel = () => {
    setAnchorEl(null);
  };

  const handleSortApartment = (sortType: "ASC" | "DESC") => {
    dispatch(sortApartment(sortType));
    handleCloseModel();
  };

  useEffect(() => {
    // dispatch(loadBookingFlow({}));
    stepWrapperRef.current = document.getElementById(
      "apartmentProcessStepWrapper"
    )!;

    dispatch(
      bookingStepChanged({
        step: BookingCreationStep.locationSelection,
        nextEnabled: !!selectedLocation,
      })
    );

    // Set the usecase
    dispatch(setUseCase({ useCase: "room" }));

    // Set the bookingFlow
    dispatch(
      bookingFlowChanged({
        bookingFlow: [
          "locationSelection",
          "apartmentSelection",
          "apartmentOverview",
          "bookingSummary",
          "personalData",
          "payment",
        ],
      })
    );
  }, []);

  useEffect(() => {
    const manageReduxData = async () => {
      if (step === BookingCreationStep.apartmentSelection) {
        await fetchApartments();
      }

      if (
        step === BookingCreationStep.apartmentSelection &&
        previousStep === BookingCreationStep.apartmentOverview &&
        roomRentalLocation.district === "all"
      ) {
        dispatch(setSelectedLocation(null));
        await fetchApartments();
      }

      if (
        step === BookingCreationStep.bookingSummary &&
        previousStep === BookingCreationStep.apartmentOverview
      ) {
        const processedReservation = await createNewReservation();

        // if (processedReservation) {
        //   // Due to asynchronous nature of redux; we add the returned processed reservation to previous state
        //   const reservationIds = reservations.map(
        //     (reservation: Reservation) => reservation.reservationId!
        //   );
        //   reservationIds.push(processedReservation.reservationId!);
        // }
      }
      if (
        step === BookingCreationStep.personalData &&
        previousStep === BookingCreationStep.bookingSummary
      ) {
        await updateReservation();
      }

      GTM.trackEecCheckoutStep(step);
    };
    manageReduxData();
  }, [step]);

  const headerText = useMemo(() => {
    switch (step) {
      case BookingCreationStep.locationSelection:
        return t("apartment.location_duration_selection");
      case BookingCreationStep.apartmentOverview:
        return t("book_location.apartment_overview.header_text");
      case BookingCreationStep.bookingSummary:
        return t("book_location.booking_summary.header_text");
      case BookingCreationStep.personalData:
        return t("book_location.apartment_overview.personal_data");
      default:
        return "";
    }
    // }, [step, orderData.date, braintreePayment.paymentToken, paymentInitFailed]);
  }, [step, orderData.date]);

  const isLoading = () => {
    if (step === BookingCreationStep.personalData) {
      // third step, enter personal data
      // return !reservation;
      return false;
    }

    return false;
  };

  const closePage = () => {
    // history.goBack();
    navigate(routes.home);
    dispatch(resetApartmentBooking());
    dispatch(resetBookingCreation());
  };

  const isLastStep = step === BookingCreationStep.payment;
  return (
    <Root>
      <Header>
        <div className="size-12 absolute left-0">
          <ApartmentRentalNavigateBackAction />
        </div>
        <TenantLogo />
        <button
          className="size-12 absolute right-0 text-foreground/90"
          onClick={() => setPageDialogOpen(true)}
        >
          <CloseIcon />
        </button>
      </Header>
      <Box sx={{ marginTop: "20px" }}>
        {step === BookingCreationStep.apartmentSelection ? (
          <div
            className="flex items-center justify-between px-4"
            style={{
              flex: 1,
              width: "100%",
              maxWidth: 1000,
              margin: "0 auto",
            }}
          >
            <div>
              <p className="text-sm font-bold text-black">
                {locationApartmentList?.length}{" "}
                {t("apartment.apartments_found", {
                  count: locationApartmentList?.length,
                })}
              </p>
            </div>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  // padding: "16px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleClick}
                  sx={{
                    borderColor: "#DEDFDF",
                    // borderColor: "#26DDE6",
                    borderWidth: 1,
                    color: "black",
                    textTransform: "none",
                    marginLeft: "16px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    height: "36px",
                    padding: "6px 16px",
                    fontSize: "14px",
                    "&:focus": {
                      borderColor: "#26DDE6",
                    },
                    "&:hover": {
                      background: "#fff",
                      borderColor: "#26DDE6",
                    },
                  }}
                >
                  {t("apartment.sort.title")}
                  <LuChevronsUpDown size={12} />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseModel}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={() => handleSortApartment("ASC")}>
                    <span className="text-[#4F4F4F]">
                      {t("apartment.sort.asc")}
                    </span>
                  </MenuItem>
                  <MenuItem onClick={() => handleSortApartment("DESC")}>
                    <span className="text-[#4F4F4F]">
                      {t("apartment.sort.desc")}
                    </span>
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          </div>
        ) : (
          <BookLocationHeader text={headerText} variant={"h2"} />
        )}
      </Box>
      <StepWrapper id={"apartmentProcessStepWrapper"}>
        {step === BookingCreationStep.locationSelection && (
          <LocationAndDurationSelectionStep />
        )}
        {step === BookingCreationStep.apartmentSelection && (
          <ApartmentSelectionStep />
        )}
        {step === BookingCreationStep.apartmentOverview && (
          <ApartmentOverview />
        )}
        {step === BookingCreationStep.bookingSummary && <BookingOverview />}
        {step === BookingCreationStep.personalData && <PersonalData />}
        {step === BookingCreationStep.payment && <Payment />}
      </StepWrapper>

      <ActionsWrapper>
        {step === BookingCreationStep.apartmentSelection ? (
          <FloatingViewMapButton />
        ) : (
          !isLastStep && <ApartmentRentalNavigateNextAction />
        )}
      </ActionsWrapper>
      <LoaderOverlay open={isLoading()} />
      <GeneralDialog
        open={pageDialogOpen}
        onClose={() => setPageDialogOpen(false)}
        onContinue={closePage}
        content={t("use_order.closing_dialog.content")}
      />
    </Root>
  );
};

export default BookLocationPage;
