import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import { RootState } from "../../../../redux/store";
import {
  setEnterQRCodeButtonClick,
  setIsLocationValid,
} from "../../../../redux/slice/itemRetrieval/itemRetrievalSlice";
import { enqueueNotification } from "../../../../redux/slice/notification/notificationSlice";
import useCameraAccess from "./useCameraAccess";
import CameraAccessDenied from "../../../layout/components/CameraAccessDenied";
import { useState } from "react";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";

const styles = {
  outerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "89vh",
    marginTop: "-100px",
    flexDirection: "column",
  },
};

function QRVerification() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { pickupDetailsResponseData, isCameraDisallowed } = useSelector(
    (state: RootState) => state.itemRetrieval
  );
  const hasCameraAccess = useCameraAccess();

  const verifyLocation = (qrCodeResult: IDetectedBarcode) => {
    const rawValue = qrCodeResult.rawValue;
    if (rawValue === pickupDetailsResponseData?.locationId) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        dispatch(setIsLocationValid(true));
        dispatch(
          enqueueNotification({
            severity: "success",
            message: t("itemRetrieval.confirmLocation.notification.success"),
          })
        );
      }, 3000);
    } else {
      dispatch(
        enqueueNotification({
          severity: "error",
          message: t("itemRetrieval.confirmLocation.notification.error"),
        })
      );
    }
  };

  const handleEnterQRCode = () => {
    dispatch(setEnterQRCodeButtonClick());
  };
  if (loading) {
    return <LoaderOverlay open={loading} />;
  }
  if (hasCameraAccess === null) {
    return (
      <Box sx={styles.outerBox}>
        <div className="text-black flex w-full items-center justify-center m-auto font-medium">
          {t("itemRetrieval.pickupDetails.requesting_camera_access")}
        </div>
      </Box>
    );
  }
  return (
    <Box sx={styles.outerBox}>
      <div className="fixed flex justify-center flex-col w-full m-auto mx-5">
        {isCameraDisallowed ? (
          <div className="-mt-20">
            <Typography textAlign="center">
              <CameraAccessDenied />
            </Typography>
          </div>
        ) : (
          <>
            <div className="mb-10">
              <Typography textAlign="center">
                {t("itemRetrieval.confirmLocation.sub_title.text1")}
                <strong className="mx-1">
                  {t("itemRetrieval.confirmLocation.sub_title.text2")}
                </strong>
                {t("itemRetrieval.confirmLocation.sub_title.text3")}
              </Typography>
            </div>
            <Scanner
              styles={{
                container: {
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "auto",
                  height: "40vh",
                  width: "40vh",
                  maxWidth: 500,
                  maxHeight: 500,
                },
              }}
              onScan={(result: IDetectedBarcode[]) =>
                verifyLocation(result?.[0])
              }
            />
          </>
        )}
        <div className="my-4 mt-16">
          <p className="text-kolula-deepGray text-center">
            {t("itemRetrieval.confirmLocation.sub_text.text1")}
            <button
              onClick={handleEnterQRCode}
              className="mx-1 text-kolula-primary underline"
            >
              {t("itemRetrieval.confirmLocation.sub_text.text2")}
            </button>
          </p>
        </div>
      </div>
    </Box>
  );
}

export default QRVerification;
