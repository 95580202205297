import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {v4 as uuidv4} from 'uuid';

export interface NotificationDequeuedPayload {
  id: string;
}

export interface NotificationEnqueuedPayload {
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  autoHideDuration?: number;
}

interface NotificationState {
  id: string;
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  autoHideDuration: number;
}

const initialState: NotificationState[] = [];

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    enqueueNotification: (state, action: PayloadAction<NotificationEnqueuedPayload>) => {
      state.push({
        id: uuidv4(),
        severity: action.payload.severity,
        message: action.payload.message,
        autoHideDuration: action.payload.autoHideDuration || 4000,
      });
      return;
    },
    dequeueNotification: (state, action: PayloadAction<NotificationDequeuedPayload>) => {
      const index = state.findIndex(notification => notification.id === action.payload.id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    clearNotifications: (state) => {
      return []; // Clear all notifications
    },
  },
});

export const { enqueueNotification, dequeueNotification, clearNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;