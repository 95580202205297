import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Link, styled, Typography } from '@mui/material';
import CustomButton from '../../layout/components/CustomButton';
import { i18n } from '../../../i18n/i18n';

const DialogText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 'semi-bold',
  }));
const DialogTitleText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',

  }));

const CancelButton = styled(CustomButton)(({ theme }) => ({
    height: '44px',
    gap: '10px',
    marginRight: '10px',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  }));

interface PageClosingDialogProps {
    open: boolean;
    onClose: () => void;
    onContinue?: () => void;
}

const ConsentDialog = (props: PageClosingDialogProps) => {
    const {t} = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                >
                <DialogTitle>
                    <DialogTitleText>
                        {t('consent.dialog.title')}
                    </DialogTitleText>
                </DialogTitle>
                <DialogContent>
                    <DialogText variant={'body2'} sx={{fontWeight: 400,}}>
                        {t('consent.dialog.content')}
                    </DialogText>
                </DialogContent>
                <DialogActions>
                    <CancelButton
                        isButtonDark={false}
                        variant="outlined"
                        onClick={() => props.onClose()}
                        color="secondary"
                        style={{ 
                            width: currentLanguage === 'en' ? '50px' : '90px',
                        }}
                    >
                        {t('consent.dialog.close')}
                    </CancelButton>
                </DialogActions>
                </Dialog>
        </>
    );
};

export default ConsentDialog;
