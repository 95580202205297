import { useTheme } from "@emotion/react";
import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { RootState } from "../../redux/store";
import routes from "../../routes/routes";
import Header from "../layout/components/Header";
import TenantLogo from "../layout/components/TenantLogo";
import LanguageSwitcher from "./components/LanguageSwitcher";

const styles = {
  root: (theme: any) => ({
    padding: "16px",
    color: "white",
    backgroundColor: theme.palette.background.default,
    height: "100%",
    boxSizing: "border-box",
    overflow: "auto",
  }),
  header: (theme: any) => ({
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    display: "grid",
    justifyItems: "center",
    marginBottom: theme.spacing(6),
    fontSize: "22px",
  }),
  hintPageBoxOne: (theme: any) => ({
    marginTop: theme.spacing(4),
    display: "grid",
    justifyItems: "center",
    // marginBottom: theme.spacing(4),
  }),
  hintPageBoxTwo: {
    display: "grid",
    justifyItems: "center",
  },
  logoutBox: {
    display: "grid",
    justifyItems: "center",
  },
  link: {
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "none",
  },
  logo: (theme: any) => ({
    width: "200px",
    [theme.breakpoints.up("lg")]: {
      width: "300px",
    },
    margin: "0 auto",
  }),
};

const HintsPage = () => {
  const { i18n, t } = useTranslation();
  const tenantConfig = useSelector(
    (state: RootState) => state.tenantConfig.config
  );
  const theme = useTheme();
  const hintPages: Record<string, any> =
    useSelector((state: RootState) => state.hints.pages) || {};

  const excludes = ["ReceiverFaqsPage"];
  const filterHintPages = Object.keys(hintPages || {}).reduce(
    (acc: Record<string, any>, curr: string) => {
      if (!excludes.includes(curr)) {
        acc[curr] = hintPages[curr];
      }
      return acc;
    },
    {}
  );

  // Render links to hint pages
  const renderHintLinks = (pages: { [key: string]: any }) => {
    return Object.keys(pages).map((key) =>
      pages[key].type === "link" ? (
        <a
          key={key}
          style={{ marginBottom: "11px", textDecoration: "none" }}
          href={(pages[key].content as any)?.[i18n.language]?.content}
          target="_blank"
          rel="noreferrer"
        >
          <Typography sx={styles.link}>
            {(pages[key].content as any)?.[i18n.language]?.title}
          </Typography>
        </a>
      ) : (
        <Link
          key={key}
          style={{
            marginTop: "15px",
            textDecoration: "none",
            fontWeight: "bold",
          }}
          component={RouterLink}
          to={routes.makeHintsPath(key)}
        >
          <Typography sx={styles.link}>
            {(pages[key].content as any)?.[i18n.language]?.title}
          </Typography>
        </Link>
      )
    );
  };

  return (
    <div className="min-h-full px-5">
      <Header>
        <TenantLogo />
      </Header>
      <Typography sx={styles.header}>Infos</Typography>

      <Box sx={styles.hintPageBoxOne}>
        {filterHintPages && renderHintLinks(filterHintPages)}
      </Box>
      {tenantConfig?.languages.length! > 1 && <LanguageSwitcher />}
    </div>
  );
};

export default HintsPage;
