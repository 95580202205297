import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import animationData from './animation/openDoorAnimation-Black.json';

const DoorOpenAnimation = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Lottie style={{
                width: 300,
                height: 300,
            }} animationData={animationData} loop={true} />
        </Box>
    );
};

export default DoorOpenAnimation;
