import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";
import { ApartmentDetails } from "../../types/apartments";

interface ApartmentListProps {
  locationId: string;
  fromDate: string;
  toDate: string;
  numberOfGuests: number;
}

interface ApartmentByCityListProps {
  city: string;
  fromDate: string;
  toDate: string;
  numberOfGuests: number;
}

interface ApartmentByCityAndDistrictListProps {
  city: string;
  district: string;
  fromDate: string;
  toDate: string;
  numberOfGuests: number;
}

export const apartmentBookingApiSlice = createApi({
  reducerPath: "apartmentBookingApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["apartmentList", "apartmentByCityList", "apartmentByCityAndDistrictList", "apartmentDetails"],
  endpoints: (builder) => ({
    getApartmentList: builder.query({
      query: (data: ApartmentListProps) => {
        return {
          url: `/apartments/location/${data.locationId}/availabilities/${data.fromDate}/${data.toDate}?guests=${data.numberOfGuests}`,
        };
      },
      providesTags: ["apartmentList"],
    }),
    getApartmentByCityList: builder.query({
      query: (data: ApartmentByCityListProps) => {
        return {
          url: `/apartments/city/${data.city}/availabilities/${data.fromDate}/${data.toDate}?guests=${data.numberOfGuests}`,
        };
      },
      providesTags: ["apartmentByCityList"],
    }),

    getApartmentByCityAndDistrictList: builder.query({
      query: (data: ApartmentByCityAndDistrictListProps) => {
        return {
          url: `/apartments/city/${data.city}/district/${data.district}/availabilities/${data.fromDate}/${data.toDate}?guests=${data.numberOfGuests}`,
        };
      },
      providesTags: ["apartmentByCityAndDistrictList"],
    }),

    getApartmentDetails: builder.query<
      ApartmentDetails,
      { apartmentId: string }
    >({
      query: ({ apartmentId }) => {
        return {
          url: `/apartments/${apartmentId}/details`,
        };
      },
      providesTags: ["apartmentDetails"],
    }),
  }),
});

export const { useGetApartmentListQuery, useGetApartmentByCityListQuery, useGetApartmentByCityAndDistrictListQuery, useGetApartmentDetailsQuery } =
  apartmentBookingApiSlice;
