import React from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";

const SelectStyled = styled(Select)(({ theme, value }) => ({
  backgroundColor: theme.palette.background.default,
  "& .MuiSelect-select:focus": {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  "& .MuiSelect-select:not(:focus)": {
    backgroundColor: theme.palette.background.default,
    width: "100%",
  },
  height: "62px",
  "& .MuiInputBase-input": {
    color: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor:
      value !== "0"
        ? theme.palette.primary.light
        : theme.palette.primary.contrastText,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.light,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.light,
  },
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.contrastText,
  },
}));

const Icon = styled(ScheduleIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1), // Ensure consistent padding for MenuItems
}));

interface BookingTimeSelectProps {
  availableSlots: string[];
  value: string;
  onChange: (value: string) => void;
  availableDurations: number[];
  disabled: boolean;
  label: string;
}

const BookingTimeSelect: React.FC<BookingTimeSelectProps> = React.memo((props: BookingTimeSelectProps) => {
  const { t } = useTranslation();

  const shouldShrinkLabel = props.value !== "0";

  return (
    <FormControl>
      {shouldShrinkLabel && (
        <InputLabel
          sx={{ color: "#333333" }}
          id="demo-select-large-label"
          shrink={shouldShrinkLabel}
        >
          {props.label}
        </InputLabel>
      )}
      <SelectStyled
        labelId="demo-select-large-label"
        id="time-select-helper"
        disabled={props.disabled}
        value={props.value}
        label={shouldShrinkLabel ? props.label : null}
        autoFocus={true}
        onChange={(event) => props.onChange(event.target.value as string)}
        IconComponent={Icon}
      >
        <MenuItemStyled value={"0"}>
          <span>{props.label}</span>
        </MenuItemStyled>
        {props.availableSlots.map((timeSlot) => (
          <MenuItemStyled key={timeSlot} value={timeSlot}>
            {timeSlot} {t("formats.time_postfix")}
          </MenuItemStyled>
        ))}
      </SelectStyled>
    </FormControl>
  );
});

export default BookingTimeSelect;
