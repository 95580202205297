import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import CustomButton from "../../../layout/components/CustomButton";
import { setNextButtonClick } from "../../../../redux/slice/temporaryStorage/temporaryStorageSlice";
import { useState } from "react";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";
import StorageListAccordion from "../../common/components/LocationSelectionStep/StorageListAccordion";
import { Box } from "@mui/material";
import {
  assetGroupActivated,
  assetGroupDeactivated,
  setSelectedGroup,
} from "../../../../redux/slice/bookingCreation/bookingCreationSlice";
import _ from "lodash";

export default function LockerSizeSelection() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { selectedGroup, selectedLocation } = useSelector(
    (state: RootState) => state.bookingCreation
  );

  const storages =
    useSelector((state: RootState) =>
      selectedLocation
        ? state.bookingCreation.groupsByLocation[selectedLocation.id]
        : []
    ) || [];

  const selectedStorage = useSelector(
    (state: RootState) => state.bookingCreation.selectedGroup
  );
  const isLoading = _.isEmpty(storages);

  const handleAccordionChange = (storage: any) => {
    setExpandedAccordion(expandedAccordion === storage.id ? null : storage.id); // Toggle the expanded accordion
    if (expandedAccordion === storage.id) {
      dispatch(assetGroupDeactivated());
    } else {
      dispatch(assetGroupActivated(storage));
      dispatch(setSelectedGroup(storage));
    }
  };

  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    selectedStorage ? selectedStorage.id : null
  ); // Track the currently expanded accordion

  const handleClickNext = () => {
    dispatch(setNextButtonClick());
  };

  return (
    <div className="flex flex-col justify-center">
      <Box sx={{ padding: 1 }}>
        {storages?.map((item: any, index: number) =>
          item.priceCategory ? (
            <StorageListAccordion
              key={item.id}
              assetGroup={item}
              active={Boolean(expandedAccordion === item.id)}
              onToggle={() => handleAccordionChange(item)}
            />
          ) : null
        )}
        <LoaderOverlay open={isLoading} />
      </Box>
      <div
        className="fixed flex w-full max-w-md left-0 right-0 px-5 bottom-4
       items-center justify-center m-auto"
      >
        <CustomButton
          isButtonDark={true}
          fullWidth={true}
          onClick={handleClickNext}
          isOutlined={true}
          disabled={!selectedGroup}
          children={t(
            "temporary_storage.new_booking.location.next_button_title"
          )}
        />
      </div>
    </div>
  );
}
