import React, {useCallback, useEffect, useState} from 'react';
import _debounce from 'lodash/debounce';
import { InputAdornment, TextField, TextFieldProps, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material';


type StyledTextFieldProps = TextFieldProps & {
    valid?: boolean;
};

const StyledTextField = styled(TextField)<StyledTextFieldProps>(({ theme, valid }) => ({
    marginBottom: theme.spacing(2),
    width: '100%',
    zIndex: 0,
    '& .MuiInputBase-input': {
        color: theme.palette.primary.main,
        borderRadius: 0,
        /* Style to override autofill background and text color */
        '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px transparent inset !important',
            boxShadow: '0 0 0 100px transparent inset !important',
            WebkitTextFillColor: theme.palette.primary.main + ' !important',
            transition: 'background-color 5000s ease-in-out 0s !important',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: valid ? theme.palette.primary.light : theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.light,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.light,
        },
    },
    '& .MuiInputBase-root': {
        color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-outlined': {
        color: theme.palette.primary.main,
        opacity: 0.8,
    },
}));

interface DebouncedTextInputProps {
    label?: string;
    initialValue?: string;
    onChange: (value: string) => void;
    valid?: boolean;
    autoComplete?: string;
    required?: boolean;
}

const DebouncedTextInput = (props: DebouncedTextInputProps) => {
    const [value, setValue] = useState<string>('');
    const theme = useTheme();

    const debouncedOnChange = useCallback(
        _debounce(props.onChange, 700, {leading: false, trailing: true}),
        [],
    );

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
        }
    }, []);

    useEffect(() => {
        debouncedOnChange(value);
    }, [value]);

    return (
        <StyledTextField
            value={value}
            onChange={event => setValue(event.target.value)}
            variant={'outlined'}
            label={props.label}
            color={'primary'}
            fullWidth={false}
            size={'medium'}
            required={props.required ?? true}
            autoComplete={props.autoComplete}
            InputLabelProps={{
                style: { color: 'rgba(0, 0, 0, 0.8)' }
            }}
            InputProps={{
                endAdornment: props.valid
                    ? <InputAdornment position="end"><CheckIcon sx={{ color: theme.palette.primary.light }} /></InputAdornment>
                    : null,
            }}
            valid={props.valid}
        />
    );
};

export default DebouncedTextInput;
