import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../layout/components/CustomButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const DialogText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const InfoIcon = styled('div')({
  width: '18px',
  fontWeight: 'bold',
  verticalAlign: 'middle',
});

const CancelButton = styled(CustomButton)(({ theme }) => ({
  width: '109px',
  height: '44px',
  gap: '10px',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.light}`,
  '&:hover': {
    backgroundColor: 'inherit', // Disable background color change on hover
    borderColor: theme.palette.primary.light, // Keep the same border color
    boxShadow: 'none', // Disable any box-shadow on hover
  },
}));

const ConfirmButton = styled(CustomButton)(({ theme }) => ({
  width: '100px',
  height: '44px',
  gap: '10px',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.light, // Disable background color change on hover
    boxShadow: 'none', // Disable any box-shadow on hover
  },
}));

interface PageClosingDialogProps {
  open: boolean;
  content: any;
  onClose: () => void;
  onContinue: () => void;
}

const GeneralDialog = (props: PageClosingDialogProps) => {
  const { t } = useTranslation();

  const onConfirmFinishRental = () => {
    props.onContinue();
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>
        <DialogText variant="h6">
          {t('use_order.closing_dialog.title')}
        </DialogText>
      </DialogTitle>
      <DialogContent>
        <DialogText variant="body2">
          {props.content}
        </DialogText>
      </DialogContent>
      <DialogActions>
        <CancelButton
          isButtonDark={false}
          fullWidth
          variant="outlined"
          onClick={() => props.onClose()}
          color="secondary"
        >
          {t('use_order.stop_using.cancel_button')}
        </CancelButton>
        <ConfirmButton
          isButtonDark={true}
          fullWidth
          variant="contained"
          onClick={onConfirmFinishRental}
          className={"confirmButton"}
          // color="primary"
        >
          {t('use_order.closing_dialog.close')}
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

export default GeneralDialog;
