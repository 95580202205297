import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import LocationSelectionPage from "../modules/locationSelection/LocationSelectionPage";
import OrdersPage from "../modules/orders/OrdersPage";
import routes from "./routes";
import LoaderOverlay from "../modules/layout/components/LoaderOverlay";
import React, { Suspense, useEffect } from "react";
import { BookLocationDialog as EquipmentRentalBookLocationDialog } from "../modules/bookLocation/equipmentRental/BookLocationDialog";
import { BookLocationDialog as P2PBookLocationDialog } from "../modules/bookLocation/p2p/BookLocationDialog";
import HintsPage from "../modules/hints/HintsPage";
import StripePaymentReturnPage from "../modules/payments/StripePaymentReturnPage";
import ConsentPage from "../modules/consent/ConsentPage";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import NoPaymentReturnPage from "../modules/payments/NoPaymentReturnPage";
import HomePage from "../modules/home/homePage";
import ItemRetrievalPage from "../modules/bookLocation/p2p/itemRetrieval/ItemRetrievalPage";
import SenderRetrievalPage from "../modules/bookLocation/p2p/senderItemPlacement/SenderItemPlacementPage";
import { TempStorageBookLocationDialog } from "../modules/bookLocation/temporaryStorage/TempStorageBookLocationDialog";
import { RoomRentalDialog } from "../modules/bookLocation/roomRental/RoomRentalDialog";
import MoreToComeDialog from "../modules/moreToCome/MoreToComeDialog";
import { VenueReservationDialog } from "../modules/bookLocation/venueReservation/venueReservationDialog";

const HintsPageDialog = React.lazy(
  () =>
    import(
      "../modules/hints/components/DialogComponent" /* webpackChunkName: "tips-dialog" */
    )
);
const UseOrderDialog = React.lazy(
  () =>
    import(
      "../modules/useOrder/UseOrderDialog" /* webpackChunkName: "tips-dialog" */
    )
);
const ReportIssueDialog = React.lazy(
  () =>
    import(
      "../modules/reportIssue/ReportIssueDialog" /* webpackChunkName: "tips-dialog" */
    )
);
const RetrieveBookingViaEmailDialog = React.lazy(
  () =>
    import(
      "../modules/bookLocation/equipmentRental/RetrieveBookingViaEmailDialog" /* webpackChunkName: "tips-dialog" */
    )
);

const RedirectToConsent: React.FC = () => {
  const location = useLocation();
  const originalURL = location.pathname + location.search;

  return (
    <Navigate
      to={`${routes.consentGDPRPagePath}?redirect=${encodeURIComponent(
        originalURL
      )}`}
      replace
    />
  );
};

interface PrivacyPageWrapperProps {
  open: boolean;
}

const PrivacyPageWrapper: React.FC<PrivacyPageWrapperProps> = ({ open }) => {
  const { privcayPage } = useParams();

  if (privcayPage) {
    if (privcayPage.toLowerCase().includes("dataprotection")) {
      return <HintsPageDialog open={open} />;
    }
  }
  return <Navigate to={routes.consentGDPRPagePath} />;
};

const PageRoutes = () => {
  const locationEquipmentRentalRouteMatch = useMatch({
    path: routes.bookLocationEquipmentRentalPath,
  });
  const roomRentalRouteMatch = useMatch({
    path: routes.roomRentalPath,
  });
  const venueReservationRouteMatch = useMatch({
    path: routes.venueReservationPath,
  });
  const locationP2PRouteMatch = useMatch({
    path: routes.bookLocationP2PPath,
  });
  const locationTemStorageNewBookingRouteMatch = useMatch({
    path: routes.temStorageNewBooking,
  });
  const showMoreToComeDialog = Boolean(
   useMatch({ path: routes.moreToComePath })
); 

  const orderDetailsMatch = useMatch(routes.orderDetailsPath);
  const retrieveDetailsMatch = Boolean(
    useMatch({ path: routes.retrieveBookingEmailPath })
  );
  // const reportDamageMatch = useMatch(Routes.reportDamagePath);
  // const reportIssueMatch = useMatch(Routes.reportIssuePath);
  // const orderFaqsMatch = useMatch(Routes.orderFaqsPath);
  // const orderRouteMatch = orderDetailsMatch || reportDamageMatch || reportIssueMatch || orderFaqsMatch;
  const orderRouteMatch = orderDetailsMatch;

  const showHintsPageDialog = Boolean(
    useMatch({ path: routes.hintsPagePath, end: true })
  );
  const dataPrivacyGDPRMatch = useMatch({
    path: routes.privacyGDPRPath,
    end: true,
  });
  const showReportIssueDialog = Boolean(
    useMatch({ path: routes.reportIssuePath })
  );

  const showBookLocationP2PDialog = Boolean(
    locationP2PRouteMatch &&
      locationP2PRouteMatch.pathname === routes.bookLocationP2PPath
  );
  const showBookLocationTempStorageDialog = Boolean(
    locationTemStorageNewBookingRouteMatch &&
      locationTemStorageNewBookingRouteMatch.pathname ===
        routes.temStorageNewBooking
  );
  const showBookLocationEquipmentRentalDialog = Boolean(
    locationEquipmentRentalRouteMatch &&
      locationEquipmentRentalRouteMatch.pathname ===
        routes.bookLocationEquipmentRentalPath
  );
  const showRoomRentalDialog = Boolean(
    roomRentalRouteMatch &&
    roomRentalRouteMatch.pathname ===
        routes.roomRentalPath
  );
  const showVenueReservationDialog = Boolean(
    venueReservationRouteMatch &&
    venueReservationRouteMatch.pathname ===
        routes.venueReservationPath
  );
  const showUseOrderDialog = Boolean(orderRouteMatch);
  const showDataPrivacyDialog = Boolean(dataPrivacyGDPRMatch);

  const consentLoaded = useSelector(
    (state: RootState) => state.consent.consentLoaded
  );
  const consent = useSelector((state: RootState) => state.consent.consent);
  const tenantConfig = useSelector(
    (state: RootState) => state.tenantConfig.config
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!consentLoaded) return;
    const originalURL = location.pathname + location.search;
    if (consent === null && tenantConfig.consentRequired) {
      navigate(
        `${routes.consentGDPRPagePath}?redirect=${encodeURIComponent(
          originalURL
        )}`,
        { replace: true }
      );
    }
  }, [consent, consentLoaded]);

  if (!consentLoaded) {
    return <LoaderOverlay open={!consentLoaded} />;
  }

  if (consent === null && tenantConfig.consentRequired === true) {
    return (
      <>
        <Routes>
          <Route path={routes.consentGDPRPagePath} element={<ConsentPage />} />
          <Route
            path={routes.privacyGDPRPath}
            element={<PrivacyPageWrapper open={showDataPrivacyDialog} />}
          />
          <Route path="*" element={<RedirectToConsent />} />
        </Routes>
        <Suspense
          fallback={
            showDataPrivacyDialog ? <LoaderOverlay open={true} /> : null
          }
        >
          <HintsPageDialog open={showDataPrivacyDialog} />
        </Suspense>
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route path={routes.orders} element={<OrdersPage />} />
        <Route path={routes.hints} element={<HintsPage />} />
        <Route path={routes.home} element={<HomePage />} />
        <Route path={routes.locations} element={<LocationSelectionPage />} />
        <Route
          path={routes.bookLocationEquipmentRentalPath}
          element={
            <EquipmentRentalBookLocationDialog
              open={showBookLocationEquipmentRentalDialog}
            />
          }
        />
          <Route
          path={routes.roomRentalPath}
          element={
            <RoomRentalDialog
              open={showRoomRentalDialog}
            />
          }
        />
        <Route
          path={routes.venueReservationPath}
          element={
            <VenueReservationDialog
              open={showVenueReservationDialog}
            />
          }
        />
        <Route
          path={routes.bookLocationP2PPath}
          element={<P2PBookLocationDialog open={showBookLocationP2PDialog} />}
        />
        <Route
          path={routes.temStorageNewBooking}
          element={
            <TempStorageBookLocationDialog
              open={showBookLocationTempStorageDialog}
            />
          }
        />
        <Route
          path={routes.p2PItemRetrievalPath}
          element={<ItemRetrievalPage />}
        />
        <Route
          path={routes.p2PSenderRetrievalPath}
          element={<SenderRetrievalPage />}
        />

        <Route
          path={routes.stripePaymentReturnPath}
          element={<StripePaymentReturnPage />}
        />
        <Route
          path={routes.noPaymentReturnPath}
          element={<NoPaymentReturnPage />}
        />
      </Routes>
      <Suspense
        fallback={
          showBookLocationEquipmentRentalDialog ? (
            <LoaderOverlay open={true} />
          ) : null
        }
      >
        {/* <BookLocationDialog
              open={showBookLocationDialog}
          /> */}
      </Suspense>
      <Suspense
        fallback={showHintsPageDialog ? <LoaderOverlay open={true} /> : null}
      >
        <HintsPageDialog open={showHintsPageDialog} />
      </Suspense>
      <Suspense
        fallback={showUseOrderDialog ? <LoaderOverlay open={true} /> : null}
      >
        <UseOrderDialog open={showUseOrderDialog && !showReportIssueDialog} />
        <ReportIssueDialog open={showReportIssueDialog} />
        <RetrieveBookingViaEmailDialog open={retrieveDetailsMatch} />
        <MoreToComeDialog open={showMoreToComeDialog}/>
      </Suspense>
    </>
  );
};
export default PageRoutes;
