import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

interface KolulaBoxDoorProps {
  top: number;
  left: number;
  orientation: 'left' | 'right';
  doorNumber: number;
  active: boolean;
}

const KolulaBoxDoor = (props: KolulaBoxDoorProps) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          width: '31px',
          height: '30px',
          position: 'absolute',
          left: props.left + (props.orientation === 'right' ? 30 : 0),
          top: props.top + 31,
          backgroundColor: props.active
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
        }}
      />

      <Box
        sx={{
          width: '30px',
          height: '92px',
          position: 'absolute',
          left: props.left + (props.orientation === 'right' ? 0 : 31),
          top: props.top,
          backgroundColor: props.active
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
        }}
      >
        <Typography
          sx={{
            position: 'absolute',
            top: '37%',
            left: '24%',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          {props.doorNumber}
        </Typography>
      </Box>
    </>
  );
};

export default KolulaBoxDoor;
