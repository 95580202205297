import React, { useState } from 'react';
import { Box, Grow, IconButton, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import CP20BoxDoor from './CP20BoxDoor';
import KoluluBoxInfoBox from '../KolulaBoxInfoBox';
import { OrderId } from '../../../../types/order';
import InfoOutlined from '@mui/icons-material/InfoOutlined';


const RootBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'black',
    width: '243px',
    height: '280px',
    margin: '0 auto',
    // boxSizing: 'border-box',
    position: 'relative',
    borderRadius: '8px', // Add border-radius for rounded corners
    border: '1px solid black', // Add a solid border
    overflow: 'hidden', // Add overflow to handle scrollbars or content overflow
    transform: 'scale(135%) translateY(45px)',
}));

const DoorWrapper = styled(Box)({
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    position: 'relative',
});

const RectangleBox = styled(Box)(({ theme }) => ({
    width: '243px',
    height: '40px',
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const InfoButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.dark,
    width: '30px',
    height: '30px',
    position: 'absolute',
    right: 5,
    // top: 0,
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.dark,
    fontSize: '13px',
    fontWeight: 'bold',
    marginLeft: '10px',
}));

interface CP12BoxProps {
    orderId?: OrderId;
    activeDoors: number[];
    title: string;
}

const ShadowOverlay = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9,
}));

  
const CP20Box = (props: CP12BoxProps) => {
    const [infoOpen, setInfoOpen] = useState<boolean>(false);
    const enableInfo = Boolean(props.orderId);


    return (
        <>
            <RootBox>
                <DoorWrapper>
                    <RectangleBox>
                        <TitleTypography>{props.title}</TitleTypography>
                        {enableInfo && (
                            <InfoButton onClick={() => setInfoOpen(true)}>
                                <InfoOutlined fontSize='small' />
                            </InfoButton>
                        )}
                    </RectangleBox>
                    {/* TOP ROW */}
                    <CP20BoxDoor top={42} left={183} height={46} doorNumber={4} active={props.activeDoors.includes(4)} />
                    <CP20BoxDoor top={42} left={122} height={46} doorNumber={3} active={props.activeDoors.includes(3)} />
                    <CP20BoxDoor top={42} left={61} height={19} doorNumber={2} active={props.activeDoors.includes(2)} />
                    <CP20BoxDoor top={42} left={0} height={29} doorNumber={1} active={props.activeDoors.includes(1)} />
                    {/* MIDDLE ROW */}
                    <CP20BoxDoor top={89} left={183} height={46} doorNumber={9} active={props.activeDoors.includes(9)} />
                    <CP20BoxDoor top={89} left={122} height={46} doorNumber={8} active={props.activeDoors.includes(8)} />
                    <CP20BoxDoor top={61.5} left={61} height={19} doorNumber={6} active={props.activeDoors.includes(6)} />
                    <CP20BoxDoor top={81.5} left={61} height={19} doorNumber={7} active={props.activeDoors.includes(7)} />
                    
                    <CP20BoxDoor top={101.5} left={61} height={57.5} doorNumber={12} active={props.activeDoors.includes(12)} />


                    <CP20BoxDoor top={72} left={0} height={29} doorNumber={5} active={props.activeDoors.includes(5)} />
                    <CP20BoxDoor top={101.5} left={0} height={29} doorNumber={10} active={props.activeDoors.includes(10)} />
                    <CP20BoxDoor top={131} left={0} height={28} doorNumber={11} active={props.activeDoors.includes(11)} />

                    
                    {/* BOTTOM ROW */}
                    <CP20BoxDoor top={136} left={183} height={46} doorNumber={14} active={props.activeDoors.includes(14)} />
                    <CP20BoxDoor top={136} left={122} height={46} doorNumber={13} active={props.activeDoors.includes(13)} />
                    <CP20BoxDoor top={160} left={61} height={119.5} doorNumber={16} active={props.activeDoors.includes(16)} />
                    <CP20BoxDoor top={160} left={0} height={119.5} doorNumber={15} active={props.activeDoors.includes(15)} />
                    {/* BOTTOM ROW */}
                    <CP20BoxDoor top={183} left={183} height={46} doorNumber={18} active={props.activeDoors.includes(18)} />
                    <CP20BoxDoor top={183} left={122} height={46} doorNumber={17} active={props.activeDoors.includes(17)} />
                    {/* BOTTOM BOTTOM ROW */}
                    <CP20BoxDoor top={229.5} left={183} height={48.5} doorNumber={20} active={props.activeDoors.includes(20)} />
                    <CP20BoxDoor top={229.5} left={122} height={48.5} doorNumber={19} active={props.activeDoors.includes(19)} />
                </DoorWrapper>
            </RootBox>
            {infoOpen &&
                <ShadowOverlay onClick={() => setInfoOpen(false)}>
                    <KoluluBoxInfoBox onClose={() => setInfoOpen(false)} orderId={props.orderId!}/>
                </ShadowOverlay>
            }
        </>
    );
};

export default CP20Box;
