import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  PersonalData,
  PersonalDataValidation,
  SomeoneElseData,
  SomeoneElseDataValidation,
} from "../../../types/bookingCreation";
import DebouncedPhoneNumberInput from "../common/components/PersonalDataStep/DebouncedPhoneNumberInput";
import DebouncedTextInput from "../common/components/PersonalDataStep/DebouncedTextInput";
import {
  agreementChanged,
  emailChanged,
  firstNameChanged,
  lastNameChanged,
  phoneChanged,
  setForSomeoneElse,
  someoneElseEmailChanged,
  someoneElseFullNameChanged,
  uscCustomerIdChanged,
} from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import { RootState } from "../../../redux/store";
import { TenantConfig as TConfig } from "../../../types/tenantConfig";
import TenantConfig from "../../../util/tenantConfig";
import {
  Typography,
  Box,
  useTheme,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useMediaQuery} from "@mui/material";

const styles = {
  text: (theme: any) => ({
    textAlign: "left",
    width: "100%",
    paddingLeft: "13px",
    fontWeight: 400,
    color: theme.typography.primary,
    fontSize: "16px",
    marginBottom: "10px",
  }),
  termsOfServiceLink: (theme: any) => ({
    color: theme.palette.secondary.contrastText,
    textDecorationColor: theme.palette.secondary.contrastText,
    marginTop: "-6px",
  }),
  question: (theme: any) => ({
    textAlign: "left",
    width: "100%",
    paddingLeft: "13px",
    fontWeight: 600,
    color: theme.typography.primary,
    fontSize: "14px",
    marginBottom: "4px",
  }),
};

const PersonalDataStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const personalData: PersonalData = useSelector(
    (state: RootState) => state.bookingCreation.personalData
  );
  const someoneElseData: SomeoneElseData = useSelector(
    (state: RootState) => state.bookingCreation.someoneElseData
  );
  const personalDataValidation: PersonalDataValidation = useSelector(
    (state: RootState) => state.bookingCreation.personalDataValidation
  );
  const someoneElseDataValidation: SomeoneElseDataValidation = useSelector(
    (state: RootState) => state.bookingCreation.someoneElseDataValidation
  );
  const isForSomeoneElse: boolean = useSelector(
    (state: RootState) => state.bookingCreation.isForSomeoneElse
  );
  const partner = useSelector(
    (state: RootState) => state.bookingCreation.partner
  );
  const isPartnerUsc = partner === "usc";
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const onFirstNameChange = (firstName: string) =>
    dispatch(firstNameChanged({ firstName }));
  const onSomeoneElseFullNameChange = (fullName: string) =>
    dispatch(someoneElseFullNameChanged({ fullName }));
  const onSomeoneElseEmailChange = (email: string) =>
    dispatch(someoneElseEmailChanged({ email }));
  const onLastNameChange = (lastName: string) =>
    dispatch(lastNameChanged({ lastName }));
  const onPhoneChange = (phone: string) => {
    dispatch(phoneChanged({ phone: phone }));
  };
  const onEmailChange = (email: string) => dispatch(emailChanged({ email }));
  const onAgreementChange = (isAgreed: boolean) =>
    dispatch(agreementChanged({ isAgreed }));
  const onUscCustomerIdChange = (uscCustomerId: string) =>
    dispatch(uscCustomerIdChanged({ uscCustomerId }));
  // const tenantConfig = useSelector((state: RootState) => state. Selector.Config.config);

  const [tenantConfig, setTenantConfig] = useState<TConfig>();
  let dataProtectionPath = "/hints";
  useEffect(() => {
    const retrieveTenantConfig = async () => {
      const config = await TenantConfig();
      setTenantConfig(config);
    };
    retrieveTenantConfig();
  }, []);

  const [bookingFor, setBookingFor] = useState("myself");
  const handleBookingFor = (selected: string) => {
    setBookingFor(selected);
    if (selected === 'myself') {
      dispatch(setForSomeoneElse({isForSomeoneElse: false}));
    } else {
      dispatch(setForSomeoneElse({isForSomeoneElse: true}));
    }
  }

  return (
    <Box
    className="w-full max-w-screen-sm p-3"
    display="flex"
    flexDirection="column"
    alignItems="center"
    sx={{
      margin: isLargeScreen ? "0 auto" : "initial",
    }}
    >
      
      <DebouncedTextInput
        label={t("book_location.personal_data.first_name")}
        initialValue={personalData.firstName}
        onChange={onFirstNameChange}
        valid={personalDataValidation.firstName}
        autoComplete={"given-name"}
      />
      <DebouncedTextInput
        label={t("book_location.personal_data.last_name")}
        initialValue={personalData.lastName}
        onChange={onLastNameChange}
        valid={personalDataValidation.lastName}
        autoComplete={"family-name"}
      />
      <DebouncedPhoneNumberInput
        initialValue={personalData.phone}
        onChange={onPhoneChange}
        valid={personalDataValidation.phone}
      />
      <DebouncedTextInput
        label={t("book_location.personal_data.email")}
        initialValue={personalData.email}
        onChange={onEmailChange}
        valid={personalDataValidation.email}
        autoComplete={"email"}
      />
      <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
        <Typography sx={styles.question}>{t("book_location.personal_data.booking_for")}</Typography>
        <RadioGroup
          row
          value={bookingFor}
          onChange={(e) => handleBookingFor(e.target.value)}
          sx={{ paddingLeft: '13px', marginBottom: "14px" }}
        >
          <FormControlLabel 
            value="myself" 
            control={
              <Radio 
                sx={{
                  '&.Mui-checked': {
                    color: '#26DDE6' // Cyan/turquoise color
                  }
                }}
              />
            } 
            label={t("book_location.personal_data.myself")} 
          />
          <FormControlLabel 
            value="someone_else" 
            control={
              <Radio 
                sx={{
                  '&.Mui-checked': {
                    color: '#26DDE6' // Cyan/turquoise color
                  }
                }}
              />
            } 
            label={t("book_location.personal_data.someone_else")} 
          />
        </RadioGroup>

        {bookingFor === "someone_else" && (
          <>
            <DebouncedTextInput
              label={t("book_location.personal_data.full_name")}
              initialValue={someoneElseData.fullName}
              onChange={onSomeoneElseFullNameChange}
              valid={someoneElseDataValidation.fullName}
              autoComplete={"given-name"}
            />
            <DebouncedTextInput
              label={t("book_location.personal_data.email")}
              initialValue={someoneElseData.email}
              onChange={onSomeoneElseEmailChange}
              valid={someoneElseDataValidation.email}
              autoComplete={"email"}
            />
          </>
        )}
      </FormControl>
      {isPartnerUsc && (
        <DebouncedTextInput
          label={t("book_location.personal_data.usc_customer_id")}
          initialValue={personalData.uscCustomerId}
          onChange={onUscCustomerIdChange}
          valid={personalDataValidation.uscCustomerId}
        />
      )}

      <Typography sx={styles.text} >
        <Trans
          i18nKey={"book_location.personal_data.terms_and_conditions"}
          components={{
            termsAndConditionLink: (
              <Link
                component={RouterLink}
                to={dataProtectionPath}
                underline="always"
                sx={styles.termsOfServiceLink}
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );
};

export default PersonalDataStep;
