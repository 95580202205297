import React, { useEffect, useState } from "react";
import moment from "moment";
import BookingTicket from "./components/ConfirmationStep/BookingTicket";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import LoaderOverlay from "../../layout/components/LoaderOverlay";
import { Trans, useTranslation } from "react-i18next";
import { bookingCreationCompleted } from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import { Box, Button, styled, Typography } from "@mui/material";
import CouponDialog from "../common/components/ConfirmationStep/CouponDialog";

interface ConfirmationStepProps {
  onAddAnotherEquipment: () => void;
}

const StyledCouponWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(1),
}));

function ConfirmationStep({ onAddAnotherEquipment }: ConfirmationStepProps) {
  const { t } = useTranslation();
  const reservations = useSelector(
    (state: RootState) => state.bookingCreation.reservations
  )!;
  const location = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  )!;
  const isLoading = !(location && reservations.length > 0);

  return (
    <>
      {isLoading ? (
        <LoaderOverlay open={isLoading} />
      ) : (
        <div className="flex flex-col h-full">
          <div className="flex-grow overflow-y-scroll no-scrollbar">
            <BookingTicket
              location={location.name}
              reservations={reservations}
              amount={1}
            />
            <div
              className="bg-[#EDF0F5] text-gray-800 p-2 rounded-lg mx-auto"
              style={{
                maxWidth: "350px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Typography>
                <Trans
                  i18nKey={"book_location.confirmation.note"}
                  components={{
                    bold: (
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      ></span>
                    ),
                  }}
                />
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ConfirmationStep;
