import React from 'react';
import {useTranslation} from 'react-i18next';
import {persistLanguage} from '../../../i18n/i18n';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Box, IconButton } from '@mui/material';
import { Assets } from '../../../assets/assets';

const styles = {
    root: (theme: any) => ({
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
    }),
    inactiveButton: {
        opacity: 0.5,
    },
    flagImage: {
        height: '30px',
        borderRadius: '15px',
        width: '30px',
        objectFit: 'cover' as const,
    },
};

const languageOptions = [
    {
        language: 'de',
        flag: Assets.flags.germany,
        alt: 'Deutsch',
    },
    {
        language: 'en',
        flag: Assets.flags.unitedKingdom,
        alt: 'English',
    },
    {
        language: 'nb',
        flag: Assets.flags.norway,
        alt: 'norsk',
    },
];

const LanguageSwitcher = () => {
    const {i18n} = useTranslation();
    const tenantConfig = useSelector((state: RootState) => state.tenantConfig.config);

    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
        persistLanguage(language);
        moment.locale(language);
    };

    return (
        <Box sx={styles.root}>
            {languageOptions
                .filter(option => tenantConfig?.languages.includes(option.language))
                .map(option => (
                    <IconButton
                        key={option.language}
                        sx={i18n.language !== option.language ? styles.inactiveButton : undefined}
                        onClick={() => setLanguage(option.language)}
                        children={<img src={option.flag} style={styles.flagImage} alt={option.alt} />}
                    />
                ))
            }
        </Box>
    );
};

export default LanguageSwitcher;
