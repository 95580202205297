import { TenantAssets } from "../assets/assets";
import { TenantConfig as TConfig } from "../types/tenantConfig";

export const TenantConfig = async (): Promise<TConfig> => {
  const tenantName = process.env.REACT_APP_TENANT;
  try {
    const { Config } = await import(`../tenants/${tenantName}/config`);
    return Config;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Tenant config not found for ${tenantName}`);
    const { DefaultConfig } = await import("./defaultConfig");
    return DefaultConfig;
  }
};

export const getLogo = async () => {
  const tenantName = process.env.REACT_APP_TENANT;
  try {
    const { Assets }: { Assets: TenantAssets} = await import(`../tenants/${tenantName}/assets/assets`);
    return Assets.logo;
  } catch (error) {
    const { Assets } = await import("../assets/assets");
    return Assets.logo.kolula;
  }
};

export const loadTheme = async () => {
  const tenantName = process.env.REACT_APP_TENANT;
  try {
    const { theme } = await import(`../tenants/${tenantName}/layout/theme`);
    await import(`../tenants/${tenantName}/layout/theme.css`);
    return theme;
  } catch (error) {
    const { theme } = await import("../material-ui/theme"); // Fallback theme
    return theme;
  }
};
export const setFavicon = async () => {
  const tenantName = process.env.REACT_APP_TENANT;
  try {
    const favicon = await import(`../tenants/${tenantName}/assets/favicon/${tenantName}.webp`);

    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    link.href = favicon.default;
  } catch (error) {
    console.error("Error loading tenant favicon:", error);
  }
};
export const setSeoTags = async () => {
  const tenantName = process.env.REACT_APP_TENANT;
  try {
    const { Config: config } = await import(`../tenants/${tenantName}/config`);
    document.title = config.seo.appTitle;
    setMetaTag("description", config.seo.appDescription);

    //Set Open Graph tags for SEO
    setOgTag("title", config.seo.ogTitle);
    setOgTag("description", config.seo.ogDescription);
    setOgTag("url", config.seo.ogUrl);
    setOgTag("image", config.seo.ogImage);
  } catch (e) {
    const { DefaultConfig: defaultconfig } = await import(`./defaultConfig`);
    document.title = defaultconfig.seo.appTitle;
    setMetaTag("description", defaultconfig.seo.appDescription);

    //Set Open Graph tags for SEO
    setOgTag("title", defaultconfig.seo.ogTitle);
    setOgTag("description", defaultconfig.seo.ogDescription);
    setOgTag("url", defaultconfig.seo.ogUrl);
    setOgTag("image", defaultconfig.seo.ogImage);
    console.error("Error loading tenant seo config:", e);
  }
};
const setMetaTag = (name: string, content: string) => {
  let metaTag = document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement;
  if (metaTag) {
    metaTag.setAttribute("content", content);
  } else {
    metaTag = document.createElement("meta") as HTMLMetaElement;
    metaTag.name = name;
    metaTag.content = content;
    document.head.appendChild(metaTag);
  }
};

const setOgTag = (property: string, content: string) => {
  let ogTag = document.querySelector(`meta[property="og:${property}"]`) as HTMLMetaElement;
  if (ogTag) {
    ogTag.setAttribute("content", content);
  } else {
    ogTag = document.createElement("meta") as HTMLMetaElement;
    ogTag.setAttribute("property", `og:${property}`);
    ogTag.content = content;
    document.head.appendChild(ogTag);
  }
};

export default TenantConfig;
