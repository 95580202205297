import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import routes from "../../../routes/routes";
import { TenantConfig } from "../../../types/tenantConfig";
import DefaultConfig from "../../../util/defaultConfig";
import { TenantConfig as TConfig } from "../../../types/tenantConfig";
import { RootState } from "../../store";

// Define the interface for your config state
export interface ConfigState {
  config: TenantConfig;
}

// Initial state
const initialState: ConfigState = {
  config: DefaultConfig,
};

export const loadTenantConfig = createAsyncThunk(
  "config/loadTenantConfig",
  async () => {
    const tenantName = process.env.REACT_APP_TENANT;
    try {
      const { Config } = await import(`../../../tenants/${tenantName}/config`);
      return Config as TenantConfig;
    } catch (error) {
      console.warn(
        `Falling back to default config as tenant config for "${tenantName}" is not found.`
      );
      return DefaultConfig; // Fallback to default config if tenant config is not found
    }
  }
);

// Create the config slice
const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {
    setTenantConfiguration: (state, action: PayloadAction<TConfig>) => {
      state.config = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadTenantConfig.fulfilled,
      (state, action: PayloadAction<TenantConfig>) => {
        state.config = action.payload;
      }
    );
  },
});

// Selectors
export const selectConfig = (state: RootState) => state.tenantConfig.config;
export const selectToolbarItems = (state: RootState) =>
  state.tenantConfig.config.toolbarItems;
// Export the actions

export const { setTenantConfiguration } = configSlice.actions;

// Export the reducer
export default configSlice.reducer;
