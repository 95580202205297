import { Box, Collapse, Typography, useTheme } from '@mui/material';
import React from 'react';
import { AssetGroup } from '../../../../../types/assetGroup';
import { Assets } from '../../../../../assets/assets';
import {getEquipmentName} from "../../../../../util";
// import Assets from '../../../../../assets/assets';

interface AssetGroupSelectionItemProps {
    assetGroup: AssetGroup;
    active: boolean;
    onToggle: (id: string) => void;
}

const StorageListAccordion = (props: AssetGroupSelectionItemProps) => {
    const InfoIconWhiteFilled = Assets.icon.infoIconWhiteFilled.component;
    const theme = useTheme()

    const handleToggle = (event: React.MouseEvent) => {
        event.stopPropagation();
        props.onToggle(props.assetGroup.id);
    };

    return (
        <Box sx={{ marginBottom: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        width: 'calc(100% - 42px)',
                        border: `1px solid ${theme.palette.primary.main}`,
                        paddingTop: 1,
                        paddingBottom: 1,
                        borderRadius: 1,
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        color: 'black',
                        backgroundColor: props.active ? theme.palette.primary.light : 'transparent',
                    })}
                    onClick={handleToggle}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            display: 'inline',
                            color: props.active ? theme.palette.secondary.light: 'inherit',
                        }}
                    >
                        {getEquipmentName(props.assetGroup.name, props.assetGroup?.langNames, "title")}
                    </Typography>
                </Box>
            </Box>
            <Collapse in={props.active} sx={{ width: 'calc(100% - 42px)', paddingLeft: 3 }}>
                <Box sx={{ display: 'flex', paddingTop: 1, }}>
                    <Box sx={{ marginRight: '5px', color: '#1E1E1E' }}>
                        <InfoIconWhiteFilled
                            style={{
                                width: '20px',
                                height: '20px',
                                // color: '#1E1E1E'
                            }}
                        />
                    </Box>
                    <Typography variant="body2" fontWeight="bold">
                        {getEquipmentName(props.assetGroup.description, props.assetGroup?.langNames, "description")}
                    </Typography>
                </Box>
            </Collapse>
        </Box>
    );
};

export default StorageListAccordion;

