import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import StorageListAccordion from '../common/components/LocationSelectionStep/StorageListAccordion';
import { assetGroupActivated, assetGroupDeactivated, setSelectedGroup } from '../../../redux/slice/bookingCreation/bookingCreationSlice';
import _ from "lodash";
import LoaderOverlay from '../../layout/components/LoaderOverlay';


function StorageSelectionStep() {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  );

    const storages = useSelector((state: RootState) =>
      selectedLocation ? state.bookingCreation.groupsByLocation[selectedLocation.id] : []
    ) || [];

  const selectedStorage = useSelector(
    (state: RootState) => state.bookingCreation.selectedGroup
  );
  const isLoading = _.isEmpty(storages);

  const handleAccordionChange = (storage: any) => {
    setExpandedAccordion(
      expandedAccordion === storage.id ? null : storage.id
    ); // Toggle the expanded accordion
    if (expandedAccordion === storage.id) {
      dispatch(assetGroupDeactivated());
    } else {
      dispatch(assetGroupActivated(storage));
      dispatch(setSelectedGroup(storage));
    }
  };

  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    selectedStorage ? selectedStorage.id : null
  ); // Track the currently expanded accordion

  return (
    <Box sx={{ padding: 1 }}>
      {storages?.map((item: any, index: number) =>
        item.priceCategory ? (
          <StorageListAccordion
            key={item.id}
            assetGroup={item}
            active={Boolean(expandedAccordion === item.id)}
            onToggle={() => handleAccordionChange(item)}
          />
        ) : null
      )}
      <LoaderOverlay open={isLoading} />
    </Box>
  )
}

export default StorageSelectionStep
