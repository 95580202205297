import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ItemRetrievalStartedPayload {
  locationId: string;
}

export interface SenderDetails {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}
export interface PickupDetails {
  senderDetails: SenderDetails;
  pickupLocation: string;
  googleLink: string;
  reservedLocker: number;
  pickupDateTime: string;
}

export interface ItemRetrievalStepChangedPayload {
  step: number;
  nextEnabled: boolean;
}

export interface ItemRetrievalFlowPayload {
  senderRetrievalFlow: string[];
}

interface ContactData {
  sub: string;
  firstname: string;
  telephone: string;
  email: string;
  lastname: string;
}

interface RecipientData {
  firstname: string;
  telephone: string;
  email: string;
  lastname: string;
}

interface Item {
  fromDate: string;
  amount: number;
  isFullDay: boolean;
  price: number;
  groupId: string;
  toDate: string;
  id: string;
  rental?: {
    id: string;
    state: string;
    orderId: string;
    orderItemId: string;
    doors: Array<{
      depotId: string;
      door: number;
      assetId: string;
    }>;
    expirationDate: string;
    token: string;
    startedAtDate: string;
    isActive: boolean;
  };
}

export interface PickupDetailsResponseDataPropType {
  id: string;
  contactData: ContactData;
  createdDate: string;
  reservationId: string;
  createdBy: string;
  locationId: string;
  service: string;
  recipientData: RecipientData;
  items: Item[];
  bookingNumber: string;
  iat: number;
  exp: number;
  location: string;
  googleLink: string;
}

export interface senderRetrievalState {
  locationId: string | null;
  pickupDetailsResponseData: PickupDetailsResponseDataPropType | null;
  step: number;
  nextEnabled: boolean;
  isAgreed: boolean;
  pickupDetails: PickupDetails;
  senderRetrievalFlow: string[];
  receivedEmail: string;
  isReceiverEmailValid: boolean;
  receiverEmailValidationMessage: string;
}

const initialState: senderRetrievalState = {
  locationId: null,
  step: 0,
  nextEnabled: false,
  senderRetrievalFlow: [],
  isAgreed: false,
  pickupDetails: {
    senderDetails: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    pickupLocation: "",
    googleLink: "",
    reservedLocker: 0,
    pickupDateTime: "",
  },
  receivedEmail: "",
  isReceiverEmailValid: false,
  receiverEmailValidationMessage: "",
  pickupDetailsResponseData: null,
};

const senderRetrievalSlice = createSlice({
  name: "senderRetrieval",
  initialState,
  reducers: {
    senderRetrievalFlowChanged: (
      state,
      action: PayloadAction<ItemRetrievalFlowPayload>
    ) => {
      state.senderRetrievalFlow = [...action.payload.senderRetrievalFlow];
    },
    setLockerDetailsResponseData: (
      state,
      action: PayloadAction<PickupDetailsResponseDataPropType>
    ) => {
      const itemData = action.payload;
      const pickupData = {
        senderDetails: {
          firstName: itemData.contactData.firstname ?? "",
          lastName: itemData.contactData.lastname ?? "",
          phone: itemData.contactData.telephone ?? "",
          email: itemData.contactData.email ?? "",
        },
        pickupLocation: itemData.location ?? "",
        googleLink: itemData.googleLink ?? "",
        reservedLocker: itemData.items?.[0].rental?.doors?.[0].door ?? 0,
        pickupDateTime: itemData.items?.[0].toDate ?? "",
      };
      state.pickupDetails = pickupData;
      state.pickupDetailsResponseData = action.payload;
    },
    setHasAgreedToConcent: (state) => {
      state.isAgreed = true;
    },

    navigatedToPreviousStep: (state) => {
      state.step -= 1;
    },

    setNextButtonClick: (state, action: PayloadAction<number>) => {
      state.step += 1;
    },
    setSuccessMessageNextButtonClick: (state) => {
      state.step = 0;
    },
    setUpdateStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setLockerDetails: (state, action: PayloadAction<PickupDetails>) => {
      state.pickupDetails = action.payload;
    },
    retrievalItemStarted: (
      state,
      action: PayloadAction<ItemRetrievalStartedPayload>
    ) => {
      state.locationId = action.payload.locationId;
    },
    senderRetrievalStepChanged: (
      state,
      action: PayloadAction<ItemRetrievalStepChangedPayload>
    ) => {
      state.step = action.payload.step;
      state.nextEnabled = action.payload.nextEnabled;
    },
    setReceivedEmail: (state, action: PayloadAction<string>) => {
      state.receivedEmail = action.payload.trim();
      const receiverEmail =
        state.pickupDetailsResponseData?.recipientData.email;
      if (
        (state.isReceiverEmailValid = state.receivedEmail === receiverEmail)
      ) {
        state.nextEnabled = true;
        state.receiverEmailValidationMessage = "Email validated successfully";
      } else {
        state.nextEnabled = false;
        state.receiverEmailValidationMessage = "Email validation failed";
      }
    },
  },
});

export const {
  retrievalItemStarted,
  senderRetrievalStepChanged,
  setHasAgreedToConcent,
  setLockerDetails,
  senderRetrievalFlowChanged,
  navigatedToPreviousStep,
  setNextButtonClick,
  setSuccessMessageNextButtonClick,
  setLockerDetailsResponseData,
  setReceivedEmail,
  setUpdateStep,
} = senderRetrievalSlice.actions;
export default senderRetrievalSlice.reducer;
