import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import { RootState } from "../../../../redux/store";
import { navigatedToNextStep } from "../../../../redux/slice/bookingCreation/bookingCreationSlice";
import { Box, styled, useTheme } from "@mui/material";
// import { palette } from "../../../../tenants/kolula/layout/palette";
import { calculateDisplayPriceForReservations } from "../../../../util/payments";

const NextButton = styled(CustomButton)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  border: "none",
  "&:hover": {
    backgroundColor: theme.palette.secondary.contrastText,
    // color: "#000000",
    border: "none",
  },
  "&:focus": {
    outline: "none",
    backgroundColor: theme.palette.primary.light,
    // color: "#000000",
  },
  textTransform: "none",
}));

interface BookLocationNavigateNextActionProps {}

const BookLocationNavigateNextAction: React.FC<
  BookLocationNavigateNextActionProps
> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme(); 
  const nextEnabled = useSelector(
    (state: RootState) => state.bookingCreation.nextEnabled
  );
  const step = useSelector((state: RootState) => state.bookingCreation.step);
  const useCase = useSelector(
    (state: RootState) => state.bookingCreation.useCase
  );

  const isZeroAmountBooking = useSelector(
    (state: RootState) =>
      calculateDisplayPriceForReservations(
        state.bookingCreation.reservations
      ) === 0
  );

  let showBookButton;
  let showProceedToPaymentButton;

  if (useCase === "rental" || useCase === "p2p") {
    const stepMapping = {
      rental: { bookStep: 3, paymentStep: 4 },
      p2p: { bookStep: 4, paymentStep: 5 },
    };

    // @ts-ignore
    const isBookStep = step === stepMapping[useCase]?.bookStep;
    // @ts-ignore
    const isPaymentStep = step === stepMapping[useCase]?.paymentStep;

    showBookButton = isBookStep || (isPaymentStep && isZeroAmountBooking);
    showProceedToPaymentButton = isPaymentStep && !isZeroAmountBooking;
  }

  const handleNextClick = () => {
    dispatch(navigatedToNextStep());
  };

  return (
    <Box sx={{ marginBottom: "-40px" }}>
      <NextButton
        key={`${nextEnabled}`} // Fixed key (removed unnecessary negation)
        variant={nextEnabled ? "outlined" : "contained"}
        disabled={!nextEnabled}
        onClick={handleNextClick}
        isButtonDark={true}
      >
        {t(
          !showBookButton && !showProceedToPaymentButton
            ? "book_location.next"
            : showBookButton
            ? "book_location.confirmation.order_now"
            : "book_location.confirmation.proceed_to_payment"
        )}
      </NextButton>
    </Box>
  );
};

export default BookLocationNavigateNextAction;
