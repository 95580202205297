import React, { useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import PhoneInput, { PhoneInputProps, CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/system";
import { hexToRgba } from "../../../../../util/utilFunctions";
import { RootState } from "../../../../../redux/store";

const Root = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
}));

type PhoneInputStyledProps = PhoneInputProps & {
  valid?: boolean;
};

const PhoneInputStyled = styled(PhoneInput)<PhoneInputStyledProps>(
  ({ theme, valid }) => ({
    marginBottom: theme.spacing(2),
    height: "3.5rem",
    width: "100%",
    "&.react-tel-input .form-control": {
      height: "3.5rem",
      width: "100%",
      background: "none",
      color: theme.palette.primary.main,
      paddingTop: "12px",
      paddingBottom: "8px",
      borderColor: valid
        ? theme.palette.primary.light
        : theme.palette.primary.main,
      borderRadius: "10px",
      [theme.breakpoints.up("md")]: {
        fontSize: "19px",
      },
      "&:hover": {
        borderColor: theme.palette.primary.light,
      },
      "&:focus": {
        borderColor: theme.palette.primary.light,
        boxShadow: `0 0 0 1px ${theme.palette.primary.light}`,
      },
    },
    "&.react-tel-input .special-label": {
      background: theme.palette.background.default,
      color: hexToRgba(theme.palette.primary.main, 0.8),
      left: "10px",
      fontSize: "12px",
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
      },
    },
    "&.react-tel-input .selected-flag .arrow": {
      borderTopColor: theme.palette.primary.main,
      borderLeftWidth: "4px",
      borderRightWidth: "4px",
      marginTop: 0,
    },
    "&.react-tel-input .selected-flag:focus .arrow": {
      borderTopColor: theme.palette.primary.main,
    },
    "&.react-tel-input .selected-flag .arrow.up": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&.react-tel-input .country-list": {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      "& .country .dial-code": {
        color: theme.palette.primary.main,
      },
      "& .country.highlight, & .country:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
  })
);

const CheckIconStyled = styled(CheckIcon)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  top: "50%",
  transform: "translateY(-50%)",
  color: theme.palette.primary.light,
  pointerEvents: "none",
}));

const PhoneInputWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  marginBottom: theme.spacing(2),
  height: "3.5rem",
  width: "100%",
  position: "relative",
}));

interface DebouncedTextInputProps {
  label?: string;
  initialValue?: string;
  onChange: (value: string) => void;
  valid?: boolean;
  autoComplete?: string;
  required?: boolean;
}

const DebouncedTextInput = (props: DebouncedTextInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>("");
  const tenantConfig = useSelector(
    (state: RootState) => state.tenantConfig.config
  );

  const normalizeValue = () => {
    if (value.startsWith("490")) {
      setValue("49" + value.slice(3));
    }
  };

  const debouncedOnChange = useCallback(
    _debounce(props.onChange, 700, { leading: false, trailing: true }),
    []
  );

  useEffect(() => {
    if (props.initialValue) {
      setValue(props.initialValue);
    }
  }, [props.initialValue]);

  const handleChange = (newValue: string, countryData: CountryData) => {
    if (newValue.length >= countryData.dialCode.length) {
      setValue(newValue);
      debouncedOnChange(newValue);
    } else {
      setValue(countryData.dialCode);
      debouncedOnChange(countryData.dialCode);
    }
  };
  

  return (
    <Root className="relative">
      <PhoneInputWrapper>
        <PhoneInputStyled
          country={tenantConfig?.phoneInput.defaultCountry}
          preferredCountries={tenantConfig?.phoneInput.preferredCountries}
          value={value}
          onChange={handleChange}
          onBlur={normalizeValue}
          specialLabel={
            props.required === false
              ? t("book_location.personal_data.phone_number")
              : t("book_location.personal_data.phone_number") + " *"
          }
          countryCodeEditable={value === ""}
          valid={props.valid}
        />
        {props.valid && <CheckIconStyled />}
      </PhoneInputWrapper>
    </Root>
  );
};

export default DebouncedTextInput;
