import React, { useEffect, useState } from "react";
import StripePayment from "../common/components/PaymentStep/StripePayment";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../../routes/routes";
import { useNavigate } from "react-router-dom";
import {
  bookingCreationCompleted,
  initializePaymentProvider,
  paymentInitializationRetryStarted,
} from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import { RootState } from "../../../redux/store";
import InitializationErrorInfo from "../common/components/PaymentStep/InitializationErrorInfo";
import LoaderOverlay from "../../layout/components/LoaderOverlay";
import { Reservation } from "../../../types/bookingCreation";
import NoPayment from "../common/components/PaymentStep/NoPayment";
import { calculateDisplayPrice, calculateTotalPrice, findCoupons } from '../../../util/payments';

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentProvider = useSelector(
    (state: RootState) => state.bookingCreation.payment.provider
  );
  const paymentInitFailed = useSelector(
    (state: RootState) => state.bookingCreation.payment.paymentInitFailed
  );
  const stripePayment = useSelector(
    (state: RootState) => state.bookingCreation.payment.stripe
  );
  const orderIds = useSelector(
    (state: RootState) => state.bookingCreation.payment.orderIds
  );
  const reservations = useSelector(
    (state: RootState) => state.bookingCreation.reservations
  )!;
  const [isLoading, setIsLoading] = useState(true);

  const totalPrice = calculateTotalPrice(reservations);

  const chargePrice = calculateDisplayPrice(totalPrice, findCoupons(reservations));

  useEffect(() => {
    if (orderIds?.length) {
      navigate(routes.orders);
      setTimeout(() => dispatch(bookingCreationCompleted()), 1000);
    }
  }, [orderIds]);

  useEffect(() => {
    console.log("totalPrice", totalPrice);
    console.log("chargePrice", chargePrice);
    if (chargePrice === 0) {
      console.log("Run No Payment Flow")
      dispatch(initializePaymentProvider("no_payment"));
    } else {
      dispatch(initializePaymentProvider("stripe"));
    }

    setIsLoading(false);
  }, [totalPrice, dispatch, reservations, paymentProvider]);

  if (isLoading || !paymentProvider) {
    return <LoaderOverlay open={true} />;
  }

  if (paymentProvider !== "no_payment" && paymentInitFailed) {
    const showTryAgain = Boolean(
      paymentProvider === "stripe" &&
      stripePayment.token &&
      stripePayment?.checkoutFrontendUrl
    );

    return (
      <InitializationErrorInfo
        showTryAgain={showTryAgain}
        onBackClick={() => navigate(routes.home)}
        onTryAgainClick={() => dispatch(paymentInitializationRetryStarted({}))}
      />
    );
  }

  if (isLoading || !paymentProvider) {
    return <LoaderOverlay open={true} />;
  } else if (paymentProvider === "stripe") {
    return <StripePayment service={"apartment"} />;
  } else if (paymentProvider === "no_payment") {
    return <NoPayment service={"storage"} />;
  }

  return <LoaderOverlay open={true} />;
};

export default Payment;
