import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PrivacyShieldIcon from "../../components/icons/PrivacyShieldIcon";
import { useStoreConsent } from "../../redux/flows/storeConsentFlow";
import { RootState } from "../../redux/store";
import routes from "../../routes/routes";
import CustomButton from "../layout/components/CustomButton";
import TenantLogo from "../layout/components/TenantLogo";
import ConsentDialog from "./components/ConsentDialog";
import Header from "../layout/components/Header";

function getKeyWithDataProtectionInLowercase(
  obj: Record<string, any>
): string | undefined {
  for (const key in obj) {
    if (key.toLowerCase().includes("dataprotection")) {
      return key;
    }
  }
  return undefined;
}

const ConsentPage = () => {
  const { t } = useTranslation();
  const [consentDialogOpen, setConsentDialogOpen] = useState<boolean>(false);
  const hintPages = useSelector((state: RootState) => state.hints.pages);
  const storeConsent = useStoreConsent(true);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const redirectURL = queryParams.get("redirect") || "/";

  let dataProtectionPath = "/hints";
  if (hintPages) {
    const path = getKeyWithDataProtectionInLowercase(hintPages);
    if (path) {
      console.log(path);
      dataProtectionPath = routes.makePrivacyGDPRPath(path);
    }
  }

  const onAcceptConsent = () => {
    storeConsent();
    navigate(redirectURL);
  };

  return (
    <div className="flex flex-col items-center px-5">
      <Header>
        <TenantLogo />
      </Header>
      <h1 className="mt-2 text-2xl font-semibold -tracking-[0.0225rem]">
        {t("consent.title")}
      </h1>
      <div className="rounded-full bg-primary/15 p-4 mt-5 mb-5">
        <PrivacyShieldIcon />
      </div>
      <p className="text-center text-lg leading-7 mb-12">
        <Trans
          i18nKey="consent.description"
          components={{
            a: (
              <RouterLink
                className="text-primary underline underline-offset-2 brightness-75"
                to={dataProtectionPath}
              ></RouterLink>
            ),
          }}
        />
      </p>

      <CustomButton
        isButtonDark={true}
        fullWidth={true}
        onClick={onAcceptConsent}
        color="secondary"
        variant="outlined"
        isOutlined={true}
        children={t("consent.allow")}
      />
      <CustomButton
        isButtonDark={false}
        fullWidth={true}
        onClick={() => setConsentDialogOpen(true)}
        color={"secondary"}
        isOutlined={true}
        children={t("consent.decline")}
      />
      <ConsentDialog
        open={consentDialogOpen}
        onClose={() => setConsentDialogOpen(false)}
      />
    </div>
  );
};

export default ConsentPage;
