import { useTranslation } from 'react-i18next';
import { Assets } from '../../../assets/assets';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import CustomButton from '../../layout/components/CustomButton';

const styles = {
    dialogText: (theme: any) => ({
        color: theme.palette.primary.main,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 1000,
        fontFamily: theme.typography.fontFamily,
        fontStyle: theme.typography.button.fontStyle,
        paddingBottom: '12px',
    }),
    dialogInfo: (theme: any) => ({
        color: theme.palette.primary.main,
        fontSize: theme.typography.body1.fontSize,
    }),
    infoIcon: {
        width: '18px',
        fontWeight: 'bold',
        verticalAlign: 'middle',
    },
    cancelButton: (theme: any) => ({
        width: '80px',
        height: '44px',
        gap: '10px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.light}`,
        '&:hover': {
            backgroundColor: 'inherit', // Disable background color change on hover
            borderColor: theme.palette.primary.light, // Keep the same border color
            boxShadow: 'none', // Disable any box-shadow on hover
        },
    }),
    confirmButton: (theme: any) => ({
        width: '85px',
        height: '44px',
        gap: '10px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.light, // Disable background color change on hover
            boxShadow: 'none', // Disable any box-shadow on hover
        },
    }),
};

interface StopUsingDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirmPlacement: () => void;
}

const ConfirmRetrievalDialog = (props: StopUsingDialogProps) => {
    const InfoIcon = Assets.icon.infoIcon.component;
    const { t } = useTranslation();

    const onConfirmFinishSenderRetrieval = () => {
        props.onConfirmPlacement();
        props.onClose();
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle sx={styles.dialogText}>
                {t('itemRetrieval.lockerOpen.dialog.header')}
            </DialogTitle>
            <DialogContent>
                <Typography sx={styles.dialogInfo} variant={'body2'}>
                    {t('itemRetrieval.lockerOpen.dialog.content')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    isButtonDark={false}
                    fullWidth
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => props.onClose()}
                    sx={styles.cancelButton}
                >
                    {t('itemRetrieval.lockerOpen.dialog.cancel_button')}
                </CustomButton>
                <CustomButton
                    isButtonDark={true}
                    fullWidth
                    variant={'contained'}
                    color={'primary'}
                    onClick={onConfirmFinishSenderRetrieval}
                    sx={styles.confirmButton}
                >
                    {t('itemRetrieval.lockerOpen.dialog.confirm_button')}
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmRetrievalDialog;
