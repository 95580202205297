import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { BookingSummary } from "../../../../../types/bookingCreation";
import {
  selectAssetGroupById,
  selectLocationById,
} from "../../../../../redux/slice/booking/bookingSlice";
import moment from "moment";
import BookingStatus from "./BookingStatus";
import { getBookingStatusType } from "../../../../../util";
import { ServiceTypeSelectorOption } from "../../../../../types/order";

type BookingSummaryCardProps = {
  bookingSummaryData: BookingSummary;
  rentalExpired: boolean;
  amount?: number;
};
function BookingSummaryCard1({
  bookingSummaryData,
  rentalExpired,
  amount = 1,
}: BookingSummaryCardProps) {
  const { t } = useTranslation();
  const bookedItem = useSelector((state: RootState) =>
    selectAssetGroupById(state, bookingSummaryData?.items[0].groupId)
  );
  const location = useSelector((state: RootState) =>
    selectLocationById(state, bookingSummaryData?.locationId)
  );
  const recipientData = bookingSummaryData.recipientData;
  const recipientDataService = bookingSummaryData.service;
  const bookingNumber = bookingSummaryData.bookingNumber;
  const startDate = bookingSummaryData?.items?.[0]?.fromDate;
  const formatBookedItemName = () => {
    if (!bookedItem) return "";

    switch (bookingSummaryData.service) {
      case ServiceTypeSelectorOption.P2P:
      case ServiceTypeSelectorOption.STORAGE:
        return `${amount}x ${t("orders.ticket_overview.booked_ticket.locker")} - ${t(
          "orders.ticket_overview.booked_ticket.size"
        )} ${bookedItem.name}`;
      default:
        return bookedItem?.name;
    }
  };
  if (!bookedItem) return null;
  const textBoldClass =
    "text-h3 text-textPrimary font-semibold leading-[20px] tracking-[0.02em] break-words";
  return (
    <div className="bg-backgroundPaper flex flex-col pt-8 pb-8 px-5 border border-borderDefault relative min-h-[330px]">
      <div className="absolute right-0 top-[29%] w-[5px] h-[10px] bg-primary rounded-l-full " />
      <div className="absolute left-0 top-[29%] w-[5px] h-[10px] bg-primary  rounded-r-full" />
      <div className="flex flex-row justify-end text-body2">
        <BookingStatus
          status={getBookingStatusType(bookingSummaryData?.items?.[0].rental, rentalExpired)}
        />
      </div>
      <div className="flex justify-between mt-2">
        <div className="text-left">
          <p>{t("orders.ticket_overview.start")}</p>
          <p className={textBoldClass}>{moment(startDate).format("DD.MM.YYYY")}</p>
          <p className={textBoldClass}>{moment(startDate).format("h:mm a")}</p>
        </div>
        <div className="text-right">
          <p>{t("orders.ticket_overview.end")}</p>
          <p className={textBoldClass}>{moment(startDate).format("DD.MM.YYYY")}</p>
          <p className={textBoldClass}>{moment(startDate).format("h:mm a")}</p>
        </div>
      </div>
      <div className="mt-1">
        <p>{t("orders.ticket_overview.location")}</p>
        <p className={textBoldClass}> {location?.name}</p>
      </div>
      <div className="mt-1">
        {recipientDataService === "p2p" && recipientData && (
          <div>
            <p>{t("orders.ticket_overview.recipient_details")}</p>
            <p className={textBoldClass}>
              {recipientData?.firstname}
              {recipientData?.lastname ? ` ${recipientData.lastname}` : ""}
            </p>
            <p className={textBoldClass}>{recipientData?.email}</p>
            <p className={textBoldClass}>{recipientData?.telephone}</p>
          </div>
        )}
      </div>
      <div className="mt-1">
        <p>
          {recipientDataService === "apartment"
            ? t("orders.ticket_overview.apartment_booked")
            : t("orders.ticket_overview.booked_items")}
        </p>
        <p className={textBoldClass}>{formatBookedItemName()}</p>
      </div>
      <div className="mt-1">
        <p className="!text-base">{t("orders.ticket_overview.booking_number")}</p>
        <p className={textBoldClass}>{bookingNumber}</p>
      </div>
    </div>
  );
}

export default BookingSummaryCard1;
