// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store"; // Adjust the import based on your file structure
import { InitializeStripePaymentData } from "../../api/types";
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import { stripePaymentInitialized } from "../slice/bookingCreation/bookingCreationSlice";

import { useInitializeStripePaymentMutation } from "../../api/payments/paymentsApiSlice";

export const useInitializeStripePayment = ({
  service,
}: {
  service: string;
}) => {
  const reservationIds = useSelector((state: RootState) =>
    state.bookingCreation.reservations.map(
      (reservation: any) => reservation.reservationId
    )
  );

  const contact = useSelector(
    (state: RootState) => state.bookingCreation.personalData
  );

  const recipient = useSelector(
    (state: RootState) => state.bookingCreation.recipientData
  );

  const [initializeStripePaymentMutation] =
    useInitializeStripePaymentMutation();

  const dispatch = useDispatch();

  const initializeStripePayment = async () => {
    try {
      if (reservationIds.length && contact) {
        const returnUrl = `${
          window.location.origin
        }/payments/stripe/return?reservationIds=${reservationIds.join(",")}`;

        const recipientData = Object.values(recipient).every(
          (value) => value === null
        )
          ? undefined
          : recipient;

        const payload: InitializeStripePaymentData = {
          reservationIds: reservationIds,
          contact: contact,
          recipient: recipientData,
          returnUrl,
          cancelUrl: `${window.location.origin}/payments/stripe/return`,
          service,
        };

        const response = await initializeStripePaymentMutation(
          payload
        ).unwrap();

        if (response) {
          dispatch(stripePaymentInitialized(response));
        }

        return response;
      }
    } catch (error) {
      Logger.for("createNewReservation").error(error);
      ErrorTracker.trackException(error as Error);
      // Handle the error state or dispatch error actions if necessary
    }
  };

  return initializeStripePayment;
};
