// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from "react-redux";
import { apartmentBookingApiSlice } from "../../api/apartment/apartmentApiSlice";
import ErrorTracker from "../helpers/errorTracker";
import Logger from "../helpers/logger";
import { setAllApartment } from "../slice/apartmentRental/apartmentRentalSlice";
import {
  setLoader
} from "../slice/bookingCreation/bookingCreationSlice";
import { baseStore, RootState } from "../store"; // Adjust the import based on your file structure

const fetchApartmentsByLocationIdCall = async (
  locationId: string,
  fromDate: string,
  toDate: string,
  numberOfGuests: number
) => {
  const data = await baseStore.dispatch(
    apartmentBookingApiSlice.endpoints.getApartmentList.initiate({
      locationId: locationId,
      fromDate: fromDate,
      toDate: toDate,
      numberOfGuests: numberOfGuests,
    })
  );

  return data;
};

const fetchApartmentsByCityCall = async (
  city: string,
  fromDate: string,
  toDate: string,
  numberOfGuests: number
) => {
  const data = await baseStore.dispatch(
    apartmentBookingApiSlice.endpoints.getApartmentByCityList.initiate({
      city: city,
      fromDate: fromDate,
      toDate: toDate,
      numberOfGuests: numberOfGuests,
    })
  );
  return data;
};

const fetchApartmentsByCityAndDistrictCall = async (
  city: string,
  district: string,
  fromDate: string,
  toDate: string,
  numberOfGuests: number
) => {
  const data = await baseStore.dispatch(
    apartmentBookingApiSlice.endpoints.getApartmentByCityAndDistrictList.initiate({
      city: city,
      district: district,
      fromDate: fromDate,
      toDate: toDate,
      numberOfGuests: numberOfGuests,
    })
  );
  return data;
};

export const useFetchApartments = () => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  );
  const roomRentalLocation = useSelector(
    (state: RootState) => state.bookingCreation.roomRentalLocation
  );
  const checkInDate = useSelector(
    (state: RootState) => state.bookingCreation.orderData.checkInDate
  );
  const checkOutDate = useSelector(
    (state: RootState) => state.bookingCreation.orderData.checkOutDate
  );
  const numberOfGuests = useSelector(
    (state: RootState) => state.bookingCreation.orderData.numberOfGuests
  );

  const fetchApartments = async () => {
    try {
      dispatch(setLoader(true));
      console.log("roomRentalLocation", roomRentalLocation);
      console.log("selectedLocation", selectedLocation);

      let apartments;
      let data;

      if (roomRentalLocation.district === "all") {
        data = await fetchApartmentsByCityCall(
          roomRentalLocation.city!, // Todo: refactor to not use !
          checkInDate?.format()!, // Todo: refactor to not use !
          checkOutDate?.format()!, // Todo: refactor to not use !
          numberOfGuests
        );
      } else {
        data = await fetchApartmentsByCityAndDistrictCall(
          roomRentalLocation.city!,
          roomRentalLocation.district!,          
          checkInDate?.format()!, // Todo: refactor to not use !
          checkOutDate?.format()!, // Todo: refactor to not use !
          numberOfGuests
        );
      }
      if (data) {
        apartments = data.data;
      }
      if (apartments) {
        dispatch(setAllApartment(apartments));
      }
      console.log("Apartments fetched successfully", apartments);
      dispatch(setLoader(false));
      return apartments;
    } catch (error) {
      Logger.for("fetchApartments").error(error);
      dispatch(setLoader(false));
      ErrorTracker.trackException(error as Error);
    }
  };

  return fetchApartments;
};
