import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";
import { DepotId } from "../../types/depot";

export const lockersApiSlice = createApi({
  reducerPath: "lockersApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["openLocker"],
  endpoints: (builder) => ({
    openLocker: builder.mutation({
      query: ({
        depotId,
        door,
        token,
      }: {
        depotId: DepotId;
        door: number;
        token: string;
      }) => {
        return {
          url: `/depot/${depotId}/doors/${door}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["openLocker"],
    }),
  }),
});

export const { useOpenLockerMutation } = lockersApiSlice;
