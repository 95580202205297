import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import { RootState } from "../../../../redux/store";
import { Box, styled } from "@mui/material";
import { palette } from "../../../../tenants/kolula/layout/palette";
import { setNextButtonClick } from "../../../../redux/slice/apartmentRental/apartmentRentalSlice";

const NextButton = styled(CustomButton)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  border: "none",
  "&:hover": {
    backgroundColor: palette.secondary.contrastText,
    color: "#000000",
    border: "none",
  },
  "&:focus": {
    outline: "none",
    backgroundColor: palette.primary.light,
    color: "#000000",
  },
  textTransform: "none",
}));

interface BookLocationNavigateNextActionProps {}

const VenueReservationNavigateNextAction: React.FC<
  BookLocationNavigateNextActionProps
> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const step = useSelector((state: RootState) => state.apartment.step);

  let isBookButtonVisible;
  let isProceedToPaymentButtonVisible;

  const { nextEnabled } = useSelector(
    (state: RootState) => state.bookingCreation
  );

  const handleNextClick = () => {
    dispatch(setNextButtonClick());
  };

  const buttonText = t(
    step === 4
      ? "book_location.venue_reservation_overview.reservation_request"
      : step === 2
      ? "book_location.venue_reservation_overview.book_now"
      : isBookButtonVisible
      ? "book_location.confirmation.order_now"
      : isProceedToPaymentButtonVisible
      ? "book_location.confirmation.proceed_to_payment"
      : "book_location.next"
  );

  return (
    <Box sx={{ marginBottom: "-40px" }}>
      <NextButton
        key={`${true}`} // Fixed key (removed unnecessary negation)
        variant={true ? "outlined" : "contained"}
        disabled={!nextEnabled}
        onClick={handleNextClick}
        isButtonDark={true}
      >
        {buttonText}
      </NextButton>
    </Box>
  );
};

export default VenueReservationNavigateNextAction;
