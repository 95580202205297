import { useTranslation } from "react-i18next";
import { Apartment } from "../../../../../types/apartments";
import {formatCurrency, getEquipmentName} from "../../../../../util";

interface Props {
  data: Apartment;
  onSelectApartment: (apartment: Apartment) => void;
}
function ApartmentListCard({ data, onSelectApartment }: Props) {
  const { t } = useTranslation();
  const { description, featuredImage, name, price, langNames } = data;
  return (
    <div
      onClick={() => onSelectApartment(data)}
      className="flex border-[#3333331A] border-b mb-6 cursor-pointer pb-3"
    >
      <div className="rounded-lg">
        <img
          className="rounded-lg h-20 w-20 object-cover"
          src={featuredImage}
          alt=""
        />
      </div>
      <div className="flex flex-col ml-4">
        <p className="text-sm font-medium text-black">{getEquipmentName(name, langNames, "title")}</p>
        <p className="text-xs text-black truncate w-64">{getEquipmentName(description, langNames, "description")}</p>
        <div>
          <p className="text-black  font-medium text-[16px]">
            {formatCurrency(price, 'de-DE')}
            &nbsp;/&nbsp;
            <span>{t("apartment.night")}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ApartmentListCard;
