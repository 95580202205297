// hooks/useCreateNewReservation.ts
import { useDispatch } from "react-redux";
import { baseStore } from "../store"; // Adjust the import based on your file structure
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import { rentalsApiSlice } from "../../api/rentals/rentalsApiSlice";
import { setItemState } from "../slice/booking/bookingSlice";
import { Rental } from "../../types/rental";
import { bookingsApiSlice } from "../../api/bookings/bookingsApiSlice";
import { enqueueNotification } from "../slice/notification/notificationSlice";
import { t } from "i18next";

type ItemStates =
  | "sender_started_using"
  | "item_placement_confirmed"
  | "receiver_started_using"
  | "item_retrieved_confirmed"
  | "sender_retrieved_confirmed";

const updatetItemStateCall = async (rental: Rental, state: string) => {
  try {
    const result = await baseStore.dispatch(
      rentalsApiSlice.endpoints.updateItemState.initiate({
        orderId: rental.orderId,
        items: [rental.orderItemId],
        state: state,
        token: rental.token,
      })
    );
    if ("error" in result && result.error) {
      throw new Error(`Failed to change rental state. Error: ${result.error}`);
    }
  } catch (error) {
    throw new Error(
      `Failed to change rental state for orderId ${rental.orderId}: ${error}`
    );
  }
};

export const useUpdateItemState = (
  rental: Rental | undefined,
  state: ItemStates
) => {
  const dispatch = useDispatch();

  const updateItemState = async () => {
    try {
      if (rental) {
        await updatetItemStateCall(rental, state);
        console.log("Item state successfully changed");

        const result: any = await baseStore.dispatch(
          bookingsApiSlice.endpoints.getUserOrder.initiate({
            orderIds: rental.orderId,
          })
        );

        if (result && result.data && result.data[0]) {
          dispatch(
            setItemState({
              orderId: rental.orderId,
              updatedOrder: result.data[0],
              state: state,
            })
          );

          if (state !== "item_retrieved_confirmed") {
            dispatch(
              enqueueNotification({
                severity: "success",
                message: t("use_order.handovers.item_placement.success"),
              })
            );
          }
        } else {
          throw "Failed to change item state.";
        }
      }
    } catch (error) {
      Logger.for("itemStateChanged").error(error);
      ErrorTracker.trackException(error as Error);
      dispatch(
        enqueueNotification({
          severity: "error",
          message: t("use_order.handovers.item_placement.error"),
        })
      );
      // Propagate the error
      throw "Error occurs while changing the item state";
    }
  };

  return updateItemState;
};
