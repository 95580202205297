import { useDispatch } from "react-redux";
import { ReedemCouponData } from "../../api/types";
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import {
  couponAppliedToReservations,
  couponRedemptionLoading,
  setAppliedCouponCode,
} from "../slice/bookingCreation/bookingCreationSlice";

import { useApplyCouponMutation } from "../../api/reservations/reservationsApiSlice";
import { useCallback } from "react";
import { enqueueNotification } from "../slice/notification/notificationSlice";
import { t } from "i18next";
import { Reservation } from "../../types/bookingCreation";

export const useApplyCoupon = () => {
  const [applyCouponMutation] = useApplyCouponMutation();
  const dispatch = useDispatch();

  return useCallback(
    async (reservationIds: string[], couponCode: string) => {
      dispatch(couponRedemptionLoading({ loading: true }));

      const payload: ReedemCouponData = {
        reservationIds,
        couponCode,
      };

      try {
        const response = await applyCouponMutation(payload).unwrap();

        if (response) {
          dispatch(couponAppliedToReservations(response));
          dispatch(couponRedemptionLoading({ loading: false }));
          dispatch(setAppliedCouponCode(couponCode));
          dispatch(
            enqueueNotification({
              severity: "success",
              message: t("book_location.success.coupon_applied"),
            })
          );
        }

        return response as Reservation[];
      } catch (error) {
        dispatch(couponRedemptionLoading({ loading: false }));
        dispatch(
          enqueueNotification({
            severity: "error",
            message: t("book_location.error.invalid_coupon"),
          })
        );

        Logger.for("createNewReservation").error(error);
        ErrorTracker.trackException(error as Error);
        // Handle the error state or dispatch error actions if necessary
      }
    },
    [applyCouponMutation, dispatch]
  );
};
