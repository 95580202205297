import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import LocationSelectionPage from './LocationSelectionPage';
import BookingDialogLayout from '../layout/components/BookingDialogLayout';

interface Props {
    openSelectLocationDialog: boolean
    handleClickOpen: () => void
    handleClose: () => void
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectLocationDialog({ handleClickOpen, handleClose, openSelectLocationDialog }: Props) {
    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={openSelectLocationDialog}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <BookingDialogLayout handleClose={handleClose} handleClickBack={handleClose} children={<LocationSelectionPage />} />
            </Dialog>
        </React.Fragment>
    );
}
