import { Reservation } from "../types/bookingCreation";

export type Coupon = {
    code: string
    amount: number
  }

export const calculateTotalPrice = (reservations: Reservation[]): number => {
    return reservations.reduce((total, reservation) => {
      const price = reservation.reservationItems[0].price;
      return total + price
    }, 0);
  };

  export const findCoupons = (reservations: Reservation[]) => {
    const foundCoupons: Coupon[] = []

    for (const reservation of reservations) {
      const code = reservation.payment.voucher?.code;
      // const amount = parseFloat((reservation.reservationItems[0].price - (reservation.payment.discount_amount_applied || 0)).toFixed(2));
      const amount = reservation.payment.discount_amount_applied || 0


      if (code && amount) {
        foundCoupons.push({ code, amount });
      }
    }

    return foundCoupons
  }

  export function consolidateCoupons(coupons: Coupon[]): Coupon[] {
    const couponMap = new Map<string, number>();

    coupons.forEach(({ code, amount }) => {
      const currentAmount = parseFloat((couponMap.get(code) || 0).toFixed(2));
      const formattedAmount = parseFloat((currentAmount + parseFloat(amount.toFixed(2))).toFixed(2));

      couponMap.set(code, formattedAmount)
    }
    );

    return Array.from(couponMap, ([code, amount]) => ({ code, amount }));
  }


 export const calculateDisplayPrice = (totalPrice: number, coupons: Coupon[]) => {

    const totalDiscountAmount = coupons.reduce((total, coupon) => {
      return total + coupon.amount
    }, 0)

    if (totalPrice > totalDiscountAmount) {
      return parseFloat((totalPrice - totalDiscountAmount).toFixed(2))
    }

    return 0
}

export const calculateDisplayPriceForReservations = (reservations: Reservation[]): number => {
  const totalPrice = calculateTotalPrice(reservations);
  return calculateDisplayPrice(totalPrice, findCoupons(reservations));
}