import { Reservation } from "../../types/bookingCreation";

/**
 * @todo fix hardcoded timezone?
 * @param data
 */
export const processReservationData = (data: any): Reservation => {
    // const hasValidDiscount = false; // Boolean(data.discountInfo.length > 0 && data.discountInfo[0].is_valid);

    return {
        reservationId: data.id,
        locationId: data.locationId,
        // TODO: implement discount
        discount: null,
        // discount: !hasValidDiscount ? null : {
        //     code: data.discountInfo[0].code,
        //     amount: data.discountInfo[0].amount,
        // },
        bookingNumber: data.bookingNumber,
        reservationItems: data.reservationItems,
        payment: {
            totalPrice: data.payment.requested_amount,
            currency: data.payment.currency,
            state: data.payment.state,
            payment_method: data.payment.payment_method,
            discount_amount_applied: data.payment.discount_amount_applied || 0,

        },
        time_to_live: data.time_to_live,
    };
};