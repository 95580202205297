// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from 'react-redux';
import { baseStore, RootState } from '../store'; // Adjust the import based on your file structure
import moment from 'moment';
import { CreateNewReservationData } from '../../api/types';
import Logger from '../helpers/logger';
import ErrorTracker from '../helpers/errorTracker';
import { locationsApiSlice, useGetLocationEquipmentQuery } from '../../api/locations/locationsApiSlice';
import { setAllGroupsByLocation } from '../slice/booking/bookingSlice';


const fetchAllGroupsCall = async(locationIds: string[]) => {    
    const data = await baseStore.dispatch(
        locationsApiSlice.endpoints.getAssetGroupsByLocationIdsList.initiate({
            locationIds: locationIds,
        })
    )
    return data;
};

export const useFetchAllGroups = () => {
  const locations = useSelector((state: RootState) => state.booking.locations);
  const dispatch = useDispatch();

  const fetchAllGroups = async () => {
    try {
        if (locations && locations.length > 0) {
            const locationIds = locations.map((location: any) => location.id);
            const { data: groups } = await fetchAllGroupsCall(locationIds);
            if (groups) {
                dispatch(setAllGroupsByLocation({ locationIds: locationIds, assetGroups: groups }));
            }

        }
    } catch (error) {
      Logger.for('createNewReservation').error(error);
      ErrorTracker.trackException(error as Error);
      // Handle the error state or dispatch error actions if necessary
    }
  };

  return fetchAllGroups;
};
