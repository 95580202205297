const routes = {
  locations: "/locations",
  orders: "/orders",
  hints: "/hints",
  welcome: "/welcome",
  home: "/",
  bookLocationP2PPath: "/p2p/book",
  p2PItemRetrievalPath: "/recipient/orders",
  temStorageNewBooking: "/temStorage/book",
  tempStorageStartUsing: "/tempStorage/startUsing",
  p2PSenderRetrievalPath: "/finishRental/:token",
  // p2PItemRetrievalPath: '/p2p/item-retrieval',
  bookLocationEquipmentRentalPath: "/equipmentRental/book",
  roomRentalPath: "/roomRental/book",
  venueReservationPath: "/venue/book",
  moreToComePath: "/moreToCome",
  orderDetailsPath: "/orders/:orderId",
  reportIssuePath: "/orders/:orderId/report-issue",
  hintsPath: "/hints",
  tipsPath: "/hints/page/tips",
  pricePath: "/hints/pricing",
  faqsPath: "/hints/page/faqs",
  termsOfServicePath: "/hints/page/termsOfService",
  dataProtectionPath: "/hints/page/dataProtection",
  imprintPath: "/hints/page/imprint",
  hintsPagePath: "/hints/page/:hintPage",
  retrieveBookingEmailPath: "/retrieveBookingEmail",

  consentGDPRPagePath: "/consent",
  privacyGDPRPath: "/consent/page/:privcayPage",

  stripePaymentReturnPath: "/payments/stripe/return",
  noPaymentReturnPath: "/payments/no_payment/return",
  makeHintsPath: (component: string) =>
    routes.hintsPagePath.replace(":hintPage", component),
  makeOrderDetailsUrl: (orderId: string) =>
    routes.orderDetailsPath.replace(":orderId", orderId),
  makePrivacyGDPRPath: (component: string) =>
    routes.privacyGDPRPath.replace(":privcayPage", component),
  makeIssueReportsUrl: (orderId: string) =>
    routes.reportIssuePath.replace(":orderId", orderId),
};

export default routes;
