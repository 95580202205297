import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import TenantLogo from "../../../../layout/components/TenantLogo";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PickupDetails } from "../../../../../redux/slice/itemRetrieval/itemRetrievalSlice";
import { formatDateTime } from "../../../../../util";

const styles = {
  root: (theme: any) => ({
    background: theme.palette.background.paper || theme.palette.primary.main,
    color: theme.palette.text.secondary || "white",
    borderRadius: 0.5,
    width: "350px",
    position: "relative",
    border: `1px solid none`,
  }),
  rightTextAlign: (theme: any) => ({
    color: theme.palette.text.secondary || "white",
    // textAlign: 'right',
  }),
  text: (theme: any) => ({
    color: theme.palette.text.secondary || "white",
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontStyle: theme.typography.h5.fontStyle,
  }),
  location: (theme: any) => ({
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    letterHeight: theme.typography.h2.letterHeight,
    letterSpacing: theme.typography.h2.letterSpacing,
    fontStyle: theme.typography.h5.fontStyle,
  }),
  itemPrice: (theme: any) => ({}),
  headerLogo: (theme: any) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingRight: "3px",
    paddingLeft: "14px",
    [theme.breakpoints.down("md")]: {
      height: "33px",
    },
  }),
  bookingsStatus: (theme: any) => ({
    marginTop: "22px",
    [theme.breakpoints.down("md")]: {
      height: "33px",
    },
  }),
  ticketDot: (theme: any) => ({
    width: "5px",
    height: "10px",
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: "29%",
  }),
  ticketDotLeft: (theme: any) => ({
    ...styles.ticketDot(theme),
    left: 0,
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  }),
  ticketDotRight: (theme: any) => ({
    ...styles.ticketDot(theme),
    right: 0,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  }),
  recipientInfo: (theme: any) => ({
    wordBreak: "break-word",
    overflowWrap: "break-word",
    width: "100%",
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    letterHeight: theme.typography.h2.letterHeight,
    letterSpacing: theme.typography.h2.letterSpacing,
    fontStyle: theme.typography.h5.fontStyle,
  }),
  ticketClass: {
    width: "100%",
    padding: "0px 12px",
    margin: "4px 0px",
    position: "relative",
    paddingLeft: "20px",
  },
  ticketcontent: {
    paddingRight: "12px",
    paddingLeft: "12px !important",
  },
};
function PickupDetailsCard({
  pickupDetails,
}: {
  pickupDetails: PickupDetails;
}) {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className="border rounded-md shadow">
      <Box className="py-4" sx={styles.root}>
        <Box sx={styles.ticketDotLeft} />
        <Grid container spacing={2} direction="column">
          <Grid
            className="flex w-full items-center justify-between px-6 mt-5"
            direction="row"
          >
            <Box sx={styles.headerLogo}>
              <TenantLogo
                style={{ width: "60%", height: "60%" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={styles.ticketClass} spacing={2} direction="column">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Typography sx={{ ...styles.text, textAlign: "left" }}>
                {t("itemRetrieval.pickupDetails.sending_from")}
              </Typography>
              <Typography sx={{ ...styles.location, textAlign: "left" }}>
                <strong>
                  {pickupDetails.senderDetails.firstName}{" "}
                  {pickupDetails.senderDetails.lastName}
                </strong>
              </Typography>
              <Typography sx={{ ...styles.location, textAlign: "left" }}>
                <strong>{pickupDetails.senderDetails.email}</strong>
              </Typography>
              <Typography sx={{ ...styles.location, textAlign: "left" }}>
                <strong>{pickupDetails.senderDetails.phone}</strong>
              </Typography>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Typography sx={{ ...styles.text, textAlign: "left" }}>
                {t("itemRetrieval.pickupDetails.pickup_location")}
              </Typography>
              <Typography sx={{ ...styles.location, textAlign: "left" }}>
                <strong>{pickupDetails.pickupLocation}</strong>
              </Typography>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Typography sx={{ ...styles.location, textAlign: "left" }}>
                <a
                  rel="noreferrer"
                  href={pickupDetails.googleLink}
                  target="_blank"
                >
                  <strong className="text-kolula-primary underline">
                    {t("itemRetrieval.pickupDetails.google_link")}
                  </strong>
                </a>
              </Typography>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Typography sx={{ ...styles.text, textAlign: "left" }}>
                {/* {t("orders.ticket_overview.start")} */}
                {t("itemRetrieval.pickupDetails.reserved_locker")}
              </Typography>
              <Typography sx={{ ...styles.location, textAlign: "left" }}>
                <strong>
                  {t("itemRetrieval.pickupDetails.locker")}{" "}
                  {pickupDetails.reservedLocker}
                </strong>
              </Typography>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Typography sx={{ ...styles.text, textAlign: "left" }}>
                {t("itemRetrieval.pickupDetails.pickup_until")}
              </Typography>
              <Typography sx={{ ...styles.location, textAlign: "left" }}>
                <strong>
                  {formatDateTime(
                    moment(pickupDetails.pickupDateTime).format(
                      "DD-MM-YYYY h:mm a"
                    )
                  )}
                </strong>
              </Typography>
            </Grid>
          </Box>
        </Grid>

        <Box sx={styles.ticketDotRight} />
      </Box>
    </Box>
  );
}

export default PickupDetailsCard;
