import React, { useEffect, useState } from 'react';
import moment from 'moment';
import BookingTicket from './components/ConfirmationStep/BookingTicket';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import LoaderOverlay from '../../layout/components/LoaderOverlay';
import { useTranslation } from 'react-i18next';
import { bookingCreationCompleted } from '../../../redux/slice/bookingCreation/bookingCreationSlice';
import { Box, Button, styled, Typography } from '@mui/material';
import CouponDialog from '../common/components/ConfirmationStep/CouponDialog';

interface ConfirmationStepProps {
  onAddAnotherEquipment: () => void;
}

const StyledCouponWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}))

function ConfirmationStep({ onAddAnotherEquipment }: ConfirmationStepProps) {
  const { t } = useTranslation();
  const reservations = useSelector((state: RootState) => state.bookingCreation.reservations)!;
  const location = useSelector((state: RootState) => state.bookingCreation.selectedLocation)!;
  const isLoading = !(location && reservations.length > 0);

  return (
    <>
      {isLoading ? (
        <LoaderOverlay open={isLoading} />
      ) : (
        <div className="flex flex-col justify-between h-full">
          <div className="overflow-auto flex-grow">
            <BookingTicket
              location={location.name}
              reservations={reservations}
              amount={1}
            />
          </div>
          <div className="text-center text-black text-xl font-semibold mb-8">
            <button
              onClick={onAddAnotherEquipment}
              className="underline bg-transparent border-none cursor-pointer"
            >
              {t('book_location.confirmation.add_another_group')}
            </button>
          </div>
        </div>
      )}
      {/* 
      <Box>
        {enableCouponDialog && (
          <StyledCouponWrapper>
            <Button
              variant={'contained'}
              size={'small'}
              color={'secondary'}
              onClick={() => setCouponDialogOpen(true)}
              children={t('book_location.confirmation.redeem_coupon')}
            />
            <RedeemCouponDialog
              open={couponDialogOpen}
              onClose={() => setCouponDialogOpen(false)}
            />
          </StyledCouponWrapper>
        )}
    
      </Box> */}
    </>
  );
}

export default ConfirmationStep;
