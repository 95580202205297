import { useDispatch, useSelector } from "react-redux";
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import { BookingSummary } from "../../types/bookingCreation";
import { getConsent, hasConsent } from "../../storage/consent";
import { setConsent, setLoadedConsent } from "../slice/consent/consentSlice";

export const useLoadConsent = () => {
  const dispatch = useDispatch();

  const loadConsent = async () => {
    try {
      if (hasConsent()) {
        console.log("Consent loaded successfully!");
        const consent = getConsent();
        if (consent) {
            dispatch(setConsent(consent));
        }
      }
      dispatch(setLoadedConsent());
    } catch (error) {
      Logger.for("loadConsent").error(error);
      ErrorTracker.trackException(error as Error);
    }
  };
  return loadConsent;
};
