import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";
import { ReedemCouponData, UpdateReservationPayload } from "../types";

export const reservationsApiSlice = createApi({
  reducerPath: "reservationsApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["reservationList", "reservation", "userOrders"],
  endpoints: (builder) => ({
    createNewReservation: builder.mutation({
      // Corrected to accept data as argument and use POST method
      query: (data) => {
        return {
          url: `/reservation`,
          method: "POST",
          data: data,
        };
      },
      invalidatesTags: ["reservationList"], // Assumed you want to invalidate locationList on create
    }),
    removeReservation: builder.mutation({
      // Corrected to accept reservationId as argument and use DELETE method
      query: ({ reservationId }) => {
        return {
          url: `/reservation/${reservationId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["reservation"], // Assumed you want to invalidate locationList on delete
    }),
    applyCoupon: builder.mutation({
      query: ({ reservationIds, couponCode }: ReedemCouponData) => {
        return {
          url: `/reservations/${reservationIds.join(",")}/voucher`,
          method: "POST",
          data: {
            code: couponCode,
          },
        };
      },
    }),
    updateReservation: builder.mutation({
      query: ({ reservationId, ...data }: UpdateReservationPayload) => {
        return {
            url: `/reservation/update-and-apply/${reservationId}`,
            method: "PATCH",
            data: data,
        };
      },
    }),
  }),
});

export const {
  useCreateNewReservationMutation,
  useRemoveReservationMutation,
  useApplyCouponMutation,
  useUpdateReservationMutation,
} = reservationsApiSlice;
