import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  PersonalData,
  PersonalDataValidation,
} from "../../../types/bookingCreation";
import DebouncedPhoneNumberInput from "../common/components/PersonalDataStep/DebouncedPhoneNumberInput";
import DebouncedTextInput from "../common/components/PersonalDataStep/DebouncedTextInput";
import {
  agreementChanged,
  emailChanged,
  firstNameChanged,
  lastNameChanged,
  phoneChanged,
  uscCustomerIdChanged,
} from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import { RootState } from "../../../redux/store";
import { TenantConfig as TConfig } from "../../../types/tenantConfig";
import TenantConfig from "../../../util/tenantConfig";
import {
  Typography,
  Box,
  useTheme,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const styles = {
    text: (theme: any) => ({
      textAlign: "left",
      width: "100%",
      paddingLeft: "25px",
      fontWeight: 400,
      color: theme.typography.primary,
      fontSize: "16px",
      marginBottom: "10px",
    }),
    termsOfServiceLink: (theme: any) => ({
      color: theme.palette.secondary.contrastText,
      textDecorationColor: theme.palette.secondary.contrastText,
      marginTop: "-6px",
    }),
  };

const PersonalDataStep = () => {
  // const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const personalData: PersonalData = useSelector(
    (state: RootState) => state.bookingCreation.personalData
  );
  const personalDataValidation: PersonalDataValidation = useSelector(
    (state: RootState) => state.bookingCreation.personalDataValidation
  );
  const partner = useSelector(
    (state: RootState) => state.bookingCreation.partner
  );
  const theme = useTheme();
  const isPartnerUsc = partner === "usc";
  let dataProtectionPath = "/hints";

  const onFirstNameChange = (firstName: string) =>
    dispatch(firstNameChanged({ firstName }));
  const onLastNameChange = (lastName: string) =>
    dispatch(lastNameChanged({ lastName }));
  const onPhoneChange = (phone: string) => {
    dispatch(phoneChanged({ phone: phone }));
  };
  const onEmailChange = (email: string) => dispatch(emailChanged({ email }));
  const onAgreementChange = (isAgreed: boolean) =>
    dispatch(agreementChanged({ isAgreed }));
  const onUscCustomerIdChange = (uscCustomerId: string) =>
    dispatch(uscCustomerIdChanged({ uscCustomerId }));
  // const tenantConfig = useSelector((state: RootState) => state. Selector.Config.config);

  const [tenantConfig, setTenantConfig] = useState<TConfig>();

  useEffect(() => {
    dispatch(agreementChanged({ isAgreed: true }));
    const retrieveTenantConfig = async () => {
      const config = await TenantConfig();
      setTenantConfig(config);
    };
    retrieveTenantConfig();
  }, []);

  return (
    <Box
      className="p-3"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <DebouncedTextInput
        label={t("book_location.personal_data.first_name")}
        initialValue={personalData.firstName}
        onChange={onFirstNameChange}
        valid={personalDataValidation.firstName}
        autoComplete={"given-name"}
      />
      <DebouncedTextInput
        label={t("book_location.personal_data.last_name")}
        initialValue={personalData.lastName}
        onChange={onLastNameChange}
        valid={personalDataValidation.lastName}
        autoComplete={"family-name"}
      />
      <DebouncedPhoneNumberInput
        initialValue={personalData.phone}
        onChange={onPhoneChange}
        valid={personalDataValidation.phone}
      />
      <DebouncedTextInput
        label={t("book_location.personal_data.email")}
        initialValue={personalData.email}
        onChange={onEmailChange}
        valid={personalDataValidation.email}
        autoComplete={"email"}
      />
      {isPartnerUsc && (
        <DebouncedTextInput
          label={t("book_location.personal_data.usc_customer_id")}
          initialValue={personalData.uscCustomerId}
          onChange={onUscCustomerIdChange}
          valid={personalDataValidation.uscCustomerId}
        />
      )}

      <Typography sx={styles.text}>
        <Trans
          i18nKey={"book_location.personal_data.terms_and_conditions"}
          components={{
            termsAndConditionLink: (
              <Link
                component={RouterLink}
                to={dataProtectionPath}
                underline="always"
                sx={styles.termsOfServiceLink}
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );
};

export default PersonalDataStep;
