import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NewBookingStepChangedPayload {
  step: number;
  nextEnabled: boolean;
}

export interface NewBookingFlowPayload {
  newBookingFlow: string[];
}

export interface temporaryStorageState {
  locationId: string | null;
  step: number;
  nextEnabled: boolean;
  isLocationValid: boolean;
  isQRCodeNumberValid: boolean;
  rentalFinished: boolean;
  isAgreed: boolean;
  newBookingFlow: string[];
  receivedQRCodeNumber: string;
}

const initialState: temporaryStorageState = {
  locationId: null,
  receivedQRCodeNumber: "",
  step: 0,
  nextEnabled: false,
  isQRCodeNumberValid: false,
  isAgreed: false,
  isLocationValid: false,
  newBookingFlow: [],
  rentalFinished: false,
};

const temporaryStorageNewBookingSlice = createSlice({
  name: "temporaryStorageNewBooking",
  initialState,
  reducers: {
    newBookingFlowChanged: (
      state,
      action: PayloadAction<NewBookingFlowPayload>
    ) => {
      state.newBookingFlow = [...action.payload.newBookingFlow];
    },
    setHasAgreedToConcent: (state) => {
      state.isAgreed = true;
    },
    navigatedToPreviousStep: (state) => {
      state.step -= 1;
    },
    setNextButtonClick: (state) => {
      state.step += 1;
    },
    newBookingStepChanged: (
      state,
      action: PayloadAction<NewBookingStepChangedPayload>
    ) => {
      state.step = action.payload.step;
      state.nextEnabled = action.payload.nextEnabled;
    },
    bookingCreationCompleted: () => initialState,
    setIsLocationValid: (state, action: PayloadAction<boolean>) => {
      state.isLocationValid = action.payload;
      state.step = 3;
    },
    setReceivedQRCodeNumber: (state, action: PayloadAction<string>) => {
      state.receivedQRCodeNumber = action.payload.trim();
    },
  },
});

export const {
  setHasAgreedToConcent,
  navigatedToPreviousStep,
  newBookingStepChanged,
  setNextButtonClick,
  newBookingFlowChanged,
  setIsLocationValid,
  setReceivedQRCodeNumber,
  bookingCreationCompleted,
} = temporaryStorageNewBookingSlice.actions;
export default temporaryStorageNewBookingSlice.reducer;
