import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import LoaderOverlay from "../../layout/components/LoaderOverlay";
import _ from "lodash";
import ApartmentListCard from "../common/components/ApartmentRental/ApartmentListCard";
import {
  setAllApartment,
  setNextButtonClick,
  setSelectedApartment,
} from "../../../redux/slice/apartmentRental/apartmentRentalSlice";
import FullScreenDialog from "../../common/FullScreenDialog";
import MapView from "./MapView";
import { useGetApartmentListQuery } from "../../../api/apartment/apartmentApiSlice";
import { Apartment } from "../../../types/apartments";
import NoResult from "../../layout/components/NoResult";

function ApartmentSelectionStep() {
  const { locationApartmentList, isMapOpen, numberOfGuest } = useSelector(
    (state: RootState) => state.apartment
  )!;
  const dispatch = useDispatch();
  const selectedLocation = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  );
  const { checkOutDate, checkInDate } = useSelector(
    (state: RootState) => state.bookingCreation.orderData
  );

  const { data, isLoading: loadingApartment } = useGetApartmentListQuery({
    locationId: selectedLocation?.id!,
    fromDate: checkInDate!?.format(),
    toDate: checkOutDate!?.format(),
    numberOfGuests: numberOfGuest,
  });

  useEffect(() => {
    dispatch(setAllApartment(data));
  }, [data, dispatch]);

  // const equipments = useSelector((state: RootState) =>
  //   selectedLocation
  //     ? state.bookingCreation.groupsByLocation[selectedLocation.id]
  //     : undefined
  // );
  // const isLoading = _.isEmpty(equipments);

  const handleSelectApartment = (apartment: Apartment) => {
    dispatch(setSelectedApartment(apartment));
    dispatch(setNextButtonClick());
  };
  if ( loadingApartment) {
    return <LoaderOverlay open={loadingApartment} />;
  } else {
    return (
      <div
        style={{
          maxHeight: "98%", // Set your desired max height
          overflowY: "auto", // Enable vertical scrolling
          marginLeft: "16px", // Add margin to the left
          marginRight: "16px", // Add margin to the right
        }}
      >
        {locationApartmentList?.length >= 1 ? (
          <>
            {locationApartmentList?.map((apartment: any) => (
              <div key={apartment.description}>
                <ApartmentListCard
                  data={apartment}
                  onSelectApartment={handleSelectApartment}
                />
              </div>
            ))}
          </>
        ) : (
          <NoResult />
        )}

        <FullScreenDialog open={isMapOpen}>{<MapView />}</FullScreenDialog>
      </div>
    );
  }
}

export default ApartmentSelectionStep;
