import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsCameraDisallowed } from "../../../../redux/slice/itemRetrieval/itemRetrievalSlice";

const useCameraAccess = () => {
  const [hasCameraAccess, setHasCameraAccess] = useState<boolean | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkCameraAccess = async () => {
      try {
        // Request camera access
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setHasCameraAccess(true);

        // Stop the camera stream after permission is granted
        stream.getTracks().forEach((track) => track.stop());

        // Set the flag in local storage to indicate permission has been requested
        localStorage.setItem("hasRequestedCameraAccess", "true");
        dispatch(setIsCameraDisallowed(false));
      } catch (err) {
        // If permission is denied or an error occurs, set the access state to false
        console.error("Camera access denied or an error occurred:", err);
        setHasCameraAccess(false);
        dispatch(setIsCameraDisallowed(true));
      }
    };

    checkCameraAccess();
  }, []);

  return hasCameraAccess;
};

export default useCameraAccess;
