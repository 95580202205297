// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from 'react-redux';
import { baseStore, RootState } from '../store'; // Adjust the import based on your file structure
import moment from 'moment';
import { CreateNewReservationData } from '../../api/types';
import Logger from '../helpers/logger';
import ErrorTracker from '../helpers/errorTracker';
import { vouchersApiSlice } from '../../api/voucher/voucherApiSlice';
import { couponRedemptionLoading, setRoomRentalVoucher } from '../slice/bookingCreation/bookingCreationSlice';
import { enqueueNotification } from '../slice/notification/notificationSlice';
import { t } from "i18next";


const fetchVoucherCall = async(voucherCode: string, locationId: string, groupId: string) => {    
    const response = await baseStore.dispatch(
        vouchersApiSlice.endpoints.getVoucherByCodeAndLocationAndGroup.initiate({
            voucherCode: voucherCode,
            locationId: locationId,
            groupId: groupId,
        })
    )
    if (!response || response.error) {
      throw new Error("Invalid API response");
    }
    return response;
};

export const useFetchVoucher = () => {
  const dispatch = useDispatch();

  const fetchVoucher = async (voucherCode: string, locationId: string, groupId: string) => {
    try {
        const { data: voucher } = await fetchVoucherCall(voucherCode, locationId, groupId); // API call for each locationId
        console.log(`Voucher fetched successfully for locationId ${locationId}`, voucher);


        if (!voucher || !voucher.validUntilDate || moment(voucher.validUntilDate).isBefore(moment())) {
          throw new Error("Voucher is invalid or expired");
        }

        if (voucher) {
          dispatch(couponRedemptionLoading({ loading: false }));
          dispatch(
            enqueueNotification({
              severity: "success",
              message: t("book_location.success.coupon_applied"),
            })
          );
          dispatch(
            setRoomRentalVoucher({
              id: voucher.id,
              code: voucher.code,
              discountIsPercentage: voucher.discountIsPercentage,
              discountValue: voucher.discountValue
            })
          );
        }
        // Dispatch action after all groups are fetched
        // dispatch(setAllDepotsByLocation({ depots: allDepotsByLocation }));
    } catch (error) {      
      dispatch(couponRedemptionLoading({ loading: false }));
      dispatch(
        enqueueNotification({
          severity: "error",
          message: t("book_location.error.invalid_coupon"),
        })
      );
      Logger.for('fetchVoucher').error(error);
      ErrorTracker.trackException(error as Error);
      // Handle the error state or dispatch error actions if necessary
    }
  };

  return fetchVoucher;
};
