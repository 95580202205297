import { InternalOrder, InternalOrderId, Order, OrderId } from "../types/order";
import KolulaIndexedDB, {
  INDEX_ORDER_ID,
  OBJECT_STORE_BOOKING_FLOW_DATA,
  OBJECT_STORE_ORDERS,
  OBJECT_STORE_ORDER_IDS,
  OBJECT_STORE_PERSONAL_DATA,
  OBJECT_STORE_STARTED_ORDERS,
} from "./KolulaIndexedDB";
import { IDBPersonalData } from "./types";
import { IndexedDBBookingFlow } from "../types/bookingCreation";

// const orderIsNotExpired = (order: Order) =>
//     moment(order.items[0].fromDate).add(1, 'days').toDate() >= new Date();
class KolulaStorageManager {
  private db: KolulaIndexedDB;

  constructor() {
    this.db = new KolulaIndexedDB();
    this.getPersonalData = this.getPersonalData.bind(this);
    this.setPersonalData = this.setPersonalData.bind(this);
    this.upsertInternalOrder = this.upsertInternalOrder.bind(this);
    this.insertInternalOrder = this.insertInternalOrder.bind(this);
    this.deleteOrdersByOrderId = this.deleteOrdersByOrderId.bind(this);
    this.getActiveOrders = this.getActiveOrders.bind(this);
    this.getOrder = this.getOrder.bind(this);
    this.setRentalStartedFlag = this.setRentalStartedFlag.bind(this);
    this.hasOrderStartedFlag = this.hasOrderStartedFlag.bind(this);
    this.deleteAllOrders = this.deleteAllOrders.bind(this);
  }

  public async getPersonalData(): Promise<IDBPersonalData | null> {
    const documents = await this.db.getAll(OBJECT_STORE_PERSONAL_DATA);
    return documents.length > 0 ? documents[0] : null;
  }

  public async setPersonalData(data: IDBPersonalData): Promise<void> {
    await this.db.put(OBJECT_STORE_PERSONAL_DATA, data);
  }

  public async upsertInternalOrder(
    internalOrder: InternalOrder
  ): Promise<void> {
    await this.deleteOrdersByOrderId(internalOrder.id);

    // for (const orderData of internalOrder) {
    await this.db.put(OBJECT_STORE_ORDERS, internalOrder);
    // }
  }

  public async insertInternalOrder(
    internalOrder: InternalOrder
  ): Promise<void> {
    // for (const orderData of internalOrder) {
    await this.db.put(OBJECT_STORE_ORDERS, internalOrder);
    // }
  }

  public async insertInternalMultipleOrders(
    internalOrders: InternalOrder[]
  ): Promise<void> {
    // Iterate over the array and insert each order into the database
    for (const order of internalOrders) {
      await this.db.put(OBJECT_STORE_ORDERS, order);
    }
  }

  // public async insertInternalMultipleOrdersIds(
  //   internalOrders: string[]
  // ): Promise<void> {

  //   console.log("internalOrders", internalOrders)
  //   // Iterate over the array and insert each order into the database
  //   for (const orderId of internalOrders) {
  //     await this.db.put(OBJECT_STORE_ORDERS,  orderId );
  //   }
  // }

  public async insertInternalMultipleOrdersIds(
    internalOrders: string[]
  ): Promise<void> {
  
    // Iterate over the array and insert each order into the database
    for (const orderId of internalOrders) {
      // Ensure that the object contains a key path (assuming the key path is `id`)
      const order = { id: orderId }; // Modify this according to your key path
      await this.db.put(OBJECT_STORE_ORDERS, order); // Insert the object
    }
  }
  

  public async setBookingFlowData(bookingFlow: string[]): Promise<void> {
    await this.db.put(OBJECT_STORE_BOOKING_FLOW_DATA, {
      id: "booking_orders",
      bookingFlow,
    });
  }

  public async getActiveOrders(): Promise<Order[]> {
    const allOrders: InternalOrder[] = await this.db.getAll(
      OBJECT_STORE_ORDERS
    );
    return allOrders;
    // TODO: implement old logic
    // return allOrders.filter(
    //     order => !order.rental?.finishedAt && orderIsNotExpired(order),
    // );
  }
  public async getActiveOrderIds(): Promise<Order[]> {
    const allOrders: InternalOrder[] = await this.db.getAll(
      OBJECT_STORE_ORDERS
    );
    return allOrders;
    // TODO: implement old logic
    // return allOrders.filter(
    //     order => !order.rental?.finishedAt && orderIsNotExpired(order),
    // );
  }

  public async getBookingFlow(): Promise<IndexedDBBookingFlow[]> {
    return await this.db.getAll(OBJECT_STORE_BOOKING_FLOW_DATA);
  }

  public async setRentalStartedFlag(
    internalRentalId: string,
    itemIds: string[]
  ): Promise<void> {
    const storeObject = {
      id: internalRentalId,
      items: itemIds.map((itemId) => ({ id: itemId, started: true })),
    };
    await this.db.put(OBJECT_STORE_STARTED_ORDERS, storeObject);
  }

  public async hasOrderStartedFlag(orderId: OrderId): Promise<boolean> {
    const flagEntry = await this.db.get(OBJECT_STORE_STARTED_ORDERS, orderId);
    return Boolean(flagEntry && flagEntry.started);
  }

  /**
   * Deletes all orders with the given internal orderId
   *
   * @param internalOrderId
   */
  private async deleteOrdersByOrderId(
    internalOrderId: InternalOrderId
  ): Promise<void> {
    const documents = await this.db.getAllByIndex(
      OBJECT_STORE_ORDERS,
      INDEX_ORDER_ID,
      internalOrderId
    );
    for (const document of documents) {
      await this.db.delete(OBJECT_STORE_ORDERS, document.id);
    }
  }

  private async getOrder(orderId: OrderId): Promise<Order | null> {
    return (await this.db.get(OBJECT_STORE_ORDERS, orderId)) || null;
  }

  /**
   * Deletes all orders from OBJECT_STORE_ORDERS
   */
  public async deleteAllOrders(): Promise<void> {
    const allOrders: InternalOrder[] = await this.db.getAll(
      OBJECT_STORE_ORDERS
    );
    for (const order of allOrders) {
      await this.db.delete(OBJECT_STORE_ORDERS, order.id);
    }
  }
}

export default KolulaStorageManager;
