import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import bookingSlice from "./slice/booking/bookingSlice";
import apartmentRentalSlice from "./slice/apartmentRental/apartmentRentalSlice";
import configSlice from "./slice/config/configSlice";
import { locationsApiSlice } from "../api/locations/locationsApiSlice";
import bookingCreationSlice from "./slice/bookingCreation/bookingCreationSlice";
import itemRetrievalSlice from "./slice/itemRetrieval/itemRetrievalSlice";
import temporaryStorageSlice from "./slice/temporaryStorage/temporaryStorageSlice";
import senderRetrievalSlice from "./slice/senderRetrieval/senderRetrievalSlice";
import notificationSlice from "./slice/notification/notificationSlice";
import { availabilitiesApiSlice } from "../api/availabilities/availabilitiesApiSlice";
import { reservationsApiSlice } from "../api/reservations/reservationsApiSlice";
import { paymentsApiSlice } from "../api/payments/paymentsApiSlice";
import { bookingsApiSlice } from "../api/bookings/bookingsApiSlice";
import hintsSlice from "./slice/hints/hintsSlice";
import { hintsApiSlice } from "../api/hints/hintsApiSlice";
import { rentalsApiSlice } from "../api/rentals/rentalsApiSlice";
import { lockersApiSlice } from "../api/lockers/lockersApiSlice";
import { issueReportApiSlice } from "../api/issueReport/issueReportApiSlice";
import consentSlice from "./slice/consent/consentSlice";
import { receiverApiSlice } from "../api/receiver/receiverApiSlice";
import { apartmentBookingApiSlice } from "../api/apartment/apartmentApiSlice";
import { vouchersApiSlice } from "../api/voucher/voucherApiSlice";

const reducers = combineReducers({
  [locationsApiSlice.reducerPath]: locationsApiSlice.reducer,
  [availabilitiesApiSlice.reducerPath]: availabilitiesApiSlice.reducer,
  [reservationsApiSlice.reducerPath]: reservationsApiSlice.reducer,
  [bookingsApiSlice.reducerPath]: bookingsApiSlice.reducer,
  [paymentsApiSlice.reducerPath]: paymentsApiSlice.reducer,
  [hintsApiSlice.reducerPath]: hintsApiSlice.reducer,
  [rentalsApiSlice.reducerPath]: rentalsApiSlice.reducer,
  [lockersApiSlice.reducerPath]: lockersApiSlice.reducer,
  [issueReportApiSlice.reducerPath]: issueReportApiSlice.reducer,
  [receiverApiSlice.reducerPath]: receiverApiSlice.reducer,
  [apartmentBookingApiSlice.reducerPath]: apartmentBookingApiSlice.reducer,
  [vouchersApiSlice.reducerPath]: vouchersApiSlice.reducer,
  booking: bookingSlice,
  tenantConfig: configSlice,
  bookingCreation: bookingCreationSlice,
  itemRetrieval: itemRetrievalSlice,
  temporaryStorage: temporaryStorageSlice,
  senderRetrieval: senderRetrievalSlice,
  notification: notificationSlice,
  hints: hintsSlice,
  consent: consentSlice,
  apartment: apartmentRentalSlice
});

export const baseStore = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,      
      immutableCheck: false,
    }).concat(
      locationsApiSlice.middleware,
      availabilitiesApiSlice.middleware,
      reservationsApiSlice.middleware,
      bookingsApiSlice.middleware,
      paymentsApiSlice.middleware,
      paymentsApiSlice.middleware,
      hintsApiSlice.middleware,
      rentalsApiSlice.middleware,
      issueReportApiSlice.middleware,
      receiverApiSlice.middleware,
      apartmentBookingApiSlice.middleware,
      lockersApiSlice.middleware,
      vouchersApiSlice.middleware,
    ),
});

export type RootState = ReturnType<typeof baseStore.getState>;
export type AppDispatch = typeof baseStore.dispatch;
