import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import { RootState } from "../../../../redux/store";
import { Box, styled } from "@mui/material";
import { palette } from "../../../../tenants/kolula/layout/palette";
import { MdOutlineMyLocation } from "react-icons/md";
import { setIsMapOpen } from "../../../../redux/slice/apartmentRental/apartmentRentalSlice";
import { FaListUl } from "react-icons/fa";

const NextButton = styled(CustomButton)(() => ({
  width: 210,
  borderRadius: 28,
  cursor: "pointer",
  border: "none",
  "&:hover": {
    backgroundColor: palette.secondary.contrastText,
    color: "#000000",
    border: "none",
  },
  "&:focus": {
    outline: "none",
    backgroundColor: palette.primary.light,
    color: "#000000",
  },
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
}));

interface BookLocationNavigateNextActionProps {
  isMapView?: boolean;
}

const FloatingViewMapButton: React.FC<BookLocationNavigateNextActionProps> = ({
  isMapView = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nextEnabled = useSelector(
    (state: RootState) => state.bookingCreation.nextEnabled
  );
  const { isMapOpen, locationApartmentList } = useSelector(
    (state: RootState) => state.apartment
  );

  const handleNextClick = () => {
    dispatch(setIsMapOpen(!isMapOpen));
  };

  return (
    <Box sx={{ marginBottom: "-40px", margin: "auto" }}>
      <NextButton
        disabled={locationApartmentList?.length === 0}
        key={`${nextEnabled}`} // Fixed key (removed unnecessary negation)
        variant={nextEnabled ? "outlined" : "contained"}
        onClick={handleNextClick}
        isButtonDark={true}
      >
        {isMapView ? (
          <>
            <span className="mr-4">
              <FaListUl />
            </span>
            {t("apartment.show_list")}
          </>
        ) : (
          <>
            <MdOutlineMyLocation />
            <span className="ml-4"> {t("apartment.map_view")}</span>
          </>
        )}
      </NextButton>
    </Box>
  );
};

export default FloatingViewMapButton;
