import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../layout/components/CustomButton";
import { setReceivedQRCodeNumber } from "../../../../redux/slice/itemRetrieval/itemRetrievalSlice";
import { RootState } from "../../../../redux/store";
import DebouncedTextInput from "../../common/components/PersonalDataStep/DebouncedTextInput";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";
import useVerifyLocationCode from "../../../../api/receiver/useVerifyLocationCode";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    boxSizing: "border-box",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "16px",
    left: "0",
    right: "0",
    display: "flex",
    justifyContent: "center",
    padding: "0 16px",
  },
};

function VerifyQrCodeNumber() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { receivedQRCodeNumber, isQRCodeNumberValid } = useSelector(
    (state: RootState) => state.itemRetrieval
  );
  const { handleVerifyLocationCode, isLoading } = useVerifyLocationCode();

  const onCodeChange = (code: string) =>
    dispatch(setReceivedQRCodeNumber(code));

  if (isLoading) {
    return <LoaderOverlay open={isLoading} />;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <DebouncedTextInput
          label={t("itemRetrieval.verifyQrCodeNumber.input_label")}
          initialValue={receivedQRCodeNumber}
          onChange={onCodeChange}
          valid={isQRCodeNumberValid}
        />
      </Box>

      <Box sx={styles.buttonContainer}>
        <CustomButton
          disabled={receivedQRCodeNumber?.length !== 6}
          isButtonDark
          fullWidth
          onClick={handleVerifyLocationCode}
          isOutlined
        >
          {t("itemRetrieval.verifyQrCodeNumber.next_button_title")}
        </CustomButton>
      </Box>
    </Box>
  );
}

export default VerifyQrCodeNumber;
