import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'variant', 
})<{
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'; 
}>(({ theme, variant }) => ({
  width: `calc(100% - ${theme.spacing(4.5)}px)`,
  paddingTop: theme.spacing(3),
  fontSize: theme.typography[variant].fontSize, 
  fontWeight: variant !== 'h1' ? 'bold' : 'normal',
  textAlign: 'center',
  textTransform: 'none',
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(6),
  },
}));

interface BookLocationHeaderProps {
  text: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const BookLocationHeader = ({ text, variant = 'h1' }: BookLocationHeaderProps) => {
  return <StyledText variant={variant}>{text}</StyledText>;
};

export default BookLocationHeader;
