import { Box, Button, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import moment from "moment";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { bookingsApiSlice } from "../../api/bookings/bookingsApiSlice";
import { locationsApiSlice } from "../../api/locations/locationsApiSlice";
import { useStartRental } from "../../redux/flows/startRentalFlow";
import { setBookingSummary } from "../../redux/slice/booking/bookingSlice";
import {
  setHasSelectedLocation,
  setSelectedLocation,
} from "../../redux/slice/bookingCreation/bookingCreationSlice";
import { enqueueNotification } from "../../redux/slice/notification/notificationSlice";
import { baseStore, RootState } from "../../redux/store";
import routes from "../../routes/routes";
import KolulaStorageManager from "../../storage/KolulaStorageManager";
import { BookingSummary as BookingSummaryProps } from "../../types/bookingCreation";
import { Reservation, ServiceTypeSelectorOption } from "../../types/order";
import { getIdString, transformReservations } from "../../util";
import BookingSummary from "../bookLocation/common/components/BookingSummary/BookingSummary";
import BookingSummaryNote from "../bookLocation/roomRental/BookingSummaryNote";
import CustomButton from "../layout/components/CustomButton";
import Header from "../layout/components/Header";
import LoaderOverlay from "../layout/components/LoaderOverlay";
import TenantLogo from "../layout/components/TenantLogo";
import SelectLocationDialog from "../locationSelection/SelectLocationDialog";
import ServiceTypeSelector from "./components/ServiceTypeSelector";

const styles = {
  outerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  innerBox: {
    height: "calc(100% - 90px)",
    maxWidth: 1000,
    margin: "0 auto",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    width: "200px",
    margin: "0 auto",
    marginTop: "40px",
    marginBottom: "40px",
    // Use the theme's breakpoints
    "@media (min-width:1200px)": {
      width: "300px",
    },
  },
  heading: {
    lineHeight: "28px",
    letterSpacing: "2px",
    fontWeight: "600",
  },
  buttonWrapper: {
    height: "100%",
    width: "100%",
    border: "1px solid red",
  },
};

const OrdersPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [initialServiceSelected, setInitialServiceSelected] = useState<ServiceTypeSelectorOption>();
  let { selectedBooking, bookingSummary } = useSelector((state: RootState) => state.booking);
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedServiceType } = useSelector((state: RootState) => state.booking);

  const [filteredBookingSummary, setFilteredBookingSummary] = useState<BookingSummaryProps[]>([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [openSelectLocationDialog, setOpenSelectLocationDialog] = React.useState(false);
  const dispatch = useDispatch();

  const { rentalFinished, rentalStarted, itemState } = useSelector(
    (state: RootState) => state.booking
  );

  const startRental = useStartRental(selectedBooking);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [startUsed, setStartUsed] = React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModel = () => {
    setAnchorEl(null);
  };

  const handleRetrieveBookingDetails = () => {
    navigate(routes.retrieveBookingEmailPath);
  };

  const indexDBStore = useMemo(() => new KolulaStorageManager(), []);

  function sortOrders(orders: BookingSummaryProps[]): BookingSummaryProps[] {
    return [...orders].sort((a, b) => {
      const getStatus = (order: BookingSummaryProps) => {
        const item = order.items[0];
        if (item.rental?.startedAtDate && !item.rental?.finishedAtDate) {
          return 1; // Started but not finished
        } else if (!item.rental?.startedAtDate) {
          return 2; // Not started
        } else {
          return 3; // Finished or expired
        }
      };

      const statusA = getStatus(a);
      const statusB = getStatus(b);

      if (statusA !== statusB) return statusA - statusB;

      if (statusA === 1) {
        return new Date(a.items[0].toDate).getTime() - new Date(b.items[0].toDate).getTime();
      }
      if (statusA === 2) {
        return new Date(a.items[0].fromDate).getTime() - new Date(b.items[0].fromDate).getTime();
      }
      if (statusA === 3) {
        const dateA = a.items[0].rental?.finishedAtDate || a.items[0].rental?.expirationDate;
        const dateB = b.items[0].rental?.finishedAtDate || b.items[0].rental?.expirationDate;

        return new Date(dateA).getTime() - new Date(dateB).getTime();
      }

      return 0;
    });
  }

  const filterAndSortBookingSummary = (selectedServiceType: string) => {
    if (selectedServiceType === ServiceTypeSelectorOption.ALL) {
      const sortedOrders = sortOrders(bookingSummary);
      setFilteredBookingSummary(sortedOrders || []);
      return;
    }

    const filteredOrders = bookingSummary.filter(
      (booking: any) => booking.service === selectedServiceType
    );
    const sortedAndFilteredOrders = sortOrders(filteredOrders);
    setFilteredBookingSummary(sortedAndFilteredOrders);
  };

  useEffect(() => {
    async function getLocalOrders() {
      let retrievedOrdersDetails: Array<Reservation> | null;
      try {
        if (token) {
          const result = await baseStore.dispatch(
            locationsApiSlice.endpoints.getBookingDetailsByToken.initiate({
              token,
            })
          );
          if (result.error) {
            throw new Error(
              (result.error as any).data?.message ||
                getI18n().t(`orders.retrieve_bookings_via_email.retrieval_error`)
            );
          }
          retrievedOrdersDetails = result.data;
          dispatch(
            enqueueNotification({
              severity: "success",
              message: getI18n().t(`orders.retrieve_bookings_via_email.success`),
            })
          );
        }
      } catch (error: any) {
        dispatch(
          enqueueNotification({
            severity: "error",
            message: error.message || getI18n().t(`orders.retrieve_bookings_via_email.error`),
          })
        );
      }

      const searchOrderId = searchParams.get("order_id");
      const orderIds = await indexDBStore.getActiveOrderIds();

      const fetchOrders = async () => {
        setLoadingOrders(true);

        const fetchSearchOrder = searchOrderId
          ? (async () => {
              const result: any = await baseStore.dispatch(
                bookingsApiSlice.endpoints.getUserOrder.initiate({
                  orderIds: searchOrderId,
                })
              );

              const indexDBStore = new KolulaStorageManager();
              const existingOrders = await indexDBStore.getActiveOrderIds();
              const orderId = result.data[0].id as string;

              if (existingOrders.map((orders) => orders.id).includes(orderId)) {
                return [];
              }

              if (result.data[0]) {
                await indexDBStore.insertInternalMultipleOrdersIds([orderId]);
              }

              return result?.data || [];
            })()
          : Promise.resolve([]);

        const fetchOrderIds = orderIds
          ? (async () => {
              const combineIds = [
                ...(orderIds || []),
                ...(retrievedOrdersDetails ? transformReservations(retrievedOrdersDetails) : []),
              ];
              const result: any = await baseStore.dispatch(
                bookingsApiSlice.endpoints.getUserOrder.initiate({
                  orderIds: getIdString(combineIds),
                })
              );
              return result?.data || [];
            })()
          : Promise.resolve([]);

        const [searchOrderData, orderIdsData] = await Promise.all([
          fetchSearchOrder,
          fetchOrderIds,
        ]);

        setLoadingOrders(false);

        const combinedData = [...searchOrderData, ...orderIdsData];
        dispatch(setBookingSummary(combinedData));
      };

      fetchOrders();
    }

    getLocalOrders();
  }, [indexDBStore]);

  const getInitialServiceState = (bookingSummary: BookingSummaryProps[]) => {
    const serviceMapping: Record<ServiceTypeSelectorOption, string> = {
      [ServiceTypeSelectorOption.RENTAL]: "RENTAL",
      [ServiceTypeSelectorOption.APARTMENT]: "APARTMENT",
      [ServiceTypeSelectorOption.STORAGE]: "STORAGE",
      [ServiceTypeSelectorOption.P2P]: "P2P",
      [ServiceTypeSelectorOption.ALL]: "ALL",
      [ServiceTypeSelectorOption.PARCEL]: "PARCEL",
    };
    const lastCreated = bookingSummary.reduce((latest, current) =>
      new Date(current.createdDate) > new Date(latest.createdDate) ? current : latest
    );

    return serviceMapping[lastCreated.service as ServiceTypeSelectorOption];
  };

  useEffect(() => {
    if (!initialServiceSelected && bookingSummary.length > 0) {
      const initialService = getInitialServiceState(
        bookingSummary
      ) as keyof typeof ServiceTypeSelectorOption;
      setInitialServiceSelected(ServiceTypeSelectorOption[initialService]);
    }
    filterAndSortBookingSummary(selectedServiceType);
  }, [bookingSummary, selectedServiceType]);

  const handleClickOpen = () => {
    navigate(routes.home);
  };

  const handleUsingOrder = () => {
    if (!rentalStarted) {
      return startUsingOrder();
    } else {
      if (selectedBooking) {
        return navigate(routes.makeOrderDetailsUrl(selectedBooking.id));
      }
    }
  };

  const checkIfOrderCanBeStarted = (order: BookingSummaryProps) => {
    const currentLocationTime = new Date();
    const startDate = moment(order.items[0].fromDate);
    const endDate = moment(order.items[0].toDate);
    const locationDate = moment(currentLocationTime);
    return startDate >= locationDate || endDate < locationDate;
  };

  const handleClose = () => {
    dispatch(setHasSelectedLocation(false));
    dispatch(setSelectedLocation(null));
    setOpenSelectLocationDialog(false);
  };

  const renderRightButton = () => {
    if (!rentalStarted && !rentalFinished) {
      return t("book_location.orders_page.start_using");
    } else {
      return t("book_location.orders_page.open_order");
    }
  };

  const disableButton = () => {
    if (rentalFinished || ["item_retrieved_confirmed"].includes(itemState ?? "")) {
      return true;
    }
    if (
      itemState === "item_placement_confirmed" &&
      selectedBooking &&
      selectedBooking.service === "p2p"
    ) {
      return true;
    }
    if (selectedBooking) {
      return checkIfOrderCanBeStarted(selectedBooking);
    }
  };

  const startUsingOrder = async () => {
    setLoading(true);
    await startRental();
    setStartUsed(true);
  };

  useEffect(() => {
    if (rentalStarted && selectedBooking && startUsed) {
      setLoading(false);
      navigate(routes.makeOrderDetailsUrl(selectedBooking.id || ""));
      setStartUsed(false);
    } else if (selectedBooking && startUsed && rentalStarted === false) {
      setStartUsed(false);
      setLoading(false);
    }
  }, [rentalStarted, startUsed]);

  if (loadingOrders) {
    return <LoaderOverlay open={loadingOrders} />;
  }

  return (
    <Box sx={styles.outerBox}>
      <Box sx={styles.innerBox}>
        <Header>
          <TenantLogo />
        </Header>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <Typography variant="h1" sx={styles.heading}>
            {t("orders.ticket_overview.header")}
          </Typography>

          <Button
            onClick={handleClick}
            sx={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.action.selected,
              textTransform: "none",
              marginLeft: "16px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              fontWeight: "bold",
              height: "36px",
              padding: "6px 16px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
              },
            }}
          >
            {t("orders.button.add")} <MdKeyboardArrowDown size={20} />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseModel}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleRetrieveBookingDetails}>
              {t("orders.button.previous")}
            </MenuItem>
            <MenuItem onClick={handleClickOpen}>{t("orders.button.new")}</MenuItem>
          </Menu>
        </Box>
        <Box>
          <ServiceTypeSelector
            initialSelected={initialServiceSelected ?? ServiceTypeSelectorOption.RENTAL}
            bookingSummary={bookingSummary || []}
          />
        </Box>
        {filteredBookingSummary?.length > 0 ? (
          <BookingSummary userReservation={filteredBookingSummary} />
        ) : (
          <Typography
            variant="h5"
            sx={{ marginTop: "52px", display: "grid", justifyItems: "center" }}
          >
            {t("orders.ticket_overview.no_booking")}
          </Typography>
        )}
      </Box>
      {filteredBookingSummary?.length > 0 && (
        <div className="flex justify-center w-full px-5 mb-20 above-iphone-se:fixed above-iphone-se:bottom-0">
          {selectedServiceType === ServiceTypeSelectorOption.APARTMENT ? (
            <BookingSummaryNote />
          ) : (
            <CustomButton
              isButtonDark={true}
              fullWidth={true}
              onClick={handleUsingOrder}
              disabled={disableButton()}
              children={renderRightButton()}
            />
          )}
        </div>
      )}

      <SelectLocationDialog
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        openSelectLocationDialog={openSelectLocationDialog}
      />
      <LoaderOverlay open={loading} />
    </Box>
  );
};

export default OrdersPage;
