import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, TextFieldProps, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomButton from '../../../../layout/components/CustomButton';
import { useState } from 'react';
import { useApplyCoupon } from '../../../../../redux/flows/applyCouponFlow';
import { RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import LoaderButton from '../../../../layout/components/LoaderButton';
import { couponRedemptionLoading } from '../../../../../redux/slice/bookingCreation/bookingCreationSlice';

type StyledTextFieldProps = TextFieldProps & {
    valid?: boolean;
};

const StyledTextField = styled(TextField)<StyledTextFieldProps>(({ theme, valid }) => ({
    marginBottom: theme.spacing(2),
    zIndex: 0,
    '& .MuiInputBase-input': {
        color: theme.palette.primary.main,
        borderRadius: 0,
        /* Prevent weird input color change due to autofill */
        '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 30px var(--dark-background-color) inset !important',
            boxShadow: '0 0 0 30px var(--dark-background-color) inset !important',
            backgroundColor: 'var(--dark-background-color)',
            WebkitTextFillColor: 'white',
            backgroundClip: 'content-box !important',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: valid ? theme.palette.primary.light : theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.light,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.light,
        },
    },
    '& .MuiInputBase-root': {
        color: theme.palette.primary.main,
    },
    '& .MuiInputLabel-outlined': {
        color: theme.palette.primary.main,
        opacity: 0.8,
    },
}));

const DialogText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const CancelButton = styled(CustomButton)(({ theme }) => ({
    width: '109px',
    height: '44px',
    gap: '10px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
        backgroundColor: 'inherit', // Disable background color change on hover
        borderColor: theme.palette.primary.light, // Keep the same border color
        boxShadow: 'none', // Disable any box-shadow on hover
    },
}));


const ConfirmButton = styled(LoaderButton)(({ theme }) => ({
    width: '100px',
    height: '44px',
    gap: '10px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: theme.palette.primary.light, // Disable background color change on hover
        boxShadow: 'none', // Disable any box-shadow on hover
    },
}));


interface ApplyCouponDialogProps {
    open: boolean;
    content: any;
    onClose: () => void;
}

const CouponDialog = (props: ApplyCouponDialogProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const reservations = useSelector((state: RootState) => state.bookingCreation.reservations);
    const [couponCode, setCouponCode] = useState<string>('');
    const isCouponReedemptionLoading = useSelector((state: RootState) => state.bookingCreation.loading.couponRedemption)
    const applyCoupon = useApplyCoupon()

    const onApplyCoupon = async () => {
        dispatch(couponRedemptionLoading({ loading: true }))
        
        const reservationIds = reservations.map((reservation: any) => {return reservation.reservationId})

        await applyCoupon(reservationIds, couponCode)

        setCouponCode('')
        props.onClose();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <Box sx={{ width: '100%' }}>

                <DialogTitle>
                    <DialogText variant="h6">
                        {t('book_location.confirmation.redeem')}
                    </DialogText>
                </DialogTitle>
                <DialogContent sx={{ minWidth: '0px', maxWidth: '100%', width: '350px' }}>
                    <StyledTextField
                        value={couponCode}
                        onChange={event => setCouponCode(event.target.value)}
                        variant={'outlined'}
                        label={t('book_location.order_data.coupon_code')}
                        color={'secondary'}
                        fullWidth={true}
                        size={'medium'}
                        sx={{ marginTop: '8px' }}
                        className={"coupon-code"}
                    />
                </DialogContent>
                <DialogActions sx={{ paddingX: '20px' }}>
                    <CancelButton
                        isButtonDark={false}
                        fullWidth
                        variant="outlined"
                        onClick={() => props.onClose()}
                        color="secondary"
                    >
                        {t('use_order.stop_using.cancel_button')}
                    </CancelButton>
                    <ConfirmButton
                        isButtonDark={true}
                        loading={isCouponReedemptionLoading}
                        fullWidth
                        variant="contained"
                        onClick={onApplyCoupon}
                        className={"confirmButton"}
                    >
                        {t('book_location.confirmation.redeem')}
                    </ConfirmButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
};


export default CouponDialog;
