import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderOverlay from "../../../layout/components/LoaderOverlay";
import BookLocationHeader from "../../common/util/BookLocationHeader";
import TenantLogo from "../../../layout/components/TenantLogo";
import GeneralDialog from "../../../common/GeneralDialog";
import { Box } from "@mui/material";
import { RootState } from "../../../../redux/store";
import {
  bookingCreationCompleted,
  setUseCase,
} from "../../../../redux/slice/bookingCreation/bookingCreationSlice";
import { styled } from "@mui/system";
import {
  itemRetrievalFlowChanged,
  itemRetrievalStepChanged,
} from "../../../../redux/slice/itemRetrieval/itemRetrievalSlice";
import PickupDetails from "../../p2p/itemRetrieval/PickupDetails";
import EmailVerification from "./EmailVerification";
import CodeVerification from "./CodeVerification";
import LockerDetails from "./LockerDetails";
import LockerOpen from "./LockerOpen";
import SuccessMessage from "./SuccessMessage";
import routes from "../../../../routes/routes";
import ItemRetrievalNavigateBackAction from "../../common/util/ItemRetrievalNavigateBackAction";
import FailureMessage from "./FailureMessage";
import VerifyQrCodeNumber from "./VerifyQrCodeNumber";
import QRVerification from "./QRVerification";

export enum ItemRetrievalSteps {
  pickupDetails,
  confirmLocation,
  verifyQrCodeNumber,
  emailVerification,
  codeVerification,
  lockerDetails,
  lockerOpen,
  successMessage,
  failureMessage,
}

const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  color: "white",
  height: "100%",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.default,
  paddingBottom: `calc(${theme.spacing(1)}px + env(safe-area-inset-bottom, 0))`,
  display: "flex",
  flexDirection: "column",
}));

const HeaderWrapper = styled(Box)({
  position: "relative",
  marginTop: "20px",
});

const StepWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "100%",
  maxWidth: 1000,
  margin: "0 auto",
  overflowY: "auto",
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(6),
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  width: "150px",
  marginTop: theme.spacing(1),
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
}));

const BackButton = styled(Box)({
  boxSizing: "border-box",
  marginRight: "auto",
});

export const CancelButton = styled(Box)({
  boxSizing: "border-box",
  marginLeft: "auto",
});

const ItemRetrievalPage = () => {
  const stepWrapperRef = useRef<HTMLElement>();
  const [pageDialogOpen, setPageDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const step = useSelector((state: RootState) => state.itemRetrieval.step);

  useEffect(() => {
    stepWrapperRef.current = document.getElementById(
      "ItemRetrievalProcessStepWrapper"
    )!;

    if (step === ItemRetrievalSteps.pickupDetails) {
      dispatch(
        itemRetrievalStepChanged({
          step: ItemRetrievalSteps.pickupDetails,
          nextEnabled: true,
        })
      );
    }
    // Set the usecase
    dispatch(setUseCase({ useCase: "p2p" }));
  
    // Set the bookingFlow
    dispatch(
      itemRetrievalFlowChanged({
        itemRetrievalFlow: [
          "pickupDetails",
          "confirmLocation",
          "verifyQrCodeNumber",
          "emailVerification",
          "codeVerification",
          "lockerDetails",
          "lockerOpen",
          "successMessage",
          "failureMessage",
        ],
      })
    );
  }, []);
  

  const headerText = useMemo(() => {
    switch (step) {
      case ItemRetrievalSteps.pickupDetails:
        return t("itemRetrieval.pickupDetails.header_text");
      case ItemRetrievalSteps.confirmLocation:
        return t("itemRetrieval.confirmLocation.header_text");
      case ItemRetrievalSteps.verifyQrCodeNumber:
        return t("itemRetrieval.verifyQrCodeNumber.header_text");
      case ItemRetrievalSteps.emailVerification:
        return t("itemRetrieval.emailVerification.header_text");
      case ItemRetrievalSteps.codeVerification:
        return t("itemRetrieval.codeVerification.header_text");
      case ItemRetrievalSteps.lockerDetails:
        return t("itemRetrieval.lockerDetails.header_text");
      case ItemRetrievalSteps.lockerOpen:
        return t("itemRetrieval.lockerOpen.header_text");
      case ItemRetrievalSteps.successMessage:
        return "";

      default:
        return "";
    }
    // }, [step, orderData.date, braintreePayment.paymentToken, paymentInitFailed]);
  }, [step]);

  const isLoading = () => {
    if (step === ItemRetrievalSteps.pickupDetails) {
      return false;
    }

    return false;
  };

  const closePage = () => {
    navigate(routes.home);
    dispatch(bookingCreationCompleted());
  };

  const isLastStep = step === ItemRetrievalSteps.successMessage;
  return (
    <Root>
      <HeaderWrapper>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <BackButton>
            {!isLastStep && <ItemRetrievalNavigateBackAction />}
          </BackButton>
          <LogoBox>
            <TenantLogo />
          </LogoBox>

          <Box className="w-3 h-10"></Box>
        </Box>
        <Box sx={{ marginTop: "20px", marginBottom: "30px" }}>
          <BookLocationHeader text={headerText} />
        </Box>
      </HeaderWrapper>

      <StepWrapper id={"ItemRetrievalProcessStepWrapper"}>
        {step === ItemRetrievalSteps.pickupDetails && <PickupDetails />}
        {step === ItemRetrievalSteps.confirmLocation && <QRVerification />}
        {step === ItemRetrievalSteps.verifyQrCodeNumber && (
          <VerifyQrCodeNumber />
        )}
        {step === ItemRetrievalSteps.emailVerification && <EmailVerification />}
        {step === ItemRetrievalSteps.codeVerification && <CodeVerification />}
        {step === ItemRetrievalSteps.lockerDetails && <LockerDetails />}
        {step === ItemRetrievalSteps.lockerOpen && <LockerOpen />}
        {step === ItemRetrievalSteps.successMessage && <SuccessMessage />}
        {step === ItemRetrievalSteps.failureMessage && <FailureMessage />}
      </StepWrapper>

      <LoaderOverlay open={isLoading()} />
      <GeneralDialog
        open={pageDialogOpen}
        onClose={() => setPageDialogOpen(false)}
        onContinue={closePage}
        content={t("use_order.closing_dialog.content")}
      />
    </Root>
  );
};

export default ItemRetrievalPage;
