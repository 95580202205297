import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from './Toolbar';

interface MainLayoutProps {
    children: React.ReactNode;
    showToolBar?: boolean;
}

const MainLayout = (props: MainLayoutProps) => {
    const contentHeight = props.showToolBar ? 'calc(100% - 59px)' : '100%';
    return (
        <Box sx={{ height: '100%' }}>
            <Box className="max-w-md mx-auto"
                sx={{
                    height: contentHeight,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {props.children}
            </Box>
            <div className='fixed inset-x-0 bottom-0'>
                {props.showToolBar && <Toolbar />}
            </div>
        </Box>
    );
};

export default MainLayout;
