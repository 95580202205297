import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import LoaderOverlay from "../../layout/components/LoaderOverlay";
import _ from "lodash";
import ApartmentListCard from "../common/components/ApartmentRental/ApartmentListCard";
import {
  setNextButtonClick,
  setSelectedApartment,
} from "../../../redux/slice/apartmentRental/apartmentRentalSlice";
import FullScreenDialog from "../../common/FullScreenDialog";
import MapView from "./MapView";
import { Apartment } from "../../../types/apartments";
import NoResult from "../../layout/components/NoResult";
import { setSelectedLocation } from "../../../redux/slice/bookingCreation/bookingCreationSlice";

function ApartmentSelectionStep() {
  const { locationApartmentList, isMapOpen } = useSelector(
    (state: RootState) => state.apartment
  )!;
  const dispatch = useDispatch();

  const roomRentalLocation = useSelector(
    (state: RootState) => state.bookingCreation.roomRentalLocation
  );
  const locations = useSelector(
    (state: RootState) => state.booking.useCaseLocations
  );
  const loader = useSelector(
    (state: RootState) => state.bookingCreation.loader
  );

  const isLoading = _.isEmpty(locationApartmentList);

  const handleSelectApartment = (apartment: Apartment) => {
    dispatch(setSelectedApartment(apartment));
    if (roomRentalLocation.district === 'all') {
      const chosenLocation = locations.find(
        (loc: any) => loc.id === apartment.locationId
      );
      dispatch(setSelectedLocation(chosenLocation!)); // Todo: refactor to not use !
    }
    dispatch(setNextButtonClick());
  };


  if (isLoading || loader) {
    return <LoaderOverlay open={isLoading} />;
  } else {
    return (
      <div
        style={{
          maxHeight: "98%", // Set your desired max height
          overflowY: "auto", // Enable vertical scrolling
          marginLeft: "16px", // Add margin to the left
          marginRight: "16px", // Add margin to the right
        }}
      >
        {locationApartmentList?.length >= 1 ? (
          <>
            {locationApartmentList?.map((apartment: any) => (
              <div key={apartment.description}>
                <ApartmentListCard
                  data={apartment}
                  onSelectApartment={handleSelectApartment}
                />
              </div>
            ))}
          </>
        ) : (
          <NoResult />
        )}

        <FullScreenDialog open={isMapOpen}>{<MapView />}</FullScreenDialog>
      </div>
    );
  }
}

export default ApartmentSelectionStep;
