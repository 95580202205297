// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store"; // Adjust the import based on your file structure
import { CompleteStripePaymentData } from "../../api/types";
import Logger from "../helpers/logger";
import ErrorTracker from "../helpers/errorTracker";
import {
  paymentCompleted,
  paymentCompletionBegan,
  paymentCompletionFailed,
} from "../slice/bookingCreation/bookingCreationSlice";

import { useCompleteNoPaymentMutation } from "../../api/payments/paymentsApiSlice";
import { useCallback } from "react";
import KolulaStorageManager from "../../storage/KolulaStorageManager";
import { enqueueNotification } from "../slice/notification/notificationSlice";
import { t } from "i18next";
import { Order } from "../../types/order";

export const useCompleteNoPayment = () => {
  const contact = useSelector(
    (state: RootState) => state.bookingCreation.personalData
  );

  const [completeNoPaymentMutation] = useCompleteNoPaymentMutation();
  const dispatch = useDispatch();

  // Use useCallback to memoize the completeStripePayment function
  return useCallback(
    async (reservationIds: string[]) => {
      const indexDBStore = new KolulaStorageManager();

      dispatch(paymentCompletionBegan());

      try {
        if (reservationIds.length && contact) {
          const payload: CompleteStripePaymentData = {
            reservationIds: reservationIds,
          };

          const response = (await completeNoPaymentMutation(
            payload
          ).unwrap()) as Order[];

          const orderIds = response.map((order) => order.id);

          if (orderIds.length) {
            await indexDBStore.insertInternalMultipleOrdersIds(orderIds);

            dispatch(paymentCompleted(orderIds));
            dispatch(
              enqueueNotification({
                severity: "success",
                message: t("book_location.payment.success"),
              })
            );
          }

          return { orderId: `${orderIds.join(",")}`, orderIds };
        }
      } catch (error) {
        dispatch(paymentCompletionFailed());
        dispatch(
          enqueueNotification({
            severity: "error",
            message: t("book_location.payment.error"),
          })
        );

        Logger.for("createNewReservation").error(error);
        ErrorTracker.trackException(error as Error);
        // Handle the error state or dispatch error actions if necessary
      }
    },
    [contact, completeNoPaymentMutation, dispatch]
  );
};
