// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from 'react-redux';
import { baseStore, RootState } from '../store'; // Adjust the import based on your file structure
import moment from 'moment';
import { CreateNewReservationData } from '../../api/types';
import Logger from '../helpers/logger';
import ErrorTracker from '../helpers/errorTracker';
import { locationsApiSlice, useGetLocationEquipmentQuery } from '../../api/locations/locationsApiSlice';
import { setAllDepotsByLocation, setAllGroupsByLocation } from '../slice/booking/bookingSlice';


const fetchAllDepotsCall = async(locationId: string) => {    
    const data = await baseStore.dispatch(
        locationsApiSlice.endpoints.getDepotByLocationId.initiate({
            locationId: locationId,
        })
    )
    return data;
};

export const useFetchAllDepots = () => {
  const locations = useSelector((state: RootState) => state.booking.locations);
  const dispatch = useDispatch();

  const fetchAllDepots = async () => {
    try {
        if (locations && locations.length > 0) {
            const locationIds = locations.map((location: any) => location.id);
            console.log(locationIds);
            const allDepotsByLocation: { [key: string]: any } = {};

            // Loop over each locationId and make an API call for each one
            for (const locationId of locationIds) {
                const { data: depots } = await fetchAllDepotsCall(locationId); // API call for each locationId
                console.log(`Depots fetched successfully for locationId ${locationId}`, depots);
                
                if (depots) {
                    allDepotsByLocation[locationId] = depots; 
                }
            }

            // Dispatch action after all groups are fetched
            dispatch(setAllDepotsByLocation({ depots: allDepotsByLocation }));
        }
    } catch (error) {
      Logger.for('createNewReservation').error(error);
      ErrorTracker.trackException(error as Error);
      // Handle the error state or dispatch error actions if necessary
    }
  };

  return fetchAllDepots;
};
