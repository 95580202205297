import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../util/axiosBaseQuery";
import { OrderId } from "../../types/order";

export const rentalsApiSlice = createApi({
  reducerPath: "rentalsApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["rentalStart", "rentalFinish"],
  endpoints: (builder) => ({
    startRental: builder.mutation({
      query: ({ orderId, items }: { orderId: OrderId; items: string[] }) => {
        return {
          url: `/rental/start`,
          method: "POST",
          data: {
            orderId: orderId,
            orderItems: items,
          },
        };
      },
      invalidatesTags: ["rentalStart"],
    }),
    finishRental: builder.mutation({
        query: ({ orderId, items, token }: { orderId: OrderId; items: string[], token: string }) => {
          return {
            url: `/rental/finish/${orderId}`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              orderId: orderId,
              orderItems: items,
            },
          };
        },
        invalidatesTags: ["rentalFinish"], 
    }),
    updateItemState: builder.mutation({
      query: ({ orderId, items, token, state }: { orderId: OrderId; items: string[], state: string, token: string }) => {
        return {
          url: `/rental/update-item/${orderId}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            orderId: orderId,
            orderItems: items,
            state: state,
          },
        };
      },
  }),
  }),
});

export const { useStartRentalMutation, useFinishRentalMutation, useUpdateItemStateMutation } =
rentalsApiSlice;
