import React from 'react'
import { EquipmentListProps } from '../../types/baseTypes'
import EquipmentSelect from './components/shared/EquipmentSelect'
import CustomButton from '../layout/components/CustomButton'
import { useTranslation } from 'react-i18next';

const viewportWidth = window.innerWidth;


interface Props {
    equipments: EquipmentListProps[]
}
function LocationListComponent({ equipments }: Props) {
    const { t } = useTranslation();

    return (
        <div>
            {equipments?.map((item, index) => {
                return <div key={index}>
                    <EquipmentSelect equipment={item} priceCategoryInfo={item?.priceCategoryInfo} image={item?.featuredImage} name={item?.name} />
                    <div className='w-full my-4 border-b-2 border-b-itemListBorder'>

                    </div>
                </div>
            })}

            <div className='mt-8'>
                <CustomButton
                    isButtonDark={true}
                    fullWidth={true}
                    onClick={() => { }}
                    children={t('book_location.next')}
                    minWidth={viewportWidth * .8}

                />
            </div>
        </div>
    )
}

export default LocationListComponent