import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import EquipmentListAccordion from "../common/components/LocationSelectionStep/EquipmentListAccordion";
import {
  assetGroupActivated,
  assetGroupDeactivated,
  setSelectedGroup,
} from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import LoaderOverlay from "../../layout/components/LoaderOverlay";
import { EquipmentListProps } from "../../../types/baseTypes";
import { Reservation } from "../../../types/bookingCreation";
import _ from "lodash";

function EquipmentSelectionStep() {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  );
  // const { data: equipments } = useGetLocationEquipmentQuery({ locationId: selectedLocation?.id });
  const equipments = useSelector((state: RootState) =>
    selectedLocation
      ? state.bookingCreation.groupsByLocation[selectedLocation.id]
      : undefined
  );
  const isLoading = _.isEmpty(equipments);

  const reservations = useSelector(
    (state: RootState) => state.bookingCreation.reservations
  )!;

  const selectedEquipment = useSelector(
    (state: RootState) => state.bookingCreation.selectedGroup
  );

  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    selectedEquipment ? selectedEquipment.id : null
  ); // Track the currently expanded accordion

  function filterEquipment(
    equipments: EquipmentListProps[],
    reservations: Reservation[]
  ): EquipmentListProps[] {
    // Create a Set of selectedPersons' ids for efficient lookup
    const selectedIds = new Set(
      reservations?.map(
        (reservation) => reservation.reservationItems?.[0].groupId
      )
    );

    // Return persons whose id is not in the selectedIds
    return equipments?.filter((equipment) => !selectedIds?.has(equipment.id));
  }

  const selectedIds = new Set(
    reservations?.map(
      (reservation: any) => reservation.reservationItems?.[0].groupId
    )
  );

  const handleAccordionChange = (equipment: any) => {
    setExpandedAccordion(
      expandedAccordion === equipment.id ? null : equipment.id
    ); // Toggle the expanded accordion
    if (expandedAccordion === equipment.id) {
      dispatch(assetGroupDeactivated());
    } else {
      dispatch(assetGroupActivated(equipment));
      dispatch(setSelectedGroup(equipment));
    }
  };

  return (
    <div
      style={{
        maxHeight: "98%", // Set your desired max height
        overflowY: "auto", // Enable vertical scrolling
        marginLeft: "16px", // Add margin to the left
        marginRight: "16px", // Add margin to the right
      }}
    >
      {equipments?.map((item: any, index: number) =>
        item.priceCategoryInfo ? (
          <div key={index}>
            <EquipmentListAccordion
              key={item.id}
              expanded={expandedAccordion === item.id} // Pass down the expanded state
              onAccordionChange={() => handleAccordionChange(item)} // Pass down the change handler
              equipment={item}
              priceCategoryInfo={item?.priceCategoryInfo}
              image={item?.featuredImage}
              name={item?.name}
              langNames={item?.langNames}
            />
          </div>
        ) : null
      )}
      <LoaderOverlay open={isLoading} />
    </div>
  );
}

export default EquipmentSelectionStep;
