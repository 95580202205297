import { Box, Grow, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
// import Assets from '../../../../assets/assets';
import { OrderId } from '../../../../types/order';
import KoluluBoxInfoBox from '../KolulaBoxInfoBox';
import CP82BoxDoor from './CP82BoxDoor';

const Root = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    width: '243px',
    height: '302px',
    margin: '0 auto',
    padding: '0px',
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: '8px',
    border: '1px solid white',
    overflow: 'hidden', // Add overflow for scrollbars
    transform: 'scale(135%) translateY(45px)',
}));

const Rectangle = styled(Box)(({ theme }) => ({
    width: '243px',
    height: '30px',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
}));

const Info = styled(IconButton)(({ theme }) => ({
    color: theme.palette.background.default,
    width: '30px',
    height: '30px',
    position: 'absolute',
    right: 5,
    top: 0,
}));

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '13px',
    fontWeight: 'bold',
    marginLeft: '10px',
}));

const ShadowOverlay = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 9,
}));

interface CP82BoxProps {
    orderId?: OrderId;
    activeDoors: number[];
    title: string;
}

const CP82Box = (props: CP82BoxProps) => {
    // const InfoIcon = Assets.icon.infoIcon.component;
    const [infoOpen, setInfoOpen] = useState<boolean>(false);
    const activeDoor = props.activeDoors[0]; // Select first item in available doors
    const enableInfo = Boolean(props.orderId);
    
    const getLeftSize = (columnOrder: number) => {
        if (columnOrder === 1) {
            return 0;
        } else if (columnOrder === 2) {
            return 61;
        } else if (columnOrder === 3) {
            return 122;
        } else {
            return 183;
        }
    };
    
    const renderColumnWithCU = (
        activeDoor: number, 
        columnOrder: number, 
        size: number, 
        doorsHeight: number, 
        firstDoorNumber: number,
        cuHeight: number,
        cuNumber: number,
    ) => {
        const leftSize = getLeftSize(columnOrder);
        const doors = [];
        let topOffset = 32;

        for (let i = 0; i < size; i++) {
            if (i === cuNumber - 1){
                doors.push({ top: topOffset, height: cuHeight, doorNumber: cuNumber });
                topOffset += cuHeight + 1;
            } else {
                doors.push({ top: topOffset, height: doorsHeight, doorNumber: firstDoorNumber + i });
                topOffset += doorsHeight + 1;
            }
        }

        return doors.map((door, index) => (
            <CP82BoxDoor
                key={door.doorNumber}
                top={door.top}
                left={leftSize ?? 0}
                height={door.height}
                doorNumber={door.doorNumber}
                active={
                    ((activeDoor - firstDoorNumber + 1 < cuNumber) && (activeDoor - firstDoorNumber === index)) 
                        ? 
                        true 
                        :
                        ((activeDoor - firstDoorNumber + 1 > cuNumber - 1) 
                        && (activeDoor - firstDoorNumber + 1 === index)) 
                            ? true 
                            : false
                }
            />
        ));
    };
    
    const renderColumn = (
        activeDoor: number, 
        columnOrder: number, 
        size: number, 
        doorsHeight: number, 
        firstDoorNumber: number,
    ) => {
        const leftSize = getLeftSize(columnOrder);
        return Array.from({ length: size }, (_, index) => {
            const top = 32 + index * (doorsHeight + 1);
            const doorNumber = firstDoorNumber + index;
            return (
                <CP82BoxDoor 
                    key={doorNumber} 
                    top={top} 
                    left={leftSize ?? 0} 
                    height={doorsHeight} 
                    doorNumber={doorNumber} 
                    active={index + 1 === activeDoor - firstDoorNumber + 1 ? true : false} 
                />
            );
        });
    };

    const renderFirstColumn = (activeDoor: number, columnOrder: number) => {
        const size = 6; // Number of doors in the column       
        const doorsHeight = 44; 
        const firstDoorNumber = 77;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    
    const renderSecondColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;       
        const doorsHeight = 53; 
        const firstDoorNumber = 72;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderThirdColumn = (activeDoor: number, columnOrder: number) => {
        const size = 6;        
        const doorsHeight = 44; 
        const firstDoorNumber = 66;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderFourthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 61;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderFifthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 6;        
        const doorsHeight = 44; 
        const firstDoorNumber = 55;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderSixthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 50;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderSeventhColumn = (activeDoor: number, columnOrder: number) => {
        const size = 6;        
        const doorsHeight = 44; 
        const firstDoorNumber = 44;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderEighthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 39;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderNinthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 6;        
        const doorsHeight = 44; 
        const firstDoorNumber = 33;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };
    const renderTenthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 5;        
        const doorsHeight = 53; 
        const firstDoorNumber = 28;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };

    const renderEleventhColumn = (activeDoor: number, columnOrder: number) => {
        const size = 12;        
        const doorsHeight = 21.5; 
        const firstDoorNumber = 16;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };

    const renderTwelfthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 8;        
        const doorsHeight = 32.75; 
        const firstDoorNumber = 8;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };

    const renderThirteenthColumn = (activeDoor: number, columnOrder: number) => {
        const size = 8;        
        const doorsHeight = 33.5; 
        const firstDoorNumber = 0;
        return renderColumn(activeDoor, columnOrder, size, doorsHeight, firstDoorNumber);
    };

    return (
        <Root>
            <Rectangle>
                <Title>{props.title}</Title>
                {enableInfo && (
                    <Info onClick={() => setInfoOpen(true)}>
                        {/* <InfoIcon /> */}
                    </Info>
                )}
            </Rectangle>
            <Box sx={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }}>
                {renderFirstColumn(props.activeDoors[0], 1)}
                {renderSecondColumn(props.activeDoors[0], 2)}
                {renderThirdColumn(props.activeDoors[0], 3)}
                {renderFourthColumn(props.activeDoors[0], 4)}
                {renderFifthColumn(props.activeDoors[0], 5)}
                {renderSixthColumn(props.activeDoors[0], 6)}
                {renderSeventhColumn(props.activeDoors[0], 7)}
                {renderEighthColumn(props.activeDoors[0], 8)}
                {renderNinthColumn(props.activeDoors[0], 9)}
                {renderTenthColumn(props.activeDoors[0], 10)}
                {renderEleventhColumn(props.activeDoors[0], 11)}
                {renderTwelfthColumn(props.activeDoors[0], 12)}
                {renderThirteenthColumn(props.activeDoors[0], 13)}
            </Box>
            <Grow in={infoOpen} style={{ transformOrigin: '0 0 0' }}>
                <ShadowOverlay onClick={() => setInfoOpen(false)}>
                    <KoluluBoxInfoBox onClose={() => setInfoOpen(false)} orderId={props.orderId!} />
                </ShadowOverlay>
            </Grow>
        </Root>
    );
};

export default CP82Box;
