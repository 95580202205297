import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CollapseIcon from "./../../../../../assets/icons/collapse_icon.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "transparent",
  border: "none",
  p: 2,
};

interface Props {
  image: string;
  openImage: boolean;
  setOpenImage: (value: boolean) => void;
}

export default function FullLockerView({
  image,
  openImage,
  setOpenImage,
}: Props) {
  const handleClose = () => setOpenImage(false);

  return (
    <div>
      <Modal
        open={openImage}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="relative">
            <button
              onClick={handleClose}
              className="absolute bg-[#00000080] h-6 w-6 
              rounded-md right-2 top-2 flex items-center justify-center"
            >
              <img
                className="h-4 object-cover "
                src={CollapseIcon}
                alt="locker"
              />
            </button>
            <img
              className="h-[70vh] w-full object-cover rounded-md"
              src={image}
              alt="locker"
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
