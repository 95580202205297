import React from 'react';

interface LocationIconProps {
  color?: string;
  fill?: string;
  size?: number;
  isActive?: boolean;
}

export const LocationIcon: React.FC<LocationIconProps> = ({ 
  color = '#666666',
  fill = 'none',
  size = 32 ,
}) => {
  return (
    <svg 
      width={size} 
      height={size} 
      viewBox="0 0 32 32" 
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
         d="M12.5 9.0002V20.0002M20.5 12.0002V23.0002M21.1708 27.6648L27.6708 24.4148C28.179 24.1607 28.5 23.6413 28.5 23.0731V6.42725C28.5 5.31217 27.3265 4.58693 26.3292 5.08561L21.1708 7.66478C20.7485 7.87593 20.2515 7.87593 19.8292 7.66478L13.1708 4.33561C12.7485 4.12446 12.2515 4.12446 11.8292 4.33561L5.32918 7.58561C4.821 7.83969 4.5 8.35909 4.5 8.92725V25.5731C4.5 26.6882 5.67347 27.4135 6.67082 26.9148L11.8292 24.3356C12.2515 24.1245 12.7485 24.1245 13.1708 24.3356L19.8292 27.6648C20.2515 27.8759 20.7485 27.8759 21.1708 27.6648Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
