const CONSENT_KEY = 'userConsent';
const CONSENT_EXPIRY_KEY = 'consentExpiry';
const CONSENT_DURATION_MS = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds

export const saveConsent = (value: boolean): void => {
    const expiryTime = Date.now() + CONSENT_DURATION_MS;
    localStorage.setItem(CONSENT_KEY, JSON.stringify(value));
    localStorage.setItem(CONSENT_EXPIRY_KEY, expiryTime.toString());
};

export const getConsent = (): boolean | null => {
    const consent = localStorage.getItem(CONSENT_KEY);
    const expiry = localStorage.getItem(CONSENT_EXPIRY_KEY);

    if (!consent || !expiry) {
        return null; 
    }

    const now = Date.now();
    if (now > parseInt(expiry)) {
        removeConsent();
        return null;
    }

    return JSON.parse(consent);
};

export const hasConsent = (): boolean => {
    return getConsent() !== null;
};

export const removeConsent = (): void => {
    localStorage.removeItem(CONSENT_KEY);
    localStorage.removeItem(CONSENT_EXPIRY_KEY);
};
