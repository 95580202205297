import React from "react";
import { BookingStatusType } from "../../../../../types/baseTypes";
import { useTranslation } from "react-i18next";

interface Props {
  status: BookingStatusType;
}
function BookingStatus({ status }: Props) {
  const { t } = useTranslation();

  function bookingStatusLabel() {
    if (status === "active") {
      return t("orders.ticket_overview.status.active");
    } else if (status === "booked") {
      return t("orders.ticket_overview.status.booked");
    } else {
      return t("orders.ticket_overview.status.finished");
    }
  }
  return (
    <div
      className={`flex w-[90px] font-light text-sm ${
        status === "active"
          ? "bg-appLightBlue"
          : status === "booked"
          ? "bg-lightGreen"
          : "bg-lightGray"
      } items-center 
    justify-center h-6 rounded-3xl`}
    >
      <div
        className={`w-2.5 h-2.5 rounded-full ${
          status === "active" ? "bg-appBlue" : status === "booked" ? "bg-appGreen" : "bg-deepGray"
        } mr-2.5`}
      ></div>
      <div
        className={`font-sm ${
          status === "active"
            ? "text-appBlue"
            : status === "booked"
            ? "text-appGreen"
            : "text-deepGray"
        }`}
      >
        {bookingStatusLabel()}
      </div>
    </div>
  );
}

export default BookingStatus;
