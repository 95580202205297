// hooks/useCreateNewReservation.ts
import { useDispatch, useSelector } from 'react-redux';
import { baseStore, RootState } from '../store'; // Adjust the import based on your file structure
import Logger from '../helpers/logger';
import ErrorTracker from '../helpers/errorTracker';
import { setGroupsByLocation } from '../slice/bookingCreation/bookingCreationSlice';
import { locationsApiSlice } from '../../api/locations/locationsApiSlice';


const fetchGroupsCall = async(locationId: string, types?: string) => {
    const data = await baseStore.dispatch(
        locationsApiSlice.endpoints.getLocationEquipment.initiate({
            locationId: locationId,
            types
        })
    )
    return data;
};

export const useFetchGroups = (types?: string) => {
  const selectedLocation = useSelector((state: RootState) => state.bookingCreation.selectedLocation);
  const dispatch = useDispatch();

  const fetchGroups = async () => {
    try {
        if (selectedLocation){
            const { data: groups } = await fetchGroupsCall(selectedLocation.id, types);
            console.log('Groups fetched successfully', groups);
            if (groups) {
                dispatch(setGroupsByLocation({ locationId: selectedLocation.id, groups: groups }))
            }
        }
    } catch (error) {
      Logger.for('createNewReservation').error(error);
      ErrorTracker.trackException(error as Error);
      // Handle the error state or dispatch error actions if necessary
    }
  };

  return fetchGroups;
};
