import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ItemRetrievalStartedPayload {
  locationId: string;
}

export interface SenderDetails {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}
export interface PickupDetails {
  senderDetails: SenderDetails;
  pickupLocation: string;
  googleLink: string;
  reservedLocker: number;
  pickupDateTime: string;
}

export interface ItemRetrievalStepChangedPayload {
  step: number;
  nextEnabled: boolean;
}

export interface ItemRetrievalFlowPayload {
  itemRetrievalFlow: string[];
}

interface ContactData {
  sub: string;
  firstname: string;
  telephone: string;
  email: string;
  lastname: string;
}

interface RecipientData {
  firstname: string;
  telephone: string;
  email: string;
  lastname: string;
}

interface Item {
  fromDate: string;
  amount: number;
  isFullDay: boolean;
  price: number;
  groupId: string;
  toDate: string;
  id: string;
  rental?: {
    id: string;
    state: string;
    orderId: string;
    orderItemId: string;
    doors: Array<{
      depotId: string;
      door: number;
      assetId: string;
    }>;
    expirationDate: string;
    token: string;
    startedAtDate: string;
    isActive: boolean;
  };
}

interface PickupDetailsResponseDataPropType {
  id: string;
  contactData: ContactData;
  createdDate: string;
  reservationId: string;
  createdBy: string;
  locationId: string;
  service: string;
  recipientData: RecipientData;
  items: Item[];
  bookingNumber: string;
  iat: number;
  exp: number;
  location: string;
  googleLink: string;
}

export interface itemRetrievalState {
  locationId: string | null;
  pickupDetailsResponseData: PickupDetailsResponseDataPropType | null;
  step: number;
  nextEnabled: boolean;
  isAgreed: boolean;
  pickupDetails: PickupDetails;
  itemRetrievalFlow: string[];
  receivedEmail: string;
  receivedQRCodeNumber: string;
  isReceiverEmailValid: boolean;
  isLocationValid: boolean;
  isCameraDisallowed: boolean;
  isQRCodeNumberValid: boolean;
  receiverEmailValidationMessage: string;
}

const initialState: itemRetrievalState = {
  locationId: null,
  step: 0,
  nextEnabled: false,
  itemRetrievalFlow: [],
  isAgreed: false,
  pickupDetails: {
    senderDetails: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    pickupLocation: "",
    googleLink: "",
    reservedLocker: 0,
    pickupDateTime: "",
  },
  receivedEmail: "",
  receivedQRCodeNumber: "",
  isReceiverEmailValid: false,
  isCameraDisallowed: false,
  isLocationValid: false,
  isQRCodeNumberValid: false,
  receiverEmailValidationMessage: "",
  pickupDetailsResponseData: null,
};

const itemRetrievalSlice = createSlice({
  name: "itemRetrieval",
  initialState,
  reducers: {
    itemRetrievalFlowChanged: (
      state,
      action: PayloadAction<ItemRetrievalFlowPayload>
    ) => {
      state.itemRetrievalFlow = [...action.payload.itemRetrievalFlow];
    },
    setPickupDetailsResponseData: (
      state,
      action: PayloadAction<PickupDetailsResponseDataPropType>
    ) => {
      const itemData = action.payload;
      const pickupData = {
        senderDetails: {
          firstName: itemData.contactData.firstname ?? "",
          lastName: itemData.contactData.lastname ?? "",
          phone: itemData.contactData.telephone ?? "",
          email: itemData.contactData.email ?? "",
        },
        pickupLocation: itemData.location ?? "",
        googleLink: itemData.googleLink ?? "",
        reservedLocker: itemData.items?.[0].rental?.doors?.[0].door ?? 0,
        pickupDateTime: itemData.items?.[0].toDate ?? "",
      };
      state.pickupDetails = pickupData;
      state.pickupDetailsResponseData = action.payload;
    },
    setHasAgreedToConcent: (state) => {
      state.isAgreed = true;
    },

    navigatedToPreviousStep: (state) => {
      const step = state.step;
      switch (step) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          state.step = 0;
          break;
        case 6:
          state.step = 5;
          break;
        default:
          state.step = 0;
      }
    },

    setNextButtonClick: (state) => {
      state.step += 1;
    },
    setSuccessMessageNextButtonClick: (state) => {
      state.step = 0;
    },
    setEnterQRCodeButtonClick: (state) => {
      state.step = 2;
    },

    setPickupDetails: (state, action: PayloadAction<PickupDetails>) => {
      state.pickupDetails = action.payload;
    },
    setIsCameraDisallowed: (state, action: PayloadAction<boolean>) => {
      state.isCameraDisallowed = action.payload;
    },
    setIsLocationValid: (state, action: PayloadAction<boolean>) => {
      state.isLocationValid = action.payload;
      state.step = 3;
    },

    retrievalItemStarted: (
      state,
      action: PayloadAction<ItemRetrievalStartedPayload>
    ) => {
      state.locationId = action.payload.locationId;
    },
    itemRetrievalStepChanged: (
      state,
      action: PayloadAction<ItemRetrievalStepChangedPayload>
    ) => {
      state.step = action.payload.step;
      state.nextEnabled = action.payload.nextEnabled;
    },
    setReceivedEmail: (state, action: PayloadAction<string>) => {
      state.receivedEmail = action.payload.trim();
      const receiverEmail =
        state.pickupDetailsResponseData?.recipientData.email;
      if (
        (state.isReceiverEmailValid = state.receivedEmail === receiverEmail)
      ) {
        state.nextEnabled = true;
        state.receiverEmailValidationMessage = "Email validated successfully";
      } else {
        state.nextEnabled = false;
        state.receiverEmailValidationMessage = "Email validation failed";
      }
    },
    setReceivedQRCodeNumber: (state, action: PayloadAction<string>) => {
      state.receivedQRCodeNumber = action.payload.trim();
    },
  },
});

export const {
  retrievalItemStarted,
  itemRetrievalStepChanged,
  setHasAgreedToConcent,
  setPickupDetails,
  setIsCameraDisallowed,
  itemRetrievalFlowChanged,
  navigatedToPreviousStep,
  setNextButtonClick,
  setSuccessMessageNextButtonClick,
  setPickupDetailsResponseData,
  setReceivedEmail,
  setIsLocationValid,
  setReceivedQRCodeNumber,
  setEnterQRCodeButtonClick,
} = itemRetrievalSlice.actions;
export default itemRetrievalSlice.reducer;
