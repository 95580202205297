import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../layout/components/CustomButton';

const ErrorViewWrapper = styled(Box)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
}))

const ActionsWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    textAlign: 'center',
}))

const BackButton = styled(CustomButton)(({ theme }) => ({
    marginTop: theme.spacing(1),
}))

const TryAgainButton = styled(CustomButton)(({ theme }) => ({
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
    },
}))

interface InitializationErrorInfoProps {
    showTryAgain: boolean;
    onBackClick: () => void;
    onTryAgainClick: () => void;
}

const InitializationErrorInfo = (props: InitializationErrorInfoProps) => {
    const { t } = useTranslation();

    return (
        <ErrorViewWrapper>
            <Typography variant={'body1'} align={'center'}>
                {t('book_location.error.payment_initialization_info')}
            </Typography>
            <ActionsWrapper >
                <BackButton
                    isButtonDark={true}
                    fullWidth={true}
                    onClick={props.onBackClick}
                    fontSize={"18px"}
                    children={t('book_location.payment.go_to_locations')}
                />
                {props.showTryAgain && (
                    <TryAgainButton
                        isButtonDark={true}
                        fullWidth={true}
                        onClick={props.onTryAgainClick}
                        fontSize={"18px"}
                        children={t('book_location.payment.try_again')}
                    />
                )}
            </ActionsWrapper>
        </ErrorViewWrapper>
    );
};

export default InitializationErrorInfo;
