import { createApi } from "@reduxjs/toolkit/query/react";
import { VoucherItemProps } from "../../types/voucher";
import axiosBaseQuery from "../../util/axiosBaseQuery";

export const vouchersApiSlice = createApi({
  reducerPath: "vouchersApiSlice",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["voucherItem"],
  endpoints: (builder) => ({
    getVoucherByCodeAndLocationAndGroup: builder.query({
      query: (data: VoucherItemProps) => {
        // Updated to not accept any arguments
        return {
          url: `/voucher/${data.voucherCode}/location/${data.locationId}/group/${data.groupId}`,
          method: "GET"
        };
      },
      providesTags: ["voucherItem"],
    }),
  }),
});

export const {
  useGetVoucherByCodeAndLocationAndGroupQuery,
} = vouchersApiSlice;
