import { Backdrop, CircularProgress, styled } from '@mui/material';
import React from 'react';

interface LoaderOverlayProps {
    open: boolean;
}

const CircularProgressStyled = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.light,
}));

const LoaderOverlay = (props: LoaderOverlayProps) => {
    return (
        <Backdrop open={props.open} style={{ zIndex: 2 }}>
            <CircularProgressStyled />
        </Backdrop>
    );
};

export default LoaderOverlay;
