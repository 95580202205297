import React from "react";
import { Button, ButtonProps, styled } from "@mui/material";

interface CustomButtonProps extends Omit<ButtonProps, "color"> {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  isButtonDark?: boolean;
  color?:
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";
  variant?: "outlined" | "contained";
  minWidth?: number | string;
  style?: React.CSSProperties;
  isOutlined?: boolean;
  fontSize?: string;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isButtonDark",
})<CustomButtonProps>(({ theme, isButtonDark, minWidth, isOutlined, fontSize }) => ({
  maxWidth: 1000,
  fontSize: fontSize ? fontSize : theme.typography.button.fontSize,
  fontWeight: theme.typography.button.fontWeight,
  fontStyle: theme.typography.button.fontStyle,
  lineHeight: theme.typography.button.lineHeight,
  height: theme.typography.button.height,
  textTransform: "none",
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  color:
    isButtonDark
      ? theme.palette.action.selected
      : theme.palette.primary.contrastText,
  // color: isButtonDark ? theme.palette.primary.main : theme.palette.primary.dark,
  backgroundColor: isButtonDark
    ? theme.palette.primary.light
    : theme.palette.primary.dark,
  border: isOutlined
    ? `1px solid ${theme.palette.primary.light}`
    : isButtonDark
      ? "1px solid white"
      : "none",
  // border: isButtonDark ? "1px solid white" : "none",
  minWidth: minWidth,
  "&:hover": {
    backgroundColor: isButtonDark
      ? theme.palette.secondary.contrastText
      : theme.palette.primary.dark,
    color: isButtonDark
      ? theme.palette.action.selected
      : theme.palette.primary.main,
  },
  "&.Mui-disabled": {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.action.disabledBackground, // Custom background color when disabled
    boxShadow: "none",
  },
}));

const CustomButton: React.FC<CustomButtonProps> = React.memo(({
  isButtonDark = false,
  className,
  variant,
  onClick,
  disabled,
  children,
  color = "primary",
  style,
  fontSize,
  ...otherProps
}) => {
  return (
    <StyledButton
      className={className}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      fullWidth
      color={color}
      isButtonDark={isButtonDark}
      style={style}
      fontSize={fontSize}
      {...otherProps}
    >
      {children}
    </StyledButton>
  );
});

export default CustomButton;
