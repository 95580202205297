import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FilteredSelect from "../../common/components/LocationSelectionStep/FilteredSelect";
import { RootState } from "../../../../redux/store";
import CustomButton from "../../../layout/components/CustomButton";
import { setNextButtonClick } from "../../../../redux/slice/temporaryStorage/temporaryStorageSlice";
import OrderDataStep from "../../equipmentRental/OrderDataStep";

export default function DateDurationSelection() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locations = useSelector(
    (state: RootState) => state.booking.useCaseLocations
  );
  const { hasSelectedLocation, orderData } = useSelector(
    (state: RootState) => state.bookingCreation
  );

  const nextEnabled = useSelector(
    (state: RootState) => state.bookingCreation.nextEnabled
  );

  const handleClickNext = () => {
    dispatch(setNextButtonClick());
  };
  return (
    <div className="flex flex-col items-center justify-center px-5">
      <OrderDataStep />
      <div className="fixed flex w-full max-w-md px-5 bottom-4 m-auto items-center justify-center">
        <CustomButton
          isButtonDark={true}
          fullWidth={true}
          disabled={!orderData.duration}
          onClick={handleClickNext}
          isOutlined={true}
          children={t(
            "temporary_storage.new_booking.location.next_button_title"
          )}
        />
      </div>
    </div>
  );
}
