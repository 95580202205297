import React, { useState } from 'react';
import { Box, Grow, IconButton, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import CP12BoxDoor from './CP12BoxDoor';
import KoluluBoxInfoBox from '../KolulaBoxInfoBox';
import { OrderId } from '../../../../types/order';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { useLocation } from 'react-router';
import routes from '../../../../routes/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

/* Layout
    9 | 10 | 11 | 12
    5 | 6  | 7  | 8
    1 | 2  | 3  | 4
 */

const RootBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'black',
    width: '243px',
    height: '280px',
    margin: '0 auto',
    // boxSizing: 'border-box',
    position: 'relative',
    borderRadius: '8px', // Add border-radius for rounded corners
    border: '1px solid black', // Add a solid border
    overflow: 'hidden', // Add overflow to handle scrollbars or content overflow
    transform: 'scale(135%) translateY(45px)',
}));

const DoorWrapper = styled(Box)({
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    position: 'relative',
});

const RectangleBox = styled(Box)(({ theme }) => ({
    width: '243px',
    height: '40px',
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const InfoButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.dark,
    width: '30px',
    height: '30px',
    position: 'absolute',
    right: 5,
    // top: 0,
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.dark,
    fontSize: '13px',
    fontWeight: 'bold',
    marginLeft: '10px',
}));

interface CP12BoxProps {
    orderId?: OrderId;
    activeDoors: number[];
    title: string;
}

const ShadowOverlay = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9,
}));

  
const CP12Box = (props: CP12BoxProps) => {
    const [infoOpen, setInfoOpen] = useState<boolean>(false);
    const enableInfo = Boolean(props.orderId);


    return (
        <>
            <RootBox>
                <DoorWrapper>
                    <RectangleBox>
                        <TitleTypography>{props.title}</TitleTypography>
                        {enableInfo && (
                            <InfoButton onClick={() => setInfoOpen(true)}>
                                <InfoOutlined fontSize='small' />
                            </InfoButton>
                        )}
                    </RectangleBox>
                    {/* TOP ROW */}
                    <CP12BoxDoor top={42} left={183} height={60} doorNumber={12} active={props.activeDoors.includes(12)} />
                    <CP12BoxDoor top={42} left={122} height={60} doorNumber={11} active={props.activeDoors.includes(11)} />
                    <CP12BoxDoor top={42} left={61} height={60} doorNumber={10} active={props.activeDoors.includes(10)} />
                    <CP12BoxDoor top={42} left={0} height={60} doorNumber={9} active={props.activeDoors.includes(9)} />
                    {/* MIDDLE ROW */}
                    <CP12BoxDoor top={103} left={183} height={60} doorNumber={8} active={props.activeDoors.includes(8)} />
                    <CP12BoxDoor top={103} left={122} height={60} doorNumber={7} active={props.activeDoors.includes(7)} />
                    <CP12BoxDoor top={103} left={61} height={60} doorNumber={6} active={props.activeDoors.includes(6)} />
                    <CP12BoxDoor top={103} left={0} height={60} doorNumber={5} active={props.activeDoors.includes(5)} />
                    {/* BOTTOM ROW */}
                    <CP12BoxDoor top={164} left={183} height={120} doorNumber={4} active={props.activeDoors.includes(4)} />
                    <CP12BoxDoor top={164} left={122} height={120} doorNumber={3} active={props.activeDoors.includes(3)} />
                    <CP12BoxDoor top={164} left={61} height={120} doorNumber={2} active={props.activeDoors.includes(2)} />
                    <CP12BoxDoor top={164} left={0} height={120} doorNumber={1} active={props.activeDoors.includes(1)} />
                </DoorWrapper>
            </RootBox>
            {infoOpen &&
                <ShadowOverlay onClick={() => setInfoOpen(false)}>
                    <KoluluBoxInfoBox onClose={() => setInfoOpen(false)} orderId={props.orderId!}/>
                </ShadowOverlay>
            }
        </>
    );
};

export default CP12Box;
