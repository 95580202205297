import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderOverlay from "../../layout/components/LoaderOverlay";
import BookLocationHeader from "../common/util/BookLocationHeader";
import PersonalDataStep from "./PersonalDataStep";
import TenantLogo from "../../layout/components/TenantLogo";
import BookLocationNavigateNextAction from "../common/util/BookLocationNavigateNextAction";
import BookLocationNavigateBackAction from "../common/util/BookLocationNavigateBackAction";
import BookLocationCancelButton from "../common/util/BookLocationCancelButton";
import GeneralDialog from "../../common/GeneralDialog";
import { Box } from "@mui/material";
import { baseStore, RootState } from "../../../redux/store";
import { usePrevious } from "../../../util/usePrevious";
import GTM from "../../../util/gtm";
import {
  addAnotherGroup,
  bookingCreationCompleted,
  bookingFlowChanged,
  bookingStepChanged,
  dateSelectedClear,
  durationSelectedClear,
  setAvailabilities,
  setUseCase,
} from "../../../redux/slice/bookingCreation/bookingCreationSlice";
import { styled } from "@mui/system";
import LocationSelectionStep from "./LocationSelectionStep";
import OrderDataStep from "./OrderDataStep";
import { availabilitiesApiSlice } from "../../../api/availabilities/availabilitiesApiSlice";
import moment from "moment-timezone";
import ConfirmationStep from "./ConfirmationStep";
import { useCreateNewReservation } from "../../../redux/flows/createReservationFlow";
import { useRemoveReservation } from "../../../redux/flows/removeReservationFlow";
import { useFetchGroups } from "../../../redux/flows/fetchGroupsFlow";
import PaymentStep from "./PaymentStep";
import routes from "../../../routes/routes";
import { useApplyCoupon } from "../../../redux/flows/applyCouponFlow";
import RecipientDataStep from "./RecipientDataStep";
import StorageSelectionStep from "./StorageSelectionStep";
import Header from "../../layout/components/Header";
import CloseIcon from "../../../components/icons/CloseIcon";

export enum BookingCreationStep {
  locationSelection,
  assetGroupSelection,
  orderData,
  personalData,
  recipientData,
  confirmation,
  paymentTypeSelection,
  payment,
}

const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  color: "white",
  height: "100%",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.default,
  paddingBottom: `calc(${theme.spacing(1)}px + env(safe-area-inset-bottom, 0))`,
  display: "flex",
  flexDirection: "column",
}));

const HeaderWrapper = styled(Box)({
  position: "relative",
  marginTop: "10px",
});

const StepWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "100%",
  maxWidth: 1000,
  margin: "0 auto",
  overflowY: "auto",
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up("lg")]: {
    paddingTop: theme.spacing(6),
  },
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  width: "calc(100% - 42px)",
  maxWidth: 1000,
  margin: "0 auto",
  paddingBottom: theme.spacing(5),
  // position: 'fixed',
  bottom: 10,
  left: 0,
  right: 0,
  [theme.breakpoints.up("lg")]: {
    paddingBottom: theme.spacing(5),
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  width: "150px",
  marginTop: theme.spacing(1),
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
}));

const BackButton = styled(Box)({
  boxSizing: "border-box",
  marginRight: "auto",
});

export const CancelButton = styled(Box)({
  boxSizing: "border-box",
  marginLeft: "auto",
});

interface BookLocationDialogProps {
  returnPage: string;
}

const BookLocationPage = (props: BookLocationDialogProps) => {
  const stepWrapperRef = useRef<HTMLElement>();
  const [pageDialogOpen, setPageDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const step = useSelector((state: RootState) => state.bookingCreation.step);

  const previousStep = usePrevious(step);
  const orderData = useSelector(
    (state: RootState) => state.bookingCreation.orderData
  );
  const reservations = useSelector(
    (state: RootState) => state.bookingCreation.reservations
  );
  const selectedLocation = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  );
  // const { data: equipements } = useGetLocationEquipmentQuery({ locationId: selectedLocation?.id });
  const assetGroupId = useSelector(
    (state: RootState) => state.bookingCreation.assetGroupId
  );
  const appliedCouponCode = useSelector(
    (state: RootState) => state.bookingCreation.appliedCouponCode
  );

  const createNewReservation = useCreateNewReservation();
  const applyCoupon = useApplyCoupon();
  const removeReservation = useRemoveReservation();
  const fetchGroups = useFetchGroups("p2p");

  const paymentProvider = useSelector(
    (state: RootState) => state.bookingCreation.payment.provider
  );

  useEffect(() => {
    // dispatch(loadBookingFlow({}));
    stepWrapperRef.current = document.getElementById(
      "bookingProcessStepWrapper"
    )!;
    // @todo body scroll lock breaks the phone number input. Maybe remove it entirely?
    //disableBodyScroll(stepWrapperRef.current);
    //return () => enableBodyScroll(stepWrapperRef.current!);
    if (step === BookingCreationStep.locationSelection) {
      dispatch(
        bookingStepChanged({
          step: BookingCreationStep.locationSelection,
          nextEnabled: !!selectedLocation,
        })
      );

      // Set the usecase
      dispatch(setUseCase({ useCase: "p2p" }));

      // Set the bookingFlow
      dispatch(
        bookingFlowChanged({
          bookingFlow: [
            "locationSelection",
            "assetGroupSelection",
            "orderData",
            "personalData",
            "recipientData",
            "confirmation",
            "paymentTypeSelection",
            "payment",
          ],
        })
      );
    }
  }, []);

  const fetchAssetGroupAvailabilities = async (
    assetGroupId: string,
    from: Date,
    to: Date
  ) => {
    const data = await baseStore.dispatch(
      availabilitiesApiSlice.endpoints.fetchAssetGroupAvailabilities.initiate({
        assetGroupId: assetGroupId,
        from: from,
        to: to,
      })
    );
    return data;
  };

  useEffect(() => {
    if (
      reservations.length === 0 &&
      step === BookingCreationStep.confirmation
    ) {
      dispatch(bookingCreationCompleted());
    }
  }, [reservations]);

  useEffect(() => {
    const manageReduxData = async () => {
      if (step === BookingCreationStep.assetGroupSelection) {
        // Clean data
        dispatch(dateSelectedClear());
        dispatch(durationSelectedClear());
        await fetchGroups();
      } else if (
        step === BookingCreationStep.orderData &&
        assetGroupId &&
        previousStep &&
        !(previousStep > BookingCreationStep.orderData)
      ) {
        // TODO change to user input
        const fromDate = moment().tz("Europe/Berlin").toDate();
        const toDate = moment()
          .tz("Europe/Berlin")
          .add(1, "months")
          .endOf("month")
          .toDate();
        const { data: availabilities } = await fetchAssetGroupAvailabilities(
          assetGroupId,
          fromDate,
          toDate
        );

        if (availabilities) {
          dispatch(setAvailabilities(availabilities.availabilities));
        }
      }
      // // previous step is orderData, current step is personal data
      if (
        previousStep === BookingCreationStep.recipientData &&
        step === BookingCreationStep.confirmation
      ) {
        if (reservations.length === 0) {
          const processedReservation = await createNewReservation();

          if (processedReservation && appliedCouponCode) {
            const reservationIds = reservations.map(
              (reservation) => reservation.reservationId
            );
            reservationIds.push(processedReservation.reservationId!);
            await applyCoupon(reservationIds, appliedCouponCode);
          }
        }
      }

      if (
        step === BookingCreationStep.orderData &&
        assetGroupId &&
        previousStep &&
        previousStep > BookingCreationStep.orderData
      ) {
        if (reservations.length > 0) {
          // user navigated back, so delete reservation
          await removeReservation();
        }
        const fromDate = moment().tz("Europe/Berlin").toDate();
        const toDate = moment()
          .tz("Europe/Berlin")
          .add(1, "months")
          .endOf("month")
          .toDate();
        const { data: availabilities } = await fetchAssetGroupAvailabilities(
          assetGroupId,
          fromDate,
          toDate
        );
        if (availabilities) {
          dispatch(setAvailabilities(availabilities.availabilities));
        }
      }

      GTM.trackEecCheckoutStep(step);
    };
    manageReduxData();
  }, [step]);

  const headerText = useMemo(() => {
    switch (step) {
      case BookingCreationStep.locationSelection:
        return t("book_location.select_location");
      case BookingCreationStep.assetGroupSelection:
        return t("book_location.p2p.locker_size");
      case BookingCreationStep.orderData:
        return t("book_location.reservation_details.header_text");
      case BookingCreationStep.recipientData:
        return t("book_location.recipient_data.header_text");
      case BookingCreationStep.confirmation:
        return t("book_location.confirmation.header_text");
      case BookingCreationStep.personalData:
        return t("book_location.personal_data.header_text");

      default:
        return "";
    }
  }, [step, orderData.date]);

  const isLoading = () => {
    if (
      step === BookingCreationStep.personalData ||
      step === BookingCreationStep.confirmation
    ) {
      return false;
    }

    return false;
  };

  const closePage = () => {
    // history.goBack();
    navigate(routes.home);
    dispatch(bookingCreationCompleted());
  };

  const handleAddAnotherEquipment = () => {
    dispatch(addAnotherGroup({}));
  };

  const isLastStep = step === BookingCreationStep.payment;
  return (
    <Root>
      <Header>
        <div className="size-12 absolute left-0">
          <BookLocationNavigateBackAction />
        </div>
        <TenantLogo />
        <button
          className="size-12 absolute right-0 text-foreground/90"
          onClick={() => setPageDialogOpen(true)}
        >
          <CloseIcon />
        </button>
      </Header>
      <Box sx={{ marginTop: "20px" }}>
        <BookLocationHeader text={headerText} />
      </Box>
      <StepWrapper id={"p2pBookingProcessStepWrapper"}>
        {step === BookingCreationStep.locationSelection && (
          <LocationSelectionStep />
        )}
        {step === BookingCreationStep.assetGroupSelection && (
          <StorageSelectionStep />
        )}
        {step === BookingCreationStep.orderData && <OrderDataStep />}
        {step === BookingCreationStep.recipientData && <RecipientDataStep />}
        {step === BookingCreationStep.confirmation && (
          <ConfirmationStep onAddAnotherEquipment={handleAddAnotherEquipment} />
        )}
        {step === BookingCreationStep.personalData && <PersonalDataStep />}
        {step === BookingCreationStep.payment && <PaymentStep />}
      </StepWrapper>
      <ActionsWrapper>
        {!isLastStep && <BookLocationNavigateNextAction />}
      </ActionsWrapper>
      <LoaderOverlay open={isLoading()} />
      <GeneralDialog
        open={pageDialogOpen}
        onClose={() => setPageDialogOpen(false)}
        onContinue={closePage}
        content={t("use_order.closing_dialog.content")}
      />
    </Root>
  );
};

export default BookLocationPage;
