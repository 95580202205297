import { Typography, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DoorOpenAnimation from '../../../useOrder/box/DoorOpenAnimation';
import CustomButton from "../../../layout/components/CustomButton";
import ConfirmRetrievalDialog from '../../../useOrder/steps/ConfirmationRetrievalDialog';
import { useUpdateItemState } from '../../../../redux/flows/updateItemStateFlow';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import LoaderOverlay from '../../../layout/components/LoaderOverlay';
import { setNextButtonClick } from '../../../../redux/slice/senderRetrieval/senderRetrievalSlice';

const styles = {
    actions: (theme: any) => ({
        display: "flex",
        paddingTop: theme.spacing(1),
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "10%",
    }),
    buttonContainer: {
        position: "fixed",
        bottom: "16px",
        left: "0",
        right: "0",
        display: "flex",
        justifyContent: "center",
        padding: "0 16px",
    },
}

const LockerOpen = () => {
    const { t } = useTranslation();
    const [confirmPlacementDialogOpen, setConfirmPlacementDialogOpen] = useState<boolean>(false);
    const order = useSelector(
        (state: RootState) => state.booking.selectedBooking
    );
    const rental = order?.items[0].rental ?? undefined;
    const updateItemState = useUpdateItemState(rental, 'sender_retrieved_confirmed');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()

    const onConfirmItemPlacement = async () => {
        if (!rental) {
            // eslint-disable-next-line no-console
            console.error("Rental not found");
            return;
        }
        setLoading(true);
        try {
            await updateItemState();
            dispatch(setNextButtonClick(3));
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <>
            <DoorOpenAnimation />
            <Typography
                variant="h2"
                sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                    margin: '20px 0',
                    fontSize: (theme) => theme.typography.h5.fontSize,
                    fontWeight: (theme) => theme.typography.h5.fontWeight,
                    textAlign: 'center',
                    paddingBottom: '40px',
                    paddingX: '20px',
                    lineHeight: '1.5',
                    whiteSpace: 'pre-line'
                }}
            >
                {t('senderRetrieval.lockerOpen.description')}
            </Typography>
            <Box sx={styles.actions}>
                <Box sx={styles.buttonContainer}>
                    <CustomButton
                        isButtonDark={true}
                        onClick={() => setConfirmPlacementDialogOpen(true)}
                        fullWidth={true}
                        style={{ textTransform: "none" }}
                        children={t("senderRetrieval.lockerOpen.confirm_retrieval")}
                    />
                </Box>
            </Box>
            <LoaderOverlay open={loading} />
            <ConfirmRetrievalDialog
                open={confirmPlacementDialogOpen}
                onClose={() => setConfirmPlacementDialogOpen(false)}
                onConfirmPlacement={onConfirmItemPlacement}
            />
        </>
    );
};

export default LockerOpen;
