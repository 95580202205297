import CameraImage from "./../../../assets/icons/no-camera 1.png";
import { useTranslation } from "react-i18next";

function CameraAccessDenied() {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col m-auto items-center justify-center">
      <div className="w-32 h-32 rounded-full bg-[#C9F7F9] 
      flex items-center justify-center mb-6">
        <img
          src={CameraImage}
          alt="no camera"
          className="object-cover"
          style={{ width: "93px", height: "93px" }}
        />
      </div>
      <div className="text-black text-lg font-medium mb-2">
        {t("itemRetrieval.confirmLocation.camera.error")}
      </div>
      <div className="text-black text-lg font-normal">
        {t("itemRetrieval.confirmLocation.camera.error_info")}
      </div>
    </div>
  );
}

export default CameraAccessDenied;
