import { Box } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import { Order } from '../../../types/order';
import ContainerBox from '../box/ContainerBox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { BookingSummary } from '../../../types/bookingCreation';

interface DoorsOverviewProps {
    orderFinished: boolean;
    rental?: any;
    finishOrderDialogOpen: boolean;
    minutesSinceOrderStarted: number;
    onCloseFinishOrderDialog: () => void;
}

const DoorsOverview = (props: DoorsOverviewProps) => {
    const [minutesToEnd, setMinutesToEnd] = useState<number>(0);
    const order = useSelector((state: RootState) => state.booking.selectedBooking);

    const updateTimes = (order: BookingSummary) => {
        setMinutesToEnd(
            (new Date(order.items[0].toDate!).getTime() - new Date().getTime()) / 60000,
        );
    };

    useLayoutEffect(() => {
        if (order) {
            updateTimes(order);
        }
        const interval = setInterval(() => {
            if (order) {
                updateTimes(order);
            }
        }, 30000);
        return () => clearInterval(interval);
    }, [order]);

    if (!order) {
        // TODO show error or go back to order selection
        return null;
    }

    if (!order.items[0].rental) {
        // TODO show error or go back to order selection
        return null;
    }

    return (
        <Box
            sx={{
                margin: 'auto',
                minWidth: '274px',
                height: '100%',
            }}
        >
            <ContainerBox
                activeDoors={
                    order.items[0].rental && order.items[0].rental.doors
                        ? order.items[0].rental.doors.map((doorData: any) => doorData.door)
                        : []
                }
                order={order}
            />
          
        </Box>
    );
};

export default DoorsOverview;
