import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { LocationPros } from "../../../../../types/baseTypes";
import { RootState } from "../../../../../redux/store";
import {
  setHasSelectedLocation,
  setSelectedLocation,
} from "../../../../../redux/slice/bookingCreation/bookingCreationSlice";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

export default function FilteredSelect({
  locations,
}: {
  locations: LocationPros[];
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const selectedLocation = useSelector(
    (state: RootState) => state.bookingCreation.selectedLocation
  );
  const [inputValue, setInputValue] = React.useState("");

  const allowTyping = locations.length > 5;

  const handleLocationChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>
  ) => {
    if (value === null) {
      dispatch(setSelectedLocation(null));
      dispatch(setHasSelectedLocation(false));
    } else {
      const selectedLocationName = value;
      const selectedLocation = locations?.find(
        (location) => location.name === selectedLocationName.split(",")[0]
      );

      if (selectedLocation) {
        dispatch(setSelectedLocation(selectedLocation));
        dispatch(setHasSelectedLocation(true));
      }
    }
  };

  React.useEffect(() => {
    if (selectedLocation) {
      dispatch(setSelectedLocation(selectedLocation));
      dispatch(setHasSelectedLocation(true));
    } else {
      dispatch(setSelectedLocation(null));
      dispatch(setHasSelectedLocation(false));
    }
  }, [selectedLocation, dispatch]);

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setInputValue(value);
  };

  return (
    <div>
      <Autocomplete
        className="rounded-full"
        disablePortal
        id="combo-box-demo"
        options={locations.map(
          (location) =>
            `${location.name}, ${location.address.street}, ${location.address.postalCode} ${location.address.city}`
        )}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: selectedLocation?.name
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            },
            "&:hover fieldset": {
              borderColor: selectedLocation?.name
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.primary.light,
            },
          },
          "& .MuiInputBase-input": {
            color: theme.palette.primary.contrastText,
          },
          "& .Mui-focused": {
            color: theme.palette.primary.light,
          },
          "& .MuiSvgIcon-root": {
            color: theme.palette.primary.light, // Change SVG (dropdown arrow) color to red
          },
        }}
        value={
          selectedLocation
            ? `${selectedLocation.name}, ${selectedLocation.address.street}, ${selectedLocation.address.postalCode} ${selectedLocation.address.city}`
            : ""
        }
        onChange={handleLocationChange}
        freeSolo={allowTyping}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("book_location.location")}
            InputProps={{
              ...params.InputProps,
              readOnly: !allowTyping,
              style: { color: "black" },
            }}
          />
        )}
        renderOption={(props, option, state) => (
          <li
            {...props}
            style={{
              backgroundColor: state.selected ? theme.palette.grey[400] : "transparent", 
              color: "black", 
            }}
          >
            {option}
          </li>
        )}
      />
    </div>
  );
}
